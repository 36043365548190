const types = {
    1: "4820",
    2: "4850/48100",
    3: "3.4kWh",
    4: "5.8kWh",
    5: "6.8kWh",
    6: "10225",
    7: "GD305_4820",
    8: "GD305_4850/48100",
    9: "GD305_3.4kWh",
    10: "GD305_5.8kWh",
    11: "GD305_6.8kWh",
    12: "GD305_32Series",
    13: "EnergyPort_GD",
    14: "EnergyPort_STM",
    15: "GD105_1818",
    16: "STACK", 
    17: "KARTING",
    18: "MOS_48V",
    19: "MOS_RGV",
    20: "GD305_1818_AS",
    21: "GD305_1818_HV_BMS",
    22: "BalconyEnergyStorage",
    23: "CommunicationBoard",
    24: "ICES_BCU",
    25: "ICES_RTC",
    26: "ICES_EMS",
}

const inverterProtocolTypes = {
    'Thinkpower': {
        0: 'Universal Firmware',
        1: 'Communication Board Firmware',
        2: 'Control Board Firmware',
    },
    'Sungery': {
        0: 'Universal Firmware',
        1: 'Communication Board Firmware',
        2: 'Control Board Firmware',
    },
    'Senergy': {
        0: 'DSP1',
        1: 'DSP2',
        2: 'DSP3',
        3: 'CSB',
    }
}

const burningTypes = {
    1: "APP",
    2: "BOOT",
}
const allBurningTypes = {
    1: "APP",
    2: "BOOT",
    3: "stm32 bootloader",
    4: "stm32 application", 
    5: "280049 application",
    6: "280049 flash",
}

const all = {
    burningTypes,
    allBurningTypes,
    types,
    inverterProtocolTypes,
};

export default all;