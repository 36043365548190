import http from '../Http';
import { useContext } from 'react';
import utils from '../utils';
import { Modal, Button, Input, Form } from 'antd';


function PasswordChanger (props) {
    const setTip = useContext(utils.tipContext);

    const {show, onClose} = props;

    const [form] = Form.useForm();

    const handleCancle = () => {
        onClose(null);
    }

    const handleSubmit = async () => {
        const ps = await form.validateFields().catch(() => {return null});
        
        if (ps === null) {
            return;
        }

        
        ps.OldPassword = ps.OldPassword || "";
        ps.Password = ps.NewPassword || "";
        

        const resp = await http.post("user/password/update", ps).then(async (r)=>{
            return await r.json();
        }).catch((e) => {
            console.log("[PasswordChanger]", e);
            return null;
        });

        try {
            if (resp.code !== 200) {
                setTip({open:true, severity:"error", msg:(resp && resp.msg) ? resp.msg : "modify error"});
                return
            }

            setTip({open: true, severity: 'success', msg: 'modify success'});

            onClose();
        } catch (e) {
            console.log("[PasswordChanger]", e);

            setTip({open:true, severity:"error", msg: "modify error"});
        }
    }


    return (<Modal 
        title="Add End User"
        visible={show}
        cancelText="Cancel"
        okText="Submit"
        mask={true}
        maskClosable={false}
        width={600}
        onCancel={handleCancle}
        onOk={handleSubmit}
        footer={[
            <Button 
                key="Clear" 
                onClick={() => {
                    form.resetFields();
                }}
            >Clear</Button>,
            <Button key="Cancel" onClick={handleCancle}>Cancel</Button>,
            <Button key="Submit" type="primary" onClick={handleSubmit}>Submit</Button>
        ]}
    >
        <Form
            labelAlign="right"
            labelCol={{ span: 5 }}
            wrapperCol={{ span: 17 }}
            form={form}
        >
            <Form.Item 
                name="OldPassword"
                label="Old Password"
                rules={[{ required: true }]}
            >
                <Input placeholder="Enter Old Password"/>
            </Form.Item>
            <Form.Item 
                name="NewPassword"
                label="New Password"
                rules={[{ required: true, min: 6 }]}
            >
                <Input placeholder="Enter New Password"/>
            </Form.Item>
        </Form>
    </Modal>);
}

export default PasswordChanger;