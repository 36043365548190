import utils from "../utils";

function TitleBar({ title, data }) {
    return (
        <>
            <span style={{ display: 'inline-block', height: '16px', border: '3px solid #1B8AC5', marginRight: '4px', transform: 'translateY(3px)' }}></span>
            <span style={{ color: '#1B8AC5', fontSize: '16px', fontWeight: 'bold', marginRight: '8px' }}>{title}</span>
            <span style={{ fontSize: '10px' }}>{data ? utils.dateFormat("YYYY-mm-dd HH:MM:SS", new Date()) : "yyyy-mm-dd hh:mm:ss"}</span>
        </>
    )
}

export default TitleBar;