import React, { useEffect, useState, useContext, useRef } from 'react';
import { Select } from 'antd';
import http from '../../Http';
import utils from '../../utils';
import RealtimeMonitor from './RealtimeMonitor';
import DataAnalysis from './DataAnalysis/DataAnalysis';
import SiteManagement from './SiteManagement';
import Outline from './Outline';
import RemoteSetting from './RemoteSetting/RemoteSetting';
import Configuration from './Configuration/Configuration.js';
import './RealtimeMonitor.css';
import './DataAnalysis/DataAnalysis.css';
import './Outline.css';
import './RemoteSetting/RemoteSetting.css';

function Inverter2(props) {
    const{activeMenu} = props;

    const setTip = useContext(utils.tipContext)

    const [inverterSelectOptions, setInverterSelectOptions] = useState([])  //所有电站列表
    const [SN, setSN] = useState(null)  //当前选择的逆变器SN
    const [protocols, setProtocols] = useState({}) 
    const [protocol, setProtocol] = useState(null)  //当前逆变器的protocol
    const [invModel, setInvModel] = useState(null) 

    const [isBalcony, setIsBalcony] = useState(false)

    //获取电站列表
    useEffect(() => {
        let active = true;

        (async () => {
            let resp = await http.post("integrated-inverter/list", {
                PageNo: 0,
                PageSize: 10000,
            }).then(async (r) => {
                return await r.json();
            }).catch((e) => {
                return null;
            });

            if (!active) {
                return;
            }
            if (resp === null || resp.code !== 200) {
                setTip({ open: true, severity: "error", msg: (resp && resp.msg) ? resp.msg : "load inverter list error" });
                return;
            }
            
            // let resp2 = await http.post("balcony/device/list", {  //阳台经济
                
            // }).then(async (r) => {
            //     return await r.json();
            // }).catch((e) => {
            //     return null;
            // });

            // if (!active) {
            //     return;
            // }
            // if (resp2 === null || resp2.code !== 200) {
            //     setTip({ open: true, severity: "error", msg: (resp && resp.msg) ? resp.msg : "load balcony device list error" });
            //     return;
            // }

            //逆变器选择框
            const protocolSet = {}
            const ls = resp.data.map((v, index) => {
                protocolSet[v.SerialName] = v.Protocol
                protocolSet[v.SerialName+"_Model"] = v.Model;
                protocolSet[v.SerialName+'_isBalcony'] = false
                return <Select.Option key={index} value={v.SerialName}>{v.SerialName}</Select.Option>
            })

            // const ls2 = resp2.data.map((v, index) => {
            //     protocolSet[v.SN+'_isBalcony'] = true
            //     return <Select.Option key={v+index} value={v.SN}>{v.SN}</Select.Option>
            // })

            //setInverterSelectOptions([...ls, ...ls2])
            setInverterSelectOptions(ls)
            setProtocols(protocolSet)
        })();

        return () => {
            active = false;
        };

    }, []);

    const handleSelectSN = (sn) => {
        setSN(sn)
        setProtocol(protocols[sn])
        setInvModel(protocols[sn+"_Model"])
        setIsBalcony(protocols[sn+"_isBalcony"])
    }

    return (
        <>
        {/* <div className="inverter-container"> */}
            {
                activeMenu == 0 ? <RealtimeMonitor inverterSelectOptions={inverterSelectOptions} SN={SN} protocol={protocol} model={invModel} isBalcony={isBalcony} handleSelectSN={handleSelectSN} {...props} /> : 
                activeMenu == 1 ? <DataAnalysis inverterSelectOptions={inverterSelectOptions} SN={SN} protocol={protocol} handleSelectSN={handleSelectSN} /> :
                activeMenu == 2 ? <SiteManagement inverterSelectOptions={inverterSelectOptions} SN={SN} protocol={protocol} handleSelectSN={handleSelectSN} /> :
                activeMenu == 3 ? <Outline inverterSelectOptions={inverterSelectOptions} SN={SN} protocol={protocol} handleSelectSN={handleSelectSN} /> :
                activeMenu == 4 ? <RemoteSetting inverterSelectOptions={inverterSelectOptions} SN={SN} protocol={protocol} handleSelectSN={handleSelectSN} /> :
                activeMenu == 5 ? <Configuration inverterSelectOptions={inverterSelectOptions} SN={SN} protocol={protocol} handleSelectSN={handleSelectSN} /> :
                null
            }
        {/* </div> */}
        </>
    )
}

export default Inverter2;