import { useEffect, useRef } from 'react';
import TitleBar from '../utils/TitleBar.js';
import ReactECharts from 'echarts-for-react';

const opt = {
    backgroundColor: 'white',
    xAxis: {
        show: false,
    },
    yAxis: {
        show: false,
    },
    series: [
        {
            name: 'CellVoltage',
            type: 'gauge',
            splitNumber: 0,
            axisLine: {            // 坐标轴线
                lineStyle: {       // 属性lineStyle控制线条样式
                    color: [[0.4, '#1B8AC5'], [1, '#f0f0f0']],
                    width: 8,
                }
            },
            axisTick: {
                show: false,
            },
            axisLabel: {
                show: false,
            },
            splitLine: {
                show: false,
            },
            pointer: {           // 分隔线
                width: 0,
            },
            title: {
                show: false,
            },
            detail: {
                offsetCenter: [0, 0],       // x, y，单位px
                textStyle: {       // 其余属性默认使用全局文本样式
                    fontWeight: 'bold',
                    color: 'black',
                    fontSize: 12,
                    lineHeight: 14,
                },
                show: true,
                formatter: (v) => {
                    return "CellVoltage\n"+v.toFixed(1)+"V";
                }
            },
            data: [{value: 0, name: 'V'}]
        },
    ],
};

function CellVoltage (props) {
    const { data} = props;

    const chart = useRef(null);

    if (opt.series) {
        let s = opt.series[0];
        let value = data ? (data["MinCellVoltage"]/1000): 0
        s.data = [{...s.data[0], value: value}];
        s.axisLine.lineStyle.color[0][0] = Math.abs(value)/6
    }

    useEffect(() => {
        const instance = chart.current.getEchartsInstance();
        if (instance) {
            instance.setOption(opt);
        }
    }, [data])

    return (
        <div style={{
            padding: '10px 15px'
        }}>
            <TitleBar title="Cell Voltage" data={data} />
            <div>
                <ReactECharts ref={chart} option={opt} style={{height: '120px'}} />
            </div>
            <div style={{width: '90%', backgroundColor: '#f0f0f0', borderRadius: '10px', padding: '2px 8px', fontSize: '12px', margin: '5px 8px'}}>
                {'Min: ' + (data ? (data["MinCellVoltage"]/1000).toFixed(3): 0) + 
                'V, C:' + (data ? data["MinCVCluster"]: 0) + 
                ', P:' + (data ? data["MinCVPacket"]: 0) + ', I:' + 
                (data ? data["MinCVIndex"]: 0)}
            </div>
            <div style={{width: '90%', backgroundColor: '#f0f0f0', borderRadius: '10px', padding: '2px 8px', fontSize: '12px', margin: '5px 8px'}}>
                {'Max: ' + (data ? (data["MaxCellVoltage"]/1000).toFixed(3): 0) + 
                'V, C:' + (data ? data["MaxCVCluster"]: 0) + 
                ', P:' + (data ? data["MaxCVPacket"]: 0) + ', I:' + 
                (data ? data["MaxCVIndex"]: 0)}
            </div>
        </div>
    );
}

export default CellVoltage;