import SwapVertIcon from '@material-ui/icons/SwapVert';
import TitleBar from '../utils/TitleBar.js';

function Switch (props) {
    const {data} = props;

    const bs = [];
    let ln = 4;
    if (data) {
        ln = data.ByteCount*4;
    }
    for (let i=0; i<ln; i++) {
        let x = data ? (data[`Route${i+1}`] == undefined ? null : data[`Route${i+1}`]) : null;

        bs.push(
            <div style={{
                margin: 5,
                display: "flex",
                flexDirection: 'column',
                justifyContent: "space-between",
                alignItems: "center",
                backgroundColor:  x==1 ? '#1B8AC5' : '#f0f0f0',
                width: 34,
                height: 300,
                borderRadius: 30,
            }}>
                <div style={{
                    width: 36,
                    height: 36,
                    display: 'flex',
                    justifyContent: "center",
                    alignItems: "center",
                    border: "2px solid #1B8AC5",
                    borderRadius: 28,
                    backgroundColor: 'white',
                }}>
                    <div style={{color:'black',fontSize:14}}>OFF</div>
                </div>
                <SwapVertIcon style={{color: x==1 ? 'white' : '#bbb', fontSize: 50}} />
                <div style={{
                    width: 36,
                    height: 36,
                    display: 'flex',
                    justifyContent: "center",
                    alignItems: "center",
                    border: "2px solid #1B8AC5",
                    borderRadius: 28,
                    backgroundColor: 'white',
                    // opacity: x==1 ? 0 : 1,
                }}>
                    <div style={{color:'black',fontSize:14}}>ON</div>
                </div>
            </div>
        );
    }

    return (
        <div style={{
            padding: '10px 15px'
        }}>
            <TitleBar title="Switch" data={data} />
            <div style={{
                marginTop: '20px',
                backgroundColor: "white",
                flex: "1, 1 1px",
                display: "flex",
                flexDirection: 'row',
                padding: 0,
            }} >
                {bs}
            </div>
        </div>
    );
}

export default Switch;