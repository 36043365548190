import React, { useState } from 'react';
import { TimePicker } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

const HourMinuteEditor = ({value, pendingValue, onChange}) => {
    dayjs.extend(customParseFormat);
    let str = (value??0).toString().padStart(4,"0");
    // str = str.substr(0,2).concat(":", str.substr(-2,2),":00");
    const ot = dayjs(str, "HHmm");

    const onTimeChange = (time, timeString) => {
        const v = time.format("HHmm");
        onChange?.(parseInt(v));
    }

    if (pendingValue!=undefined&&pendingValue!=null) {
        return <TimePicker format={"HH:mm"} 
            value={ot}
            suffixIcon={ <EditOutlined />}
            allowClear={false}
            onChange={onTimeChange}
        />;
    }
    return <TimePicker format={"HH:mm"} 
        value={ot}
        allowClear={false}
        onChange={onTimeChange}
    />;
}

export default HourMinuteEditor;