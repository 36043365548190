function InputPrefix({ label, required, noArrow }) {
    return (
        <div>
            {required ? <span style={{color: 'red', verticalAlign: 'middle'}}>* </span> : null}{label}{' '}
            {!noArrow ? 
                <span style={{display: 'inline-block', width: 0, height: 0, borderLeft: '6px solid black', borderRight: '6px solid transparent', borderTop: '4px solid transparent', borderBottom: '4px solid transparent', transform: 'translateY(-1px)'}}></span> :null
            }

        </div>
    )
}

export default InputPrefix