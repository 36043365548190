import http from '../Http';
import { useEffect, useState, useContext, useRef } from 'react';
import { createTheme } from '@material-ui/core/styles';
import utils from '../utils';
import { Table, Input, Button, Modal, Image } from 'antd';
import { PlusOutlined, DeleteOutlined, EditOutlined, ExclamationCircleOutlined  } from '@ant-design/icons';
import InverterManufacturerAdd from "./InverterManufacturerAdd";
import InverterManufacturerModify from "./InverterManufacturerModify";

const theme = createTheme({});

function InverterManufacturer (props) {
    const setTip = useContext(utils.tipContext);

    const [pageNo, setPageNo] = useState(0);
    const [pageSize, setPageSize] = useState(100);
    const [params, setParams] = useState({
        Name: '',
    });
    const [loading, setLoading] = useState(false);
    const [rows, setRows] = useState([]);
    const [rowTotal, setRowTotal] = useState(0);
    const [tableBodyHeight, setTableBodyHeight] = useState(240);
    const [selectedKeys, setSelectedKeys] = useState([]);
    const [showAdd, setShowAdd] = useState(false);
    const [showModify, setShowModify] = useState(false);
    const [modifyRowIndex, setModifyRowIndex] = useState(null);

    const refTableContainer = useRef();

    useEffect(() => {
        let active = true;
        console.log("InverterManufacturerModify Effect");

        (async () => {
            setLoading(true);
            
            
            let resp = await http.post("inverter-manufacturer/list", {
                PageNo: pageNo,
                PageSize: pageSize,
                ...params,
            }).then(async (r)=>{
                return await r.json();
            }).catch((e)=>{
                console.log("[InverterManufacturer]", e);
                return null;
            });

            if (! active) {
                return;
            }
            
            if (resp === null || resp.code !== 200) {
                setTip({open:true, severity:"error", msg:(resp && resp.msg) ? resp.msg : "load data error"});
                
                setRowTotal(0)
                setRows([]);
        
                return;
            }
            setRowTotal(resp.data.RowTotal);
            setRows(resp.data.Rows);
            setLoading(false);
        })();

        return () => {
            active = false;
            console.log("InverterManufacturer Effect Cancel");
        };
    }, [pageNo, pageSize, params]);

    useEffect(() => {
        const el = refTableContainer.current;

        if (el.objResizer) {
            return;
        }
        
        let obj = document.createElement('object');
        el.objResizer = obj; 
        obj.setAttribute('style',
            'display: block; position: absolute; top: 0; left: 0; height: 100%; width: 100%; overflow: hidden;opacity: 0; pointer-events: none; z-index: -1;');
        obj.onload = function () {
            setTableBodyHeight(calcTableBodyHeight());

            this.contentDocument.defaultView.addEventListener('resize', (evt) => {
                setTableBodyHeight(calcTableBodyHeight());
            });
        };
        obj.type = 'text/html';
        el.appendChild(obj);
        obj.data = 'about:blank';

        return () => {
            if (! el) {
                return
            }

            //
        }
    }, [refTableContainer.current]);

    const handleSearch = (v, e) => {
        setSelectedKeys([]);
        setPageNo(0);
        setParams({
            Name: v || "",
        });
    }

    const renderAction = (v, r, idx) => {
        return (
            <>
                <Button onClick={()=>{handleModify(idx)}} type="link" icon={<EditOutlined  size="small"/>} />
                <Button onClick={()=>{handleDelete(r.ID, r.Name, idx)}} type="link" icon={<DeleteOutlined  size="small"/>} />
            </>
        )
    }

    const handleAdd = () => {
        setShowAdd(true);
    }

    const handleModify = (rowIndex) => {
        setModifyRowIndex(rowIndex);
        setShowModify(true);
    }

    const handleDelete = async (ID, Name, idx) => {
        Modal.confirm({
            title: 'Do you Want to delete this item?',
            icon: <ExclamationCircleOutlined />,
            content: Name,
            onOk: async () => {
                try {
                    const resp = await http.post("inverter-manufacturer/delete", {ID: ID}).then(async(r) => {
                        return await r.json();
                    }).catch((e) => {
                        console.log("[InverterManufacturer]", e);
                        return null;
                    });
        
                    if (resp.code !== 200) {
                        setTip({open:true, severity:"error", msg:(resp && resp.msg) ? resp.msg : "delete error"});
                        return
                    }
                    setTip({open: true, severity: 'success', msg: 'delete success'});
        
                    setRows([...rows.slice(0,idx), ...rows.slice(idx+1)]);
                    setRowTotal(rowTotal-1);
                    selectedKeys.every((v,i) => {
                        if (v === ID) {
                            setSelectedKeys([...selectedKeys.slice(0,i), ...selectedKeys.slice(i+1)]);
                            return false;
                        }
        
                        return true;
                    })
                } catch(e) {
                    console.log("[InverterManufacturer]", e);
        
                    setTip({open:true, severity:"error", msg: "delete error"});
                }
            },
            onCancel() {
                //
            },
        });
    }

    const handleDeleteAllOfSelected = async () => {
        if (selectedKeys.length < 1) {
            return;
        }

        let ns = [];
        selectedKeys.forEach((ID) => {
            rows.every((r) => {
                if (ID === r.ID) {
                    ns.push(<label>{r.Name}</label>);
                    return false;
                }

                return true;
            });
        })
        if (ns.length > 10) {
            ns = ns.slice(0, 10);
            ns.push(<lable>...</lable>);
        }

        Modal.confirm({
            title: 'Do you Want to delete these items?',
            icon: <ExclamationCircleOutlined />,
            content: (<div style={{display:'flex', flexDirection:'column'}}>{ns}</div>),
            onOk: async () => {
                try {
                    const resp = await http.post("inverter-manufacturer/multi/delete", {IDs: selectedKeys.join(",")}).then(async(r) => {
                        return await r.json();
                    }).catch((e) => {
                        console.log("[InverterManufacturer]", e);
                        return null;
                    });
        
                    if (resp.code !== 200) {
                        setTip({open:true, severity:"error", msg:(resp && resp.msg) ? resp.msg : "delete error"});
                        return
                    }
                    setTip({open: true, severity: 'success', msg: 'delete success'});
        
                    let rs = [];
                    rows.forEach((v, i) => {
                        const ID = v.ID;
                        let ok = false;
                        selectedKeys.every((ID2) => {
                            if (ID === ID2) {
                                ok = true;
                                return false;
                            }
        
                            return true;
                        });
                        if (!ok) {
                            rs.push(v);
                        } 
                    });
                    setRows(rs);
                    setRowTotal(rowTotal - selectedKeys.length);
                    setSelectedKeys([]);
                } catch(e) {
                    console.log("[InverterManufacturer]", e);
        
                    setTip({open:true, severity:"error", msg: "delete error"});
                }
            },
            onCancel() {
                //
            },
        });
    }

    const handleSelect = (selectedRowKeys, selectedRows) => {
        setSelectedKeys([...selectedRowKeys]);
    }

    const handleAddingClose = (row) => {
        setShowAdd(false);

        if (! row) {
            return;
        }

        document.getElementsByClassName("ant-table-body")[0].scrollTo(0, 0);
        setSelectedKeys([row.ID]);
        setRows([row, ...rows]);
        setRowTotal(rowTotal+1);
    }

    const handleModifyingClose = (row) => {
        setShowModify(false);
        setModifyRowIndex(null);

        if (! row) {
            return;
        }

        setSelectedKeys([row.ID]);
        const rw = {...rows[modifyRowIndex], ...row}
        setRows([...rows.slice(0, modifyRowIndex), rw, ...rows.slice(modifyRowIndex+1)]);
    }

    const calcTableBodyHeight = () => {
        if (! refTableContainer.current) {
            return 240;
        }

        const hd = document.getElementsByClassName("ant-table-thead")[0];

        const h = refTableContainer.current.getBoundingClientRect().bottom - hd.getBoundingClientRect().bottom;

        return  h>=54 ? h - 54 : h /2;
    }

    return (
        <div style={{
            flex: "1 1 auto",
            display: "flex",
            flexDirection: "column",
            backgroundColor: '#EFF8FE',
            padding: 8,
        }}>
            <div style={{
                flex: '0 0 auto',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                paddingBottom: 8,
            }}>
                <div style={{
                    flex: "0 0 300px",
                    maxWidth: 400,
                }}>
                    <Input.Search placeholder="Enter Product Name" onSearch={handleSearch} enterButton />
                </div>
                
                <div style={{
                    flex: '1 1 auto',
                    display: "flex",
                    flexDirection: 'row',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                }}>
                    <Button onClick={handleAdd} type="primary"  icon={<PlusOutlined /> } shape="circle" size="middle" />
                    <Button onClick={handleDeleteAllOfSelected} disabled={selectedKeys.length===0} type="primary" shape="circle" icon={<DeleteOutlined  />} size="middle" style={{marginLeft: 5}}/>

                </div>
            </div>
            <div style={{
                flex:"1 1 1px",
                position: "relative",
            }}>
                <div ref={refTableContainer} style={{position: "absolute", height: '100%', width: "100%", overflow: "hidden"}}>
                    <Table 
                        columns={[{
                            title: "Name",
                            dataIndex: "Name",
                            width: 200,
                            fixed: 'left',
                        },{
                            title: "Image",
                            dataIndex: 'Image',
                            width: 150,
                            render: (url) => {
                                return (<Image src={url}/>)
                            }
                        },{
                            title: "Create Time",
                            dataIndex: 'CreateTime',
                            width: 150,
                        },{
                            title: 'Action',
                            key: 'Action',
                            render: renderAction,
                        }]}
                        pagination={{
                            pageSizeOptions: [25,50,100],
                            current: pageNo+1,
                            pageSize: pageSize,
                            total: rowTotal,
                            showSizeChanger: true,
                            showTotal: (total) => {return `Total ${total}`},
                            onShowSizeChange: (current, size) => {
                                //console.log("current", 1, "size", size)
                                setPageSize(size);
                            },
                            onChange: (page, pageSize) => {
                                //console.log("page", page, "pageSize", pageSize)
                                setPageNo(page-1);                                
                            }
                        }}
                        tableLayout="fixed"
                        bordered={true}
                        size="middle"
                        loading={loading}
                        rowKey="ID"
                        dataSource={rows}
                        scroll={{ x: 1500, y: tableBodyHeight }}
                        rowSelection={{
                            selectedRowKeys: selectedKeys,
                            onChange: handleSelect,
                            selections: [Table.SELECTION_ALL, Table.SELECTION_INVERT, Table.SELECTION_NONE]
                        }}
                    />
                </div>
            </div>
            <InverterManufacturerAdd show={showAdd} onClose={handleAddingClose}/>
            {modifyRowIndex!==null&&<InverterManufacturerModify show={showModify} row={rows[modifyRowIndex]} onClose={handleModifyingClose}/>}
        </div>
    );
}

export default InverterManufacturer;