import { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { DatePicker } from 'antd';
import http from '../Http';
import utils from "../utils";
import { Button } from "@material-ui/core";
import ReactECharts from 'echarts-for-react';
import 'react-month-picker/css/month-picker.css';
import moment from 'moment';

const useStyles = makeStyles({
    calender: {
        color: "white",
        "& input": {
            color: 'white'
        },
        "& .react-datetime-picker__clear-button": {
            display: "none"
        },
        "& .react-datetime-picker__calendar-button>svg": {
            stroke: "white"
        },
        "& .react-calendar__month-view__weekdays": {
            color:"black"
        },
        "& .react-calendar__month-view__days__day": {
            color:"black"
        },
        "& .react-calendar__month-view__days__day--weekend": {
            color:"#d10000"
        },
        "& .react-calendar__navigation__label__labelText--from": {
            color:"black"
        },
        "& .react-calendar__navigation__prev-button": {
            color:"black"
        },
        "& .react-calendar__navigation__prev2-button": {
            color:"black"
        },
        "& .react-calendar__navigation__next-button": {
            color:"black"
        },
        "& .react-calendar__navigation__next-button:disabled": {
            color:"white"
        },
        "& .react-calendar__navigation__next2-button": {
            color:"black"
        },
        "& .react-calendar__navigation__next2-button:disabled": {
            color:"white"
        }
    },
    selectYear: {
        "& .MuiNativeSelect-icon": {
            stroke: "white",
        }
    }
});

const option = {
    darkMode: true,
    tooltip: {
        trigger: 'axis',
        axisPointer: {
            type: 'shadow',
        },
    },
    toolbox: {
        feature: {
            saveAsImage: {}
        }
    },
    grid: {
        left: 40,
        right: '4%',
        bottom: 40,
        containLabel: true
    },
    xAxis: [{
        type: 'category',
        boundaryGap: false,
        data: [],
        name: "(Hour)",
        nameLocation: "center",
        nameGap: 25,
    }],
    yAxis: [
        {
            type: 'value',
            name: "(kWh)",
            nameLocation: "middle",
            nameRotate: 90,
            nameGap: 40,
        }
    ],
    series: [{
        name: 'discharge',
        type: 'bar',
        smooth: true,
        itemStyle: {
            opacity: 0.8,
            color: {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [{
                    offset: 0, color: '#2e7d32' // 0% 处的颜色
                }, {
                    offset: 1, color: '#a5d6a7' // 100% 处的颜色
                }],
                global: false // 缺省为 false
            }
        },
        emphasis: {
            focus: 'none'
        },
        data: []
    }]
};

const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'Septemper', 'October', 'November', 'December'];

function Discharge(props) {
    const { setTip, deviceID, date, setDate } = props;

    //const [isCalendarOpen, setIsCalendarOpen] = useState(false);
    //const [date, setDate] = useState(new Date());
    const [dateScale, setDateScale] = useState(0); // 0 daily, 1 monthly, 2 yearly

    const chartEl = useRef(null);
    const chart = useRef(null);
    const refMonthPicker = useRef(null);

    const classes = useStyles();

    useEffect(() => {
        let active = true;
        const ymd = utils.dateFormat("YYYY-mm-dd", date);

        if (dateScale == 0) {
            option.xAxis[0].data = ['1', '2', '3', '4', '5', '6', '7', '8', '9','10','11','12','13','14','15','16','17','18','19','20','21','22','23','24'];
            option.xAxis[0].name = "(Hour)";
        } else if (dateScale == 1) {
            const y = date.getFullYear();
            const m = date.getMonth();
            let days = Array(((new Date(y, m+1)).getTime() - (new Date(y, m)).getTime()) / 3600/1000/24);
            days.fill(0).forEach((v, i) => {
                days[i] = i+1;
            });
            option.xAxis[0].data = days;
            option.xAxis[0].name = "(Day)";
        } else {
            const ms = Array(12).fill(0)
            ms.forEach((v, i) => {
                ms[i] = i+1;
            })
            option.xAxis[0].data = ms;
            option.xAxis[0].name = "(Month)";
        }
        

        (async () => {
            let resp = await http.post("report/device/discharging/"+(dateScale==0?"daily":dateScale==1?"monthly":"yearly")+"/report", {
                "DeviceID": deviceID,
                "DateTime": ymd,
            }).then(async (r)=>{
                return await r.json();
            }).catch((e)=>{
                console.log("Report Discharge", e);
                return null;
            });

            if (! active) {
                return;
            }
            
            if (resp == null || resp.code != 200) {
                setTip({open:true, severity:"error", msg:(resp && resp.msg) ? resp.msg : "load data error"});
                
                option.series[0].data = [];
            } else {
                let ds = {};
                let vs = [];

                const tag = dateScale == 0
                    ? "YMDH"
                    : dateScale == 1
                        ? "YMD"
                        : "YM";
                resp.data.forEach((v) => {
                    ds[v[tag]] = v;
                });
                for (let i=0; i<option.xAxis[0].data.length; i++) {
                    const dt = new Date(date.getFullYear(), 
                        (dateScale==2 ? i : date.getMonth()), 
                        (dateScale==2 ? 1 : dateScale==1 ? i : date.getDate()), 
                        (dateScale==0 ? i : 0)
                    );
                    const str = utils.dateFormat((dateScale==0 ? "YYYY-mm-dd HH:MM:SS": "YYYY-mm-dd"), dt);
                    if (ds[str]) {
                        vs.push(((ds[str]["V"])/10).toFixed(1));
                    } else {
                        vs.push(0);
                        //vs.push((Math.random()*100).toFixed(1));
                    }
                }
                option.series[0].data = vs;
            }

            //
            const instance = chart.current.getEchartsInstance();
            if (instance) {
                instance.setOption(option);
            }
        })();

        return () => {
            active = false;
        };
    }, [deviceID, date, dateScale]);

    const handleShowPicker = () => {
        refMonthPicker.current.show();
    }
    const handleGoPreDate = () => {
        if (dateScale == 0) {
            setDate(new Date(date.setDate(date.getDate()-1)));
        } else if (dateScale == 1) {
            setDate(new Date(date.getFullYear(), date.getMonth()-1));
        } else {
            if (date.getFullYear() <= 2018) {
                setTip({open:true, severity:"info", msg:"date exceeded"});
            }
            setDate(new Date(date.getFullYear()-1, 1));
        }
    }

    const handleGoNextDate = () => {
        const f = dateScale==0 ? "YYYY-mm-dd" : dateScale==1 ? "YYYY-mm" : "YYYY";
        if (utils.dateFormat(f, date).substr() >= utils.dateFormat(f, new Date())) {
            setTip({open:true, severity:"info", msg:"date exceeded"});
            return;
        }

        if (dateScale == 0) {
            setDate(new Date(date.setDate(date.getDate()+1)));
        } else if (dateScale == 1) {
            setDate(new Date(date.getFullYear(), date.getMonth()+1));
        } else {
            setDate(new Date(date.getFullYear()+1, 1));
        }
    }

    return (
        <div style={{
            flex: "1 1 auto",
            display: "flex",
            flexDirection: "column",
            alignItems: "stretch",
            backgroundColor: "white",
            overflow: 'auto',
            borderRadius: 10,
        }}>
            <div style={{
                flex: '0 0 auto',
                display: 'flex',
                flexDirection: 'row',
                backgroundColor: '#ccc',
            }}>
                <Button 
                    onClick={()=>{
                        if (dateScale == 0) {
                            return;
                        }

                        setDateScale(0);
                    }}
                    variant="contained"
                    style={{
                        borderRadius: 0,
                        color: dateScale==0 ? "white" : "black",
                        backgroundColor: dateScale==0 ? "#919292" : "#ccc",
                        flex: '1 1 auto'
                    }}
                >Daily</Button>
                <Button
                    onClick={()=>{
                        if (dateScale == 1) {
                            return;
                        }

                        setDateScale(1);
                    }}
                    variant="contained"
                    style={{
                        color: dateScale==1 ? "white" : "black",
                        backgroundColor: dateScale==1 ? "#919292" : "#ccc",
                        flex: '1 1 auto'
                    }}
                >Monthly</Button>
                <Button
                    onClick={()=>{
                        if (dateScale == 2) {
                            return;
                        }

                        setDateScale(2);
                    }}
                    variant="contained"
                    style={{
                        color: dateScale==2 ? "white" : "black",
                        backgroundColor: dateScale==2 ? "#919292" : "#ccc",
                        flex: '1 1 auto'
                    }}
                >Yearly</Button>
            </div>
            {/* <div style={{
                flex: '0 0 60px',
                display: "flex",
            }}>
                <IconButton onClick={handleGoPreDate}>
                    <NavigateBeforeIcon style={{ fontSize: 30, color: "white", }} />
                </IconButton>
                <div style={{
                    flex: '1 0 auto',
                    display: "flex",
                    justifyContent: 'center',
                    alignItems: "center",
                    color: "white",
                    fontSize: 20
                }}>
                    {dateScale==0 ? (<DatePicker 
                        className={classes.calender}
                        disableClock={true}
                        value={new Date(date.getTime())}
                        format="yyyy-MM-dd"
                        maxDate={new Date()}
                        isCalendarOpen={isCalendarOpen}
                        locale="en"
                        onChange={(v) => {
                            setDate(v);
                        }}
                        onCalendarClose={() => {
                            setIsCalendarOpen(false);
                        }}
                    />) : dateScale==1 ? (<MonthPicker 
                        ref={refMonthPicker}
                        lang={months}
                        value={{year: 2021, month: 8}}
                        onChange={(y, m) => {
                            setDate(new Date(y, m-1));
                        }}
                    >
                        <div onClick={handleShowPicker} style={{border: "1px solid white",paddingLeft: 3,paddingRight: 3, cursor: "pointer"}}>{utils.dateFormat("YYYY-mm", date)}</div>
                    </MonthPicker>) : (
                        <FormControl className={classes.formControl}>
                            <NativeSelect
                                className={classes.selectYear}
                                style={{
                                    color: "white",
                                    fontSize: 20
                                }}
                                value={date.getFullYear()}
                                onChange={(e) => {
                                    setDate(new Date(e.target.value));
                                }}
                                name="year"
                            >
                                {Array((new Date()).getFullYear() - 2017).fill(0).map((v, idx) =>{
                                    return <option key={idx} value={2018+idx} style={{color: "black"}}>{2018+idx}</option>
                                })}
                            </NativeSelect>
                        </FormControl>
                    )}
                </div>
                <IconButton onClick={handleGoNextDate}>
                    <NavigateNextIcon style={{ fontSize: 30, color: "white", }} />
                </IconButton>
            </div> */}
            <div style={{
                flex: '1 0 20px',
                display: "flex",
                justifyContent: 'center',
                alignItems: "flex-end",
                color: "white",
                fontSize: 20,
            }}>
                <DatePicker
                    picker={dateScale==0 ? 'date' : dateScale==1 ? 'month' : 'year'}
                    locale="en"
                    onChange={(v) => {
                        setDate(v.toDate());
                    }}
                    //defaultValue={moment()}
                    value={moment(date)}
                    style={{borderRadius: '20px'}}
                />
            </div>
            <div style={{
                flex: "1 1 auto",
                display: "flex",
                flexDirection: "column",
                alignItems: 'stretch',
                overflow: "auto",
                padding: 5,
                backgroundColor: "white",
            }}>
                <div style={{
                    flex: "1, 1 auto",
                    minWidth: 400,
                }} ref={chartEl}>
                    <ReactECharts ref={chart} option={option} style={{ height: 400 }} />
                </div>
            </div>
        </div>
    )
}

export default Discharge;