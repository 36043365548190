import { useState, useEffect, useRef } from 'react';
import http from '../Http';
import utils from "../utils";
import ReactECharts from 'echarts-for-react';
import { DatePicker } from 'antd';
import moment from 'moment';

const option = {
    darkMode: true,
    tooltip: {
        trigger: 'axis',
        axisPointer: {
            type: 'cross',
            label: {
                backgroundColor: '#6a7985'
            }
        },
    },
    legend: {
        show: false,
    },
    toolbox: {
        feature: {
            saveAsImage: {}
        }
    },
    grid: {
        left: 40,
        right: '4%',
        bottom: 40,
        containLabel: true
    },
    xAxis: {
        type: 'time',
        name: "(Hour)",
        nameLocation: "center",
        nameGap: 25,
        splitNumber: 24,
        axisLabel: {
            formatter: '{H}'
        },
    },
    yAxis: [
        {
            type: 'value',
            name: "(%)",
            nameLocation: "middle",
            nameRotate: 90,
            nameGap: 40,
        }
    ],
    series: [{
        name: 'SOC',
        type: 'line',
        stack: '',
        smooth: true,
        lineStyle: {
            width: 1,
            color: "blue"
        },
        showSymbol: false,
        areaStyle: {
            opacity: 0,
        },
        emphasis: {
            focus: 'none'
        },
        data: []
    }]
};

function SOC(props) {
    const { setTip, deviceID, date, setDate } = props;

    //const [date, setDate] = useState(new Date());

    const chartEl = useRef(null);
    const chart = useRef(null);

    useEffect(() => {
        let active = true;
        const st = utils.dateFormat("YYYY-mm-dd", date);
        let date2 = new Date(st);
        date2.setDate(date2.getDate()+1)
        const et = utils.dateFormat("YYYY-mm-dd", date2);

        (async () => {
            let resp = await http.post("report/device/soc/daily/report", {
                "DeviceID": deviceID,
                "STime": st,
                "ETime": et,
            }).then(async (r) => {
                return await r.json();
            }).catch((e) => {
                console.log("Report Voltage", e);
                return null;
            });

            if (!active) {
                return;
            }

            if (resp == null || resp.code != 200) {
                setTip({ open: true, severity: "error", msg: (resp && resp.msg) ? resp.msg : "load data error" });

                option.series[0].data = [];
            } else {
                let soc = [];
                if(resp.data instanceof Array) {
                    resp.data.forEach((v) => {
                        soc.push([v['CreateTime'], v['SOC']/10])
                    });
                    //填充数据
                    let min = resp.data[0]['CreateTime'],
                        max = resp.data[resp.data.length-1]['CreateTime'];
                    if(min >= min.substring(0, 10) + ' 01:00:00') {
                        soc.unshift([utils.dateFormat("YYYY-mm-dd HH:MM:SS", new Date(new Date(min).getTime()-1000)), 0])
                        soc.unshift([min.substring(0, 10) + ' 00:00:01', 0])
                    }
                    if(max <= max.substring(0, 10)+' 23:00:00') {
                        soc.push([utils.dateFormat("YYYY-mm-dd HH:MM:SS", new Date(new Date(max).getTime()+1000)), 0])
                        soc.push([max.substring(0, 10)+' 23:59:59', 0])
                    }
                }
                option.series[0].data = soc;
            }

            //
            const instance = chart.current.getEchartsInstance();
            if (instance) {
                instance.setOption(option);
            }
        })();

        return () => {
            active = false;
        };
    }, [deviceID, date]);

    const handleGoPreDay = () => {
        setDate(new Date(date.setDate(date.getDate() - 1)));
    }

    const handleGoNextDay = () => {
        if (utils.dateFormat("YYYY-mm-dd", date) >= utils.dateFormat("YYYY-mm-dd", new Date())) {
            setTip({ open: true, severity: "info", msg: "date exceeded" });
            return;
        }

        setDate(new Date(date.setDate(date.getDate() + 1)));
    }

    return (
        <div style={{
            flex: "1 1 auto",
            display: "flex",
            flexDirection: "column",
            alignItems: "stretch",
            backgroundColor: "white",
            overflow: 'auto',
            borderRadius: 10,
        }}>
            <div style={{
                flex: '1 0 20px',
                display: "flex",
                justifyContent: 'center',
                alignItems: "flex-end",
                color: "white",
                fontSize: 20,
            }}>
                <DatePicker
                    locale="en"
                    onChange={(v) => {
                        setDate(v.toDate());
                    }}
                    //defaultValue={moment()}
                    value={moment(date)}
                    style={{ borderRadius: '20px' }}
                />
            </div>
            <div style={{
                flex: "1 1 auto",
                display: "flex",
                flexDirection: "column",
                alignItems: 'stretch',
                overflow: "auto",
                padding: 5,
                backgroundColor: "white",
            }}>
                <div style={{
                    flex: "1, 1 auto",
                    minWidth: 400,
                }} ref={chartEl}>
                    <ReactECharts ref={chart} option={option} style={{ height: 400 }} />
                </div>
            </div>
        </div>
    )
}

export default SOC;