import React, {useState} from 'react';
import Login from './Login'
import Home from './Home'
import { ThemeContext } from './Http'

function App() {
    let [session, setSession] = useState(null);
    let [whichSystem, setWiWhichSystem] = useState("BMS");

    return (
        session === null 
        ? 
        <Login onLoginSuccess={(s, w) => {
            setSession(s);
            setWiWhichSystem(w);
        }}/> 
        : 
        <ThemeContext.Provider value={session}>
            <Home whichSystem={whichSystem} onLogout={()=>{
                setSession(null);
            }} onChangeSystem={(w)=>{
                setWiWhichSystem(w);
            }}/>
        </ThemeContext.Provider>
  );
}

export default App;

