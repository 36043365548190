import React, { useEffect, useState, useRef, useContext } from 'react';
import { Button, Select, Table, DatePicker } from 'antd';
import http from '../../../Http';
import utils from '../../../utils';
import config from '../../../Config';
import moment from 'moment';

function getTableScroll() {
    var th = document.getElementsByClassName("ant-table-thead")[0];
    let tHeaderBottom = 0
    if (th) {
        tHeaderBottom = th.getBoundingClientRect().top
    }
    let height = `calc(100vh - ${tHeaderBottom + 100}px)`
    return height
}

function DryContactData(props) {
    const { inverterSelectOptions, SN, protocol, handleSelectSN } = props
    const setTip = useContext(utils.tipContext)

    const [pageNo, setPageNo] = useState(0)
    const [pageSize, setPageSize] = useState(100)
    const [rows, setRows] = useState([])
    const [rowTotal, setRowTotal] = useState(0)
    const [loading, setLoading] = useState(false)

    const [STime, setSTime] = useState(moment().format("YYYY-MM-DD"))

    const refDownloader = useRef(null)

    const [scrollY, setScrollY] = useState("")

    useEffect(() => {
        setScrollY(getTableScroll())
    }, [])

    const ETime = moment(STime).add(1, "days").format("YYYY-MM-DD")

    //取历史数据
    useEffect(() => {
        if (!SN) return;

        let active = true;

        (async () => {
            setLoading(true);

            let resp = await http.post("dry-contact/history/list", {
                PageNo: pageNo,
                PageSize: pageSize,
                SerialNumber: SN,
                STime: STime,
                ETime: ETime,
            }).then(async (r) => {
                return await r.json();
            }).catch((e) => {
                return null;
            });

            if (!active) {
                return;
            }

            if (resp === null || resp.code !== 200) {
                setTip({ open: true, severity: "error", msg: (resp && resp.msg) ? resp.msg : "load data error" });
                setRowTotal(0)
                setRows([])
                return;
            }

            //console.log('drycontact: ', resp.data);

            resp.data.Rows = resp.data.Rows.map(item => {
                //handle data
                let data = item.Data;
                if(data){
                    if(data.length < 16) { //小于16个字符则补足16个
                        data = data.padEnd(16, '0');
                    } else if(data.length > 16) {  //大于16个字符则取前16个
                        data = data.slice(0, 16);
                    }
                    //将长度为16的hex字符串转换为64bit的串
                    const bits = data.split('').map(v => Number('0x' + v).toString(2).padStart(4, '0')).join('')  
                    //将64bit的串按字节分割为数组
                    const bytes = bits.match(/(.{8})/g);
                    //对bytes的每个字节进行处理
                    const vs = bytes.map(v => parseInt(v, 2))
                    const ValueNumber = vs[0]
                    const ValueAlarmLevel = vs[1]
                    const TempDetector = vs[2] == 0 ? 'Normal' : vs[2] == 1 ? 'Alarm' : 'Unknown'
                    const SmokeDetector = vs[3] == 0 ? 'Normal' : vs[3] == 1 ? 'Alarm' : 'Unknown'
                    const SpaceDetector = vs[4]
                    const AlarmSwitch = vs[5] == 0 ? 'Up' : vs[5] == 1 ? 'Down' : 'Unknown'
                    const MessageCounter = vs[6]
                    const v8 = bytes[7]  //第8个字节单独处理
                    const v8_1 = v8.slice(6, 8)
                    const v8_2 = v8.slice(0, 6)
                    const levelValue = parseInt(v8_1, 2)
                    const FaultLevel = levelValue == 0 ? 'No Fault' : levelValue == 1 ? 'Level1 Fault' : levelValue === 2 ? 'Level2 Fault' : 'Unknown'
                    const FaultCode = parseInt(v8_2, 2)
                    
                    item = {
                        ...item,
                        ValueNumber,
                        ValueAlarmLevel,
                        TempDetector,
                        SmokeDetector,
                        SpaceDetector,
                        AlarmSwitch,
                        MessageCounter,
                        FaultLevel,
                        FaultCode
                    }
                }

                return item;
            })

            setRowTotal(resp.data.RowTotal);
            setRows(resp.data.Rows);
            setLoading(false);
        })();

        return () => {
            active = false;
        };
    }, [pageNo, pageSize, SN, STime]);

    const handleExport = () => {
        if (!refDownloader.current) {
            return;
        }
        refDownloader.current.click()
    }

    return (
        <div style={{ width: "100%", height: "100%", display: "flex", flexDirection: "column" }}>
            <div style={{ flex: "none", padding: "10px" }}>
                <span>Select Inverter</span>
                <Select
                    showSearch
                    placeholder="Enter Inverter Name"
                    defaultActiveFirstOption={false}
                    showArrow={true}
                    filterOption={true}
                    onSelect={(value) => handleSelectSN(value)}
                    value={SN}
                    style={{ width: '200px', marginRight: '20px', marginLeft: '10px' }}
                >{inverterSelectOptions}</Select>

                <DatePicker
                    defaultValue={STime}
                    value={moment(STime)}
                    onChange={(_, ds) => {
                        setSTime(ds)
                    }}
                    allowClear={false}
                />

                <Button type="primary" disabled={rows.length < 1 ? true : false} onClick={handleExport} style={{ float: 'right', marginRight: '10px', backgroundColor: '#0486be' }}>Export Data</Button>
            </div>
            <div style={{ flex: "auto", overflow: "hidden" }}>
                <Table
                    columns={[
                        {
                            title: "",
                            width: 40,
                            fixed: 'left',
                            render: (v, r, idx) => {
                                return (pageNo * pageSize + idx + 1);
                            }
                        }, {
                            title: 'SN',
                            dataIndex: "SN",
                            width: 160,
                            fixed: 'left',
                        }, {
                            title: 'Device Type',
                            dataIndex: 'DeviceType',
                            width: 90,
                            render: (v) => {
                                return v 
                            }
                        }, {
                            title: 'Value Number',
                            dataIndex: 'ValueNumber',
                            width: 110,
                        }, {
                            title: 'Value Alarm Level',
                            dataIndex: 'ValueAlarmLevel',
                            width: 130,
                        }, {
                            title: 'Temperature Detector',
                            dataIndex: 'TempDetector',
                            width: 155,
                        }, {
                            title: 'Smoke Detector',
                            dataIndex: 'SmokeDetector',
                            width: 120,
                        }, {
                            title: 'Space Detector',
                            dataIndex: 'SpaceDetector',
                            width: 120,
                        }, {
                            title: 'Alarm Switch',
                            dataIndex: 'AlarmSwitch',
                            width: 120,
                        }, {
                            title: 'Message Counter',
                            dataIndex: 'MessageCounter',
                            width: 125,
                        }, {
                            title: 'Fault Level',
                            dataIndex: 'FaultLevel',
                            width: 100,
                        }, {
                            title: 'Fault Code',
                            dataIndex: 'FaultCode',
                            width: 100,
                        }, {
                            title: 'CreateTime',
                            dataIndex: 'CreateTime',
                            width: 120,
                        }
                    ]}
                    pagination={{
                        pageSizeOptions: [25, 50, 100],
                        current: pageNo + 1,
                        pageSize: pageSize,
                        total: rowTotal,
                        showSizeChanger: true,
                        showTotal: (total) => { return `Total ${total}` },
                        onShowSizeChange: (current, size) => {
                            setPageSize(size);
                        },
                        onChange: (page, pageSize) => {
                            setPageNo(page - 1);
                        }
                    }}
                    tableLayout="fixed"
                    bordered={true}
                    size="small"
                    loading={loading}
                    rowKey="ID"
                    dataSource={rows}
                    scroll={{ x: 'max-content', y: scrollY }}
                >
                </Table>
            </div>

            <a ref={refDownloader} style={{ display: "none" }} href={config.apiUrl + "data-export/drycontact/history/do?Token=" + http.token + "&SerialNumber=" + SN + "&STime=" + STime + "&ETime=" + ETime}></a>
        </div>
    )
}

export default DryContactData;