import config from './Config';
import http from "./Http";
import { ThemeContext } from './Http';
import React, { useState, useEffect, useContext, useRef } from 'react';
import { Input, Select, Button, Modal, Table } from 'antd';
import utils from './utils';
import DeviceSummary from "./Monitor/DeviceSummary";
import Alarm from "./Monitor/Alarm";
import CellVoltage from "./Monitor/CellVoltage";
import Temperature from "./Monitor/Temperature";
import Relay from "./Monitor/Relay";
import Switch from "./Monitor/Switch";
import InverterStatus from "./Monitor/InverterStatus";
import PackBalanceStatus from "./Monitor/PackBalanceStatus";

function Monitor(props) {
    const setTip = useContext(utils.tipContext);
    const { deviceSNFromIMS } = props;

    const session = useContext(ThemeContext);

    const [searchID, setSearchID] = useState(null);
    const [clusterBatteries, setClusterBatteries] = useState([]);
    const [showBatteriesCluster, setShowBatteriesCluster] = useState(false);
    const [deviceSN, setDeviceSN] = useState(deviceSNFromIMS);
    const [sck, setSck] = useState(null); // WebSocket
    const [summaryData, setSummaryData] = useState(null);
    const [alarmData, setAlarmData] = useState(null);
    const [cellVoltageData, setCellVoltageData] = useState(null);
    const [temperatureData, setTemperatureData] = useState(null);
    const [switchData, setSwitchData] = useState(null);
    const [relayData, setRelayData] = useState(null);
    const [inverterStatusData, setInverterStatusData] = useState(null);
    const [cellVoltageStatusData, setCellVoltageStatusData] = useState(null);
    const [packBalanceStatusData, setPackBalanceStatusData] = useState(null);
    const [temperatureStatusData, setTemperatureStatusData] = useState(null);

    const [deviceSelectOptions, setDeviceSelectOptions] = useState([]);
    const refTimerDeviceSearcher = useRef(null);
    const refDeviceSearchValue = useRef(null);

    useEffect(() => {
        if (sck) {
            return;
        }

        //console.log("Monitor===>", "useEffect[sck]")

        const wss = new WebSocket(config.wssUrl + "?Token=" + http.token);
        wss.addEventListener('open', handleWssOpen);
        wss.addEventListener('message', handleWssMessage);
        wss.addEventListener('close', handleWssClose);
        wss.addEventListener('error', handleWssError);

        // return () => {
        //     wss.close();
        // }
    }, [sck]);

    //根据选择的电池ID查找电池簇
    useEffect(() => {
        if (!searchID) return;

        let active = true;

        (async () => {
            let resp = await http.post("cluster/pack-list", {
                deviceID: searchID,
            }).then(async (r) => {
                return await r.json();
            }).catch((e) => {
                return null;
            });

            if (!active) {
                return;
            }

            if (resp === null || resp.code !== 200) {
                setTip({ open: true, severity: "error", msg: (resp && resp.msg) ? resp.msg : "load data error" });
                setClusterBatteries([]);
                return;
            }

            if(resp.data && resp.data.length > 1) {
                setDeviceSN(null);
                setClusterBatteries(resp.data);
                setShowBatteriesCluster(true);
                setSearchID(null);
            } else if (resp.data && resp.data.length == 1) {
                setDeviceSN(resp.data[0].SN)
            }
        })();

        return () => {
            active = false;
        };
    }, [searchID]);

    useEffect(() => {
        if (!sck || !deviceSN) {
            return;
        }

        sck.send(JSON.stringify({
            "Type": 1,
            "ID": "99999",
            "DeviceSN": deviceSN,
            "Action": 1,
        }));

        setSummaryData(null);
        setAlarmData(null);
        setRelayData(null);
        setSwitchData(null);
        setCellVoltageData(null);
        setTemperatureData(null);
        setInverterStatusData(null);
        setCellVoltageStatusData(null);
        setPackBalanceStatusData(null);
        setTemperatureStatusData(null);

        return () => {
            sck.send(JSON.stringify({
                "Type": 1,
                "ID": "99999",
                "DeviceSN": deviceSN,
                "Action": 0,
            }));
        }
    }, [deviceSN])

    const handleWssOpen = (evt) => {
        const wss = evt.currentTarget;
        setSck(wss);

        if (deviceSN) {
            wss.send(JSON.stringify({
                "Type": 1,
                "ID": "99999",
                "DeviceSN": deviceSN,
                "Action": 1,
            }));
        }

        evt.currentTarget.tmrPing = setInterval(() => {
            if (!wss.tmrPing) {
                return;
            }

            wss.send("Ping");
        }, 30000);
    }

    const handleWssMessage = (evt) => {
        //console.log("Wss Message", evt, evt.data);

        const d = evt.data;
        if (d == "Pong") {
            return;
        }

        //
        try {
            const data = JSON.parse(d);
            if (!data || data["Type"] != 'REALTIME') {
                return;
            }

            const x = JSON.parse(data["X"])

            switch (data["MsgID"]) {
                case 0x1000:
                    setSummaryData(x);
                    break;
                case 0x1001:
                    setAlarmData(x);
                    break;
                case 0x1002:
                    setRelayData(x);
                    break;
                case 0x1003:
                    setSwitchData(x);
                    break;
                case 0x1005:
                    setCellVoltageData(x);
                    break;
                case 0x1021:
                    setTemperatureData(x);
                    break;
                case 0x1040:
                    setInverterStatusData(x);
                case 0x1050:
                    setCellVoltageStatusData(x);
                    break;
                case 0x1051:
                    setPackBalanceStatusData(x);
                    break;
                case 0x1052:
                    setTemperatureStatusData(x);
                    break;
            }
        } catch (e) {
            console.log("[Monitor]", e);
        }
    }

    const handleWssClose = (evt) => {
        console.log("Wss Close", evt);

        let wss = evt.currentTarget;
        wss.removeEventListener('open', handleWssOpen);
        wss.removeEventListener('message', handleWssMessage);
        wss.removeEventListener('close', handleWssClose);
        wss.removeEventListener('error', handleWssError);
        if (wss.tmrPing) {
            clearInterval(wss.tmrPing);
            wss.tmrPing = null;
        }

        setSck(null);

    }

    const handleWssError = (evt) => {
        console.log("Wss Error", evt);

        evt.currentTarget.close();
    }

    const handleSearchDevice = (v) => {
        if (refTimerDeviceSearcher.current != null) {
            clearTimeout(refTimerDeviceSearcher.current);
        }
        refDeviceSearchValue.current = v;

        refTimerDeviceSearcher.current = setTimeout(async () => {
            const resp = await http.post("device/id-name/list", {
                SN: v,
            }).then(async (r) => {
                return await r.json();
            }).catch((e) => {
                console.log("[SummaryDataExporter]", e);
                return null;
            });

            if (refDeviceSearchValue.current !== v) {
                return;
            }

            try {
                if (resp.code !== 200) {
                    setTip({ open: true, severity: "error", msg: (resp && resp.msg) ? resp.msg : "load device list error" });
                    return;
                }

                const ls = resp.data.map(({ SN, ID }) => {
                    return (
                        <Select.Option key={SN} value={ID}>{SN}</Select.Option>
                    );
                });
                setDeviceSelectOptions(ls);
            } catch (e) {
                console.log("[SummaryDataExporter]", e);

                setTip({ open: true, severity: "error", msg: "load device list error" });
            }
        }, 300);
    }

    return (
        <div style={{
            flex: '1 1 auto',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'stretch',
            backgroundColor: '#EFF8FE',
            overflow: 'auto',
            padding: '10px'
        }}>
            {/* 搜索行 */}
            <div style={{
                padding: '10px',
                display: 'flex',
                alignItems: 'center',
            }}>
                <span style={{ fontSize: '18px', fontWeight: '600', marginRight: '30px' }}>Monitor</span>
                <Input.Group compact>
                <Button>Device SN</Button>
                    <Select
                        style={{minWidth: 400}}
                        showSearch
                        defaultActiveFirstOption={false}
                        showArrow={false}
                        filterOption={false}
                        value={deviceSN}
                        onSearch={(value) => {
                            handleSearchDevice(value);
                        }}
                        onSelect={(value) => {
                            //setDeviceSN(value)
                            setSearchID(value);
                        }}
                    >
                        {deviceSelectOptions}
                    </Select>
                </Input.Group>
            </div>
            {/* 第一行 */}
            <div style={{
                flex: "1 1 auto",
                display: "flex",
                flexDirection: "row",
                flexWrap: "nowrap",
            }}>
                <div style={{ flex: '1 1 340px', height: '350px', backgroundColor: 'white', borderRadius: '10px', margin: '10px' }}>
                    <DeviceSummary data={summaryData} />
                </div>
                <div style={{ flex: '1 1 620px', height: '350px', backgroundColor: 'white', borderRadius: '10px', margin: '10px' }}>
                    <Alarm data={alarmData} />
                </div>
                {session.IsAdmin == 1 || session.IsDistributorUser == 1 ? (
                    <div style={{ flex: '1 1 200px', height: '350px', backgroundColor: 'white', borderRadius: '10px', margin: '10px' }}>
                        <CellVoltage data={summaryData} />
                    </div>
                ) : <div style={{ display: 'none' }} />}
            </div>
            {/* 第二行 */}
            {session.IsAdmin == 1 || session.IsDistributorUser == 1 ? (
                <div style={{
                    flex: "1 1 auto",
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "nowrap",
                }}>
                    <div style={{ flex: '1 1 300px', height: '450px', backgroundColor: 'white', borderRadius: '10px', margin: '10px', maxWidth: '300px', overflow: 'auto' }}>
                        <Temperature data={{
                            "Temperatures": (temperatureData ? temperatureData.Temperatures : []),
                            "Status": (temperatureStatusData ? temperatureStatusData.Status : []),
                        }} />
                    </div>
                    <div style={{ flex: '1 1 180px', height: '450px', backgroundColor: 'white', borderRadius: '10px', margin: '10px' }}>
                        <Relay data={relayData} />
                    </div>
                    <div style={{ flex: '1 1 180px', height: '450px', backgroundColor: 'white', borderRadius: '10px', margin: '10px' }}>
                        <Switch data={switchData} />
                    </div>
                    <div style={{ flex: '1 1 500px', height: '450px', display: 'flex', flexWrap: 'wrap' }}>
                        <div style={{ flex: '1 1 460px', height: '230px', backgroundColor: 'white', borderRadius: '10px', margin: '10px' }}>
                            <InverterStatus data={inverterStatusData} />
                        </div>
                        <div style={{ flex: '1 1 180px', height: '200px', backgroundColor: 'white', borderRadius: '10px', margin: '10px' }}>
                            <PackBalanceStatus data={packBalanceStatusData} />
                        </div>
                    </div>
                </div>
            ) : <div style={{ display: "none" }}></div>}

            {/* 当电池簇有多个电池时，显示电池列表 */}
            <Modal
                title="Battery Cluster"
                visible={showBatteriesCluster}
                cancelText="cancel"
                okText="OK"
                mask={true}
                maskClosable={false}
                width={420}
                footer={null}
                onCancel={() => setShowBatteriesCluster(false)}
            >
                <Table
                    columns={[{
                        title: "",
                        width: 20,
                        fixed: 'left',
                        render: (v, r, idx) => {
                            return idx + 1
                        }
                    }, {
                        title: "SN",
                        dataIndex: "SN",
                        width: 100,
                    }, {
                        title: 'Action',
                        key: 'Action',
                        width: 40,
                        render: (v, r, idx) => {
                            return (
                                <Button type="link" onClick={() => {
                                    setDeviceSN(r.SN)
                                    setShowBatteriesCluster(false)
                                }}>Monitor</Button>
                            )
                        }
                    }]}
                    tableLayout="fixed"
                    bordered={true}
                    size="small"
                    pagination={false}
                    rowKey="ID"
                    dataSource={clusterBatteries}
                />
            </Modal>
        </div>
    )

    // return (
    //     <div style={{
    //         flex: "1 1 auto",
    //         display: "flex",
    //         flexDirection: "column",
    //         alignItems: "stretch",
    //     }}>
    //         <div style={{
    //             flex: "0 0 40px",
    //             backgroundColor: "black",
    //             display: "flex",
    //             flexDirection: "row",
    //         }}>
    //             <div style={{
    //                 flex: "1 1 auto",
    //                 display: "flex",
    //                 justifyContent: "center",
    //                 alignItems: "center",
    //             }}>
    //                 <Typography style={{ color: '#eee' }}>Monitor:{deviceSN}</Typography>
    //             </div>
    //             <div style={{
    //                 display: "flex",
    //                 alignItems: "center",
    //             }}>
    //                 <LinkIcon style={{ color: sck ? '#76ff03' : "#888" }} />
    //             </div>
    //         </div>
    //         <div style={{
    //             flex: "1 1 1px",
    //             display: "flex",
    //             flexDirection: "row",
    //             justifyContent: "stretch",
    //             alignItems: 'stretch',
    //             flexWrap: "wrap",
    //             overflow: "auto",
    //             backgroundColor: "#666",
    //             padding: 5,
    //         }}>
    //             {/* Summary */}
    //             <div style={{
    //                 flex: "1 0 400px",
    //                 display: "flex",
    //                 flexDirection: "column",
    //                 alignItems: "stretch",
    //                 margin: 5,
    //             }}>
    //                 <DeviceSummary2 data={summaryData} />
    //             </div>
    //             <div style={{
    //                 flex: "1 0 350px",
    //                 display: "flex",
    //                 flexDirection: "column",
    //                 alignItems: "stretch",
    //                 margin: 5,
    //             }}>
    //                 <Alarm data={alarmData} />
    //             </div>
    //             {session.IsAdmin == 1 || session.IsDistributorUser == 1 ?
    //                 (<>
    //                     <div style={{
    //                         flex: "1 0 250px",
    //                         display: "flex",
    //                         flexDirection: "column",
    //                         alignItems: "stretch",
    //                         margin: 5,
    //                     }}>
    //                         <CellVoltage data={{
    //                             "CellVoltages":(cellVoltageData?cellVoltageData.CellVoltages:[]),
    //                             "Status":(cellVoltageStatusData?cellVoltageStatusData.Status:[]),
    //                         }} />
    //                     </div>
    //                     <div style={{
    //                         flex: "1 0 250px",
    //                         display: "flex",
    //                         flexDirection: "column",
    //                         alignItems: "stretch",
    //                         margin: 5,
    //                     }}>
    //                         <Temperature data={{
    //                                 "Temperatures":(temperatureData?temperatureData.Temperatures:[]),
    //                                 "Status":(temperatureStatusData?temperatureStatusData.Status:[]),
    //                         }} />
    //                     </div>
    //                     <div style={{
    //                         flex: "1 0 280px",
    //                         display: "flex",
    //                         flexDirection: "column",
    //                         alignItems: "stretch",
    //                         margin: 5,
    //                     }}>
    //                         <Relay data={relayData} />
    //                     </div>
    //                     <div style={{
    //                         flex: "1 0 280px",
    //                         display: "flex",
    //                         flexDirection: "column",
    //                         alignItems: "stretch",
    //                         margin: 5,
    //                     }}>
    //                         <Switch data={switchData} />
    //                     </div>
    //                     {/* Inverter Status */}
    //                     <div style={{
    //                         flex: "1 0 280px",
    //                         display: "flex",
    //                         flexDirection: "column",
    //                         alignItems: "stretch",
    //                         margin: 5,
    //                     }}>
    //                         <InverterStatus data={inverterStatusData} />
    //                     </div>
    //                     {/* Pack Balance Status */}
    //                     <div style={{
    //                         flex: "1 0 280px",
    //                         display: "flex",
    //                         flexDirection: "column",
    //                         alignItems: "stretch",
    //                         margin: 5,
    //                     }}>
    //                         <PackBalanceStatus data={packBalanceStatusData} />
    //                     </div>
    //                 </>) : <div style={{ display: "none" }}></div>
    //             }
    //         </div>
    //     </div>
    // )
}

export default Monitor;