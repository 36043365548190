import config from './Config';
import http from './Http';
import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { DataGrid } from '@material-ui/data-grid';
import { createTheme } from '@material-ui/core/styles';
import InputBase from '@material-ui/core/InputBase';
import { IconButton, Typography } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import {Battery20,Battery30,Battery50,Battery60,Battery80,Battery90,BatteryFull} from '@material-ui/icons';
import {BatteryAlert, BatteryUnknown} from '@material-ui/icons';

const theme = createTheme({});

const useStyles = makeStyles(() => ({
    root: {
        '& .MuiDataGrid-columnHeader': {
            display: 'none',
        },
    }
}));

function DeviceListFor (props) {
    const {setTip, onSelect} = props;

    const [pageNo, setPageNo] = useState(0);
    const [pageSize, setPageSize] = useState(15);
    const [params, setParams] = useState({SN: props.deviceSN ? props.deviceSN : null});
    const [loading, setLoading] = useState(false);
    const [rows, setRows] = useState([]);
    const [rowTotal, setRowTotal] = useState(0);
    const [sn, setSN] = useState("");

    const classes = useStyles();

    useEffect(() => {
        let active = true;
        console.log("DeviceListFor Effect");

        (async () => {
            setLoading(true);
            
            let resp = await http.post("device/list2", {
                PageNo: pageNo,
                PageSize: pageSize,
                ...params,
            }).then(async (r)=>{
                return await r.json();
            }).catch((e)=>{
                console.log("DeviceListFor", e);
                return null;
            });

            if (! active) {
                return;
            }
            
            if (resp == null || resp.code != 200) {
                setTip({open:true, severity:"error", msg:(resp && resp.msg) ? resp.msg : "load data error"});
                
                setRowTotal(0)
                setRows([]);
        
                return;
            }
            setRowTotal(resp.data.RowTotal);
            setRows(resp.data.Rows);
            setLoading(false);
        })();

        return () => {
            active = false;
            console.log("DeviceListFor Effect Cancel");
        };
    },[pageNo, pageSize, params]);

    const handlePageNoChange = (ps) => {
        setPageNo(ps.page);
    }

    const handlePageSizeChange = (ps) => {
        setPageSize(ps.pageSize);
    }

    const handleSelectionModelChange = (ps) => {
        console.log(ps);

        if (ps.length < 1) {
            return;
        }
        
        if (onSelect) {
            let id = ps[0];
            rows.every((v) => {
                if (v.id == id) {
                    onSelect(v.id, v.SN);
                    return false;
                }

                return true;
            })
        }
    }

    const renderCell = (ps) => {
        let color = "#888";
        let icon = <BatteryUnknown style={{color:color}}/>;
        const row = ps.row;
        if (row.IsOnline == 1) {
            const soc = (row.LatestSOC | 0) / 10
            if (row.HasAlarm > 0) {
                color = "red";
            } else {
                color = "green";
            }
            if (soc <=20) {
                icon = <Battery20 style={{color:color}} />;
            } else if (soc <=30) {
                icon = <Battery30 style={{color:color}} />;
            } else if (soc <=50) {
                icon = <Battery50 style={{color:color}} />;
            } else if (soc <=60) {
                icon = <Battery60 style={{color:color}} />;
            } else if (soc <=80) {
                icon = <Battery80 style={{color:color}} />;
            } else if (soc <=90) {
                icon = <Battery90 style={{color:color}} />;
            } else if (soc > 90) {
                icon = <BatteryFull style={{color:color}} />;
            }
        } else {
            icon = <BatteryUnknown style={{color:color}} />;
        }

        return (
            <>
                {icon}
                <Typography>{ps.row.SN}</Typography><br/>
            </>

        )
    };

    const handleSearch = () => {
        setParams({
            SN: sn,
        });
    }

    return (
        <div style={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
        }}>
            <div style={{
                margin: 3,
                border: "1px solid #888",
                borderRadius: 3,
            }}>
                <InputBase 
                    value={sn}
                    style={{
                        paddingLeft:theme.spacing(1),
                    }}
                    placeholder="Enter Device SN"
                    onChange={(e)=>{setSN(e.target.value)}}
                    onKeyUp={(e)=>{
                        //console.log(e.code);
                        if (e.code == 'Enter') {
                            handleSearch();
                        }
                    }}
                />
                <IconButton onClick={handleSearch}>
                    <SearchIcon/>
                </IconButton>
            </div>
            <div style={{flex:"1"}}>
                <DataGrid
                    className={classes.root}
                    loading={loading}
                    page={pageNo}
                    pageSize={pageSize}
                    rowCount={rowTotal}
                    // autoPageSize={true}
                    headerHeight={0}
                    density="compact"
                    disableColumnMenu
                    hideFooterSelectedRowCount
                    sortingOrder="null"
                    paginationMode='server'
                    columns={[{ field: 'SN', headerName: "Device", flex:"1", sortable: false, renderCell: renderCell }]}
                    onPageChange={handlePageNoChange}
                    onPageSizeChange={handlePageSizeChange}
                    onSelectionModelChange={handleSelectionModelChange}
                    rows={rows}
                />
            </div>
        </div>
    );
}

export default DeviceListFor;