import React, { useEffect, useState, useRef, useContext } from 'react';
import { Button, Select, Table, DatePicker } from 'antd';
import http from '../../../Http';
import utils from '../../../utils';
import config from '../../../Config';
import moment from 'moment';

function getTableScroll() {
    var th = document.getElementsByClassName("ant-table-thead")[0];
    let tHeaderBottom = 0
    if (th) {
        tHeaderBottom = th.getBoundingClientRect().top
    }
    let height = `calc(100vh - ${tHeaderBottom + 100}px)`
    return height
}

function AirConditionerData(props) {
    const { inverterSelectOptions, SN, protocol, handleSelectSN } = props
    const setTip = useContext(utils.tipContext)

    const [pageNo, setPageNo] = useState(0)
    const [pageSize, setPageSize] = useState(100)
    const [rows, setRows] = useState([])
    const [rowTotal, setRowTotal] = useState(0)
    const [loading, setLoading] = useState(false)

    const [STime, setSTime] = useState(moment().format("YYYY-MM-DD"))

    const [deviceAddressOptions, setDeviceAddressOptions] = useState([])
    const [deviceAddress, setDeviceAddress] = useState('')

    const refDownloader = useRef(null)

    const [scrollY, setScrollY] = useState("")

    useEffect(() => {
        setScrollY(getTableScroll())
    }, [])

    const ETime = moment(STime).add(1, "days").format("YYYY-MM-DD")

    //取历史数据
    useEffect(() => {
        if (!SN) return;

        let active = true;

        (async () => {
            setLoading(true);

            let resp = await http.post("air-conditioner/history/list", {
                PageNo: pageNo,
                PageSize: pageSize,
                SerialNumber: SN,
                DeviceAddress: deviceAddress,
                STime: STime,
                ETime: ETime,
            }).then(async (r) => {
                return await r.json();
            }).catch((e) => {
                return null;
            });

            if (!active) {
                return;
            }

            if (resp === null || resp.code !== 200) {
                setTip({ open: true, severity: "error", msg: (resp && resp.msg) ? resp.msg : "load data error" });
                setRowTotal(0)
                setRows([])
                return;
            }

            //console.log('air: ', resp.data);

            resp.data.Rows = resp.data.Rows.map(item => {
                //handle data
                let coilData = item.CoilData;
                let registerData = item.RegisterData;
                if(coilData) {
                    if(coilData.length < 16) { //小于16个字符则补足16个
                        coilData = coilData.padEnd(16, '0');
                    } else if(coilData.length > 16) {  //大于16个字符则取前16个
                        coilData = coilData.slice(0, 16);
                    }
                    //将长度为16的hex字符串转换为64bit的串
                    const bits = coilData.split('').map(v => Number('0x' + v).toString(2).padStart(4, '0')).join('')  
                    //取前12个bit
                    const vs = bits.slice(0, 12)
                    //对vs的每个bit进行处理
                    const EvaporatorSensorFault = vs[0] == 0 ? 'Normal' : 'Fault'
                    const CabTempHumSensorFault = vs[1] == 0 ? 'Normal' : 'Fault'
                    const CabinetHighHumAlarm = vs[2] == 0 ? 'Normal' : 'Alarm'
                    const CabinetHighTempAlarm = vs[3] == 0 ? 'Normal' : 'Alarm'
                    const CabinetLowTempAlarm = vs[4] == 0 ? 'Normal' : 'Alarm'
                    const HighPressureAlarm = vs[5] == 0 ? 'Normal' : 'Alarm'
                    const LackOrLeakageFluorine = vs[6] == 0 ? 'Normal' : 'Alarm'
                    const CompressorRelay = vs[7] == 0 ? 'On' : 'Off'
                    const HeatingRelay = vs[8] == 0 ? 'On' : 'Off'
                    const FanRelay = vs[9] == 0 ? 'On' : 'Off'
                    const VentilationRelay = vs[10] == 0 ? 'On' : 'Off'
                    const SystemOnOff = vs[11] == 0 ? 'On' : 'Off'

                    item = {
                        ...item,
                        EvaporatorSensorFault,
                        CabTempHumSensorFault,
                        CabinetHighHumAlarm,
                        CabinetHighTempAlarm,
                        CabinetLowTempAlarm,
                        HighPressureAlarm,
                        LackOrLeakageFluorine,
                        CompressorRelay,
                        HeatingRelay,
                        FanRelay,
                        VentilationRelay,   
                        SystemOnOff,
                    }
                }
                if(registerData) {  //只取前2个字
                    if(registerData.length < 8) { //小于8个字符则补足8个
                        registerData = registerData.padEnd(8, '0');
                    } else if(registerData.length > 8) {  //大于8个字符则取前8个
                        registerData = registerData.slice(0, 8);
                    }
                    //将hex字符串转换为bit串
                    const bits = registerData.split('').map(v => Number('0x' + v).toString(2).padStart(4, '0')).join('')  
                    //将bit的串按双字节分割为数组
                    const bytes = bits.match(/(.{16})/g);
                    //对bytes的每个字进行处理
                    const vs = bytes.map(v => {
                        v = parseInt(v, 2)
                        if(v > 0x7fff) {
                            v -= 0xffff + 1
                        }
                        return v;
                    })
                    const CabinetTemperature = vs[0];
                    const CabinetHumidity = vs[1];

                    item = {
                        ...item,
                        CabinetTemperature,
                        CabinetHumidity,
                    }
                }

                return item;
            })

            setRowTotal(resp.data.RowTotal);
            setRows(resp.data.Rows);
            setLoading(false);
        })();

        return () => {
            active = false;
        };
    }, [pageNo, pageSize, SN, deviceAddress, STime]);

    //取DeviceAddress列表
    useEffect(() => {
        if (!SN) return;

        let active = true;

        (async () => {
            let resp = await http.post("air-conditioner/device-address/list", {
                SerialNumber: SN,
            }).then(async (r) => {
                return await r.json();
            }).catch((e) => {
                return null;
            });

            if (!active) {
                return;
            }

            if (resp === null || resp.code !== 200) {
                setTip({ open: true, severity: "error", msg: (resp && resp.msg) ? resp.msg : "load data error" });
                setDeviceAddressOptions([])
                return;
            }

            //所有设备地址项
            var ts = [];
            ts.push(<Select.Option key='' value=''>All</Select.Option>)
            for (let k in resp.data) {
                const v = resp.data[k].DeviceAddress
                ts.push(<Select.Option key={v} value={v}>{v}</Select.Option>)
            } 

            setDeviceAddressOptions(ts);
        })();

        return () => {
            active = false;
        };
    }, [pageNo, pageSize, SN]);

    const handleExport = () => {
        if (!refDownloader.current) {
            return;
        }
        refDownloader.current.click()
    }

    return (
        <div style={{ width: "100%", height: "100%", display: "flex", flexDirection: "column" }}>
            <div style={{ flex: "none", padding: "10px" }}>
                <span>Select Inverter</span>
                <Select
                    showSearch
                    placeholder="Enter Inverter Name"
                    defaultActiveFirstOption={false}
                    showArrow={true}
                    filterOption={true}
                    onSelect={(value) => {
                        handleSelectSN(value)
                        setDeviceAddressOptions([])
                        setDeviceAddress('')
                    }}
                    value={SN}
                    style={{ width: '200px', marginRight: '20px', marginLeft: '10px' }}
                >{inverterSelectOptions}</Select>
                <span>Select Device Address</span>
                <Select 
                    showSearch
                    placeholder="Select Device Address"  
                    showArrow={true}
                    filterOption={true} 
                    onSelect={(value) => {
                        setDeviceAddress(value)
                        setPageNo(0)
                    }}
                    value={deviceAddress} 
                    style={{ width: '150px', marginRight: '20px', marginLeft: '10px'}}
                >
                    {deviceAddressOptions}
                </Select>

                <DatePicker
                    defaultValue={STime}
                    value={moment(STime)}
                    onChange={(_, ds) => {
                        setSTime(ds)
                    }}
                    allowClear={false}
                />

                <Button type="primary" disabled={rows.length < 1 ? true : false} onClick={handleExport} style={{ float: 'right', marginRight: '10px', backgroundColor: '#0486be' }}>Export Data</Button>
            </div>
            <div style={{ flex: "auto", overflow: "hidden" }}>
                <Table
                    columns={[
                        {
                            title: "",
                            width: 40,
                            fixed: 'left',
                            render: (v, r, idx) => {
                                return (pageNo * pageSize + idx + 1);
                            }
                        }, {
                            title: 'SN',
                            dataIndex: "SN",
                            width: 160,
                            fixed: 'left',
                        }, {
                            title: 'Device Type',
                            dataIndex: 'DeviceType',
                            width: 90,
                            render: (v) => {
                                return v 
                            }
                        }, {
                            title: 'Device Address',
                            dataIndex: 'DeviceAddress',
                            width: 120,
                        }, {
                            title: 'Evaporator sensor Fault',
                            dataIndex: 'EvaporatorSensorFault',
                            width: 168,
                        }, {
                            title: 'Cabinet TempAndHum Sensor Fault',
                            dataIndex: 'CabTempHumSensorFault',
                            width: 240,
                        }, {
                            title: 'Cabinet HighHum Alarm',
                            dataIndex: 'CabinetHighHumAlarm',
                            width: 170,
                        }, {
                            title: 'Cabinet HighTemp Alarm',
                            dataIndex: 'CabinetHighTempAlarm',
                            width: 175,
                        }, {
                            title: 'Cabinet LowTemp Alarm',
                            dataIndex: 'CabinetLowTempAlarm',
                            width: 170,
                        }, {
                            title: 'High Pressure Alarm',
                            dataIndex: 'HighPressureAlarm',
                            width: 150,
                        }, {
                            title: 'LackOrLeakage Fluorine',
                            dataIndex: 'LackOrLeakageFluorine',
                            width: 170,
                        }, {
                            title: 'Compressor Relay',
                            dataIndex: 'CompressorRelay',
                            width: 130,
                        }, {
                            title: 'Heating Relay',
                            dataIndex: 'HeatingRelay',
                            width: 110,
                        }, {
                            title: 'Fan Relay',
                            dataIndex: 'FanRelay',
                            width: 100,
                        }, {
                            title: 'Ventilation Relay',
                            dataIndex: 'VentilationRelay',
                            width: 140,
                        }, {
                            title: 'System On/Off',
                            dataIndex: 'SystemOnOff',
                            width: 140,
                        }, {
                            title: 'Cabinet Temperature',
                            dataIndex: 'CabinetTemperature',
                            width: 150,
                            render: v => {
                                return v ? v / 10 : v
                            }
                        }, {
                            title: 'Cabinet Humidity',
                            dataIndex: 'CabinetHumidity',
                            width: 150,
                            render: v => {
                                return v ? v / 10 : v
                            }
                        }, {
                            title: 'CreateTime',
                            dataIndex: 'CreateTime',
                            width: 120,
                        }
                    ]}
                    pagination={{
                        pageSizeOptions: [25, 50, 100],
                        current: pageNo + 1,
                        pageSize: pageSize,
                        total: rowTotal,
                        showSizeChanger: true,
                        showTotal: (total) => { return `Total ${total}` },
                        onShowSizeChange: (current, size) => {
                            setPageSize(size);
                        },
                        onChange: (page, pageSize) => {
                            setPageNo(page - 1);
                        }
                    }}
                    tableLayout="fixed"
                    bordered={true}
                    size="small"
                    loading={loading}
                    rowKey="ID"
                    dataSource={rows}
                    scroll={{ x: 'max-content', y: scrollY }}
                >
                </Table>
            </div>

            <a ref={refDownloader} style={{ display: "none" }} href={config.apiUrl + "data-export/air-conditioner/history/do?Token=" + http.token + "&SerialNumber=" + SN + "&DeviceAddress=" + deviceAddress + "&STime=" + STime + "&ETime=" + ETime}></a>
        </div>
    )
}

export default AirConditionerData;