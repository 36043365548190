import http from '../Http';
import { useContext, useState, useRef, useEffect } from 'react';
import utils from '../utils';
import { Modal, Button, Input, Form, Select, Switch } from 'antd';
import { PlusOutlined, MinusCircleOutlined, EnvironmentOutlined } from '@ant-design/icons';
import GPSGetter from "../DistributorManagement/GPSGetter";
import InputPrefix from '../utils/InputPrefix';


function EndUserModifier(props) {
    const setTip = useContext(utils.tipContext);

    const { show, onClose, row } = props;

    const [form] = Form.useForm();

    const [deviceSelectOptions, setDeviceSelectOptions] = useState([]);
    const [params, setParams] = useState({});
    const [showGPSGetter, setShowGPSGetter] = useState(false);

    const refTimerDeviceSearcher = useRef(null);
    const refDeviceSearchValue = useRef(null);

    const latRef = useRef();
    const lngRef = useRef();
    const [centerPoint, setCenterPoint] = useState([])
    const [centerPin, setCenterPin] = useState([])

    const [timezoneSelectOptions, setTimezoneSelectOptions] = useState([]);
    const refTimerTimezoneSearcher = useRef(null);
    const refTimezoneSearchValue = useRef(null);

    useEffect(() => {
        if (!row) {
            return;
        }

        let active = true;
        console.log("EndUserModifier Effect");

        (async () => {
            let resp = await http.post("user/management/end-user/detail/get", {
                UserID: row.ID,
            }).then(async (r) => {
                return await r.json();
            }).catch((e) => {
                console.log("[UserManagement]", e);
                return null;
            });

            if (!active) {
                return;
            }

            if (resp === null || resp.code !== 200) {
                setTip({ open: true, severity: "error", msg: (resp && resp.msg) ? resp.msg : "load data error" });

                setParams({});

                return;
            }
            if (resp.data && resp.data.length > 0) {
                const ps = resp.data[0];
                if (ps.Devices && ps.Devices.length > 0) {
                    ps.Devices = JSON.parse(ps.Devices);
                    ps.Devices.forEach((v) => {
                        v._ID = v.ID;
                        v.ID = v.SN;
                    })
                }
                if (ps.Latitude !== null) {
                    ps.Latitude /= 1000000;
                }
                if (ps.Longitude !== null) {
                    ps.Longitude /= 1000000;
                }

                setParams(ps);
                form.setFieldsValue(ps);
            }

        })();

        return () => {
            active = false;
            console.log("UserManagement Effect Cancel");
        };
    }, [row]);

    const handleCancle = () => {
        onClose(null);
    }

    const handleSubmit = async () => {
        const ps = await form.validateFields().catch(() => { return null });

        if (ps === null) {
            return;
        }

        ps.Devices = ps.Devices && ps.Devices.length > 0 ? ps.Devices.map((v) => {
            let ID = v.ID;
            if (params.Devices) {
                params.Devices.forEach((v) => {
                    if (ID === v.ID) {
                        ID = v._ID;
                    }
                });
            }
            return `${ID}`;
        }).join(",") : "";

        ps.CreateTime = row.CreateTime;
        ps.Enabled = ps.Enabled ? 1 : 0;
        ps.Name = ps.Name || "";
        ps.Phone = ps.Phone || "";
        ps.EMail = ps.EMail || "";
        ps.Address = ps.Address || "";
        ps.Latitude *= 1000000;
        ps.Longitude *= 1000000;


        const resp = await http.post("user/management/end-user/modify", ps).then(async (r) => {
            return await r.json();
        }).catch((e) => {
            console.log("[UserModify]", e);
            return null;
        });

        try {
            if (resp.code !== 200) {
                setTip({ open: true, severity: "error", msg: (resp && resp.msg) ? resp.msg : "modify error" });
                return
            }

            setTip({ open: true, severity: 'success', msg: 'modify success' });

            ps.GmtOffset = resp.data.GmtOffset;

            onClose({ ...params, ...ps });
        } catch (e) {
            console.log("[UserModify]", e);

            setTip({ open: true, severity: "error", msg: "modify error" });
        }
    }

    const handleGetGPS = () => {
        setShowGPSGetter(true);
        const lat = latRef.current.props.value;
        const lng = lngRef.current.props.value;
        setCenterPoint([lat, lng])
        setCenterPin([{ "location": [lat, lng] }])
    }

    const handleGPSGetterClose = (location) => {
        setShowGPSGetter(false);

        if (location) {
            const lat = parseFloat(location.Latitude.toFixed(6));
            const lng = parseFloat(location.Longitude.toFixed(6));

            form.setFieldsValue({ Latitude: lat, Longitude: lng });
        }
    }

    const handleSearchDevice = (v, index) => {
        if (refTimerDeviceSearcher.current != null) {
            clearTimeout(refTimerDeviceSearcher.current);
        }
        refDeviceSearchValue.current = v;

        const ids = form.getFieldValue("Devices");
        let id = "";
        ids.forEach((v, idx) => {
            if (idx === index) {
                return;
            }

            if (v && v.ID && v.ID.length >= 19) {
                id = v.ID;
            }
        })

        refTimerDeviceSearcher.current = setTimeout(async () => {
            const resp = await http.post("device/id-name/list", {
                DistributorIsTheSameAsTheDeviceID: id,
                SN: v,
            }).then(async (r) => {
                return await r.json();
            }).catch((e) => {
                console.log("[UserAdd]", e);
                return null;
            });

            if (refDeviceSearchValue.current !== v) {
                return;
            }

            try {
                if (resp.code !== 200) {
                    setTip({ open: true, severity: "error", msg: (resp && resp.msg) ? resp.msg : "load device list error" });
                    return;
                }

                const ls = resp.data.map(({ SN, ID, UserID }) => {
                    var rBy = "";
                    if (UserID != null) {
                        rBy = UserID;
                        if (rBy.length > 3) {
                            rBy = rBy.substring(0, 3).padEnd(rBy.length, "*");
                        }
                        rBy = "(RegisteredBy: " + rBy + ")";
                    }
                    return (
                        <Select.Option key={ID} value={ID} disabled={UserID != null}>{SN + rBy}</Select.Option>
                    );
                });
                setDeviceSelectOptions(ls);
            } catch (e) {
                console.log("[UserAdd]", e);

                setTip({ open: true, severity: "error", msg: "load device list error" });
            }
        }, 300);
    }

    const handleSearchTimezone = (v) => {
        if (refTimerTimezoneSearcher.current != null) {
            clearTimeout(refTimerTimezoneSearcher.current);
        }
        refTimezoneSearchValue.current = v;


        refTimerTimezoneSearcher.current = setTimeout(async () => {
            const resp = await http.post("timezone/list", {
                Timezone: v,
            }).then(async (r) => {
                return await r.json();
            }).catch((e) => {
                console.log("[DeviceAdd]", e);
                return null;
            });

            if (refTimezoneSearchValue.current !== v) {
                return;
            }

            try {
                if (resp.code !== 200) {
                    setTip({ open: true, severity: "error", msg: (resp && resp.msg) ? resp.msg : "load timezone list error" });
                    return;
                }

                const ls = resp.data.map(({ ZoneName, GmtOffset }) => {
                    var n = parseInt(GmtOffset);
                    var tz = "UTC+";
                    if (n < 0) {
                        tz = "UTC-";
                        n = Math.abs(n);
                    }
                    tz += Math.floor(n / 3600).toString().padStart(2, "0") + ":" + Math.floor((n % 3600) / 60).toString().padStart(2, "0")
                    return (
                        <Select.Option key={ZoneName} value={ZoneName}>{ZoneName}({tz})</Select.Option>
                    );
                });
                setTimezoneSelectOptions(ls);
            } catch (e) {
                console.log("[Device-Adder]", e);

                setTip({ open: true, severity: "error", msg: "load device list error" });
            }
        }, 300);
    }

    return (<Modal
        title="Modify End User"
        visible={show}
        cancelText="Cancel"
        okText="Submit"
        mask={true}
        maskClosable={false}
        width={500}
        onCancel={handleCancle}
        onOk={handleSubmit}
        footer={[
            <Button
                key="Clear"
                shape="round"
                onClick={() => {
                    form.resetFields();
                }}
            >Clear</Button>,
            <Button key="Cancel" shape="round" onClick={handleCancle}>Cancel</Button>,
            <Button key="Submit" shape="round" type="primary" onClick={handleSubmit}>Submit</Button>
        ]}
    >
        <Form
            labelAlign="right"
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 25 }}
            form={form}
            initialValues={params}
        >
            <Form.Item label="Devices" rules={[{ required: true }]}>
                <Form.List name="Devices" label="Devices">
                    {(fields, { add, remove }) => (
                        <>
                            {fields.map(({ key, name, fieldKey, ...restField }, idx) => (
                                <div key={key} style={{
                                    display: "flex",
                                    alignItems: "center",
                                }}>
                                    <Form.Item
                                        {...restField}
                                        name={[name, 'ID']}
                                        fieldKey={[fieldKey, 'ID']}
                                        rules={[{ required: true, message: 'Missing SN' }]}
                                        style={{
                                            flex: "1 1 150px",
                                        }}
                                    >
                                        <Select
                                            showSearch
                                            placeholder="Device SN"
                                            defaultActiveFirstOption={false}
                                            showArrow={false}
                                            filterOption={false}
                                            onSearch={(value) => {
                                                handleSearchDevice(value, idx);
                                            }}
                                        >{deviceSelectOptions}</Select>
                                    </Form.Item>
                                    <div style={{ marginLeft: '6px', marginBottom: "24px" }}>
                                        <MinusCircleOutlined onClick={() => remove(name)} />
                                    </div>
                                </div>
                            ))}
                            <Form.Item>
                                <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />} style={{borderRadius: '20px'}}>
                                    Register Device
                                </Button>
                            </Form.Item>
                        </>
                    )}
                </Form.List>
            </Form.Item>
            <Form.Item
                name="Name"
                label=""
            >
                <Input prefix={<InputPrefix label="Title" />} style={{ borderRadius: '20px' }} />
            </Form.Item>
            <Form.Item
                name="ID"
                label=""
                rules={[{ required: true, min: 3 }]}
            >
                <Input prefix={<InputPrefix label="UserID" required />} style={{ borderRadius: '20px' }} disabled />
            </Form.Item>
            <Form.Item
                name="Phone"
                label=""
            >
                <Input prefix={<InputPrefix label="Phone" />} placeholder="Phone Number" style={{ borderRadius: '20px' }} />
            </Form.Item>
            <Form.Item
                name="EMail"
                label=""
            >
                <Input prefix={<InputPrefix label="E-Mail" />} style={{ borderRadius: '20px' }} />
            </Form.Item>
            <Form.Item
                name="Address"
                label=""
            >
                <Input prefix={<InputPrefix label="Address" />} style={{ borderRadius: '20px' }} />
            </Form.Item>
            <Input.Group compact>
                <Button><span style={{color: 'red', verticalAlign: 'middle', marginRight: '5px'}}>*</span>Timezone</Button>
                <Form.Item
                    name="Timezone"
                    label=""
                    style={{width: 'calc(100% - 102px'}}
                    rules={[{ required: true, type: 'string' }]}
                >
                    <Select
                        showSearch
                        placeholder="Timezone"
                        defaultActiveFirstOption={false}
                        showArrow={false}
                        filterOption={false}
                        onSearch={handleSearchTimezone}
                    >{timezoneSelectOptions}</Select>
                </Form.Item>
            </Input.Group>
            <Form.Item
                label=""
            >
                <Form.Item
                    name="Longitude"
                    rules={[{ required: true, type: 'number' }]}
                    style={{ display: 'inline-block', width: 'calc(50% - 30px)', marginRight: '10px' }}
                >
                    <Input ref={lngRef} prefix={<InputPrefix label="GPS" />} placeholder="Logitude, e.g.: 118.123456" style={{ borderRadius: '20px' }} />
                </Form.Item>
                <Form.Item
                    name="Latitude"
                    rules={[{ required: true, type: 'number' }]}
                    style={{ display: 'inline-block', width: 'calc(50% - 30px)', marginRight: '10px' }}
                >
                    <Input ref={latRef} prefix={<InputPrefix noArrow />} placeholder="Latitude, e.g.: 31.123456" style={{ borderRadius: '20px' }} />
                </Form.Item>
                <Button type="link" onClick={handleGetGPS} icon={<EnvironmentOutlined />} />
            </Form.Item>
            <Form.Item
                name="Enabled"
                label="Enabled"
                valuePropName="checked"
                style={{marginTop: '-20px'}}
            >
                <Switch />
            </Form.Item>
        </Form>
        <GPSGetter centerPoint={centerPoint} centerPin={centerPin} show={showGPSGetter} onClose={handleGPSGetterClose} />
    </Modal>);
}

export default EndUserModifier;