import React, { useEffect, useState, useContext } from 'react';
import DTUConfiguration from './DTUConfiguration';
import InverterConfiguration from './InverterConfiguration';

function Configuration(props) {
    const {inverterSelectOptions, SN, protocol, handleSelectSN} = props
    const [activeMenu, setActiveMenu] = useState(0) 

    return (
        <div className="configuration">
            <div className="data-nav"> {/* 左侧菜单栏 */}
                <ul>
                    <li className={activeMenu==0 ? 'active' : null} onClick={() => setActiveMenu(0)}>DTU</li>
                    <li className={activeMenu==1 ? 'active' : null} onClick={() => setActiveMenu(1)}>Inverter</li>
                </ul>
            </div>
            <div className="data-content">  {/* 主体内容 */}
                { 
                    activeMenu == 0 ? <DTUConfiguration
                    inverterSelectOptions={inverterSelectOptions} SN={SN} protocol={protocol} handleSelectSN={handleSelectSN} /> : 
                    activeMenu == 1 ? <InverterConfiguration inverterSelectOptions={inverterSelectOptions} SN={SN} protocol={protocol} handleSelectSN={handleSelectSN} /> : 
                    null
                }
            </div>
        </div>
    )
}

export default Configuration;