import config from '../../Config';
import http from "../../Http";
import utils from '../../utils';
import React, { useContext, useState, useEffect, useRef } from 'react';

function WS (props) {
    const setTip = useContext(utils.tipContext);
    const {onConnect, onData, onClose, onError, SerialNumber} = props;
    const refWss = useRef(null);

    const connect = () => {
        const wss = new WebSocket(config.wssInverterUrl+`?Token=${http.token}&SerialNumber=${SerialNumber}`);
        wss.addEventListener('open', handleWssOpen);
        wss.addEventListener('message', handleWssMessage);
        wss.addEventListener('close', handleWssClose);
        wss.addEventListener('error', handleWssError);
    }

    useEffect(() => {
        connect();

        return () => {
            if (refWss.current) {
                refWss.current.close();
            }
            refWss.current = null;
        }
    }, []);

    const handleWssOpen = (evt) => {
        const wss = evt.currentTarget;

        refWss.current = wss;

        evt.currentTarget.tmrPing = setInterval(()=>{
            if (! wss.tmrPing) {
                return;
            }
    
            wss.send("Ping");
        }, 30000);

        if (onConnect) {
            onConnect();
        }
    }

    const handleWssMessage = (evt) => {
        const d = evt.data;
        if (d == "Pong") {
            return;
        }

        try {
            const data = JSON.parse(d);
            if (! data || data["InverterSN"] != SerialNumber) {
                return;
            }

            if (onData) {
                onData(data);
            }
        } catch(e) {
            console.log("[WS]", e);
        }
    }

    const handleWssClose = (evt) => {
        console.log("Wss Close", evt);

        let wss = evt.currentTarget;
        wss.removeEventListener('open', handleWssOpen);
        wss.removeEventListener('message', handleWssMessage);
        wss.removeEventListener('close', handleWssClose);
        wss.removeEventListener('error', handleWssError);
        if (wss.tmrPing) {
            clearInterval(wss.tmrPing);
            wss.tmrPing = null;
        }

        refWss.current = null;

        setTimeout(() => {
            connect();
        }, 1000);

        if (onClose) {
            onClose();
        }

    }

    const handleWssError = (evt) => {
        console.log("Wss Error", evt);

        evt.currentTarget.close();

        if (onError) {
            onError();
        }
    }

    return <div></div>
}

export default WS;