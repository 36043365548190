import TitleBar from '../utils/TitleBar.js';

function PackBalanceStatus(props) {
    const { data } = props;

    const bs = [];
    const ds = (data && data.Status) ? data.Status : [];
    //var ds = [1,2,3,0,3]

    for (let i = 0; i< ds.length; i++) {
        var color = "gray";
        var txt = "";
        var s = ds[i];
        if (s == 0) { // closed
            color = "brown";
            txt = "closed";
        } else if (s == 1) { //open
            color = "blue";
            txt = "open";
        } else if (s == 2) { //fault
            color = "red";
            txt = "fault";
        } else if (s == 3) { //reserved
            color = "black";
            txt = "reserved";
        }
        bs.push(
            <div style={{
                display: 'flex'
            }}>
                <span style={{ color: "black", fontSize: 10 }}>Pack{(i+1).toString().padStart(2, 0)}:&nbsp;</span>
                <span style={{ color: color, fontSize: 10 }}>{txt}</span>
            </div>
        );
    }

    return (
        <div style={{
            padding: '10px 15px'
        }}>
            <TitleBar title="Pack Balance Status" data={data} />
            <div style={{marginTop: '20px'}}>
                <div style={{backgroundColor: '#f0f0f0', borderRadius: '10px', marginRight: '10px', marginLeft: '10px', padding: '4px 6px'}}>
                    <div style={{fontSize: '12px', fontWeight: 'bold', borderBottom: '1px solid #1B8AC5', marginBottom: '6px'}}>
                        Status
                    </div>
                    <div>
                        {bs}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PackBalanceStatus;