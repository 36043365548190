import { useContext, useRef } from 'react';
import { Modal, Form, Upload, Button } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import config from './Config';
import http from './Http';
import utils from './utils';

function LogoModifier({ showLogoModifier, logoPath, session, onClose }) {
    const [form] = Form.useForm()
    const setTip = useContext(utils.tipContext)
    const refLogo = useRef()

    const handleSubmit = async () => {
        const ps = await form.validateFields().catch(() => {return null})
        if (ps == null) {
            return
        }

        ps.DistributorID = session&&session.Distributors ? session&&session.Distributors&&session.Distributors[session.Distributors.length-1].ID : ''
        ps.logoPath = ps.logoPath&&ps.logoPath.file&&ps.logoPath.file.response&&ps.logoPath.file.response.data ? ps.logoPath.file.response.data[ps.logoPath.file.name] : ''

        const resp = await http.post('logo/modify', ps).then(async r => {
            return await r.json()
        }).catch(e => {
            return null
        })

        try {
            if(resp.code != 200) {
                onClose()
                return
            }
            ps.logoPath = resp.data.LogoPath
            onClose(ps.logoPath)
        } catch(e) {
            onClose()
        }
    }

    const handleCancel = () => {
        onClose()
        form.resetFields()
    }

    return (
        <Modal
            title="Logo Modifier"
            visible={showLogoModifier}
            cancelText="Cancel"
            okText="Submit"
            mask={true}
            maskClosable={false}
            width={600}
            onCancel={handleCancel}
            onOk ={handleSubmit}
            footer={[
                <Button key="Cancel" onClick={handleCancel}>Cancel</Button>,
                <Button key="Submit" onClick={handleSubmit}>Submit</Button>,
            ]}
        >
            <Form 
                labelAlign="right"
                labelCol={{span: 7}}
                wrapperCol={{span: 16}}
                form={form}
            >
                <Form.Item
                    name="logoPath"
                    valuePropName="file"
                >
                    <Upload
                        maxCount={1}
                        accept=".png,.jpg,.jpeg"
                        name="logoPath"
                        action={config.apiUrl+"uploader/logo/do?Token="+http.token}
                        headers={{Token:http.token}}
                        listType="text"
                        beforeUpload={file => {
                            const fileType = file.type
                            const typeTrue = fileType==='image/png' || fileType==='image/jpg' || fileType==='image/jpeg'
                            if(!typeTrue) {
                                setTip({ open: true, severity: "error", msg: "The image type must be png,jpg,jpeg" })
                                return Upload.LIST_IGNORE
                            }
                            return true
                        }}
                        defaultFileList={[{
                            status: "done",
                            url: logoPath ? logoPath : config.appIcon
                        }]}
                        onChange={r => {
                            if(r.file.status == 'done') {
                                refLogo.current.src = config.apiUrl+r.file.response.data[r.file.name]
                            }
                        }}
                    >
                        <Button icon={<UploadOutlined />}>Choose Logo</Button> <span style={{verticalAlign: 'bottom', fontStyle: 'italic', color: '#888'}}>the best logo size is 160px * 40px</span>
                    </Upload>
                </Form.Item>
            </Form>
            <div style={{width: '500px', height: '92px', backgroundColor: 'rgb(99, 173, 130)', paddingLeft: '24px', lineHeight: '92px'}}>
                <img ref={refLogo} style={{width: '160px', height: '40px', verticalAlign: 'middle'}} 
                    src={logoPath ? logoPath : config.appIcon}
                />
            </div>
        </Modal>
    )
}

export default LogoModifier;