import http from '../Http';
import { useState, useContext, useEffect } from 'react';
import utils from '../utils';
import { Modal, Button, Input, Form, Select, Space } from 'antd';
import {PlusOutlined, MinusCircleOutlined} from '@ant-design/icons';


function ProductTypeAdd (props) {
    const setTip = useContext(utils.tipContext);

    const {show, onClose} = props;

    const [fieldItems, setFieldItems] = useState([])
    const [showField, setShowField] = useState(false)
    const [templates, setTemplates] = useState([])  //产品模板集合
    const [templateID, setTemplateID] = useState('0')  //产品模板id

    const [form] = Form.useForm();

    //取所有产品的模板
    useEffect(() => {
        let active = true;

        (async () => {
            let resp = await http.post("product/name/list", {
                Value: ''
            }).then(async (r)=>{
                return await r.json();
            }).catch((e)=>{
                return null;
            });

            if (! active) {
                return;
            }
            
            if (resp === null || resp.code !== 200) {
                setTip({open:true, severity:"error", msg:(resp && resp.msg) ? resp.msg : "load data error"});
                
                setTemplates([])
        
                return;
            }

            setTemplates([{ID: '0', Name: 'No Template'}, ...resp.data])
        })();

        return () => {
            active = false;
        };
    }, []);

    //取产品模板
    useEffect(() => {
        if(templateID == '0') {
            setFieldItems([])
            return
        }
        let active = true;

        (async () => {
            let resp = await http.post("product/template/get", {
                ID: templateID
            }).then(async (r)=>{
                return await r.json();
            }).catch((e)=>{
                return null;
            });

            if (! active) {
                return;
            }
            
            if (resp === null || resp.code !== 200) {
                setTip({open:true, severity:"error", msg:(resp && resp.msg) ? resp.msg : "load data error"});
                
                setTemplates([])
        
                return;
            }

            const templateItems = resp.data.filter(v => !['Name', 'Category', 'VoltageType'].includes(v['Name'])).map(v => {
                let item,
                    name = v['Name'],
                    data = v['RangeX'],
                    unit = v['Unit'];
                if(data.startsWith('1,')) {
                    let s = data.substring(data.indexOf('[')+1, data.indexOf(']'))
                    if(!s.includes(',')) {  //Single Value 
                        item = <Input.Group compact>
                            <Form.Item name={[name, 'FieldValue']} noStyle rules={[{required: true}]}>
                                <Input style={{width: 300}} />
                            </Form.Item>
                            <Form.Item name={[name, '1-Unit']} initialValue={unit} noStyle>
                                <Input placeholder='Unit' style={{width: 70, marginLeft: 10}} />
                            </Form.Item>
                        </Input.Group>
                    } else {  //Range
                        item = <Input.Group compact>
                            <Form.Item name={[name, 'FieldValue1']} noStyle rules={[{ required: true, type:"number", transform: function(v) {return parseFloat(v) } }]}>
                                <Input placeholder="Min" style={{width: 100}} />
                            </Form.Item>
                            <Form.Item noStyle>
                                <Input placeholder="~" disabled style={{
                                    width: 30,
                                    borderLeft: 0,
                                    borderRight: 0,
                                    pointerEvents: 'none',
                                }} />
                            </Form.Item>
                            <Form.Item name={[name, 'FieldValue2']} noStyle rules={[{ required: true, type:"number", transform: function(v) {return parseFloat(v) } }]}>
                                <Input placeholder="Max" style={{width: 100}} />
                            </Form.Item>
                            <Form.Item name={[name, '2-Unit']} initialValue={unit} noStyle>
                                <Input placeholder='Unit' style={{width: 70, marginLeft: 10}} />
                            </Form.Item>
                        </Input.Group>
                    }
                } else if(data.startsWith('2,')) {  //Enum
                    let opts = data.substring(data.indexOf('{')+1, data.indexOf(':')).split(',').map(v => ({
                        label: v.substring(0, v.indexOf('@')),
                        value: v.substring(v.indexOf('@')+1)
                    }))
                    const text = opts.map(v => v.label+'@'+v.value).join(',')
                    item = <Input.Group compot>
                        <Form.Item name={[name, 'FieldValue']} noStyle rules={[{ required: true }]}>
                            <Select style={{width: 380}}>
                                {opts.map(v => (
                                    <Select.Option key={v.value} value={v.value}>{v.label}</Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <Form.Item hidden initialValue={text} name={[name, '3-Unit']}>
                            <Input />
                        </Form.Item>
                    </Input.Group>
                } else if(data.startsWith('3,')) {
                    let s = data.substring(data.indexOf(',')+1)
                    if(s.indexOf('*') == s.lastIndexOf('*')) {  //2D
                        item = <Input.Group compact>
                            <Form.Item name={[name, 'FieldValue1']} noStyle rules={[{ required: true }]}>
                                <Input placeholder="D1" style={{width: 100}} />
                            </Form.Item>
                            <Form.Item noStyle>
                                <Input placeholder="*" disabled style={{
                                    width: 30,
                                    borderLeft: 0,
                                    borderRight: 0,
                                    pointerEvents: 'none',
                                }} />
                            </Form.Item>
                            <Form.Item name={[name, 'FieldValue2']} noStyle rules={[{ required: true }]}>
                                <Input placeholder="D2" style={{width: 100}} />
                            </Form.Item>
                            <Form.Item name={[name, '4-Unit']} initialValue={unit} noStyle>
                                <Input placeholder='Unit' style={{width: 70, marginLeft: 10}} />
                            </Form.Item>
                        </Input.Group>
                    } else {  //3D
                        item = <Input.Group compact>
                            <Form.Item name={[name, 'FieldValue1']} noStyle rules={[{ required: true }]}>
                                <Input placeholder="D1" style={{width: 80}} />
                            </Form.Item>
                            <Form.Item noStyle>
                                <Input placeholder="*" disabled style={{
                                    width: 30,
                                    borderLeft: 0,
                                    borderRight: 0,
                                    pointerEvents: 'none',
                                }} />
                            </Form.Item>
                            <Form.Item name={[name, 'FieldValue2']} noStyle rules={[{ required: true }]}>
                                <Input placeholder="D2" style={{width: 80}} />
                            </Form.Item>
                            <Form.Item noStyle>
                                <Input placeholder="*" disabled style={{
                                    width: 30,
                                    borderLeft: 0,
                                    borderRight: 0,
                                    pointerEvents: 'none',
                                }} />
                            </Form.Item>
                            <Form.Item name={[name, 'FieldValue3']} noStyle rules={[{ required: true }]}>
                                <Input placeholder="D3" style={{width: 80}} />
                            </Form.Item>
                            <Form.Item name={[name, '5-Unit']} initialValue={unit} noStyle>
                                <Input placeholder='Unit' style={{width: 70, marginLeft: 10}} />
                            </Form.Item>
                        </Input.Group>
                    }
                }
                return <Form.Item 
                    key={v['Name']}
                    name={v['Name']}
                    label={v['Name']}
                    rules={[{ required: true }]}
                >
                    {item}
                </Form.Item>
            })
            setFieldItems(templateItems)
        })();

        return () => {
            active = false;
        };
    }, [templateID])

    const handleCancle = () => {
        setFieldItems([]);
        setTemplateID('0')
        form.resetFields();
        onClose(null);
    }

    const handleSubmit = async () => {
        const ps = await form.validateFields().catch(() => {return null});
        
        if (ps === null) {
            return;
        }

        const ps1 = {}  //发送到数据库
        const ps2 = {}  //返回给上级组件

        ps1['Name'] = `RangeX:1,[${ps['Name']}],Unit:`
        ps1['Category'] = `RangeX:2,{General BMS@0,Energy Port@1,Master-Control Unit@2:${ps['Category']}},Unit:`
        ps1['VoltageType'] = `RangeX:2,{H@H,L@L:${ps['VoltageType']}},Unit:`
        
        for(let key in ps) {
            if(['Name', 'Category', 'VoltageType'].includes(key)) continue
            if('1-Unit' in ps[key]) {  //Single Value
                ps2[key] = `RangeX:1,[${ps[key]['FieldValue'].trim()}],Unit:${ps[key]['1-Unit'].trim()}`
            } else if('2-Unit' in ps[key]) {  //Range
                ps2[key] = `RangeX:1,[${ps[key]['FieldValue1'].trim()},${ps[key]['FieldValue2'].trim()}],Unit:${ps[key]['2-Unit'].trim()}`
            } else if('3-Unit' in ps[key]) {  //Enum
                ps2[key] = `RangeX:2,{${ps[key]['3-Unit'].trim()}:${ps[key]['FieldValue'].trim()}},Unit:`
            } else if('4-Unit' in ps[key]) {  //2 Dimentions
                ps2[key] = `RangeX:3,${ps[key]['FieldValue1'].trim()}*${ps[key]['FieldValue2'].trim()},Unit:${ps[key]['4-Unit'].trim()}`
            } else if('5-Unit' in ps[key]) {  //3 Dimentions
                ps2[key] = `RangeX:3,${ps[key]['FieldValue1'].trim()}*${ps[key]['FieldValue2'].trim()}*${ps[key]['FieldValue3']},Unit:${ps[key]['5-Unit'].trim()}`
            }
            ps1[key] = encodeURIComponent(ps2[key])
        }
        
        const resp = await http.post("product/add", ps1).then(async (r)=>{
            return await r.json();
        }).catch((e) => {
            console.log("[Product Type Add]", e);
            return null;
        });

        try {
            if (resp.code !== 200) {
                setTip({open:true, severity:"error", msg:(resp && resp.msg) ? resp.msg : "add error"});
                return
            }

            setTip({open: true, severity: 'success', msg: 'add success'});

            ps2['Name'] = ps1['Name']
            ps2['Category'] = ps1['Category']
            ps2['VoltageType'] = ps1['VoltageType']
            ps2.ID = resp.data.ID;
            ps2.CreateTime = resp.data.CreateTime;

            setFieldItems([]);
            setTemplateID('0')
            form.resetFields();
            onClose(ps2);
        } catch (e) {
            console.log("[Product Type Add]", e);

            setTip({open:true, severity:"error", msg: "add error"});
        }
    }

    const handleAddField = () => {
        setShowField(true)
    }

    const handleFieldClose = (r) => {
        setShowField(false)
        if(!r) return;
        let fieldItemValue = null
        switch(r.FieldType) {
            case 1:  //Single Value
                fieldItemValue = <Input.Group compact>
                    <Form.Item name={[r.FieldName, 'FieldValue']} noStyle rules={[{required: true}]}>
                        <Input style={{width: 300}} />
                    </Form.Item>
                    <Form.Item name={[r.FieldName, '1-Unit']} noStyle initialValue={' '}>
                        <Input placeholder='Unit' style={{width: 70, marginLeft: 10}} />
                    </Form.Item>
                </Input.Group>
                break;
            case 2:  //Range
                fieldItemValue = <Input.Group compact>
                    <Form.Item name={[r.FieldName, 'FieldValue1']} noStyle rules={[{ required: true, type:"number", transform: function(v) {return parseFloat(v) } }]}>
                        <Input placeholder="Min" style={{width: 100}} />
                    </Form.Item>
                    <Form.Item noStyle>
                        <Input placeholder="~" disabled style={{
                            width: 30,
                            borderLeft: 0,
                            borderRight: 0,
                            pointerEvents: 'none',
                        }} />
                    </Form.Item>
                    <Form.Item name={[r.FieldName, 'FieldValue2']} noStyle rules={[{ required: true, type:"number", transform: function(v) {return parseFloat(v) } }]}>
                        <Input placeholder="Max" style={{width: 100}} />
                    </Form.Item>
                    <Form.Item name={[r.FieldName, '2-Unit']} noStyle initialValue={' '}>
                        <Input placeholder='Unit' style={{width: 70, marginLeft: 10}} />
                    </Form.Item>
                </Input.Group>
                break;
            case 3:  //Enum
                const text = r.Enum.map(v => v.Label+'@'+v.Value).join(',')
                fieldItemValue = <Input.Group compot>
                    <Form.Item name={[r.FieldName, 'FieldValue']} noStyle rules={[{ required: true }]}>
                        <Select style={{width: 380}}>
                            {r.Enum.map(v => (
                                <Select.Option key={v.Value} value={v.Value}>{v.Label}</Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item hidden initialValue={text} name={[r.FieldName, '3-Unit']}>
                        <Input />
                    </Form.Item>
                </Input.Group>
                break;
            case 4:  //2 Dimentsions
                fieldItemValue = <Input.Group compact>
                    <Form.Item name={[r.FieldName, 'FieldValue1']} noStyle rules={[{ required: true }]}>
                        <Input placeholder="D1" style={{width: 100}} />
                    </Form.Item>
                    <Form.Item noStyle>
                        <Input placeholder="*" disabled style={{
                            width: 30,
                            borderLeft: 0,
                            borderRight: 0,
                            pointerEvents: 'none',
                        }} />
                    </Form.Item>
                    <Form.Item name={[r.FieldName, 'FieldValue2']} noStyle rules={[{ required: true }]}>
                        <Input placeholder="D2" style={{width: 100}} />
                    </Form.Item>
                    <Form.Item name={[r.FieldName, '4-Unit']} noStyle initialValue={' '}>
                        <Input placeholder='Unit' style={{width: 70, marginLeft: 10}} />
                    </Form.Item>
                </Input.Group>
                break;
            case 5:  //3 Dimentions
                fieldItemValue = <Input.Group compact>
                    <Form.Item name={[r.FieldName, 'FieldValue1']} noStyle rules={[{ required: true }]}>
                        <Input placeholder="D1" style={{width: 80}} />
                    </Form.Item>
                    <Form.Item noStyle>
                        <Input placeholder="*" disabled style={{
                            width: 30,
                            borderLeft: 0,
                            borderRight: 0,
                            pointerEvents: 'none',
                        }} />
                    </Form.Item>
                    <Form.Item name={[r.FieldName, 'FieldValue2']} noStyle rules={[{ required: true }]}>
                        <Input placeholder="D2" style={{width: 80}} />
                    </Form.Item>
                    <Form.Item noStyle>
                        <Input placeholder="*" disabled style={{
                            width: 30,
                            borderLeft: 0,
                            borderRight: 0,
                            pointerEvents: 'none',
                        }} />
                    </Form.Item>
                    <Form.Item name={[r.FieldName, 'FieldValue3']} noStyle rules={[{ required: true }]}>
                        <Input placeholder="D3" style={{width: 80}} />
                    </Form.Item>
                    <Form.Item name={[r.FieldName, '5-Unit']} noStyle initialValue={' '}>
                        <Input placeholder='Unit' style={{width: 70, marginLeft: 10}} />
                    </Form.Item>
                </Input.Group>
                break;
            default:
                fieldItemValue=null;
                break;
        }
        const fieldItem = <Form.Item 
            key={r.FieldName}
            name={r.FieldName}
            label={r.FieldName}
            rules={[{ required: true }]}
        >
            {fieldItemValue}
        </Form.Item>
        setFieldItems([...fieldItems, fieldItem])
    }

    return (<Modal 
        title="Add Product Type"
        //title={title}
        visible={show}
        cancelText="Cancel"
        okText="Submit"
        mask={true}
        maskClosable={false}
        width={700}
        onCancel={handleCancle}
        onOk={handleSubmit}
        footer={[
            <Button 
                key="Clear" 
                onClick={() => {
                    form.resetFields();
                }}
            >Clear</Button>,
            <Button key="Cancel" onClick={handleCancle}>Cancel</Button>,
            <Button key="Submit" type="primary" onClick={handleSubmit}>Submit</Button>
        ]}
    >
        <div style={{marginBottom: '20px', marginTop: '-10px', paddingBottom: '5px'}}>
            <span style={{fontWeight: 'bold'}}>Use Template: </span>
            <Select style={{width: '200px', marginLeft: '10px'}} value={templateID} onChange={v => setTemplateID(v)}>
                {templates.map(v => <Select.Option key={v.ID} value={v.ID}>
                    {v.Name}
                </Select.Option>)}
            </Select>
        </div>
        <Form
            labelAlign="right"
            labelCol={{ span: 9 }}
            wrapperCol={{ span: 14 }}
            form={form}
        >
            <Form.Item 
                name="Name"
                label="Name"
                rules={[{ required: true }]}
            >
                <Input />
            </Form.Item>
            <Form.Item 
                name="Category"
                label="Category"
                rules={[{ required: true }]}
            >
                <Select >
                    <Select.Option value={0}>General BMS</Select.Option>
                    <Select.Option value={1}>Energy Port</Select.Option>
                    <Select.Option value={2}>Master-Control Unit</Select.Option>
                </Select>
            </Form.Item>
            <Form.Item 
                name="VoltageType"
                label="VoltageType"
                rules={[{ required: true }]}
            >
                <Select>
                    <Select.Option value="H">High Voltage</Select.Option>
                    <Select.Option value="L">Low Voltage</Select.Option>
                </Select>
            </Form.Item>

            {fieldItems}

            {templateID==0 && <Button type="dashed" block icon={<PlusOutlined />} style={{width: '400px', marginLeft: '200px'}} onClick={handleAddField}>Add Field</Button>}          
        </Form>

        <FieldItemAdd show={showField} onClose={handleFieldClose} />
    </Modal>)
}

function FieldItemAdd({ show, onClose }) {

    const [enumItems, setEnumItems] = useState(null)
    
    const [form] = Form.useForm()
    
    const handleFieldCancel = () => {
        setEnumItems(null)
        form.resetFields()
        onClose()
    }

    const handleFieldSubmit = async () => {
        const ps = await form.validateFields().catch(() => {return null});
        
        if (ps === null) {
            return;
        }

        setEnumItems(null)
        form.resetFields()
        onClose(ps)
    }

    const handleFieldTypeSelect = (value) => {
        if(value == 3) {
            const item = 
                <Form.Item name="Enum" label="Enum" rules={[{ required: true }]}>
                    <Form.List name="Enum">
                    {(fields, {add, remove}) => (
                        <>
                            {fields.map(({key, name, ...restField}) => (
                                <Space key={key} align="baseline">
                                    <Form.Item
                                        {...restField}
                                        name={[name, 'Label']}
                                        rules={[{ required: true, message: 'please enter label' }]
                                    }
                                    >
                                        <Input placeholder="Label" />
                                    </Form.Item>
                                    <Form.Item
                                        {...restField}
                                        name={[name, 'Value']}
                                        rules={[{ required: true, message: 'please enter value' }]
                                    }
                                    >
                                        <Input placeholder="Value" />
                                    </Form.Item>
                                    <MinusCircleOutlined onClick={() => remove(name)} />
                                </Space>
                            ))}
                            <Form.Item>
                                <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                    Add Enum Item
                                </Button>
                            </Form.Item>
                        </>
                    )}
                </Form.List>
            </Form.Item>
            setEnumItems(item)
        } else {
            setEnumItems(null)
        }
    }

    return (
        <Modal
            title="Add Field"
            visible={show}
            cancelText="Cancel"
            okText="Submit"
            mask={true}
            maskClosable={false}
            width={600}
            onCancel={handleFieldCancel}
            onOk={handleFieldSubmit}
            footer={[
                <Button key="Cancel" onClick={handleFieldCancel}>Cancel</Button>,
                <Button key="Submit" type="primary" onClick={handleFieldSubmit}>Submit</Button>
            ]}
        >
            <Form
                labelAlign="right"
                labelCol={{ span: 9 }}
                wrapperCol={{ span: 14 }}
                form={form}
            >
                <Form.Item 
                    name="FieldName"
                    label="Field Name"
                    rules={[{ required: true }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item 
                    name="FieldType"
                    label="Field Type"
                    initialValue={1}
                    //rules={[{ required: true }]}
                >
                    <Select onSelect={handleFieldTypeSelect}>
                        <Select.Option value={1}>Single Value</Select.Option>
                        <Select.Option value={2}>Range</Select.Option>
                        <Select.Option value={3}>Enum</Select.Option>
                        <Select.Option value={4} hidden>Two Dimentions</Select.Option>
                        <Select.Option value={5}>Three Dimentions</Select.Option>
                    </Select>
                </Form.Item>

                {enumItems}
            </Form>
        </Modal>
    )
}

export default ProductTypeAdd;