import http from '../Http';
import { useEffect, useState, useContext, useRef } from 'react';
import utils from '../utils';
import { Table, Input, Button, Modal, Form, Row, Col, Checkbox, Select, Popover } from 'antd';
import { PlusOutlined, DeleteOutlined, EditOutlined, ExclamationCircleOutlined, UpOutlined, DownOutlined } from '@ant-design/icons';
import defs from "./defs"
import InverterFirmwareAdd from "./InverterFirmwareAdd";
import InputPrefix from '../utils/InputPrefix';

function InverterFirmwareManagement(props) {
    const setTip = useContext(utils.tipContext);

    const [pageNo, setPageNo] = useState(0);
    const [pageSize, setPageSize] = useState(25);
    const [params, setParams] = useState({
        Name: '',
        InverterModel: '',
        Version: '',
    });
    const [loading, setLoading] = useState(false);
    const [rows, setRows] = useState([]);
    const [rowTotal, setRowTotal] = useState(0);
    const [selectedKeys, setSelectedKeys] = useState([]);
    const [showAdd, setShowAdd] = useState(false);
    const [inverterModelSelectOptions, setInverterModelSelectOptions] = useState([]);

    const refTimerInverterModelSearcher = useRef(null);
    const refInverterModelSearchValue = useRef(null);

    const [form] = Form.useForm();

    useEffect(() => {
        let active = true;

        (async () => {
            setLoading(true);
            let resp = await http.post("integrated-inverter/firmware/list2", {
                PageNo: pageNo,
                PageSize: pageSize,
                ...params,
            }).then(async (r) => {
                return await r.json();
            }).catch((e) => {
                console.log("[FirmwareManagement]", e);
                return null;
            });

            if (!active) {
                return;
            }

            if (resp === null || resp.code !== 200) {
                setTip({ open: true, severity: "error", msg: (resp && resp.msg) ? resp.msg : "load data error" });

                setRowTotal(0)
                setRows([]);

                return;
            }

            setRowTotal(resp.data.RowTotal);
            setRows(resp.data.Rows);
            setLoading(false);
        })();

        return () => {
            active = false;
            console.log("FirmwareManagement Effect Cancel");
        };
    }, [pageNo, pageSize, params]);

    const handleSearchInverterModel = (v) => {
        if (refTimerInverterModelSearcher.current != null) {
            clearTimeout(refTimerInverterModelSearcher.current);
        }
        refInverterModelSearchValue.current = v;

        refTimerInverterModelSearcher.current = setTimeout(async () => {
            const resp = await http.post("integrated-inverter/model/list", {
                Value: v
            }).then(async (r) => {
                return await r.json();
            }).catch((e) => {
                return null;
            });

            if (refInverterModelSearchValue.current !== v) {
                return;
            }

            try {
                if (resp.code !== 200) {
                    setTip({ open: true, severity: "error", msg: (resp && resp.msg) ? resp.msg : "load inverter model error" });
                    return;
                }

                const ls = resp.data.map(({ ID, Name }) => {
                    return (
                        <Select.Option key={ID} value={Name}>{Name}</Select.Option>
                    );
                });
                setInverterModelSelectOptions(ls);
            } catch (e) {
                setTip({ open: true, severity: "error", msg: "load inverter model error" });
            }
        }, 300);
    }

    const handleSearch = () => {
        setSelectedKeys([]);
        setPageNo(0);

        const ps = form.getFieldsValue();
        ps.Name = ps.Name || "";
        ps.InverterModel = ps.InverterModel || "";
        ps.Version = ps.Version || "";
        setParams(ps);
    }

    const renderAction = (v, r, idx) => {
        return (
            <>
                <Button onClick={() => { handleDelete(r.ID, r.Name, idx) }} type="link" icon={<DeleteOutlined size="small" />} />
            </>
        )
    }

    const handleAdd = () => {
        setShowAdd(true);
    }

    const handleDelete = async (ID, Name, idx) => {
        Modal.confirm({
            title: 'Do you Want to delete this item?',
            icon: <ExclamationCircleOutlined />,
            content: Name,
            onOk: async () => {
                try {
                    const resp = await http.post("integrated-inverter/firmware/delete", { ID: ID }).then(async (r) => {
                        return await r.json();
                    }).catch((e) => {
                        console.log("[FirmwareManagement]", e);
                        return null;
                    });

                    if (resp.code !== 200) {
                        setTip({ open: true, severity: "error", msg: (resp && resp.msg) ? resp.msg : "delete error" });
                        return
                    }
                    setTip({ open: true, severity: 'success', msg: 'delete success' });

                    setRows([...rows.slice(0, idx), ...rows.slice(idx + 1)]);
                    setRowTotal(rowTotal - 1);
                    selectedKeys.every((v, i) => {
                        if (v === ID) {
                            setSelectedKeys([...selectedKeys.slice(0, i), ...selectedKeys.slice(i + 1)]);
                            return false;
                        }

                        return true;
                    })
                } catch (e) {
                    console.log("[FirmwareManagement]", e);

                    setTip({ open: true, severity: "error", msg: "delete error" });
                }
            },
            onCancel() {
                //
            },
        });
    }

    const handleDeleteAllOfSelected = async () => {
        if (selectedKeys.length < 1) {
            return;
        }

        let ns = [];
        selectedKeys.forEach((ID) => {
            rows.every((r) => {
                if (ID === r.ID) {
                    ns.push(<label>{r.Name}</label>);
                    return false;
                }

                return true;
            });
        })
        if (ns.length > 10) {
            ns = ns.slice(0, 10);
            ns.push(<lable>...</lable>);
        }

        Modal.confirm({
            title: 'Do you Want to delete these items?',
            icon: <ExclamationCircleOutlined />,
            content: (<div style={{ display: 'flex', flexDirection: 'column' }}>{ns}</div>),
            onOk: async () => {
                try {
                    const resp = await http.post("integrated-inverter/firmware/multi/delete", { IDs: selectedKeys.join(",") }).then(async (r) => {
                        return await r.json();
                    }).catch((e) => {
                        console.log("[FirmwareManagement]", e);
                        return null;
                    });

                    if (resp.code !== 200) {
                        setTip({ open: true, severity: "error", msg: (resp && resp.msg) ? resp.msg : "delete error" });
                        return
                    }
                    setTip({ open: true, severity: 'success', msg: 'delete success' });

                    let rs = [];
                    rows.forEach((v, i) => {
                        const ID = v.ID;
                        let ok = false;
                        selectedKeys.every((ID2) => {
                            if (ID === ID2) {
                                ok = true;
                                return false;
                            }

                            return true;
                        });
                        if (!ok) {
                            rs.push(v);
                        }
                    });
                    setRows(rs);
                    setRowTotal(rowTotal - selectedKeys.length);
                    setSelectedKeys([]);
                } catch (e) {
                    console.log("[FirmwareManagement]", e);

                    setTip({ open: true, severity: "error", msg: "delete error" });
                }
            },
            onCancel() {
                //
            },
        });
    }

    const handleSelect = (selectedRowKeys, selectedRows) => {
        setSelectedKeys([...selectedRowKeys]);
    }

    const handleAddingClose = (row) => {
        setShowAdd(false);

        if (!row) {
            return;
        }

        document.getElementsByClassName("ant-table-body")[0].scrollTo(0, 0);
        setSelectedKeys([row.ID]);
        setRows([row, ...rows]);
        setRowTotal(rowTotal + 1);
    }

    var ts = [];
    for (let k in defs.types) {
        //defs.types.map((t, k) => {return (<Select.Option key={k} value={k}>t</Select.Option>);})
        ts.push(<Select.Option key={k} value={k}>{defs.types[k]}</Select.Option>)
    }

    //按下Enter键时进行查找
    const searchByEnter = (e) => {
        if (e.code == "Enter") {
            handleSearch()
        }
    }

    return (
        <div style={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            padding: 8,
        }}>
            <div style={{
                flex: "0 1 auto",
            }}>
                <Form
                    form={form}
                    style={{
                        padding: 24,
                        background: '#fbfbfb',
                        border: '1px solid #d9d9d9',
                        borderRadius: 2
                    }}
                >
                    <Row gutter={24}>
                        <Col span={6} key="InverterModel">
                            <Input.Group compact>
                                <Button shape="round">InverterModel</Button>
                                <Form.Item
                                    name="InverterModel"
                                    label=""
                                    style={{ display: 'inline-block', width: 'calc(100% - 120px)' }}
                                >
                                    <Select
                                        showSearch
                                        placeholder="Enter Inverter Model"
                                        defaultActiveFirstOption={false}
                                        showArrow={false}
                                        filterOption={false}
                                        onSearch={handleSearchInverterModel}
                                        onInputKeyDown={searchByEnter}
                                    >{inverterModelSelectOptions}</Select>
                                </Form.Item>
                            </Input.Group>
                        </Col>
                        <Col span={6} key="Name">
                            <Form.Item
                                name="Name"
                                label=""
                            >
                                <Input prefix={<InputPrefix label="Name" />} placeholder="Enter Firmware Name" onPressEnter={handleSearch} style={{ borderRadius: '20px' }} />
                            </Form.Item>
                        </Col>
                        <Col span={6} key="Version">
                            <Form.Item
                                name="Version"
                                label=""
                            >
                                <Input prefix={<InputPrefix label="Version" />} placeholder="e.g.: 1.8.8" onPressEnter={handleSearch} style={{ borderRadius: '20px' }} />
                            </Form.Item>
                        </Col>
                        <Col span={6} style={{ textAlign: 'right' }}>
                            <Button type="primary" shape='round' style={{ marginRight: '8px' }} onClick={handleSearch}>
                                Search
                            </Button>
                            <Button
                                shape='round'
                                onClick={() => {
                                    form.resetFields();
                                }}
                            >
                                Clear
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </div>
            <div style={{
                flex: '0 0 auto',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end',
                paddingTop: 8,
                paddingBottom: 8,
            }}>
                <Button onClick={handleAdd} type="primary" shape="circle" icon={<PlusOutlined />} size="middle" />
                <Button onClick={handleDeleteAllOfSelected} disabled={selectedKeys.length === 0} type="primary" shape="circle" icon={<DeleteOutlined />} size="middle" style={{ marginLeft: 5 }} />
            </div>
            <div style={{
                flex: "1 1 1px",
                position: "relative",
                overflow: "hidden"
            }}>
                {/* <div style={{
                    flex: '0 0 auto',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-end',
                    paddingTop: 4,
                    paddingBottom: 4,
                }}> */}
                <div style={{ height: '100%', width: "100%", overflow: "hidden" }}>
                    <Table
                        columns={[{
                            title: "",
                            width: 40,
                            fixed: 'left',
                            render: (v, r, idx) => {
                                return (pageNo * pageSize + idx + 1);
                            }
                        }, {
                            title: "Name",
                            dataIndex: "Name",
                            width: 200,
                            fixed: 'left',
                        }, {
                            title: "Version",
                            dataIndex: "Version",
                            width: 120,
                        }, {
                            title: "InverterModel",
                            dataIndex: "ModelName",
                            width: 160,
                        }, {
                            title: "Type",
                            dataIndex: 'Type',
                            width: 120,
                            // render: (v, r, idx) => {
                            //     return defs.types[v] || "";
                            // }
                        }, {
                            title: "MD5",
                            dataIndex: "MD5",
                            width: 270,
                        }, {
                            title: "DataLength",
                            dataIndex: "DataLength",
                            width: 140,
                            render: (v) => {
                                return v ? Math.round(v / 1024) + 'KB (' + v + 'B)' : ''
                            }
                        }, {
                            title: "Note",
                            dataIndex: "Note",
                            width: 200,
                            render: (v) => {
                                if (!v) {
                                    return v;
                                }

                                if (v.indexOf("\n") > -1) {
                                    const content = (
                                        <Input.TextArea rows={5} value={v} readOnly />
                                    )
                                    return (
                                        <Popover title="Note" content={content}>
                                            <div>{v}</div>
                                        </Popover>
                                    );
                                }

                                return v;
                            }
                        }, {
                            title: "Create Time",
                            dataIndex: 'CreateTime',
                            width: 150,
                        }, {
                            title: 'Action',
                            key: 'Action',
                            width: 90,
                            render: renderAction,
                        }]}
                        style={{
                            height: "100%"
                        }}
                        pagination={{
                            pageSizeOptions: [25, 50, 100],
                            current: pageNo + 1,
                            pageSize: pageSize,
                            total: rowTotal,
                            showSizeChanger: true,
                            showTotal: (total) => { return `Total ${total}` },
                            onShowSizeChange: (current, size) => {
                                setPageSize(size);
                            },
                            onChange: (page, pageSize) => {
                                setPageNo(page - 1);
                            }
                        }}
                        tableLayout="fixed"
                        bordered={true}
                        size="middle"
                        loading={loading}
                        rowKey="ID"
                        dataSource={rows}
                        scroll={{ x: 1500, y: true }}
                        rowSelection={{
                            selectedRowKeys: selectedKeys,
                            onChange: handleSelect,
                            selections: [Table.SELECTION_ALL, Table.SELECTION_INVERT, Table.SELECTION_NONE]
                        }}
                    />
                </div>
            </div>
            <InverterFirmwareAdd show={showAdd} onClose={handleAddingClose} />

        </div>
    );
}

export default InverterFirmwareManagement;