import http from '../Http';
import { useContext, useState, useRef } from 'react';
import utils from '../utils';
import { Modal, Button, Input, Form, Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import config from '../Config';

function DeviceIssueAdd(props) {
    const setTip = useContext(utils.tipContext);

    const {show, onClose} = props;

    const [form] = Form.useForm();

    const [issueContent, setIssueContent] = useState("");
    const [disabled, setDisabled] = useState(false);

    const handleCancle = () => {
        form.resetFields();
        setDisabled(false);
        onClose(null);
    }

    const handleSubmit = async () => {
        const ps = await form.validateFields().catch(() => {return null});
        
        if (ps === null) {
            return;
        }

        const rawContent = ps.Issue;
        ps.SN = ps.SN || "";
        ps.Content = rawContent ? encodeURIComponent(rawContent) : "";
        ps.Asker = ps.Asker || "";
        ps.Image = [];
        ps.Picture && ps.Picture.fileList && ps.Picture.fileList.forEach(v => {
            ps.Image.push(v.response && v.response.code == 200 ? v.response.data[v.name] : '');
        })
        ps.Image = ps.Image.join(',');
        
        const resp = await http.post("after-sale/device/issue/add", ps).then(async (r)=>{
            return await r.json();
        }).catch((e) => {
            return null;
        });

        try {
            if (resp.code !== 200) {
                setTip({open:true, severity:"error", msg:(resp && resp.msg) ? resp.msg : "add error"});
                return
            }

            setTip({open: true, severity: 'success', msg: 'add success'});

            ps.Content = rawContent;
            ps.ID = resp.data.ID;
            ps.CreateTime = resp.data.CreateTime;
            ps.ProductName = resp.data.ProductName;
            ps.DistributorName = resp.data.DistributorName;
            onClose(ps);

            form.resetFields();
        } catch (e) {
            setTip({open:true, severity:"error", msg: "add error"});
        }
    }

    return (<Modal 
        title="Add Issue"
        visible={show}
        cancelText="Cancel"
        okText="Submit"
        mask={true}
        maskClosable={false}
        width={500}
        onCancel={handleCancle}
        onOk={handleSubmit}
        footer={[
            <Button 
                key="Clear" 
                shape="round"
                onClick={() => {
                    form.resetFields();
                }}
                disabled={disabled}
            >Clear</Button>,
            <Button key="Cancel" shape="round" onClick={handleCancle} disabled={disabled}>Cancel</Button>,
            <Button key="Submit" shape="round" type="primary" onClick={handleSubmit} disabled={disabled}>Submit</Button>
        ]}
    >
        <Form
            labelAlign="right"
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 25 }}
            form={form}
            initialValues={{
                Enabled: true,
            }}
        >
            <Form.Item 
                name="SN"
                label="SN"
                rules={[{ required: true }]}
            >
                <Input placeholder="Device SN" style={{ borderRadius: '5px' }} />
            </Form.Item>
            <Form.Item 
                name="Issue"
                label="Issue"
                rules={[{ required: true }]}
            >
                <Input.TextArea 
                    rows={4} 
                    maxLength={500} 
                    showCount={true} 
                    value={issueContent} 
                    placeholder="Enter your Issue"
                    onChange={(e) => {setIssueContent(e.target.value)}}
                />
            </Form.Item>
            <Form.Item 
                name="Asker"
                label="Asker"
            >
                <Input placeholder="Your Name" style={{ borderRadius: '5px' }} />
            </Form.Item>
            <Form.Item 
                name="Picture"
                label="Image"
            >
                <Upload 
                    name="Picture"
                    listType="picture"
                    accept=".png,.jpg,jpeg"
                    action={config.apiUrl + "after-sale/picture/upload/do"}
                    headers={{'Token': http.token}}
                    multiple={true}
                    beforeUpload={() => {
                        setDisabled(true);
                    }}
                    onChange={(info) => {
                        if(info && info.file && info.file.status === 'done') {
                            setDisabled(false);
                        }
                    }}
                >
                    <Button icon={<UploadOutlined />}>Choose Images</Button>
                </Upload>
            </Form.Item>
        </Form>
    </Modal>);
}

export default DeviceIssueAdd;