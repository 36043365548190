import http from '../Http';
import { useEffect, useState, useContext, useRef } from 'react';
import { createTheme } from '@material-ui/core/styles';
import utils from '../utils';
import { Table, Input, Button, Modal } from 'antd';
import { PlusOutlined, DeleteOutlined, EditOutlined, ExclamationCircleOutlined  } from '@ant-design/icons';
import ProductTypeAdd from "./ProductTypeAdd";
import ProductTypeModify from "./ProductTypeModify";

function ProductType (props) {
    const setTip = useContext(utils.tipContext);

    const [pageNo, setPageNo] = useState(0);
    const [pageSize, setPageSize] = useState(100);
    const [params, setParams] = useState({
        Name: '',
    });
    const [loading, setLoading] = useState(false);
    const [rows, setRows] = useState([]);
    const [rowTotal, setRowTotal] = useState(0);
    const [tableBodyHeight, setTableBodyHeight] = useState(240);
    const [selectedKeys, setSelectedKeys] = useState([]);
    const [showAdd, setShowAdd] = useState(false);
    const [showModify, setShowModify] = useState(false);
    const [modifyRowIndex, setModifyRowIndex] = useState(null);
    const refTableContainer = useRef();

    useEffect(() => {
        let active = true;
        console.log("ProductType Effect");

        (async () => {
            setLoading(true);
            
            
            let resp = await http.post("product/list", {
                PageNo: pageNo,
                PageSize: pageSize,
                ...params,
            }).then(async (r)=>{
                return await r.json();
            }).catch((e)=>{
                console.log("[ProductType]", e);
                return null;
            });

            if (! active) {
                return;
            }
            
            if (resp === null || resp.code !== 200) {
                setTip({open:true, severity:"error", msg:(resp && resp.msg) ? resp.msg : "load data error"});
                
                setRowTotal(0)
                setRows([]);
        
                return;
            }

            //console.log('rows2: ', resp.data.Rows2)
            //处理数据
            const obj = {}
            resp.data.Rows2.forEach(v => {
                if(!(v['ID'] in obj)) {
                    obj[v['ID']] = {'ID': v['ID'], 'CreateTime': v['CreateTime']}
                    
                }
                //obj[v['ID']][v['Name']] = parseValue(v)
                obj[v['ID']][v['Name']] = `RangeX:${v['RangeX']},Unit:${v['Unit']}`
            })
            const arr = Object.values(obj)

            //console.log('arr: ', arr)
            setRowTotal(resp.data.RowTotal);
            setRows(arr);
            setLoading(false);
        })();

        return () => {
            active = false;
            console.log("ProductType Effect Cancel");
        };
    }, [pageNo, pageSize, params]);

    useEffect(() => {
        const el = refTableContainer.current;
        //console.log("useEffect[efTableContainer.current]", refTableContainer.current)

        if (el.objResizer) {
            return;
        }
        
        let obj = document.createElement('object');
        el.objResizer = obj; 
        obj.setAttribute('style',
            'display: block; position: absolute; top: 0; left: 0; height: 100%; width: 100%; overflow: hidden;opacity: 0; pointer-events: none; z-index: -1;');
        obj.onload = function () {
            //console.log("onload=====>>"); 
            setTableBodyHeight(calcTableBodyHeight());

            this.contentDocument.defaultView.addEventListener('resize', (evt) => {
                //console.log("====>xxxxoooo");
                setTableBodyHeight(calcTableBodyHeight());
            });
            
            const hd = document.getElementsByClassName("ant-table-thead")[0];
            const ro = new ResizeObserver((evt)=>{
                setTableBodyHeight(calcTableBodyHeight());
            });
            ro.observe(hd);
            // hd.addEventListener('resize', (evt) => {
            //     setTableBodyHeight(calcTableBodyHeight());
            // });
        };
        obj.type = 'text/html';
        el.appendChild(obj);
        obj.data = 'about:blank';

        return () => {
            if (! el) {
                return
            }

            //
        }
    }, [refTableContainer.current]);

    const handleSearch = (v, e) => {
        setSelectedKeys([]);
        setPageNo(0);
        setParams({
            Name: v || "",
        });
    }

    const renderAction = (v, r, idx) => {
        return (
            <>
                <Button onClick={()=>{handleModify(idx)}} type="link" icon={<EditOutlined  size="small"/>} />
                <Button onClick={()=>{handleDelete(r.ID, r.Name, idx)}} type="link" icon={<DeleteOutlined  size="small"/>} />
            </>
        )
    }

    const handleAdd = () => {
        setShowAdd(true);
    }

    const handleModify = (rowIndex) => {
        setModifyRowIndex(rowIndex);
        setShowModify(true);
    }

    const handleDelete = async (ID, Name, idx) => {
        Modal.confirm({
            title: 'Do you Want to delete this item?',
            icon: <ExclamationCircleOutlined />,
            content: Name,
            onOk: async () => {
                try {
                    const resp = await http.post("product/delete", {ID: ID}).then(async(r) => {
                        return await r.json();
                    }).catch((e) => {
                        console.log("[ProductType]", e);
                        return null;
                    });
        
                    if (resp.code !== 200) {
                        setTip({open:true, severity:"error", msg:(resp && resp.msg) ? resp.msg : "delete error"});
                        return
                    }
                    setTip({open: true, severity: 'success', msg: 'delete success'});
        
                    setRows([...rows.slice(0,idx), ...rows.slice(idx+1)]);
                    setRowTotal(rowTotal-1);
                    selectedKeys.every((v,i) => {
                        if (v === ID) {
                            setSelectedKeys([...selectedKeys.slice(0,i), ...selectedKeys.slice(i+1)]);
                            return false;
                        }
        
                        return true;
                    })
                } catch(e) {
                    console.log("[ProductType]", e);
        
                    setTip({open:true, severity:"error", msg: "delete error"});
                }
            },
            onCancel() {
                //
            },
        });
    }

    const handleDeleteAllOfSelected = async () => {
        if (selectedKeys.length < 1) {
            return;
        }

        let ns = [];
        selectedKeys.forEach((ID) => {
            rows.every((r) => {
                if (ID === r.ID) {
                    ns.push(<label>{r.Name}</label>);
                    return false;
                }

                return true;
            });
        })
        if (ns.length > 10) {
            ns = ns.slice(0, 10);
            ns.push(<lable>...</lable>);
        }

        Modal.confirm({
            title: 'Do you Want to delete these items?',
            icon: <ExclamationCircleOutlined />,
            content: (<div style={{display:'flex', flexDirection:'column'}}>{ns}</div>),
            onOk: async () => {
                try {
                    const resp = await http.post("product/multi/delete", {IDs: selectedKeys.join(",")}).then(async(r) => {
                        return await r.json();
                    }).catch((e) => {
                        console.log("[ProductType]", e);
                        return null;
                    });
        
                    if (resp.code !== 200) {
                        setTip({open:true, severity:"error", msg:(resp && resp.msg) ? resp.msg : "delete error"});
                        return
                    }
                    setTip({open: true, severity: 'success', msg: 'delete success'});
        
                    let rs = [];
                    rows.forEach((v, i) => {
                        const ID = v.ID;
                        let ok = false;
                        selectedKeys.every((ID2) => {
                            if (ID === ID2) {
                                ok = true;
                                return false;
                            }
        
                            return true;
                        });
                        if (!ok) {
                            rs.push(v);
                        } 
                    });
                    setRows(rs);
                    setRowTotal(rowTotal - selectedKeys.length);
                    setSelectedKeys([]);
                } catch(e) {
                    console.log("[ProductType]", e);
        
                    setTip({open:true, severity:"error", msg: "delete error"});
                }
            },
            onCancel() {
                //
            },
        });
    }

    const handleSelect = (selectedRowKeys, selectedRows) => {
        setSelectedKeys([...selectedRowKeys]);
    }

    const handleAddingClose = (row) => {
        setShowAdd(false);

        if (! row) {
            return;
        }

        document.getElementsByClassName("ant-table-body")[0].scrollTo(0, 0);
        setSelectedKeys([row.ID]);
        setRows([row, ...rows]);
        setRowTotal(rowTotal+1);
    }

    const handleModifyingClose = (row) => {
        setShowModify(false);
        setModifyRowIndex(null);

        if (! row) {
            return;
        }

        setSelectedKeys([row.ID]);
        const rw = {...rows[modifyRowIndex], ...row}
        setRows([...rows.slice(0, modifyRowIndex), rw, ...rows.slice(modifyRowIndex+1)]);
    }

    const calcTableBodyHeight = () => {
        if (! refTableContainer.current) {
            return 240;
        }

        const hd = document.getElementsByClassName("ant-table-thead")[0];

        const h = refTableContainer.current.getBoundingClientRect().bottom - hd.getBoundingClientRect().bottom;
        //console.log("calcTableBodyHeight====>>>", h, refTableContainer.current.getBoundingClientRect().bottom, hd.getBoundingClientRect().bottom);

        return  h>=54 ? h - 54 : h /2;
    }

    //对从数据库返回的字段进行解析
    const parseValue = (v) => {
        if(!v) return ''
        let r = v + ''
        const data = v.substring(v.indexOf('RangeX:')+7, v.indexOf('Unit:')-1), 
            unit = v.substring(v.indexOf('Unit:')+5)
        if(v.startsWith('RangeX:1')) {  
            let s = data.substring(data.indexOf('[')+1, data.indexOf(']'))
            if(s.includes(',')) {  //Range
                let min = s.substring(0, s.indexOf(','))
                let max = s.substring(s.indexOf(',')+1)
                r = '[' + min + unit + ', ' + max + unit + ']'
            } else {  //Singie Value
                r = s + unit
            }
        } else if(v.startsWith('RangeX:2')) {  //Enum
            let selected = data.substring(data.indexOf(':')+1, data.indexOf('}'))
            let opts = data.substring(3, data.indexOf(':')).split(',').filter(v => v.includes('@'+selected))
            r = opts[0].substring(0, opts[0].indexOf('@'))
        } else if(v.startsWith('RangeX:3')) {  
            let s = data.substring(data.indexOf(',')+1)
            if(s.indexOf('*') == s.lastIndexOf('*')) {  //2D
                let w = s.substring(s.indexOf(',')+1, s.indexOf('*'))
                let h = s.substring(s.indexOf('*')+1)
                r = w + unit + '*' + h + unit
            } else {  //3D
                let w = s.substring(s.indexOf(',')+1, s.indexOf('*'))
                let h = s.substring(s.indexOf('*')+1, s.lastIndexOf('*'))
                let d = s.substring(s.lastIndexOf('*')+1)
                r = w + unit + '*' + h + unit + '*' + d + unit 
            }
        } 
        return r
    }

    //所有的列表项名称
    let allItemNames = []
    rows.forEach(v => {
        let keys = Object.keys(v)
        allItemNames = Array.from(new Set([...allItemNames, ...keys]))
    })
    //所有的列表项
    const items = [
        {
            title: 'Name',
            dataIndex: 'Name',
            width: 100,
            fixed: 'left',
            render: v => v ? parseValue(v) : ''
        }, {
            title: 'Category',
            dataIndex: 'Category',
            width: 100,
            render: (v) => {
                // const tt = {
                //     0: "General BMS",
                //     1: "Energy Port",
                //     2: "Master-Control Unit",
                // }
                return v ? parseValue(v) : ""
            }
        }, {
            title: 'VoltageType',
            dataIndex: 'VoltageType',
            width: 100,
            render: v => v ? parseValue(v) : ''
        }
    ].concat(
        allItemNames.sort().filter(v => !['ID', 'Name', 'Category', 'VoltageType', 'CreateTime'].includes(v)).map(v => (
        {
            key: v,
            title: v,
            dataIndex: v,
            width: 100,
            render: v => v ? parseValue(v) : ''
        }
    ))).concat(
        {
            title: "Create Time",
            dataIndex: 'CreateTime',
            width: 150,
        },{
            title: 'Action',
            key: 'Action',
            width: 90,
            render: renderAction,
        }
    )

    return (
        <div style={{
            flex: "1 1 auto",
            display: "flex",
            flexDirection: "column",
            backgroundColor: '#EFF8FE',
            padding: 8,
        }}>
            <div style={{
                flex: '0 0 auto',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                paddingBottom: 8,
            }}>
                <div style={{
                    flex: "0 0 300px",
                    maxWidth: 400,
                }}>
                    <Input.Search placeholder="Enter Product Name" onSearch={handleSearch} enterButton />
                </div>
                
                <div style={{
                    flex: '1 1 auto',
                    display: "flex",
                    flexDirection: 'row',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                }}>
                    <Button onClick={handleAdd} type="primary"  icon={<PlusOutlined /> } shape="circle" size="middle" />
                    <Button onClick={handleDeleteAllOfSelected} disabled={selectedKeys.length===0} type="primary" shape="circle" icon={<DeleteOutlined  />} size="middle" style={{marginLeft: 5}}/>

                </div>
            </div>
            <div style={{
                flex:"1 1 1px",
                position: "relative",
            }}>
                <div ref={refTableContainer} style={{position: "absolute", height: '100%', width: "100%", overflow: "hidden"}}>
                    <Table 
                        columns={items}
                        pagination={{
                            pageSizeOptions: [25,50,100],
                            current: pageNo+1,
                            pageSize: pageSize,
                            total: rowTotal,
                            showSizeChanger: true,
                            showTotal: (total) => {return `Total ${total}`},
                            onShowSizeChange: (current, size) => {
                                //console.log("current", 1, "size", size)
                                setPageSize(size);
                            },
                            onChange: (page, pageSize) => {
                                //console.log("page", page, "pageSize", pageSize)
                                setPageNo(page-1);                                
                            }
                        }}
                        tableLayout="fixed"
                        bordered={true}
                        size="middle"
                        loading={loading}
                        rowKey="ID"
                        dataSource={rows}
                        scroll={{ x: 1500, y: tableBodyHeight }}
                        rowSelection={{
                            selectedRowKeys: selectedKeys,
                            onChange: handleSelect,
                            selections: [Table.SELECTION_ALL, Table.SELECTION_INVERT, Table.SELECTION_NONE]
                        }}
                    />
                </div>
            </div>
            <ProductTypeAdd show={showAdd} onClose={handleAddingClose}/>
            {modifyRowIndex!==null&&<ProductTypeModify show={showModify} row={rows[modifyRowIndex]} onClose={handleModifyingClose}/>}
        </div>
    );
}

export default ProductType;