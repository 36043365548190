import React, { useEffect, useState, useRef, useContext } from 'react';
import { Select, DatePicker, Button, Table } from 'antd';
import moment from 'moment';
import http from '../../../Http';
import utils from '../../../utils';
import config from '../../../Config';

function getTableScroll() {
    var th = document.getElementsByClassName("ant-table-thead")[0];
    let tHeaderBottom = 0
    if (th) {
        tHeaderBottom = th.getBoundingClientRect().bottom
    }
    let height = `calc(100vh - ${tHeaderBottom + 66}px)`
    return height
}

function AlarmHistory(props) {
    const {inverterSelectOptions, SN, protocol, handleSelectSN} = props
    const setTip = useContext(utils.tipContext)

    const [alarmType, setAlarmType] = useState('')

    const [pageNo, setPageNo] = useState(0)
    const [pageSize, setPageSize] = useState(25)
    const [loading, setLoading] = useState(false)
    const [rows, setRows] = useState([])
    const [rowTotal, setRowTotal] = useState(0)
    const [alarmTypeOptions, setAlarmTypeOptions] = useState([])
    const [STime, setSTime] = useState(moment().format("YYYY-MM-DD"))

    const refDownloader = useRef(null)

    const ETime = moment(STime).add(1, "days").format("YYYY-MM-DD")

    const [scrollY, setScrollY] = useState("")

    useEffect(() => {
        setScrollY(getTableScroll())
      }, [])

    //根据不同protocol逆变器获取告警类型列表
    useEffect(() => {
        if(!SN) return;

        let active = true;

        (async () => {
            setLoading(true)
            let resp = await http.post("integrated-inverter/alarm-type/get", {
                Protocol: protocol,
            }).then(async (r)=>{
                return await r.json();
            }).catch((e)=>{
                return null;
            });

            if (!active) {
                return;
            }
            
            if (resp === null || resp.code !== 200) {
                setTip({open:true, severity:"error", msg:(resp && resp.msg) ? resp.msg : "load inverter record error"});
                setAlarmTypeOptions([]);
                return;
            }

            const ts = []
            ts.push(<Select.Option key='' value=''>All Alarms</Select.Option>)
            for(const v of resp.data) {
                ts.push(<Select.Option key={v.ID} value={v.ID}>{v.Name}</Select.Option>)
            }
            setAlarmTypeOptions(ts);
            
        })();

        return () => {
            active = false;
        };

    }, [SN]);

    //获取告警记录列表
    useEffect(() => {
        if(!SN) return;

        let active = true;

        (async () => {
            setLoading(true)
            let resp = await http.post("integrated-inverter/alarm/list2", {
                PageNo: pageNo,
                PageSize: pageSize, 
                SerialNumber: SN,
                AlarmTypes: alarmType,
                STime: STime,
                ETime: ETime,
            }).then(async (r)=>{
                return await r.json();
            }).catch((e)=>{
                return null;
            });

            if (!active) {
                return;
            }
            
            if (resp === null || resp.code !== 200) {
                setTip({open:true, severity:"error", msg:(resp && resp.msg) ? resp.msg : "load inverter record error"});
                setRowTotal(0);
                setRows([]);
                return;
            }

            setRowTotal(resp.data.RowTotal)
            setRows(resp.data.Rows)
            setLoading(false)
        })();

        return () => {
            active = false;
        };

    }, [pageNo, pageSize, SN, alarmType, STime]);

    const handleExport = () => {
        refDownloader.current && refDownloader.current.click()
    }

    return (
        <div style={{ width: "100%", height: "100%", display: "flex", flexDirection: "column" }}>
            <div style={{ flex: "none", padding: "10px" }}>
                <span>Select Inverter</span>
                <Select
                    showSearch
                    placeholder="Enter Inverter Name"
                    defaultActiveFirstOption={false}
                    showArrow={true}
                    filterOption={true}
                    onSelect={(value) => {
                        handleSelectSN(value)
                        setAlarmTypeOptions([])
                        setAlarmType('')
                    }}
                    value={SN}
                    style={{ width: '200px', marginRight: '20px', marginLeft: '10px' }}
                >{inverterSelectOptions}</Select>
                <Select 
                    showSearch
                    placeholder="Select Alarm Type"  
                    showArrow={true}
                    filterOption={true} 
                    onSelect={(value) => setAlarmType(value)}
                    value={alarmType} 
                    style={{ width: '200px', marginRight: '20px'}}
                >
                    {alarmTypeOptions}
                </Select>
                <DatePicker
                    defaultValue={STime}
                    value={moment(STime)}
                    onChange={(_, ds) => {
                        setSTime(ds)
                    }}
                    allowClear={false}
                />
                <Button type="primary" disabled={rows.length < 1 ? true : false} onClick={handleExport} style={{float: 'right', marginRight: '10px', backgroundColor: '#0486be'}}>Export Data</Button>
            </div>
            <div className="alarm-history-main">
                <Table
                    columns={[
                        {
                            title: "",
                            width: 40,
                            fixed: 'left',
                            render: (v, r, idx) => {
                                return (pageNo * pageSize + idx + 1);
                            }
                        },
                        {
                            title: 'SN',
                            dataIndex: "SerialNumber",
                            width: 120,
                            fixed: 'left',
                        },
                        {
                            title: 'Alarm Type',
                            width: 120,
                            render: () => {
                                return 'Alarm'
                            },
                        },
                        {
                            title: 'Alarm',
                            dataIndex: 'AlarmName',
                            width: 120,
                        },
                        {
                            title: 'STime',
                            dataIndex: 'YYYYMMDDHHMMSS_S',
                            width: 120,
                            render: r => {
                                if(!r) return ''
                                r += ''
                                return r.substr(0, 4)+'-'+r.substr(4, 2)+'-'+r.substr(6, 2)+' ' + r.substr(8, 2)+':'+r.substr(10, 2)+':'+(r.substr(12, 2)?r.substr(12, 2):'00')
                            }
                        },
                        {
                            title: 'ETime',
                            dataIndex: 'YYYYMMDDHHMMSS_E',
                            width: 120,
                            render: r => {
                                if(!r) return ''
                                r += ''
                                return r.substr(0, 4)+'-'+r.substr(4, 2)+'-'+r.substr(6, 2)+' ' + r.substr(8, 2)+':'+r.substr(10, 2)+':'+(r.substr(12, 2)?r.substr(12, 2):'00')
                            }
                        },
                    ]}
                    pagination={{
                        pageSizeOptions: [25,50,100],
                        current: pageNo+1,
                        pageSize: pageSize,
                        total: rowTotal,
                        showSizeChanger: true,
                        showTotal: (total) => {return `Total ${total}`},
                        onShowSizeChange: (current, size) => {
                            setPageSize(size);
                        },
                        onChange: (page, pageSize) => {
                            setPageNo(page-1);                                
                        }
                    }}
                    tableLayout="fixed"
                    bordered={true}
                    size="small"
                    loading={loading}
                    rowKey="ID"
                    dataSource={rows}
                    scroll={{x: "max-content", y: scrollY}}
                >
                </Table>
            </div>

            <a ref={refDownloader} style={{display: "none"}} href={config.apiUrl+"data-export/inverter/alarm/do?Token="+http.token+"&SerialNumber="+SN+"&AlarmTypes="+alarmType+"&STime="+STime+"&ETime="+ETime}></a>
        </div>
    )
}

export default AlarmHistory;