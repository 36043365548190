import http from '../Http';
import { useContext, useRef, useState } from 'react';
import utils from '../utils';
import { Modal, Button, Input, Form, Image } from 'antd';


function InverterManufacturerAdd (props) {
    const setTip = useContext(utils.tipContext);

    const {show, onClose} = props;

    const [form] = Form.useForm();

    const imageFile = useRef(null);

    const [imageBase64, setImageBase] = useState(null);

    const handleCancle = () => {
        onClose(null);
    }

    const handleImageFileChange = async () => {
        const base64 =  await new Promise((resolve, reject) => {
            const reader = new FileReader();
            const tmr = setTimeout(() => {
                reject(null);
            }, 500);
            reader.addEventListener('load', () => {
                clearTimeout(tmr);

                resolve(reader.result);
            });
            reader.readAsDataURL(imageFile.current.input.files.item(0));
        }).catch((e) => {
            console.log("[InverterManufacturerAdd]", e);
        });

        setImageBase(base64 || "");
    }

    const handleSubmit = async () => {
        const ps = await form.validateFields().catch(() => {return null});
        
        if (ps === null) {
            return;
        }

        ps.ImageBase64 = (imageBase64 || "").replace(/;/g, "；").replace(/\+/g, "＋");

        const resp = await http.post("inverter-manufacturer/add", ps).then(async (r)=>{
            return await r.json();
        }).catch((e) => {
            console.log("[InverterManufacturerAdd]", e);
            return null;
        });

        try {
            if (resp.code !== 200) {
                setTip({open:true, severity:"error", msg:(resp && resp.msg) ? resp.msg : "add error"});
                return
            }

            setTip({open: true, severity: 'success', msg: 'add success'});

            ps.Image = ps.ImageBase64.replace(/；/g, ";").replace(/＋/g, "+")
            ps.ID = resp.data.ID;
            ps.CreateTime = resp.data.CreateTime;
            onClose(ps);
        } catch (e) {
            console.log("[InverterManufacturerAdd]", e);

            setTip({open:true, severity:"error", msg: "add error"});
        }
    }

    return (<Modal 
        title="Add Inverter Manufacturer"
        visible={show}
        cancelText="Cancel"
        okText="Submit"
        mask={true}
        maskClosable={false}
        width={520}
        onCancel={handleCancle}
        onOk={handleSubmit}
    >
        <Form
            labelAlign="right"
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 10 }}
            form={form}
        >
            <Form.Item 
                name="Name"
                label="Name"
                rules={[{ required: true }]}
            >
                <Input />
            </Form.Item>
            <Form.Item 
                name="Image"
                label="Image"
            >
                <label for="_image" style={{cursor:"pointer"}}>Choose images (PNG, JPG)</label>
                <Input ref={imageFile} onChange={handleImageFileChange} id="_image" type='file' accept=".png,.jpg,.jpeg" style={{opacity:0}} />
                <Image height={40} src={imageBase64}/>
            </Form.Item>
        </Form>
    </Modal>)
}

export default InverterManufacturerAdd;