import { useEffect, useState, useContext, useRef  } from 'react';
import { Modal, Tabs, TimePicker, Button, Form, Input, Select, Switch, Table, Row, Col, Popconfirm } from 'antd';
import { EditOutlined, ClockCircleOutlined } from '@ant-design/icons';
import utils from '../../../utils';
import http from '../../../Http';
import moment from 'moment';
import InverterBurningList from './InverterBurningList';
import DTUBurningList from './DTUBurningList';
import InverterFirmwareManagement from '../../../Burning/InverterFirmwareManagement';
import DTUFirmwareManagement from '../../../Burning/DTUFirmwareManagement';
import ThinkPowerSetting from "./ThinkpowerSetting"; //2023.11.12
import MegarevoSetting from "./MegarevoSetting"; //2023.12.19
import SinexcelSetting from "./SinexcelSetting"; //2024.3.29
import MegarevoCESSSetting from './MegarevoCESSSetting'; //2024.4.2
import SungerySetting from './SungerySetting'; //2024.5.16

let sessionID = 1

function InvSetting(props) {
    const { SN, protocol } = props;
    const setTip = useContext(utils.tipContext);
    const { TabPane } = Tabs;
    const { Item } = Form;
    const { Option } = Select;

    //2023.11.12
    const [settingDefs, setSettingDefs] = useState(null)

    //设置项的定义类型
    const [settingItemDefs, setSettingItemDefs] = useState(null)

    //表单实例
    const [form1] = Form.useForm();
    const [form2] = Form.useForm();
    const [form3] = Form.useForm();
    const [form4] = Form.useForm();
    const [form5] = Form.useForm();
    const [form6] = Form.useForm();
    const [form7] = Form.useForm();
    const [form8] = Form.useForm();

    //逆变器参数已有的值
    const [initValues, setInitValues] = useState({});
    //每次设置成功后, 重新从后台取值保存到initValues中
    const [flag, setFlag] = useState(true);
    //标记输入框是否修改
    const [isEdits, setIsEdits] = useState({});

    //保存设置的值
    const [changedValues, setChangedValues] = useState({});

    //获取逆变器的参数的值
    useEffect(() => {
        if (!SN) return;
        form1.resetFields();
        form2.resetFields();
        form3.resetFields();
        form4.resetFields();
        form5.resetFields();
        form6.resetFields();
        form7.resetFields();
        form8.resetFields();
        let active = true;
        (async () => {
            let resp = await http.post("integrated-inverter/setting/list", {
                SerialNumber: SN
            }).then(async (r) => {
                return await r.json();
            }).catch((e) => {
                return null;
            });

            if (!active) {
                return;
            }

            if (resp === null || resp.code !== 200) {
                setTip({ open: true, severity: "error", msg: (resp && resp.msg) ? resp.msg : "load inverter record error" });

                return;
            }

            const values = {};
            let edits = {};
            let defs = {}
            let v_30B9 = null  //特殊值
            let v_30BA = null
            resp.data.map(v => {
                const addr = v.RegisterAddress;
                const value = protocol == 'Thinkpower' ? v.MultiValue : v.Value

                if (addr == '30B9') {  //'30B9', '30BA'两个地址表示一个值, 要单独处理
                    v_30B9 = value
                } else if (addr == '30BA') {
                    v_30BA = value
                } else if (['2102', '2103', '2104', '2105'].includes(addr)) {  //Time类型
                    values[addr] = value != null ? moment(Math.floor(value / 256).toString().padStart(2, '0') + ':' + (value % 256).toString().padStart(2, '0'), 'HH:mm') : null;
                } else if (v.Enums != null) {
                    if (v.Enums.split(';').length == 2 && v.Enums.includes('nable')) {  //Switch类型
                        values[addr] = value == 1 ? true : false
                    } else {  //Select类型
                        values[addr] = value != null ? value.toString() : null
                    }
                } else { //Value类型
                    values[addr] = value ? value * (v.Scale || 1) : null
                }

                edits[addr] = false
                defs[v.RegisterAddress] = {
                    Scale: v.Scale,
                    Enums: v.Enums,
                    Ranges: v.Ranges,
                }
            })

            //2023.11.12
            if (protocol == "Thinkpower") {
                var arr = resp.data;
                var dfs = [];
                for (var i=0; i<arr.length; i++) {
                    var def = arr[i];
                    if (def.ReadWrite != "RW" && def.ReadWrite != "W") {
                        continue;
                    }
                    def.Intls = JSON.parse(def.Intls);
                    dfs.push(def);
                }
                setSettingDefs(dfs);
            } else if (protocol == "Megarevo") { //2023.12.19
                var arr = resp.data;
                var dfs = [];
                for (var i=0; i<arr.length; i++) {
                    var def = arr[i];
                    if (def.ReadWrite != "RW" && def.ReadWrite != "W") {
                        continue;
                    }
                    def.Intls = JSON.parse(def.Intls);
                    dfs.push(def);
                }
                setSettingDefs(dfs);
            } else if (protocol == "Sinexcel") { //2024.3.29
                var arr = resp.data;
                var dfs = [];
                for (var i=0; i<arr.length; i++) {
                    var def = arr[i];
                    def.Intls = JSON.parse(def.Intls);
                    dfs.push(def);
                }
                setSettingDefs(dfs);
            } else if (protocol == "Megarevo-CESS") { //2024.4.2
                var arr = resp.data;
                var dfs = [];
                for (var i=0; i<arr.length; i++) {
                    var def = arr[i];
                    def.Intls = JSON.parse(def.Intls);
                    dfs.push(def);
                }
                setSettingDefs(dfs);
            } else if (protocol == "Sungery") { //2024.4.2
                var arr = resp.data;
                var dfs = [];
                for (var i=0; i<arr.length; i++) {
                    var def = arr[i];
                    def.Intls = JSON.parse(def.Intls);
                    dfs.push(def);
                }
                setSettingDefs(dfs);
            }
            //

            values['30B9,30BA'] = (v_30B9 != null || v_30BA != null) ? 65536 * v_30B9 + v_30BA : null
            edits['30B9,30BA'] = false;

            setSettingItemDefs(defs)
            setInitValues(values)
            setIsEdits(edits);
            form1.resetFields();
            form2.resetFields();
            form3.resetFields();
            form4.resetFields();
            form5.resetFields();
            form6.resetFields();
            form7.resetFields();
            form8.resetFields();
        })();

        return () => {
            active = false;
        };

    }, [SN, flag]);


    //保存设置的地址和值
    const changeValues = (obj) => {
        let key = Object.keys(obj)[0];
        let value = Object.values(obj)[0];

        if (key == '30B9,30BA') {  //'30B9', '30BA'两个地址表示一个值, 要单独处理
            obj = { '30B9': Math.floor(value / 65536), '30BA': value % 65536 }
        } else if (['2102', '2103', '2104', '2105'].includes(key)) {  //Time类型
            obj = { [key]: parseInt(value.format('HH')) * 256 + parseInt(value.format('mm')) }
        } else if (settingItemDefs[key].Enums != null) {
            if (settingItemDefs[key].Enums.split(';').length == 2 && settingItemDefs[key].Enums.includes('nable')) { //Switch类型
                obj = { [key]: value ? 1 : 0 }
            } else {  //Select类型
                obj = { [key]: value != null ? parseInt(value) : '' }
            }
        } else {  //Value类型
            obj = { [key]: value / (settingItemDefs[key].Scale || 1) }
        }

        setChangedValues({ ...changedValues, ...obj })
        setIsEdits({ ...isEdits, ...{ [key]: true } })
    }

    //将设置的值发送到服务器
    const finish = () => {
        //console.log('当前修改的值: ', changedValues)
        if (Object.keys(changedValues).length == 0) {  //没有做任何修改
            return;
        }
        let active = true;
        (async () => {
            let resp = await http.postJSON("integrated-inverter/setting/request", {
                SerialNumber: SN,
                Setting: changedValues,
                SessionID: ++sessionID % 32  //sessionID不超过32
            }).then(async r => {
                return await r.json();
            }).catch((e) => {
                return null;
            });
            if (!active) {
                return
            }
            //设置失败
            if (resp == null || resp.code !== 200) {
                setTip({ open: true, severity: "error", msg: (resp && resp.msg) ? resp.msg : "inverter setting error" });
                return;
            }
            //设置成功
            setTip({ open: true, severity: 'success', msg: 'inverter setting success' })
            setChangedValues({});
            setFlag(!flag);
        })();

    }

    //重置
    const reset = () => {
        setFlag(!flag);
        form1.resetFields();
        form2.resetFields();
        form3.resetFields();
        form4.resetFields();
        form5.resetFields();
        form6.resetFields();
        form7.resetFields();
        form8.resetFields();
        setChangedValues({});
    }

    //切换面板时, 重置修改的值
    const handleTabChange = async (key) => {
        setChangedValues({})
    }

    //分类设置项
    const grid_parameters = <TabPane tab="Grid Parameters" key="11">
        <Form
            labelCol={{ span: 12 }}
            wrapperCol={{ span: 10 }}
            layout="horizontal"
            form={form1}
            initialValues={initValues}
            onValuesChange={changeValues}
            onFinish={finish}
        >
            <Row>
                <Col span={8}>
                    <Item
                        name="5101"
                        label="Regulation code"
                    >
                        <Select allowClear suffixIcon={isEdits['5101'] ? <EditOutlined /> : <span />}>
                            <Option value="1">AU(Australia AS/NZS 4777.2/3)</Option>
                            <Option value="2">DE(Germany VDE 0126-1-1/A1)</Option>
                            <Option value="3">TW(Taiwan TW GRID)</Option>
                            <Option value="4">DE(Germany VED-AR-N 4105)</Option>
                            <Option value="5">JP(Japan JETGR0002-1-2.0)</Option>
                            <Option value="6">IT(Italy CEI 0-21)</Option>
                            <Option value="7">SE(Sweden SWEDEN Grid)</Option>
                            <Option value="8">UK(British G83/1-1, G59)</Option>
                            <Option value="9">UL(USA UL)</Option>
                            <Option value="10">TL(Thailand PEA)</Option>
                            <Option value="11">SE(Sweden SWEDEN GRID) 2007</Option>
                            <Option value="12">NL(Netherlands NETHERLANDS Grid)</Option>
                            <Option value="13">TL(Thailand  MEA)</Option>
                            <Option value="14">CN(China NB/T 32004)</Option>
                            <Option value="15">IND(India IEC61727)</Option>
                            <Option value="16">AU(Australia AS/NZS 4777.2:2015)</Option>
                            <Option value="17">NZ(Australia AS/NZS 4777.2:2015)</Option>
                            <Option value="18">MX(Mexico IEEE1547)</Option>
                            <Option value="19">UA(Ukraine VDE-AR-N 4105.)</Option>
                            <Option value="20">TW(Taiwan CNS 15382:2017)</Option>
                            <Option value="21">DE(Germany VDE0126-1-1/08.13)</Option>
                            <Option value="22">BR(Brazil ABNT NBR16149 2013)</Option>
                            <Option value="23">CS(Czech EN 50160)</Option>
                            <Option value="24">UK(British G59)</Option>
                            <Option value="25">PT(Portugal EN50438)</Option>
                            <Option value="26">ES(Spain RD1699/RD413)</Option>
                            <Option value="27">ES(Spain RD661/RD413/PO12.3)</Option>
                            <Option value="28">IT(Italy CEI 0-21 ACEA)</Option>
                            <Option value="29">DE(Germany VDE-AR-N 4105:2018)</Option>
                            <Option value="65535">Not defined</Option>
                        </Select>
                    </Item>
                </Col>
                <Col span={8}>
                    <Item
                        name="5000"
                        label="First Connect Delay Time(s)"
                    >
                        <Input placeholder="[10, 600]" suffix={isEdits['5000'] ? <EditOutlined /> : <span />} />
                    </Item>
                </Col>
                <Col span={8}>
                    <Item
                        name="5001"
                        label="Reconnect Delay Time(s)"
                    >
                        <Input placeholder="[10, 900]" suffix={isEdits['5001'] ? <EditOutlined /> : <span />} />
                    </Item>
                </Col>
            </Row>
            <Row>
                <Col span={8}>
                    <Item
                        name="5002"
                        label="Frequency High Loss Level_1(Hz)"
                    >
                        <Input placeholder="[50, 60]" suffix={isEdits['5002'] ? <EditOutlined /> : <span />} />
                    </Item>
                </Col>
                <Col span={8}>
                    <Item
                        name="5003"
                        label="Frequency Low Loss Level_1(Hz)"
                    >
                        <Input placeholder="[40, 50]" suffix={isEdits['5003'] ? <EditOutlined /> : <span />} />
                    </Item>
                </Col>
                <Col span={8}>
                    <Item
                        name="5004"
                        label="Voltage High Loss Level_1(V)"
                    >
                        <Input placeholder="[230, 322]" suffix={isEdits['5004'] ? <EditOutlined /> : <span />} />
                    </Item>
                </Col>
            </Row>
            <Row>
                <Col span={8}>
                    <Item
                        name="5005"
                        label="Voltage Low Loss Level_1(V)"
                    >
                        <Input placeholder="[23, 230]" suffix={isEdits['5005'] ? <EditOutlined /> : <span />} />
                    </Item>
                </Col>
                <Col span={8}>
                    <Item
                        name="5006"
                        label="Frequency High Loss Time Level_1(ms)"
                    >
                        <Input placeholder="[0, 60000]" suffix={isEdits['5006'] ? <EditOutlined /> : <span />} />
                    </Item>
                </Col>
                <Col span={8}>
                    <Item
                        name="5007"
                        label="Frequency Low Loss Time Level_1(ms)"
                    >
                        <Input placeholder="[0, 60000]" suffix={isEdits['5007'] ? <EditOutlined /> : <span />} />
                    </Item>
                </Col>
            </Row>
            <Row>
                <Col span={8}>
                    <Item
                        name="5008"
                        label="Voltage High Loss Time Level_1(ms)"
                    >
                        <Input placeholder="[0, 60000]" suffix={isEdits['5008'] ? <EditOutlined /> : <span />} />
                    </Item>
                </Col>
                <Col span={8}>
                    <Item
                        name="5009"
                        label="Voltage Low Loss Time Level_1(ms)"
                    >
                        <Input placeholder="[0, 60000]" suffix={isEdits['5009'] ? <EditOutlined /> : <span />} />
                    </Item>
                </Col>
                <Col span={8}>
                    <Item
                        name="5010"
                        label="Frequency High Loss Level_2(Hz)"
                    >
                        <Input placeholder="[50, 60]" suffix={isEdits['5010'] ? <EditOutlined /> : <span />} />
                    </Item>
                </Col>
            </Row>
            <Row>
                <Col span={8}>
                    <Item
                        name="5011"
                        label="Frequency Low Loss Level_2(Hz)"
                    >
                        <Input placeholder="[40, 50]" suffix={isEdits['5011'] ? <EditOutlined /> : <span />} />
                    </Item>
                </Col>
                <Col span={8}>
                    <Item
                        name="5012"
                        label="Voltage High Loss Level_2(V)"
                    >
                        <Input placeholder="[230, 322]" suffix={isEdits['5012'] ? <EditOutlined /> : <span />} />
                    </Item>
                </Col>
                <Col span={8}>
                    <Item
                        name="5013"
                        label="Voltage Low Loss Level_2(V)"
                    >
                        <Input placeholder="[23, 230]" suffix={isEdits['5013'] ? <EditOutlined /> : <span />} />
                    </Item>
                </Col>
            </Row>
            <Row>
                <Col span={8}>
                    <Item
                        name="5014"
                        label="Frequency High Loss Time Level_2(ms)"
                    >
                        <Input placeholder="[0, 60000]" suffix={isEdits['5014'] ? <EditOutlined /> : <span />} />
                    </Item>
                </Col>
                <Col span={8}>
                    <Item
                        name="5015"
                        label="Frequency Low Loss Time Level_2(ms)"
                    >
                        <Input placeholder="[0, 60000]" suffix={isEdits['5015'] ? <EditOutlined /> : <span />} />
                    </Item>
                </Col>
                <Col span={8}>
                    <Item
                        name="5016"
                        label="Voltage High Loss Time Level_2(ms)"
                    >
                        <Input placeholder="[0, 60000]" suffix={isEdits['5016'] ? <EditOutlined /> : <span />} />
                    </Item>
                </Col>
            </Row>
            <Row>
                <Col span={8}>
                    <Item
                        name="5017"
                        label="Voltage Low Loss Time Level_2(ms)"
                    >
                        <Input placeholder="[0, 60000]" suffix={isEdits['5017'] ? <EditOutlined /> : <span />} />
                    </Item>
                </Col>
            </Row>
            <Item
                wrapperCol={{ span: 16, offset: 10 }}
            >
                <Button type="primary" shape="round" htmlType="reset" onClick={reset}>Reset</Button>
                <div style={{ width: '50px', display: 'inline-block' }}></div>
                <Button type="primary" shape="round" htmlType="submit">Submit</Button>
            </Item>
        </Form>
    </TabPane>

    const feature_parameters = <TabPane tab="Feature Parameters" key="12">
        <Form
            labelCol={{ span: 12 }}
            wrapperCol={{ span: 10 }}
            layout="horizontal"
            form={form2}
            initialValues={initValues}
            onValuesChange={changeValues}
            onFinish={finish}
        >
            <Row>
                <Col span={8}>
                    <Item
                        name="5112"
                        label="Low Voltage Through"
                        valuePropName="checked"
                    >
                        <Switch checkedChildren={isEdits['5112'] ? <EditOutlined /> : <span />}
                            unCheckedChildren={isEdits['5112'] ? <EditOutlined /> : <span />} />
                    </Item>
                </Col>
                <Col span={8}>
                    <Item
                        name="510E"
                        label="Island Detection"
                        valuePropName="checked"
                    >
                        <Switch checkedChildren={isEdits['510E'] ? <EditOutlined /> : <span />}
                            unCheckedChildren={isEdits['510E'] ? <EditOutlined /> : <span />} />
                    </Item>
                </Col>
                <Col span={8}>
                    <Item
                        name="5117"
                        label="Isolation Detection"
                        valuePropName="checked"
                    >
                        <Switch checkedChildren={isEdits['5117'] ? <EditOutlined /> : <span />}
                            unCheckedChildren={isEdits['5117'] ? <EditOutlined /> : <span />} />
                    </Item>
                </Col>
            </Row>
            <Row>
                <Col span={8}>
                    <Item
                        name="5118"
                        label="Leakage Current Detection(GFCI)"
                        valuePropName="checked"
                    >
                        <Switch checkedChildren={isEdits['5118'] ? <EditOutlined /> : <span />}
                            unCheckedChildren={isEdits['5118'] ? <EditOutlined /> : <span />} />
                    </Item>
                </Col>
                <Col span={8}>
                    <Item
                        name="5114"
                        label="Reactive Power(%)"
                    >
                        <Input placeholder="[-110, 110]" suffix={isEdits['5114'] ? <EditOutlined /> : <span />} />
                    </Item>
                </Col>
                <Col span={8}>
                    <Item
                        name="5104"
                        label="Derated Power(%)"
                    >
                        <Input placeholder="[0, 110]" suffix={isEdits['5104'] ? <EditOutlined /> : <span />} />
                    </Item>
                </Col>
            </Row>
            <Row>
                <Col span={8}>
                    <Item
                        name="5031"
                        label="Power Factor"
                    >
                        <Input placeholder="[-1000, -800], [800, 1000]" suffix={isEdits['5031'] ? <EditOutlined /> : <span />} />
                    </Item>
                </Col>
                <Col span={8}>
                    <Item
                        name="5113"
                        label="Insulation Type"
                    >
                        <Select allowClear suffixIcon={isEdits['5113'] ? <EditOutlined /> : <span />}>
                            <Option value="0">with ground line, no transformer</Option>
                            <Option value="1">no ground line, with transformer</Option>
                            <Option value="2">with ground line, with transformer</Option>
                        </Select>
                    </Item>
                </Col>
                <Col span={8}>
                    <Item
                        name="501B"
                        label="Insulation Impedance(KΩ)"
                    >
                        <Input placeholder="[10, 5000]" suffix={isEdits['501B'] ? <EditOutlined /> : <span />} />
                    </Item>
                </Col>
            </Row>
            <Row>
                <Col span={8}>
                    <Item
                        name="5110"
                        label="Leakage Current Point(mA)"
                    >
                        <Input placeholder="[10, 300]" suffix={isEdits['5110'] ? <EditOutlined /> : <span />} />
                    </Item>
                </Col>
                <Col span={8}>
                    <Item
                        name="510F"
                        label="Unbalanced Voltage Point(%)"
                    >
                        <Input placeholder="[1, 50]" suffix={isEdits['510F'] ? <EditOutlined /> : <span />} />
                    </Item>
                </Col>
                <Col span={8}>
                    <Item
                        name="5018"
                        label="Moving Average Voltage Limit(V)"
                    >
                        <Input placeholder="[230, 999]" suffix={isEdits['5018'] ? <EditOutlined /> : <span />} />
                    </Item>
                </Col>
            </Row>
            <Item
                wrapperCol={{ span: 16, offset: 10 }}
            >
                <Button type="primary" shape="round" htmlType="reset" onClick={reset}>Reset</Button>
                <div style={{ width: '50px', display: 'inline-block' }}></div>
                <Button type="primary" shape="round" htmlType="submit">Submit</Button>
            </Item>
        </Form>
    </TabPane>

    const power_limit = <TabPane tab="Power Limit" key="13">
        <Form
            labelCol={{ span: 12 }}
            wrapperCol={{ span: 10 }}
            layout="horizontal"
            form={form3}
            initialValues={initValues}
            onValuesChange={changeValues}
            onFinish={finish}
        >
            <Row>
                <Col span={8}>
                    <Item
                        name="30B3"
                        label="Power Control"
                    >
                        <Select allowClear suffixIcon={isEdits['30B3'] ? <EditOutlined /> : <span />}>
                            <Option value="0">Disable</Option>
                            <Option value="1">External device</Option>
                            <Option value="2">PVI external CT sensor</Option>
                            <Option value="3">Digital meter device</Option>
                        </Select>
                    </Item>
                </Col>
                <Col span={8}>
                    <Item
                        name="30B1"
                        label="Meter Type"
                    >
                        <Select allowClear suffixIcon={isEdits['30B1'] ? <EditOutlined /> : <span />}>
                            <Option value="0">Unknown</Option>
                            <Option value="1">Gavazzi/EM340DINAV23XS1X</Option>
                            <Option value="2">CHINT/DTSU666</Option>
                            <Option value="3">Acrel/DTSF1352-C</Option>
                            <Option value="4">Lovato/DMG210</Option>
                            <Option value="5">G2000</Option>
                            <Option value="6">Gavazzi/ET112</Option>
                            <Option value="7">Kostal /KSEM</Option>
                            <Option value="8">CHINT/DDSU666</Option>
                        </Select>
                    </Item>
                </Col>
                <Col span={8}>
                    <Item
                        name="30B5"
                        label="Meter Location"
                    >
                        <Select allowClear suffixIcon={isEdits['30B5'] ? <EditOutlined /> : <span />}>
                            <Option value="0">Meter on Grid</Option>
                            <Option value="1">Meter on Load</Option>
                        </Select>
                    </Item>
                </Col>
            </Row>
            <Row>
                <Col span={8}>
                    <Item
                        name="30B2"
                        label="Power Flow Direction"
                    >
                        <Select allowClear suffixIcon={isEdits['30B2'] ? <EditOutlined /> : <span />}>
                            <Option value="0">From grid to inverter</Option>
                            <Option value="1">From inverter to gird</Option>
                        </Select>
                    </Item>
                </Col>
                <Col span={8}>
                    <Item
                        name="30B9,30BA"
                        label="Maximum feed in grid power(W)"
                    >
                        <Input placeholder="[0, 200000]" suffix={isEdits['30B9,30BA'] ? <EditOutlined /> : <span />} />
                    </Item>
                </Col>
            </Row>
            <Item
                wrapperCol={{ span: 16, offset: 10 }}
            >
                <Button type="primary" shape="round" htmlType="reset" onClick={reset}>Reset</Button>
                <div style={{ width: '50px', display: 'inline-block' }}></div>
                <Button type="primary" shape="round" htmlType="submit">Submit</Button>
            </Item>
        </Form>
    </TabPane>

    const hybird_setting = <TabPane tab="Hybird Setting" key="14">
        <Form
            labelCol={{ span: 12 }}
            wrapperCol={{ span: 10 }}
            layout="horizontal"
            form={form4}
            initialValues={initValues}
            onValuesChange={changeValues}
            onFinish={finish}
        >
            <Row>
                <Col span={8}>
                    <Item
                        name="2100"
                        label="Hybird work mode"
                    >
                        <Select allowClear suffixIcon={isEdits['2100'] ? <EditOutlined /> : <span />}>
                            <Option value="0">Self Used Mode</Option>
                            <Option value="1">Feed-in Priority Mode</Option>
                            <Option value="2">Time-Based Control</Option>
                            <Option value="3">Back-up Mode</Option>
                            <Option value="4">Battery Discharge mode</Option>
                        </Select>
                    </Item>
                </Col>
                <Col span={8}>
                    <Item
                        name="2101"
                        label="Charge frequence"
                    >
                        <Select allowClear suffixIcon={isEdits['2101'] ? <EditOutlined /> : <span />}>
                            <Option value="0">Once</Option>
                            <Option value="1">Everyday</Option>
                        </Select>
                    </Item>
                </Col>
                <Col span={8}>
                    <Item
                        name="2102"
                        label="Charge start time"
                    >
                        <TimePicker format='HH:mm' suffixIcon={isEdits['2102'] ? <EditOutlined /> : <ClockCircleOutlined />} />
                    </Item>
                </Col>
            </Row>
            <Row>
                <Col span={8}>
                    <Item
                        name="2103"
                        label="Charge end time"
                    >
                        <TimePicker format='HH:mm' suffixIcon={isEdits['2103'] ? <EditOutlined /> : <ClockCircleOutlined />} />
                    </Item>
                </Col>
                <Col span={8}>
                    <Item
                        name="2104"
                        label="Discharge start time"
                    >
                        <TimePicker format='HH:mm' suffixIcon={isEdits['2104'] ? <EditOutlined /> : <ClockCircleOutlined />} />
                    </Item>
                </Col>
                <Col span={8}>
                    <Item
                        name="2105"
                        label="Discharge end time"
                    >
                        <TimePicker format='HH:mm' suffixIcon={isEdits['2105'] ? <EditOutlined /> : <ClockCircleOutlined />} />
                    </Item>
                </Col>
            </Row>
            <Row>
                <Col span={8}>
                    <Item
                        name="2115"
                        label="Grid Charge"
                        valuePropName="checked"
                    >
                        <Switch checkedChildren={isEdits['2115'] ? <EditOutlined /> : <span />}
                            unCheckedChildren={isEdits['2115'] ? <EditOutlined /> : <span />} />
                    </Item>
                </Col>
                <Col span={8}>
                    <Item
                        name="2116"
                        label="Maximum grid charger power(W)"
                    >
                        <Input suffix={isEdits['2116'] ? <EditOutlined /> : <span />} />
                    </Item>
                </Col>
                <Col span={8}>
                    <Item
                        name="2117"
                        label="Capacity of grid charger end(%)"
                    >
                        <Input suffix={isEdits['2117'] ? <EditOutlined /> : <span />} />
                    </Item>
                </Col>
            </Row>
            <Row>
                <Col span={8}>
                    <Item
                        name="2118"
                        label="Maximum charger power(W)"
                    >
                        <Input suffix={isEdits['2118'] ? <EditOutlined /> : <span />} />
                    </Item>
                </Col>
                <Col span={8}>
                    <Item
                        name="2119"
                        label="Capacity of charger end(%)"
                    >
                        <Input suffix={isEdits['2119'] ? <EditOutlined /> : <span />} />
                    </Item>
                </Col>
                <Col span={8}>
                    <Item
                        name="211A"
                        label="Maximum discharger power(W)"
                    >
                        <Input suffix={isEdits['211A'] ? <EditOutlined /> : <span />} />
                    </Item>
                </Col>
            </Row>
            <Row>
                <Col span={8}>
                    <Item
                        name="211B"
                        label="Capacity of discharger end(%)"
                    >
                        <Input suffix={isEdits['211B'] ? <EditOutlined /> : <span />} />
                    </Item>
                </Col>
                <Col span={8}>
                    <Item
                        name="211C"
                        label="Off-grid mode"
                        valuePropName="checked"
                    >
                        <Switch checkedChildren={isEdits['211C'] ? <EditOutlined /> : <span />}
                            unCheckedChildren={isEdits['211C'] ? <EditOutlined /> : <span />} />
                    </Item>
                </Col>
                <Col span={8}>
                    <Item
                        name="211D"
                        label="Rated output voltage(V)"
                    >
                        <Input suffix={isEdits['211D'] ? <EditOutlined /> : <span />} />
                    </Item>
                </Col>
            </Row>
            <Row>
                <Col span={8}>
                    <Item
                        name="211E"
                        label="Rated output frequency(Hz)"
                    >
                        <Input suffix={isEdits['211E'] ? <EditOutlined /> : <span />} />
                    </Item>
                </Col>
                <Col span={8}>
                    <Item
                        name="211F"
                        label="Off-gird start-up battery capacity(%)"
                    >
                        <Input suffix={isEdits['211F'] ? <EditOutlined /> : <span />} />
                    </Item>
                </Col>
                <Col span={8}>
                    <Item
                        name="2143"
                        label="Parallel mode"
                        valuePropName="checked"
                    >
                        <Switch checkedChildren={isEdits['2143'] ? <EditOutlined /> : <span />}
                            unCheckedChildren={isEdits['2143'] ? <EditOutlined /> : <span />} />
                    </Item>
                </Col>
            </Row>
            <Item
                wrapperCol={{ span: 16, offset: 10 }}
            >
                <Button type="primary" shape="round" htmlType="reset" onClick={reset}>Reset</Button>
                <div style={{ width: '50px', display: 'inline-block' }}></div>
                <Button type="primary" shape="round" htmlType="submit">Submit</Button>
            </Item>
        </Form>
    </TabPane>

    return (
        <Tabs style={{
            flex: "1 1 auto",
        }} tabPosition="top" onChange={handleTabChange}>
            {protocol == 'Thinkpower' ? 
                (<TabPane tab="Settings" key="Settings"><ThinkPowerSetting SN={SN} SettingDefs={settingDefs}/></TabPane>)
                : protocol == 'Megarevo' ?
                (<TabPane tab="Settings" key="Settings"><MegarevoSetting SN={SN} SettingDefs={settingDefs}/></TabPane>)
                : protocol == "Sinexcel" ? 
                (<TabPane tab="Settings" key="Settings"><SinexcelSetting SN={SN} SettingDefs={settingDefs}/></TabPane>)
                : protocol == "Megarevo-CESS" ? 
                (<TabPane tab="Settings" key="Settings"><MegarevoCESSSetting SN={SN} SettingDefs={settingDefs}/></TabPane>)
                : protocol == "Sungery" ? 
                (<TabPane tab="Settings" key="Settings"><SungerySetting SN={SN} SettingDefs={settingDefs}/></TabPane>)
                :
                (<>
                    {grid_parameters}
                    {feature_parameters}
                    {power_limit}
                    {hybird_setting}
                </>)
            }
            {/* {protocol == 'Thinkpower' ? tp_basic_setting : grid_parameters}
            {protocol == 'Thinkpower' ? tp_battery_setting : feature_parameters}
            {protocol == 'Thinkpower' ? tp_grid_setting : power_limit}
            {protocol == 'Thinkpower' ? tp_other_setting : hybird_setting} */}
            <TabPane tab="Inverter Burning" key="5">
                <InverterBurningList />
            </TabPane>
            <TabPane tab="DTU Burning" key="6">
                <DTUBurningList />
            </TabPane>
            <TabPane tab="Inverter Firmware" key="7">
                <InverterFirmwareManagement />
            </TabPane>
            <TabPane tab="DTU Firmware" key="8">
                <DTUFirmwareManagement />
            </TabPane>
        </Tabs>
    )
}

export default InvSetting;