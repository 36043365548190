import { useContext, useState, useRef } from 'react';
import { Modal, Button, Input, Form, Select, Switch, Space } from 'antd';
import { PlusOutlined, MinusCircleOutlined } from '@ant-design/icons';
import http from '../../../Http';
import utils from '../../../utils';
import InputPrefix from '../../../utils/InputPrefix';

function InverterModify(props) {
    const setTip = useContext(utils.tipContext);

    const { show, onClose, row } = props;

    const [form] = Form.useForm();

    const [distributorSelectOptions, setDistributorSelectOptions] = useState([]);
    const [timezoneSelectOptions, setTimezoneSelectOptions] = useState([]);

    const refTimerDistributorSearcher = useRef(null);
    const refDistributorSearchValue = useRef(null);
    const refTimerTimezoneSearcher = useRef(null);
    const refTimezoneSearchValue = useRef(null);
    const [inverterModelSelectOptions, setInverterModelSelectOptions] = useState([]);
    const refTimerInverterModelSearcher = useRef(null);
    const refInverterModelSearchValue = useRef(null);

    const handleCancle = () => {
        onClose(null);
        form.resetFields()
    }

    const handleSubmit = async () => {
        const ps = await form.validateFields().catch(() => { return null });

        if (ps === null) {
            return;
        }

        ps.ID = row.ID;
        ps.CreateTime = row.CreateTime;
        ps.Model = ps.Model || "";
        ps.DistributorName = ps.DistributorName || ""; 
        ps.Timezone = ps.Timezone || "";
        ps.Batteries = ps.Batteries && ps.Batteries.length > 0 ? ps.Batteries.map(v => v.DeviceSN).join(";") : "";
        ps.Batteries = encodeURIComponent(ps.Batteries)

        const resp = await http.post("integrated-inverter/modify", ps).then(async (r) => {
            return await r.json();
        }).catch((e) => {
            return null;
        });

        try {
            if (resp.code !== 200) {
                setTip({ open: true, severity: "error", msg: (resp && resp.msg) ? resp.msg : "modify error" });
                return
            }

            setTip({ open: true, severity: 'success', msg: 'modify success' });

            ps.ID = resp.data.ID;
            ps.CreateTime = resp.data.CreateTime;
            onClose(ps);
        } catch (e) {
            setTip({ open: true, severity: "error", msg: "modify error" });
        }
    }

    const handleSearchDistributor = (v) => {
        if (refTimerDistributorSearcher.current != null) {
            clearTimeout(refTimerDistributorSearcher.current);
        }
        refDistributorSearchValue.current = v;

        refTimerDistributorSearcher.current = setTimeout(async () => {
            const resp = await http.post("distributor/name/list", {
                Value: v,
            }).then(async (r) => {
                return await r.json();
            }).catch((e) => {
                console.log("[DeviceAdd]", e);
                return null;
            });

            if (refDistributorSearchValue.current !== v) {
                return;
            }

            try {
                if (resp.code !== 200) {
                    setTip({ open: true, severity: "error", msg: (resp && resp.msg) ? resp.msg : "load distributor list error" });
                    return;
                }

                const ls = resp.data.map(({ Name, ID }) => {
                    return (
                        <Select.Option key={ID} value={Name}>{Name}</Select.Option>
                    );
                });
                setDistributorSelectOptions(ls);
            } catch (e) {
                setTip({ open: true, severity: "error", msg: "load distributor list error" });
            }
        }, 300);
    }

    const handleSearchInverterModel = (v) => {
        if (refTimerInverterModelSearcher.current != null) {
            clearTimeout(refTimerInverterModelSearcher.current);
        }
        refInverterModelSearchValue.current = v;

        refTimerInverterModelSearcher.current = setTimeout(async () => {
            const resp = await http.post("integrated-inverter/model/list", {
                Value: v
            }).then(async (r) => {
                return await r.json();
            }).catch((e) => {
                return null;
            });

            if (refInverterModelSearchValue.current !== v) {
                return;
            }

            try {
                if (resp.code !== 200) {
                    setTip({ open: true, severity: "error", msg: (resp && resp.msg) ? resp.msg : "load inverter model error" });
                    return;
                }

                const ls = resp.data.map(({ ID, Name }) => {
                    return (
                        <Select.Option key={ID} value={Name}>{Name}</Select.Option>
                    );
                });
                setInverterModelSelectOptions(ls);
            } catch (e) {
                setTip({ open: true, severity: "error", msg: "load inverter model error" });
            }
        }, 300);
    }

    const handleSearchTimezone = (v) => {
        if (refTimerTimezoneSearcher.current != null) {
            clearTimeout(refTimerTimezoneSearcher.current);
        }
        refTimezoneSearchValue.current = v;


        refTimerTimezoneSearcher.current = setTimeout(async () => {
            const resp = await http.post("timezone/list", {
                Timezone: v,
            }).then(async (r) => {
                return await r.json();
            }).catch((e) => {
                console.log("[DeviceAdd]", e);
                return null;
            });

            if (refTimezoneSearchValue.current !== v) {
                return;
            }

            try {
                if (resp.code !== 200) {
                    setTip({ open: true, severity: "error", msg: (resp && resp.msg) ? resp.msg : "load timezone list error" });
                    return;
                }

                const ls = resp.data.map(({ ZoneName, GmtOffset }) => {
                    var n = parseInt(GmtOffset);
                    var tz = "UTC+";
                    if (n < 0) {
                        tz = "UTC-";
                        n = Math.abs(n);
                    }
                    tz += Math.floor(n / 3600).toString().padStart(2, "0") + ":" + Math.floor((n % 3600) / 60).toString().padStart(2, "0")
                    return (
                        <Select.Option key={ZoneName} value={ZoneName}>{ZoneName}({tz})</Select.Option>
                    );
                });
                setTimezoneSelectOptions(ls);
            } catch (e) {
                console.log("[Device-Adder]", e);

                setTip({ open: true, severity: "error", msg: "load device list error" });
            }
        }, 300);
    }

    const params = {...row}
    params.Batteries = params.Batteries ? params.Batteries.split(',').map(v => ({DeviceSN: v})) : null

    return (<Modal
        title="Modify Inverter"
        visible={show}
        cancelText="Cancel"
        okText="Submit"
        mask={true}
        maskClosable={false}
        width={500}
        onCancel={handleCancle}
        onOk={handleSubmit}
        footer={[
            <Button
                key="Clear"
                shape="round"
                onClick={() => {
                    form.resetFields();
                }}
            >Clear</Button>,
            <Button key="Cancel" shape="round" onClick={handleCancle}>Cancel</Button>,
            <Button key="Submit" shape="round" type="primary" onClick={handleSubmit}>Submit</Button>
        ]}
    >
        <Form
            labelAlign="right"
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 25 }}
            form={form}
            initialValues={params}
        >
            <Form.Item
                name="SN"
                label=""
                rules={[{ required: true }]} 
            >
                <Input prefix={<InputPrefix label="SN" required />} placeholder="Enter Inverter SN" style={{ borderRadius: '20px' }} />
            </Form.Item>
            <Input.Group compact>
                <Button><span style={{color: 'red', verticalAlign: 'middle'}}>*&nbsp;</span>Model</Button>
                <Form.Item
                    name="Model"
                    label=""
                    rules={[{ required: true }]}
                    style={{width: 'calc(100% - 80px'}}
                >
                    <Select
                        showSearch
                        placeholder="Enter Inverter Model"
                        defaultActiveFirstOption={false}
                        showArrow={false}
                        filterOption={false}
                        onSearch={handleSearchInverterModel}
                    >{inverterModelSelectOptions}</Select>
                </Form.Item>
            </Input.Group>
            <Input.Group compact>
                <Button>Distributor</Button>
                <Form.Item
                    name="DistributorName"
                    label=""
                    style={{width: 'calc(100% - 98px'}}
                >
                    <Select
                        showSearch
                        placeholder="Enter Distributor Name"
                        defaultActiveFirstOption={false}
                        showArrow={false}
                        filterOption={false}
                        onSearch={handleSearchDistributor}
                    >{distributorSelectOptions}</Select>
                </Form.Item>
            </Input.Group>
            <Input.Group compact>
                <Button><span style={{color: 'red', verticalAlign: 'middle'}}>&nbsp;</span>Timezone</Button>
                <Form.Item
                    name="Timezone"
                    label=""
                    style={{width: 'calc(100% - 102px'}}
                >
                    <Select
                        showSearch
                        placeholder="Timezone"
                        defaultActiveFirstOption={false}
                        showArrow={false}
                        filterOption={false}
                        onSearch={handleSearchTimezone}
                    >{timezoneSelectOptions}</Select>
                </Form.Item>
            </Input.Group>
            
            <Form.Item label="Batteries">
                <Form.List name="Batteries" label="Batteries">
                    {(fields, { add, remove }) => (
                        <>
                            {fields.map(({ key, name, fieldKey, ...restField }) => (
                                <Space key={key} style={{ display: 'flex', marginBottom: 2 }} align="baseline">
                                    <Form.Item
                                        {...restField}
                                        name={[name, 'DeviceSN']}
                                        fieldKey={[fieldKey, 'DeviceSN']}
                                        rules={[{ required: true, message: 'Missing Battery SN' }]
                                        }
                                    >
                                        <Input placeholder="Enter Battery SN" style={{width: 300}} />
                                    </Form.Item>
                                    <MinusCircleOutlined onClick={() => remove(name)} />
                                </Space>
                            ))}
                            <Form.Item>
                                <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />} style={{borderRadius: '20px'}}>
                                    Add Battery
                                </Button>
                            </Form.Item>
                        </>
                    )}
                </Form.List>
            </Form.Item>
        </Form>
    </Modal>)
}

export default InverterModify;