import TitleBar from '../utils/TitleBar.js';

function InverterStatus(props) {
    const { data } = props;

    return (
        <div style={{
            padding: '10px 15px'
        }}>
            <TitleBar title="Inverter Status" data={data} />
            <div style={{marginTop: '20px'}}>
                <div style={{backgroundColor: '#f0f0f0', width: '52%', borderRadius: '10px', float: 'left', marginRight: '10px', padding: '4px 6px 10px'}}>
                    <div style={{fontSize: '12px', fontWeight: 'bold', borderBottom: '1px solid #1B8AC5', marginBottom: '6px'}}>
                        Current,Voltage&Temperature
                    </div>
                    <div>
                        <div style={{fontSize: '10px'}}>Max Charge Current: {data ? (((data["MaxChargeCurrent"] - 32767) * 0.1).toFixed(1)) + " A" : "-"}</div>
                        <div style={{fontSize: '10px'}}>Max Discharge Current: {data ? (((data["MaxDischargeCurrent"] - 32767) * 0.1).toFixed(1)) + " A" : "-"}</div>
                        <div style={{fontSize: '10px'}}>Charge Voltage: {data ? ((data["ChargeVoltage"] * 0.1).toFixed(1)) + " V" : "-"}</div>
                        <div style={{fontSize: '10px'}}>Discharge Voltage: {data ? ((data["DischargeVoltage"] * 0.1).toFixed(1)) + " V" : "-"}</div>
                        <div style={{fontSize: '10px'}}>Battary Voltage: {data ? ((data["BatteryVoltage"] * 0.01).toFixed(1)) + " V" : "-"}</div>
                        <div style={{fontSize: '10px'}}>Battary Current: {data ? (((data["BatteryCurrent"] - 32767) * 0.1).toFixed(1)) + " A" : "-"}</div>
                        <div style={{fontSize: '10px'}}>Battary Temperature: {data ? ((data["BatteryTemperature"] * 0.1).toFixed(1)) + " ℃" : "-"}</div>
                    </div>
                </div>
                <div style={{backgroundColor: '#f0f0f0', width: '44%', borderRadius: '10px', float: 'right', padding: '4px 6px 10px'}}>
                    <div style={{fontSize: '12px', fontWeight: 'bold', borderBottom: '1px solid #1B8AC5', marginBottom: '6px'}}>
                        Status
                    </div>
                    <div>
                        <div style={{fontSize: '10px'}}>Charge Enable: {data ? (data["ChargeEnabled"] == 1 ? "enabled" : "disabled") : "-"}</div>
                        <div style={{fontSize: '10px'}}>Discharge Enable: {data ? (data["DischargeEnabled"] == 1 ? "enabled" : "disabled") : "-"}</div>
                        <div style={{fontSize: '10px'}}>Emergency Charge Enable: {data ? (data["EmergencyChargeEnabled"] == 1 ? "enabled" : "disabled") : "-"}</div>
                        <div style={{fontSize: '10px'}}>ChargeEnabled: {data ? (data["Status"] == 1 ? "init" : data["Status"] == 2 ? "comm" : data["Status"] == 3 ? "comm failed" : "unknown") : "-"}</div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default InverterStatus;