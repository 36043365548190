import { useRef, useState } from 'react';
import { Button, Select, Input } from 'antd';
import Voltage from './Curve/Voltage';
import SOC from './Curve/SOC';
import Charge from './Curve/Charge';
import Discharge from './Curve/Discharge';
import http from './Http';


function Curve (props) {
    const { setTip } = props;

    const [pageIndex, setPageIndex] = useState(0);
    const [deviceID, setDeviceID] = useState(null);
    const [deviceSelectOptions, setDeviceSelectOptions] = useState([]);
    const refTimerDeviceSearcher = useRef(null);
    const refDeviceSearchValue = useRef(null);

    const [date, setDate] = useState(new Date())

    const handleSearchDevice = (v) => {
        if (refTimerDeviceSearcher.current != null) {
            clearTimeout(refTimerDeviceSearcher.current);
        }
        refDeviceSearchValue.current = v;

        refTimerDeviceSearcher.current = setTimeout(async () => {
            const resp = await http.post("device/id-name/list", {
                SN: v,
            }).then(async (r) => {
                return await r.json();
            }).catch((e) => {
                console.log("[SummaryDataExporter]", e);
                return null;
            });

            if (refDeviceSearchValue.current !== v) {
                return;
            }

            try {
                if (resp.code !== 200) {
                    setTip({ open: true, severity: "error", msg: (resp && resp.msg) ? resp.msg : "load device list error" });
                    return;
                }

                const ls = resp.data.map(({ SN, ID }) => {
                    return (
                        <Select.Option key={ID} value={ID}>{SN}</Select.Option>
                    );
                });
                setDeviceSelectOptions(ls);
            } catch (e) {
                console.log("[SummaryDataExporter]", e);

                setTip({ open: true, severity: "error", msg: "load device list error" });
            }
        }, 300);
    }

    return (
        <div style={{
            flex: '1 1 auto',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'stretch',
            backgroundColor: '#EFF8FE',
            overflow: 'auto',
            padding: '10px'
        }}>
            {/* 搜索行 */}
            <div style={{
                padding: '10px 10px 18px',
                display: 'flex',
                alignItems: 'center',
            }}>
                <span style={{ fontSize: '18px', fontWeight: '600', marginRight: '30px' }}>Curve</span>
                <Input.Group compact>
                <Button>Device SN</Button>
                    <Select
                        style={{minWidth: 400}}
                        showSearch
                        defaultActiveFirstOption={false}
                        showArrow={false}
                        filterOption={false}
                        onSearch={(value) => {
                            handleSearchDevice(value);
                        }}
                        onSelect={(value) => {
                            setDeviceID(value)
                        }}
                    >
                        {deviceSelectOptions}
                    </Select>
                </Input.Group>
            </div>
            {/* 绘图行 */}
            <div style={{
                flex: '1 0 1px',
                display: "flex",
                alignItems: "stretch",
            }}>
                {pageIndex == 0 ? (
                    <Voltage 
                        setTip= {setTip}
                        deviceID={deviceID}
                        date={date}
                        setDate={setDate}
                    />
                ) : pageIndex == 1 ? (
                    <SOC 
                        setTip={setTip}
                        deviceID={deviceID}
                        date={date}
                        setDate={setDate}
                    />
                ) : pageIndex == 2 ? (
                    <Charge 
                        setTip= {setTip}
                        deviceID={deviceID}
                        date={date}
                        setDate={setDate}
                    />
                ) : (
                    <Discharge 
                        setTip= {setTip}
                        deviceID={deviceID}
                        date={date}
                        setDate={setDate}
                    />
                )}
            </div>
            {/* 底部按钮行 */}
            <div style={{
                flex: '0 0 auto',
                display: 'flex',
                flexDirection: 'row',
            }}>
                <Button 
                    onClick={()=>{
                        if (pageIndex == 0) {
                            return;
                        }

                        setPageIndex(0);
                    }}
                    variant="contained"
                    style={{
                        color: pageIndex==0 ? "white" : "black",
                        backgroundColor: pageIndex==0 ? "#1B8AC5" : "#f0f0f0",
                        flex: '1 1 auto'
                    }}
                >Voltage</Button>
                <Button 
                    onClick={()=>{
                        if (pageIndex == 1) {
                            return;
                        }

                        setPageIndex(1);
                    }}
                    variant="contained"
                    style={{
                        color: pageIndex==1 ? "white" : "black",
                        backgroundColor: pageIndex==1 ? "#1B8AC5" : "#f0f0f0",
                        flex: '1 1 auto'
                    }}
                >SOC</Button>
                <Button
                    onClick={()=>{
                        if (pageIndex == 2) {
                            return;
                        }

                        setPageIndex(2);
                    }}
                    variant="contained"
                    style={{
                        color: pageIndex==2 ? "white" : "black",
                        backgroundColor: pageIndex==2 ? "#1B8AC5" : "#f0f0f0",
                        flex: '1 1 auto'
                    }}
                >Charge</Button>
                <Button
                    onClick={()=>{
                        if (pageIndex == 3) {
                            return;
                        }

                        setPageIndex(3);
                    }}
                    variant="contained"
                    style={{
                        color: pageIndex==3 ? "white" : "black",
                        backgroundColor: pageIndex==3 ? "#1B8AC5" : "#f0f0f0",
                        flex: '1 1 auto'
                    }}
                >Discharge</Button>
            </div>
        </div>
    )
}

export default Curve;