import http from '../../../Http';
import { useContext } from 'react';
import utils from '../../../utils';
import { Modal, Button, Input, Form } from 'antd';
//import { PlusOutlined, MinusCircleOutlined } from '@ant-design/icons';
import InputPrefix from '../../../utils/InputPrefix';

function DTUAdd(props) {
    const setTip = useContext(utils.tipContext);

    const { show, onClose } = props;

    const [form] = Form.useForm();

    const handleCancle = () => {
        onClose(null);
    }

    const handleSubmit = async () => {
        const ps = await form.validateFields().catch(() => { return null });

        if (ps === null) {
            return;
        }

        const resp = await http.post("dtu/add", ps).then(async (r) => {
            return await r.json();
        }).catch((e) => {
            console.log("[DtuAdd]", e);
            return null;
        });

        try {
            if (resp.code !== 200) {
                setTip({ open: true, severity: "error", msg: (resp && resp.msg) ? resp.msg : "add error" });
                return
            }

            setTip({ open: true, severity: 'success', msg: 'add success' });

            ps.ID = resp.data.ID;
            ps.CreateTime = resp.data.CreateTime;
            form.resetFields();
            onClose(ps);
        } catch (e) {
            console.log("[DtuAdd]", e);

            setTip({ open: true, severity: "error", msg: "add error" });
        }
    }

    return (<Modal
        title="Add DTU"
        visible={show}
        cancelText="Cancel"
        okText="Submit"
        mask={true}
        maskClosable={false}
        width={500}
        onCancel={handleCancle}
        onOk={handleSubmit}
        footer={[
            <Button
                key="Clear"
                shape="round"
                onClick={() => {
                    form.resetFields();
                }}
            >Clear</Button>,
            <Button key="Cancel" shape="round" onClick={handleCancle}>Cancel</Button>,
            <Button key="Submit" shape="round" type="primary" onClick={handleSubmit}>Submit</Button>
        ]}
    >
        <Form
            labelAlign="right"
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 25 }}
            form={form}
        >
            <Form.Item
                name="SN"
                label=""
                rules={[{ required: true }]} 
            >
                <Input prefix={<InputPrefix label="SN" required />} placeholder="Enter DTU SN" style={{ borderRadius: '20px' }} />
            </Form.Item>
        </Form>
    </Modal>)
}

export default DTUAdd;