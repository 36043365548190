import React, { useEffect, useState, useRef, useContext } from 'react';
import { Button, Select, DatePicker, Table } from 'antd';
import moment from 'moment';
import http from '../../../Http';
import utils from '../../../utils';
import config from '../../../Config';
import calc from '../calc.js';

function getTableScroll() {
    var th = document.getElementsByClassName("ant-table-thead")[0];
    let tHeaderBottom = 0
    if (th) {
        tHeaderBottom = th.getBoundingClientRect().top
    }
    let height = `calc(100vh - ${tHeaderBottom + 100}px)`
    return height
}

function DataHistory(props) {
    const { inverterSelectOptions, SN, protocol, handleSelectSN } = props
    const setTip = useContext(utils.tipContext)

    const [dataHistoryDate, setDataHistoryDate] = useState(moment().format('YYYY-MM-DD')) //时间
    const [aWeekAgoDate, setAWeekAgoDate] = useState(moment().subtract(7, 'days').format('YYYY-MM-DD'))  //一周前的日期, 导出用

    const [pageNo, setPageNo] = useState(0)
    const [pageSize, setPageSize] = useState(100)
    const [rows, setRows] = useState([])
    const [rowTotal, setRowTotal] = useState(0)
    const [loading, setLoading] = useState(false)


    const refDownloader1 = useRef(null)
    const refDownloader2 = useRef(null)

    const [scrollY, setScrollY] = useState("")

    useEffect(() => {
        setScrollY(getTableScroll())
    }, [])

    //取历史数据
    useEffect(() => {
        if (!SN) return;

        let active = true;

        (async () => {
            setLoading(true);

            let resp = await http.post("integrated-inverter/history/list", {
                PageNo: pageNo,
                PageSize: pageSize,
                SerialNumber: SN,
                STime: moment(dataHistoryDate).format("YYYY-MM-DD"),
                ETime: moment(dataHistoryDate).add(1, "days").format("YYYY-MM-DD"),
            }).then(async (r) => {
                return await r.json();
            }).catch((e) => {
                return null;
            });

            if (!active) {
                return;
            }

            if (resp === null || resp.code !== 200) {
                setTip({ open: true, severity: "error", msg: (resp && resp.msg) ? resp.msg : "load data error" });
                setRowTotal(0)
                setRows([])
                return;
            }
            setRowTotal(resp.data.RowTotal);
            setRows(resp.data.Rows);
            setLoading(false);
        })();

        return () => {
            active = false;
        };
    }, [pageNo, pageSize, SN, dataHistoryDate]);

    const handleExport = () => {
        if (!refDownloader1.current) {
            return;
        }
        refDownloader1.current.click()
    }

    const handleExportWeek = () => {
        if (!refDownloader2.current) {
            return;
        }
        refDownloader2.current.click()
    }

    //根据不同逆变器显示不同字段
    const DefaultColumns = [
        {
            title: "",
            width: 40,
            fixed: 'left',
            render: (v, r, idx) => {
                return (pageNo * pageSize + idx + 1);
            }
        }, {
            title: 'SN',
            dataIndex: "SerialNumber",
            width: 160,
            fixed: 'left',
        }, {
            title: 'CreateTime',
            dataIndex: 'CreateTime',
            width: 160,
            fixed: 'left',
        }, {
            title: 'ModelName',
            dataIndex: 'ModelName',
            width: 160,
        }, {
            title: 'DisplayFormat',
            dataIndex: 'DisplayFormat',
            width: 200,
        }, {
            title: 'DSP1Version',
            dataIndex: 'DSP1Version',
            width: 160,
        }, {
            title: 'DSP2Version',
            dataIndex: 'DSP2Version',
            width: 160,
        }, {
            title: 'DSP3Version',
            dataIndex: 'DSP3Version',
            width: 160,
        }, {
            title: 'CSBVersion',
            dataIndex: 'CSBVersion',
            width: 160,
        }, {
            title: 'Mode',
            dataIndex: 'Mode',
            width: 90,
        }, {
            title: 'EMSStatus',
            dataIndex: 'EMSStatus',
            width: 90,
        }, {
            title: 'Temp(°C)',
            dataIndex: 'Temp',
            width: 90,
            render: (v) => {
                return calc.temperature(v, protocol) 
            }
        }, {
            title: 'HTotal(m)',
            dataIndex: 'HTotal',
            width: 90,
        }, {
            title: 'SelfConsumptionDay(%)',
            dataIndex: 'SelfConsumptionDay',
            width: 90,
        }, {
            title: 'SelfSufficiencyDay(%)',
            dataIndex: 'SelfSufficiencyDay',
            width: 90,
        }, {
            title: 'SelfConsumptionMon(%)',
            dataIndex: 'SelfConsumptionMon',
            width: 90,
        }, {
            title: 'SelfSufficiencyMon(%)',
            dataIndex: 'SelfSufficiencyMon',
            width: 90,
        }, {
            title: 'SelfConsumptionYear(%)',
            dataIndex: 'SelfConsumptionYear',
            width: 90,
        }, {
            title: 'SelfSufficiencyYear(%)',
            dataIndex: 'SelfSufficiencyYear',
            width: 90,
        }, {
            title: 'SelfConsumptionTotal(%)',
            dataIndex: 'SelfConsumptionTotal',
            width: 90,
        }, {
            title: 'SelfSufficiencyTotal(%)',
            dataIndex: 'SelfSufficiencyTotal',
            width: 90,
        }, {
            title: 'DRMStatus',
            dataIndex: 'DRMStatus',
            width: 90,
        }, {
            title: 'VAC1Inv(V)',
            dataIndex: 'VAC1Inv',
            width: 90,
            render: (v) => {
                return calc.voltage(v, protocol)
            }
        }, {
            title: 'IAC1Inv(A)',
            dataIndex: 'IAC1Inv',
            width: 90,
            render: (v) => {
                return calc.current(v, protocol)
            }
        }, {
            title: 'HzAC1Inv(Hz)',
            dataIndex: 'HzAC1Inv',
            width: 90,
            render: (v) => {
                return calc.frequency(v, protocol)
            }
        }, {
            title: 'PAC1Inv(W)',
            dataIndex: 'PAC1Inv',
            width: 90,
            render: (v) => {
                return calc.power(v, protocol)
            }
        }, {
            title: 'VAC2Inv(V)',
            dataIndex: 'VAC2Inv',
            width: 90,
            render: (v) => {
                return calc.voltage(v, protocol)
            }
        }, {
            title: 'IAC2Inv(A)',
            dataIndex: 'IAC2Inv',
            width: 90,
            render: (v) => {
                return calc.current(v, protocol)
            }
        }, {
            title: 'HzAC2Inv(Hz)',
            dataIndex: 'HzAC2Inv',
            width: 90,
            render: (v) => {
                return calc.frequency(v, protocol)
            }
        }, {
            title: 'PAC2Inv(W)',
            dataIndex: 'PAC2Inv',
            width: 90,
            render: (v) => {
                return calc.power(v, protocol) 
            }
        }, {
            title: 'VAC3Inv(V)',
            dataIndex: 'VAC3Inv',
            width: 90,
            render: (v) => {
                return calc.voltage(v, protocol)
            }
        }, {
            title: 'IAC3Inv(A)',
            dataIndex: 'IAC3Inv',
            width: 90,
            render: (v) => {
                return calc.current(v, protocol)
            }
        }, {
            title: 'HzAC3Inv(Hz)',
            dataIndex: 'HzAC3Inv',
            width: 90,
            render: (v) => {
                return calc.frequency(v, protocol) 
            }
        }, {
            title: 'PAC3Inv(W)',
            dataIndex: 'PAC3Inv',
            width: 90,
            render: (v) => {
                return calc.power(v, protocol) 
            }
        }, {
            title: 'PInv(W)',
            dataIndex: 'PInv',
            width: 90,
            render: (v) => {
                return calc.power(v, protocol) 
            }
        }, {
            title: 'QInv',
            dataIndex: 'QInv',
            width: 90,
        }, {
            title: 'PFInv',
            dataIndex: 'PFInv',
            width: 90,
        }, {
            title: 'PDC(W)',
            dataIndex: 'PDC',
            width: 90,
            render: (v) => {
                return calc.power(v, protocol) 
            }
        }, {
            title: 'PPeak(W)',
            dataIndex: 'PPeak',
            width: 90,
            render: (v) => {
                return calc.power(v, protocol) 
            }
        }, {
            title: 'EDay(kWh)',
            dataIndex: 'EDay',
            width: 90,
            render: (v) => {
                return calc.energy(v, protocol) 
            }
        }, {
            title: 'EMon(kWh)',
            dataIndex: 'EMon',
            width: 90,
            render: (v) => {
                return calc.energy(v, protocol) 
            }
        }, {
            title: 'EYear(kWh)',
            dataIndex: 'EYear',
            width: 90,
            render: (v) => {
                return calc.energy(v, protocol) 
            }
        }, {
            title: 'ETotal(kWh)',
            dataIndex: 'ETotal',
            width: 90,
            render: (v) => {
                return calc.energy(v, protocol) 
            }
        }, {
            title: 'PDCToLoad(W)',
            dataIndex: 'PDCToLoad',
            width: 90,
            render: (v) => {
                return calc.power(v, protocol) 
            }
        }, {
            title: 'EDayToLoad(kWh)',
            dataIndex: 'EDayToLoad',
            width: 90,
            render: (v) => {
                return calc.energy(v, protocol)
            }
        }, {
            title: 'EMonToLoad(kWh)',
            dataIndex: 'EMonToLoad',
            width: 90,
            render: (v) => {
                return calc.energy(v, protocol)
            }
        }, {
            title: 'EYearToLoad(kWh)',
            dataIndex: 'EYearToLoad',
            width: 90,
            render: (v) => {
                return calc.energy(v, protocol)
            }
        }, {
            title: 'ETotalToLoad(kWh)',
            dataIndex: 'ETotalToLoad',
            width: 90,
            render: (v) => {
                return calc.energy(v, protocol)
            }
        }, {
            title: 'VMPPT1(V)',
            dataIndex: 'VMPPT1',
            width: 90,
            render: (v) => {
                return calc.voltage(v, protocol) 
            }
        }, {
            title: 'IMPPT1(A)',
            dataIndex: 'IMPPT1',
            width: 90,
            render: (v) => {
                return calc.current(v, protocol)
            }
        }, {
            title: 'VMPPT2(V)',
            dataIndex: 'VMPPT2',
            width: 90,
            render: (v) => {
                return calc.voltage(v, protocol)
            }
        }, {
            title: 'IMPPT2(A)',
            dataIndex: 'IMPPT2',
            width: 90,
            render: (v) => {
                return calc.current(v, protocol) 
            }
        }, {
            title: 'VMPPT3(V)',
            dataIndex: 'VMPPT3',
            width: 90,
            render: (v) => {
                return calc.voltage(v, protocol) 
            }
        }, {
            title: 'IMPPT3(A)',
            dataIndex: 'IMPPT3',
            width: 90,
            render: (v) => {
                return calc.current(v, protocol) 
            }
        }, {
            title: 'VMPPT4(V)',
            dataIndex: 'VMPPT4',
            width: 90,
            render: (v) => {
                return calc.voltage(v, protocol) 
            }
        }, {
            title: 'IMPPT4(A)',
            dataIndex: 'IMPPT4',
            width: 90,
            render: (v) => {
                return calc.current(v, protocol) 
            }
        }, {
            title: 'PMPPT1(W)',
            dataIndex: 'PMPPT1',
            width: 90,
            render: (v) => {
                return calc.power(v, protocol) 
            }
        }, {
            title: 'PMPPT2(W)',
            dataIndex: 'PMPPT2',
            width: 90,
            render: (v) => {
                return calc.power(v, protocol) 
            }
        }, {
            title: 'PMPPT3(W)',
            dataIndex: 'PMPPT3',
            width: 90,
            render: (v) => {
                return calc.power(v, protocol) 
            }
        }, {
            title: 'PMPPT4(W)',
            dataIndex: 'PMPPT4',
            width: 90,
            render: (v) => {
                return calc.power(v, protocol) 
            }
        }, {
            title: 'BatType',
            dataIndex: 'BatType',
            width: 90,
        }, {
            title: 'Ah',
            dataIndex: 'Ah',
            width: 90,
        }, {
            title: 'BMSVersion',
            dataIndex: 'BMSVersion',
            width: 90,
        }, {
            title: 'BMSStatus',
            dataIndex: 'BMSStatus',
            width: 90,
        }, {
            title: 'VBat(V)',
            dataIndex: 'VBat',
            width: 90,
            render: (v) => {
                return calc.voltage(v, protocol)
            }
        }, {
            title: 'IBat(A)',
            dataIndex: 'IBat',
            width: 90,
            render: (v) => {
                return calc.current(v, protocol)
            }
        }, {
            title: 'PBat(W)',
            dataIndex: 'PBat',
            width: 90,
            render: (v, r) => {
                if(protocol === 'Sungery') {
                    v = calc.current(r['IBat'], protocol) * calc.voltage(r['VBat'], protocol)
                }
                return calc.power(v, protocol)
            }
        }, {
            title: 'SOC',
            dataIndex: 'SOC',
            width: 90,
            render: (v) => {
                return calc.soc(v, protocol)
            }
        }, {
            title: 'SOH',
            dataIndex: 'SOH',
            width: 90,
            render: (v) => {
                return calc.soh(v, protocol)
            }
        }, {
            title: 'BatTemp(°C)',
            dataIndex: 'BatTemp',
            width: 90,
            render: (v) => {
                return calc.temperature(v, protocol)
            }
        }, {
            title: 'CyclesTimes',
            dataIndex: 'CyclesTimes',
            width: 90,
        }, {
            title: 'BatErrorCode',
            dataIndex: 'BatErrorCode',
            width: 90,
        }, {
            title: 'BatStatus',
            dataIndex: 'BatStatus',
            width: 90,
        }, {
            title: 'EDayBatChrg(kWh)',
            dataIndex: 'EDayBatChrg',
            width: 90,
            render: (v) => {
                return calc.energy(v, protocol)
            }
        }, {
            title: 'EMonBatChrg(kWh)',
            dataIndex: 'EMonBatChrg',
            width: 90,
            render: (v) => {
                return calc.energy(v, protocol)
            }
        }, {
            title: 'EYearBatChrg(kWh)',
            dataIndex: 'EYearBatChrg',
            width: 90,
            render: (v) => {
                return calc.energy(v, protocol)
            }
        }, {
            title: 'ETotalBatChrg(kWh)',
            dataIndex: 'ETotalBatChrg',
            width: 90,
            render: (v) => {
                return calc.energy(v, protocol)
            }
        }, {
            title: 'EDayBatDischrg(kWh)',
            dataIndex: 'EDayBatDischrg',
            width: 90,
            render: (v) => {
                return calc.energy(v, protocol)
            }
        }, {
            title: 'EMonBatDischrg(kWh)',
            dataIndex: 'EMonBatDischrg',
            width: 90,
            render: (v) => {
                return calc.energy(v, protocol)
            }
        }, {
            title: 'EYearBatDischrg(kWh)',
            dataIndex: 'EYearBatDischrg',
            width: 90,
            render: (v) => {
                return calc.energy(v, protocol)
            }
        }, {
            title: 'ETotalBatDischrg(kWh)',
            dataIndex: 'ETotalBatDischrg',
            width: 90,
            render: (v) => {
                return calc.energy(v, protocol)
            }
        }, {
            title: 'BatChargeVoltage(V)',
            dataIndex: 'BatChargeVoltage',
            width: 90,
            render: (v) => {
                return calc.voltage(v, protocol) 
            }
        }, {
            title: 'BatChargeCurrentLimit(A)',
            dataIndex: 'BatChargeCurrentLimit',
            width: 90,
            render: (v) => {
                return calc.current(v, protocol) 
            }
        }, {
            title: 'BatDischargeCurrentLimit(A)',
            dataIndex: 'BatDischargeCurrentLimit',
            width: 90,
            render: (v) => {
                return calc.current(v, protocol) 
            }
        }, {
            title: 'BatCellMaxVoltage(V)',
            dataIndex: 'BatCellMaxVoltage',
            width: 90,
            render: (v) => {
                return calc.voltage(v, protocol, 'BatCellMaxVoltage')
            }
        }, {
            title: 'BatCellMinVoltage(V)',
            dataIndex: 'BatCellMinVoltage',
            width: 90,
            render: (v) => {
                return calc.voltage(v, protocol, 'BatCellMinVoltage') 
            }
        }, {
            title: 'BatCellMaxTemperature(°C)',
            dataIndex: 'BatCellMaxTemperature',
            width: 90,
            render: (v) => {
                return calc.temperature(v, protocol) 
            }
        }, {
            title: 'BatCellMinTemperature(°C)',
            dataIndex: 'BatCellMinTemperature',
            width: 90,
            render: (v) => {
                return calc.temperature(v, protocol) 
            }
        }, {
            title: 'BatChargeCutoffVoltage(V)',
            dataIndex: 'BatChargeCutoffVoltage',
            width: 90,
            render: (v) => {
                return calc.voltage(v, protocol) 
            }
        }, {
            title: 'BatDischargeCutoffVoltage(V)',
            dataIndex: 'BatDischargeCutoffVoltage',
            width: 90,
            render: (v) => {
                return calc.voltage(v, protocol) 
            }
        }, {
            title: 'PAC1EPS(W)',
            dataIndex: 'PAC1EPS',
            width: 90,
            render: (v) => {
                return calc.power(v, protocol)
            }
        }, {
            title: 'PAC2EPS(W)',
            dataIndex: 'PAC2EPS',
            width: 90,
            render: (v) => {
                return calc.power(v, protocol)
            }
        }, {
            title: 'PAC3EPS(W)',
            dataIndex: 'PAC3EPS',
            width: 90,
            render: (v) => {
                return calc.power(v, protocol)
            }
        }, {
            title: 'FACEPS(Hz)',
            dataIndex: 'FACEPS',
            width: 90,
            render: (v) => {
                return calc.frequency(v, protocol)
            }
        }, {
            title: 'VAC1EPS(V)',
            dataIndex: 'VAC1EPS',
            width: 90,
            render: (v) => {
                return calc.voltage(v, protocol)
            }
        }, {
            title: 'IAC1EPS(A)',
            dataIndex: 'IAC1EPS',
            width: 90,
            render: (v) => {
                return calc.current(v, protocol)
            }
        }, {
            title: 'VAC2EPS(V)',
            dataIndex: 'VAC2EPS',
            width: 90,
            render: (v) => {
                return calc.voltage(v, protocol)
            }
        }, {
            title: 'IAC2EPS(A)',
            dataIndex: 'IAC2EPS',
            width: 90,
            render: (v) => {
                return calc.current(v, protocol)
            }
        }, {
            title: 'VAC3EPS(V)',
            dataIndex: 'VAC3EPS',
            width: 90,
            render: (v) => {
                return calc.voltage(v, protocol)
            }
        }, {
            title: 'IAC3EPS(A)',
            dataIndex: 'IAC3EPS',
            width: 90,
            render: (v) => {
                return calc.current(v, protocol)
            }
        }, {
            title: 'EDayEPS(kWh)',
            dataIndex: 'EDayEPS',
            width: 90,
            render: (v) => {
                return calc.energy(v, protocol)
            }
        }, {
            title: 'EMonEPS(kWh)',
            dataIndex: 'EMonEPS',
            width: 90,
            render: (v) => {
                return calc.energy(v, protocol)
            }
        }, {
            title: 'EYearEPS(kWh)',
            dataIndex: 'EYearEPS',
            width: 90,
            render: (v) => {
                return calc.energy(v, protocol)
            }
        }, {
            title: 'ETotalEPS(kWh)',
            dataIndex: 'ETotalEPS',
            width: 90,
            render: (v) => {
                return calc.energy(v, protocol)
            }
        },
        {
            title: 'PAC1GEN(W)',
            dataIndex: 'PAC1GEN',
            width: 90,
            render: (v) => {
                return calc.power(v, protocol)
            }
        }, {
            title: 'PAC2GEN(W)',
            dataIndex: 'PAC2GEN',
            width: 90,
            render: (v) => {
                return calc.power(v, protocol)
            }
        }, {
            title: 'PAC3GEN(W)',
            dataIndex: 'PAC3GEN',
            width: 90,
            render: (v) => {
                return calc.power(v, protocol)
            }
        }, {
            title: 'FACGEN(Hz)',
            dataIndex: 'FACGEN',
            width: 90,
            render: (v) => {
                return calc.frequency(v, protocol)
            }
        }, {
            title: 'VAC1GEN(V)',
            dataIndex: 'VAC1GEN',
            width: 90,
            render: (v) => {
                return calc.voltage(v, protocol)
            }
        }, {
            title: 'IAC1GEN(A)',
            dataIndex: 'IAC1GEN',
            width: 90,
            render: (v) => {
                return calc.current(v, protocol)
            }
        }, {
            title: 'VAC2GEN(V)',
            dataIndex: 'VAC2GEN',
            width: 90,
            render: (v) => {
                return calc.voltage(v, protocol)
            }
        }, {
            title: 'IAC2GEN(A)',
            dataIndex: 'IAC2GEN',
            width: 90,
            render: (v) => {
                return calc.current(v, protocol)
            }
        }, {
            title: 'VAC3GEN(V)',
            dataIndex: 'VAC3GEN',
            width: 90,
            render: (v) => {
                return calc.voltage(v, protocol)
            }
        }, {
            title: 'IAC3GEN(A)',
            dataIndex: 'IAC3GEN',
            width: 90,
            render: (v) => {
                return calc.current(v, protocol)
            }
        }, {
            title: 'EDayGEN(kWh)',
            dataIndex: 'EDayGEN',
            width: 90,
            render: (v) => {
                return calc.energy(v, protocol)
            }
        }, {
            title: 'EMonGEN(kWh)',
            dataIndex: 'EMonGEN',
            width: 90,
            render: (v) => {
                return calc.energy(v, protocol)
            }
        }, {
            title: 'EYearGEN(kWh)',
            dataIndex: 'EYearGEN',
            width: 90,
            render: (v) => {
                return calc.energy(v, protocol)
            }
        }, {
            title: 'ETotaGEN(kWh)',
            dataIndex: 'ETotaGEN',
            width: 90,
            render: (v) => {
                return calc.energy(v, protocol)
            }
        },
        {
            title: 'PAC1LOAD(W)',
            dataIndex: 'PAC1LOAD',
            width: 90,
            render: (v) => {
                return calc.power(v, protocol)
            }
        }, {
            title: 'PAC2LOAD(W)',
            dataIndex: 'PAC2LOAD',
            width: 90,
            render: (v) => {
                return calc.power(v, protocol)
            }
        }, {
            title: 'PAC3LOAD(W)',
            dataIndex: 'PAC3LOAD',
            width: 90,
            render: (v) => {
                return calc.power(v, protocol)
            }
        }, {
            title: 'FACLOAD(Hz)',
            dataIndex: 'FACLOAD',
            width: 90,
            render: (v) => {
                return calc.frequency(v, protocol)
            }
        }, {
            title: 'VAC1LOAD(V)',
            dataIndex: 'VAC1LOAD',
            width: 90,
            render: (v) => {
                return calc.voltage(v, protocol)
            }
        }, {
            title: 'IAC1LOAD(A)',
            dataIndex: 'IAC1LOAD',
            width: 90,
            render: (v) => {
                return calc.current(v, protocol)
            }
        }, {
            title: 'VAC2LOAD(V)',
            dataIndex: 'VAC2LOAD',
            width: 90,
            render: (v) => {
                return calc.voltage(v, protocol)
            }
        }, {
            title: 'IAC2LOAD(A)',
            dataIndex: 'IAC2LOAD',
            width: 90,
            render: (v) => {
                return calc.current(v, protocol)
            }
        }, {
            title: 'VAC3LOAD(V)',
            dataIndex: 'VAC3LOAD',
            width: 90,
            render: (v) => {
                return calc.voltage(v, protocol)
            }
        }, {
            title: 'IAC3LOAD(A)',
            dataIndex: 'IAC3LOAD',
            width: 90,
            render: (v) => {
                return calc.current(v, protocol)
            }
        }, {
            title: 'EDayLOAD(kWh)',
            dataIndex: 'EDayLOAD',
            width: 90,
            render: (v) => {
                return calc.energy(v, protocol)
            }
        }, {
            title: 'EMonLOAD(kWh)',
            dataIndex: 'EMonLOAD',
            width: 90,
            render: (v) => {
                return calc.energy(v, protocol)
            }
        }, {
            title: 'EYearLOAD(kWh)',
            dataIndex: 'EYearLOAD',
            width: 90,
            render: (v) => {
                return calc.energy(v, protocol)
            }
        }, {
            title: 'ETotalLOAD(kWh)',
            dataIndex: 'ETotalLOAD',
            width: 90,
            render: (v) => {
                return calc.energy(v, protocol)
            }
        },
        {
            title: 'PAC1GRID(W)',
            dataIndex: 'PAC1GRID',
            width: 90,
            render: (v) => {
                return calc.power(v, protocol)
            }
        }, {
            title: 'PAC2GRID(W)',
            dataIndex: 'PAC2GRID',
            width: 90,
            render: (v) => {
                return calc.power(v, protocol)
            }
        }, {
            title: 'PAC3GRID(W)',
            dataIndex: 'PAC3GRID',
            width: 90,
            render: (v) => {
                return calc.power(v, protocol)
            }
        }, {
            title: 'FACGRID(Hz)',
            dataIndex: 'FACGRID',
            width: 90,
            render: (v) => {
                return calc.frequency(v, protocol)
            }
        }, {
            title: 'VAC1GRID(V)',
            dataIndex: 'VAC1GRID',
            width: 90,
            render: (v) => {
                return calc.voltage(v, protocol)
            }
        }, {
            title: 'IAC1GRID(A)',
            dataIndex: 'IAC1GRID',
            width: 90,
            render: (v) => {
                return calc.current(v, protocol)
            }
        }, {
            title: 'VAC2GRID(V)',
            dataIndex: 'VAC2GRID',
            width: 90,
            render: (v) => {
                return calc.voltage(v, protocol)
            }
        }, {
            title: 'IAC2GRID(A)',
            dataIndex: 'IAC2GRID',
            width: 90,
            render: (v) => {
                return calc.current(v, protocol)
            }
        }, {
            title: 'VAC3GRID(V)',
            dataIndex: 'VAC3GRID',
            width: 90,
            render: (v) => {
                return calc.voltage(v, protocol)
            }
        }, {
            title: 'IAC3GRID(A)',
            dataIndex: 'IAC3GRID',
            width: 90,
            render: (v) => {
                return calc.current(v, protocol)
            }
        }, {
            title: 'EDayToGrid(kWh)',
            dataIndex: 'EDayToGrid',
            width: 90,
            render: (v) => {
                return calc.energy(v, protocol)
            }
        }, {
            title: 'EMonToGrid(kWh)',
            dataIndex: 'EMonToGrid',
            width: 90,
            render: (v) => {
                return calc.energy(v, protocol)
            }
        }, {
            title: 'EYearToGrid(kWh)',
            dataIndex: 'EYearToGrid',
            width: 90,
            render: (v) => {
                return calc.energy(v, protocol)
            }
        }, {
            title: 'ETotalToGrid(kWh)',
            dataIndex: 'ETotalToGrid',
            width: 90,
            render: (v) => {
                return calc.energy(v, protocol)
            }
        }, {
            title: 'EDayFromGrid(kWh)',
            dataIndex: 'EDayFromGrid',
            width: 90,
            render: (v) => {
                return calc.energy(v, protocol)
            }
        }, {
            title: 'EMonFromGrid(kWh)',
            dataIndex: 'EMonFromGrid',
            width: 90,
            render: (v) => {
                return calc.energy(v, protocol)
            }
        }, {
            title: 'EYearFromGrid(kWh)',
            dataIndex: 'EYearFromGrid',
            width: 90,
            render: (v) => {
                return calc.energy(v, protocol)
            }
        }, {
            title: 'ETotalFromGrid(kWh)',
            dataIndex: 'ETotalFromGrid',
            width: 90,
            render: (v) => {
                return calc.energy(v, protocol)
            }
        }, {
            title: 'ErrorCode1',
            dataIndex: 'ErrorCode1',
            width: 90,
        }, {
            title: 'ErrorCode2',
            dataIndex: 'ErrorCode2',
            width: 90,
        }, {
            title: 'ErrorCode3',
            dataIndex: 'ErrorCode3',
            width: 90,
        },
    ]

    const MegarevoColumns = [
        {
            title: "",
            width: 40,
            fixed: 'left',
            render: (v, r, idx) => {
                return (pageNo * pageSize + idx + 1);
            }
        }, {
            title: 'SN',
            dataIndex: "SerialNumber",
            width: 160,
            fixed: 'left',
        }, {
            title: 'CreateTime',
            dataIndex: 'CreateTime',
            width: 160,
            fixed: 'left',
        }, {
            title: 'ModelName',
            dataIndex: 'ModelName',
            width: 160,
        }, {
            title: 'DisplayFormat',
            dataIndex: 'DisplayFormat',
            width: 200,
        }, {
            title: 'DSP1Version',
            dataIndex: 'DSP1Version',
            width: 160,
        }, {
            title: 'DSP2Version',
            dataIndex: 'DSP2Version',
            width: 160,
        }, {
            title: 'DSP3Version',
            dataIndex: 'DSP3Version',
            width: 160,
        }, {
            title: 'CSBVersion',
            dataIndex: 'CSBVersion',
            width: 160,
        }, {
            title: 'Mode',
            dataIndex: 'Mode',
            width: 90,
        }, {
            title: 'EMSStatus',
            dataIndex: 'EMSStatus',
            width: 90,
        }, {
            title: 'Temp(°C)',
            dataIndex: 'Temp',
            width: 90,
            render: (v) => {
                return calc.temperature(v, protocol) 
            }
        }, {
            title: 'HTotal(m)',
            dataIndex: 'HTotal',
            width: 90,
        }, {
            title: 'SelfConsumptionDay(%)',
            dataIndex: 'SelfConsumptionDay',
            width: 90,
        }, {
            title: 'SelfSufficiencyDay(%)',
            dataIndex: 'SelfSufficiencyDay',
            width: 90,
        }, {
            title: 'SelfConsumptionMon(%)',
            dataIndex: 'SelfConsumptionMon',
            width: 90,
        }, {
            title: 'SelfSufficiencyMon(%)',
            dataIndex: 'SelfSufficiencyMon',
            width: 90,
        }, {
            title: 'SelfConsumptionYear(%)',
            dataIndex: 'SelfConsumptionYear',
            width: 90,
        }, {
            title: 'SelfSufficiencyYear(%)',
            dataIndex: 'SelfSufficiencyYear',
            width: 90,
        }, {
            title: 'SelfConsumptionTotal(%)',
            dataIndex: 'SelfConsumptionTotal',
            width: 90,
        }, {
            title: 'SelfSufficiencyTotal(%)',
            dataIndex: 'SelfSufficiencyTotal',
            width: 90,
        }, {
            title: 'DRMStatus',
            dataIndex: 'DRMStatus',
            width: 90,
        }, {
            title: 'VAC1Inv(V)',
            dataIndex: 'VAC1Inv',
            width: 90,
            render: (v) => {
                return calc.voltage(v, protocol)
            }
        }, {
            title: 'IAC1Inv(A)',
            dataIndex: 'IAC1Inv',
            width: 90,
            render: (v) => {
                return calc.current(v, protocol)
            }
        }, {
            title: 'HzAC1Inv(Hz)',
            dataIndex: 'HzAC1Inv',
            width: 90,
            render: (v) => {
                return calc.frequency(v, protocol)
            }
        }, {
            title: 'PAC1Inv(W)',
            dataIndex: 'PAC1Inv',
            width: 90,
            render: (v) => {
                return calc.power(v, protocol)
            }
        }, {
            title: 'VAC2Inv(V)',
            dataIndex: 'VAC2Inv',
            width: 90,
            render: (v) => {
                return calc.voltage(v, protocol)
            }
        }, {
            title: 'IAC2Inv(A)',
            dataIndex: 'IAC2Inv',
            width: 90,
            render: (v) => {
                return calc.current(v, protocol)
            }
        }, {
            title: 'HzAC2Inv(Hz)',
            dataIndex: 'HzAC2Inv',
            width: 90,
            render: (v) => {
                return calc.frequency(v, protocol)
            }
        }, {
            title: 'PAC2Inv(W)',
            dataIndex: 'PAC2Inv',
            width: 90,
            render: (v) => {
                return calc.power(v, protocol) 
            }
        }, {
            title: 'VAC3Inv(V)',
            dataIndex: 'VAC3Inv',
            width: 90,
            render: (v) => {
                return calc.voltage(v, protocol)
            }
        }, {
            title: 'IAC3Inv(A)',
            dataIndex: 'IAC3Inv',
            width: 90,
            render: (v) => {
                return calc.current(v, protocol)
            }
        }, {
            title: 'HzAC3Inv(Hz)',
            dataIndex: 'HzAC3Inv',
            width: 90,
            render: (v) => {
                return calc.frequency(v, protocol) 
            }
        }, {
            title: 'PAC3Inv(W)',
            dataIndex: 'PAC3Inv',
            width: 90,
            render: (v) => {
                return calc.power(v, protocol) 
            }
        }, {
            title: 'PInv(W)',
            dataIndex: 'PInv',
            width: 90,
            render: (v) => {
                return calc.power(v, protocol) 
            }
        }, {
            title: 'QInv',
            dataIndex: 'QInv',
            width: 90,
        }, {
            title: 'PFInv',
            dataIndex: 'PFInv',
            width: 90,
        }, {
            title: 'PDC(W)',
            dataIndex: 'PDC',
            width: 90,
            render: (v) => {
                return calc.power(v, protocol) 
            }
        }, {
            title: 'PPeak(W)',
            dataIndex: 'PPeak',
            width: 90,
            render: (v) => {
                return calc.power(v, protocol) 
            }
        }, {
            title: 'EDay(kWh)',
            dataIndex: 'EDay',
            width: 90,
            render: (v) => {
                return calc.energy(v, protocol) 
            }
        }, {
            title: 'EMon(kWh)',
            dataIndex: 'EMon',
            width: 90,
            render: (v) => {
                return calc.energy(v, protocol) 
            }
        }, {
            title: 'EYear(kWh)',
            dataIndex: 'EYear',
            width: 90,
            render: (v) => {
                return calc.energy(v, protocol) 
            }
        }, {
            title: 'ETotal(kWh)',
            dataIndex: 'ETotal',
            width: 90,
            render: (v) => {
                return calc.energy(v, protocol) 
            }
        }, {
            title: 'PDCToLoad(W)',
            dataIndex: 'PDCToLoad',
            width: 90,
            render: (v) => {
                return calc.power(v, protocol) 
            }
        }, {
            title: 'EDayToLoad(kWh)',
            dataIndex: 'EDayToLoad',
            width: 90,
            render: (v) => {
                return calc.energy(v, protocol)
            }
        }, {
            title: 'EMonToLoad(kWh)',
            dataIndex: 'EMonToLoad',
            width: 90,
            render: (v) => {
                return calc.energy(v, protocol)
            }
        }, {
            title: 'EYearToLoad(kWh)',
            dataIndex: 'EYearToLoad',
            width: 90,
            render: (v) => {
                return calc.energy(v, protocol)
            }
        }, {
            title: 'ETotalToLoad(kWh)',
            dataIndex: 'ETotalToLoad',
            width: 90,
            render: (v) => {
                return calc.energy(v, protocol)
            }
        }, {
            title: 'VMPPT1(V)',
            dataIndex: 'VMPPT1',
            width: 90,
            render: (v) => {
                return calc.voltage(v, protocol) 
            }
        }, {
            title: 'IMPPT1(A)',
            dataIndex: 'IMPPT1',
            width: 90,
            render: (v) => {
                return calc.current(v, protocol)
            }
        }, {
            title: 'VMPPT2(V)',
            dataIndex: 'VMPPT2',
            width: 90,
            render: (v) => {
                return calc.voltage(v, protocol)
            }
        }, {
            title: 'IMPPT2(A)',
            dataIndex: 'IMPPT2',
            width: 90,
            render: (v) => {
                return calc.current(v, protocol) 
            }
        }, {
            title: 'VMPPT3(V)',
            dataIndex: 'VMPPT3',
            width: 90,
            render: (v) => {
                return calc.voltage(v, protocol) 
            }
        }, {
            title: 'IMPPT3(A)',
            dataIndex: 'IMPPT3',
            width: 90,
            render: (v) => {
                return calc.current(v, protocol) 
            }
        }, {
            title: 'VMPPT4(V)',
            dataIndex: 'VMPPT4',
            width: 90,
            render: (v) => {
                return calc.voltage(v, protocol) 
            }
        }, {
            title: 'IMPPT4(A)',
            dataIndex: 'IMPPT4',
            width: 90,
            render: (v) => {
                return calc.current(v, protocol) 
            }
        }, {
            title: 'PMPPT1(W)',
            dataIndex: 'PMPPT1',
            width: 90,
            render: (v) => {
                return calc.power(v, protocol) 
            }
        }, {
            title: 'PMPPT2(W)',
            dataIndex: 'PMPPT2',
            width: 90,
            render: (v) => {
                return calc.power(v, protocol) 
            }
        }, {
            title: 'PMPPT3(W)',
            dataIndex: 'PMPPT3',
            width: 90,
            render: (v) => {
                return calc.power(v, protocol) 
            }
        }, {
            title: 'PMPPT4(W)',
            dataIndex: 'PMPPT4',
            width: 90,
            render: (v) => {
                return calc.power(v, protocol) 
            }
        }, {
            title: 'BatType',
            dataIndex: 'BatType',
            width: 90,
        }, {
            title: 'Ah',
            dataIndex: 'Ah',
            width: 90,
        }, {
            title: 'BMSVersion',
            dataIndex: 'BMSVersion',
            width: 90,
        }, {
            title: 'BMSStatus',
            dataIndex: 'BMSStatus',
            width: 90,
        }, {
            title: 'VBat(V)',
            dataIndex: 'VBat',
            width: 90,
            render: (v) => {
                return calc.voltage(v, protocol)
            }
        }, {
            title: 'IBat(A)',
            dataIndex: 'IBat',
            width: 90,
            render: (v) => {
                return calc.current(v, protocol)
            }
        }, {
            title: 'PBat(W)',
            dataIndex: 'PBat',
            width: 90,
            render: (v, r) => {
                if(protocol === 'Sungery') {
                    v = calc.current(r['IBat'], protocol) * calc.voltage(r['VBat'], protocol)
                }
                return calc.power(v, protocol)
            }
        }, {
            title: 'SOC',
            dataIndex: 'SOC',
            width: 90,
            render: (v) => {
                return calc.soc(v, protocol)
            }
        }, {
            title: 'SOH',
            dataIndex: 'SOH',
            width: 90,
            render: (v) => {
                return calc.soh(v, protocol)
            }
        }, {
            title: 'BatTemp(°C)',
            dataIndex: 'BatTemp',
            width: 90,
            render: (v) => {
                return calc.temperature(v, protocol)
            }
        }, {
            title: 'CyclesTimes',
            dataIndex: 'CyclesTimes',
            width: 90,
        }, {
            title: 'BatErrorCode',
            dataIndex: 'BatErrorCode',
            width: 90,
        }, {
            title: 'BatStatus',
            dataIndex: 'BatStatus',
            width: 90,
        }, {
            title: 'EDayBatChrg(kWh)',
            dataIndex: 'EDayBatChrg',
            width: 90,
            render: (v) => {
                return calc.energy(v, protocol)
            }
        }, {
            title: 'EMonBatChrg(kWh)',
            dataIndex: 'EMonBatChrg',
            width: 90,
            render: (v) => {
                return calc.energy(v, protocol)
            }
        }, {
            title: 'EYearBatChrg(kWh)',
            dataIndex: 'EYearBatChrg',
            width: 90,
            render: (v) => {
                return calc.energy(v, protocol)
            }
        }, {
            title: 'ETotalBatChrg(kWh)',
            dataIndex: 'ETotalBatChrg',
            width: 90,
            render: (v) => {
                return calc.energy(v, protocol)
            }
        }, {
            title: 'EDayBatDischrg(kWh)',
            dataIndex: 'EDayBatDischrg',
            width: 90,
            render: (v) => {
                return calc.energy(v, protocol)
            }
        }, {
            title: 'EMonBatDischrg(kWh)',
            dataIndex: 'EMonBatDischrg',
            width: 90,
            render: (v) => {
                return calc.energy(v, protocol)
            }
        }, {
            title: 'EYearBatDischrg(kWh)',
            dataIndex: 'EYearBatDischrg',
            width: 90,
            render: (v) => {
                return calc.energy(v, protocol)
            }
        }, {
            title: 'ETotalBatDischrg(kWh)',
            dataIndex: 'ETotalBatDischrg',
            width: 90,
            render: (v) => {
                return calc.energy(v, protocol)
            }
        }, {
            title: 'BatChargeVoltage(V)',
            dataIndex: 'BatChargeVoltage',
            width: 90,
            render: (v) => {
                return calc.voltage(v, protocol) 
            }
        }, {
            title: 'BatChargeCurrentLimit(A)',
            dataIndex: 'BatChargeCurrentLimit',
            width: 90,
            render: (v) => {
                return calc.current(v, protocol) 
            }
        }, {
            title: 'BatDischargeCurrentLimit(A)',
            dataIndex: 'BatDischargeCurrentLimit',
            width: 90,
            render: (v) => {
                return calc.current(v, protocol) 
            }
        }, {
            title: 'BatCellMaxVoltage(V)',
            dataIndex: 'BatCellMaxVoltage',
            width: 90,
            render: (v) => {
                return calc.voltage(v, protocol, 'BatCellMaxVoltage')
            }
        }, {
            title: 'BatCellMinVoltage(V)',
            dataIndex: 'BatCellMinVoltage',
            width: 90,
            render: (v) => {
                return calc.voltage(v, protocol, 'BatCellMinVoltage') 
            }
        }, {
            title: 'BatCellMaxTemperature(°C)',
            dataIndex: 'BatCellMaxTemperature',
            width: 90,
            render: (v) => {
                return calc.temperature(v, protocol) 
            }
        }, {
            title: 'BatCellMinTemperature(°C)',
            dataIndex: 'BatCellMinTemperature',
            width: 90,
            render: (v) => {
                return calc.temperature(v, protocol) 
            }
        }, {
            title: 'BatChargeCutoffVoltage(V)',
            dataIndex: 'BatChargeCutoffVoltage',
            width: 90,
            render: (v) => {
                return calc.voltage(v, protocol) 
            }
        }, {
            title: 'BatDischargeCutoffVoltage(V)',
            dataIndex: 'BatDischargeCutoffVoltage',
            width: 90,
            render: (v) => {
                return calc.voltage(v, protocol) 
            }
        }, {
            title: 'PAC1EPS(W)',
            dataIndex: 'PAC1EPS',
            width: 90,
            render: (v) => {
                return calc.power(v, protocol)
            }
        }, {
            title: 'PAC2EPS(W)',
            dataIndex: 'PAC2EPS',
            width: 90,
            render: (v) => {
                return calc.power(v, protocol)
            }
        }, {
            title: 'PAC3EPS(W)',
            dataIndex: 'PAC3EPS',
            width: 90,
            render: (v) => {
                return calc.power(v, protocol)
            }
        }, {
            title: 'FACEPS(Hz)',
            dataIndex: 'FACEPS',
            width: 90,
            render: (v) => {
                return calc.frequency(v, protocol)
            }
        }, {
            title: 'VAC1EPS(V)',
            dataIndex: 'VAC1EPS',
            width: 90,
            render: (v) => {
                return calc.voltage(v, protocol)
            }
        }, {
            title: 'IAC1EPS(A)',
            dataIndex: 'IAC1EPS',
            width: 90,
            render: (v) => {
                return calc.current(v, protocol)
            }
        }, {
            title: 'VAC2EPS(V)',
            dataIndex: 'VAC2EPS',
            width: 90,
            render: (v) => {
                return calc.voltage(v, protocol)
            }
        }, {
            title: 'IAC2EPS(A)',
            dataIndex: 'IAC2EPS',
            width: 90,
            render: (v) => {
                return calc.current(v, protocol)
            }
        }, {
            title: 'VAC3EPS(V)',
            dataIndex: 'VAC3EPS',
            width: 90,
            render: (v) => {
                return calc.voltage(v, protocol)
            }
        }, {
            title: 'IAC3EPS(A)',
            dataIndex: 'IAC3EPS',
            width: 90,
            render: (v) => {
                return calc.current(v, protocol)
            }
        }, {
            title: 'EDayEPS(kWh)',
            dataIndex: 'EDayEPS',
            width: 90,
            render: (v) => {
                return calc.energy(v, protocol)
            }
        }, {
            title: 'EMonEPS(kWh)',
            dataIndex: 'EMonEPS',
            width: 90,
            render: (v) => {
                return calc.energy(v, protocol)
            }
        }, {
            title: 'EYearEPS(kWh)',
            dataIndex: 'EYearEPS',
            width: 90,
            render: (v) => {
                return calc.energy(v, protocol)
            }
        }, {
            title: 'ETotalEPS(kWh)',
            dataIndex: 'ETotalEPS',
            width: 90,
            render: (v) => {
                return calc.energy(v, protocol)
            }
        },
        {
            title: 'PAC1GEN(W)',
            dataIndex: 'PAC1GEN',
            width: 90,
            render: (v) => {
                return calc.power(v, protocol)
            }
        }, {
            title: 'PAC2GEN(W)',
            dataIndex: 'PAC2GEN',
            width: 90,
            render: (v) => {
                return calc.power(v, protocol)
            }
        }, {
            title: 'PAC3GEN(W)',
            dataIndex: 'PAC3GEN',
            width: 90,
            render: (v) => {
                return calc.power(v, protocol)
            }
        }, {
            title: 'FACGEN(Hz)',
            dataIndex: 'FACGEN',
            width: 90,
            render: (v) => {
                return calc.frequency(v, protocol)
            }
        }, {
            title: 'VAC1GEN(V)',
            dataIndex: 'VAC1GEN',
            width: 90,
            render: (v) => {
                return calc.voltage(v, protocol)
            }
        }, {
            title: 'IAC1GEN(A)',
            dataIndex: 'IAC1GEN',
            width: 90,
            render: (v) => {
                return calc.current(v, protocol)
            }
        }, {
            title: 'VAC2GEN(V)',
            dataIndex: 'VAC2GEN',
            width: 90,
            render: (v) => {
                return calc.voltage(v, protocol)
            }
        }, {
            title: 'IAC2GEN(A)',
            dataIndex: 'IAC2GEN',
            width: 90,
            render: (v) => {
                return calc.current(v, protocol)
            }
        }, {
            title: 'VAC3GEN(V)',
            dataIndex: 'VAC3GEN',
            width: 90,
            render: (v) => {
                return calc.voltage(v, protocol)
            }
        }, {
            title: 'IAC3GEN(A)',
            dataIndex: 'IAC3GEN',
            width: 90,
            render: (v) => {
                return calc.current(v, protocol)
            }
        }, {
            title: 'EDayGEN(kWh)',
            dataIndex: 'EDayGEN',
            width: 90,
            render: (v) => {
                return calc.energy(v, protocol)
            }
        }, {
            title: 'EMonGEN(kWh)',
            dataIndex: 'EMonGEN',
            width: 90,
            render: (v) => {
                return calc.energy(v, protocol)
            }
        }, {
            title: 'EYearGEN(kWh)',
            dataIndex: 'EYearGEN',
            width: 90,
            render: (v) => {
                return calc.energy(v, protocol)
            }
        }, {
            title: 'ETotaGEN(kWh)',
            dataIndex: 'ETotaGEN',
            width: 90,
            render: (v) => {
                return calc.energy(v, protocol)
            }
        },
        {
            title: 'PAC1LOAD(W)',
            dataIndex: 'PAC1LOAD',
            width: 90,
            render: (v) => {
                return calc.power(v, protocol)
            }
        }, {
            title: 'PAC2LOAD(W)',
            dataIndex: 'PAC2LOAD',
            width: 90,
            render: (v) => {
                return calc.power(v, protocol)
            }
        }, {
            title: 'PAC3LOAD(W)',
            dataIndex: 'PAC3LOAD',
            width: 90,
            render: (v) => {
                return calc.power(v, protocol)
            }
        }, {
            title: 'FACLOAD(Hz)',
            dataIndex: 'FACLOAD',
            width: 90,
            render: (v) => {
                return calc.frequency(v, protocol)
            }
        }, {
            title: 'VAC1LOAD(V)',
            dataIndex: 'VAC1LOAD',
            width: 90,
            render: (v) => {
                return calc.voltage(v, protocol)
            }
        }, {
            title: 'IAC1LOAD(A)',
            dataIndex: 'IAC1LOAD',
            width: 90,
            render: (v) => {
                return calc.current(v, protocol)
            }
        }, {
            title: 'VAC2LOAD(V)',
            dataIndex: 'VAC2LOAD',
            width: 90,
            render: (v) => {
                return calc.voltage(v, protocol)
            }
        }, {
            title: 'IAC2LOAD(A)',
            dataIndex: 'IAC2LOAD',
            width: 90,
            render: (v) => {
                return calc.current(v, protocol)
            }
        }, {
            title: 'VAC3LOAD(V)',
            dataIndex: 'VAC3LOAD',
            width: 90,
            render: (v) => {
                return calc.voltage(v, protocol)
            }
        }, {
            title: 'IAC3LOAD(A)',
            dataIndex: 'IAC3LOAD',
            width: 90,
            render: (v) => {
                return calc.current(v, protocol)
            }
        }, {
            title: 'EDayLOAD(kWh)',
            dataIndex: 'EDayLOAD',
            width: 90,
            render: (v) => {
                return calc.energy(v, protocol)
            }
        }, {
            title: 'EMonLOAD(kWh)',
            dataIndex: 'EMonLOAD',
            width: 90,
            render: (v) => {
                return calc.energy(v, protocol)
            }
        }, {
            title: 'EYearLOAD(kWh)',
            dataIndex: 'EYearLOAD',
            width: 90,
            render: (v) => {
                return calc.energy(v, protocol)
            }
        }, {
            title: 'ETotalLOAD(kWh)',
            dataIndex: 'ETotalLOAD',
            width: 90,
            render: (v) => {
                return calc.energy(v, protocol)
            }
        },
        {
            title: 'PAC1GRID(W)',
            dataIndex: 'PAC1GRID',
            width: 90,
            render: (v) => {
                return calc.power(v, protocol)
            }
        }, {
            title: 'PAC2GRID(W)',
            dataIndex: 'PAC2GRID',
            width: 90,
            render: (v) => {
                return calc.power(v, protocol)
            }
        }, {
            title: 'PAC3GRID(W)',
            dataIndex: 'PAC3GRID',
            width: 90,
            render: (v) => {
                return calc.power(v, protocol)
            }
        }, {
            title: 'FACGRID(Hz)',
            dataIndex: 'FACGRID',
            width: 90,
            render: (v) => {
                return calc.frequency(v, protocol)
            }
        }, {
            title: 'VAC1GRID(V)',
            dataIndex: 'VAC1GRID',
            width: 90,
            render: (v) => {
                return calc.voltage(v, protocol)
            }
        }, {
            title: 'IAC1GRID(A)',
            dataIndex: 'IAC1GRID',
            width: 90,
            render: (v) => {
                return calc.current(v, protocol)
            }
        }, {
            title: 'VAC2GRID(V)',
            dataIndex: 'VAC2GRID',
            width: 90,
            render: (v) => {
                return calc.voltage(v, protocol)
            }
        }, {
            title: 'IAC2GRID(A)',
            dataIndex: 'IAC2GRID',
            width: 90,
            render: (v) => {
                return calc.current(v, protocol)
            }
        }, {
            title: 'VAC3GRID(V)',
            dataIndex: 'VAC3GRID',
            width: 90,
            render: (v) => {
                return calc.voltage(v, protocol)
            }
        }, {
            title: 'IAC3GRID(A)',
            dataIndex: 'IAC3GRID',
            width: 90,
            render: (v) => {
                return calc.current(v, protocol)
            }
        }, {
            title: 'EDayToGrid(kWh)',
            dataIndex: 'EDayToGrid',
            width: 90,
            render: (v) => {
                return calc.energy(v, protocol)
            }
        }, {
            title: 'EMonToGrid(kWh)',
            dataIndex: 'EMonToGrid',
            width: 90,
            render: (v) => {
                return calc.energy(v, protocol)
            }
        }, {
            title: 'EYearToGrid(kWh)',
            dataIndex: 'EYearToGrid',
            width: 90,
            render: (v) => {
                return calc.energy(v, protocol)
            }
        }, {
            title: 'ETotalToGrid(kWh)',
            dataIndex: 'ETotalToGrid',
            width: 90,
            render: (v) => {
                return calc.energy(v, protocol)
            }
        }, {
            title: 'EDayFromGrid(kWh)',
            dataIndex: 'EDayFromGrid',
            width: 90,
            render: (v) => {
                return calc.energy(v, protocol)
            }
        }, {
            title: 'EMonFromGrid(kWh)',
            dataIndex: 'EMonFromGrid',
            width: 90,
            render: (v) => {
                return calc.energy(v, protocol)
            }
        }, {
            title: 'EYearFromGrid(kWh)',
            dataIndex: 'EYearFromGrid',
            width: 90,
            render: (v) => {
                return calc.energy(v, protocol)
            }
        }, {
            title: 'ETotalFromGrid(kWh)',
            dataIndex: 'ETotalFromGrid',
            width: 90,
            render: (v) => {
                return calc.energy(v, protocol)
            }
        }, {
            title: 'ErrorCode1',
            dataIndex: 'ErrorCode1',
            width: 90,
        }, {
            title: 'ErrorCode2',
            dataIndex: 'ErrorCode2',
            width: 90,
        }, {
            title: 'ErrorCode3',
            dataIndex: 'ErrorCode3',
            width: 90,
        }, {
            title: 'WarningOrFault01',
            dataIndex: 'WarningOrFault01',
            width: 90,
        }, {
            title: 'WarningOrFault02',
            dataIndex: 'WarningOrFault02',
            width: 90,
        }, {
            title: 'WarningOrFault03',
            dataIndex: 'WarningOrFault03',
            width: 90,
        }, {
            title: 'WarningOrFault04',
            dataIndex: 'WarningOrFault04',
            width: 90,
        }, {
            title: 'WarningOrFault05',
            dataIndex: 'WarningOrFault05',
            width: 90,
        }, {
            title: 'WarningOrFault06',
            dataIndex: 'WarningOrFault06',
            width: 90,
        }, {
            title: 'WarningOrFault07',
            dataIndex: 'WarningOrFault07',
            width: 90,
        }, {
            title: 'WarningOrFault08',
            dataIndex: 'WarningOrFault08',
            width: 90,
        }, {
            title: 'WarningOrFault09',
            dataIndex: 'WarningOrFault09',
            width: 90,
        }, {
            title: 'WarningOrFault10',
            dataIndex: 'WarningOrFault10',
            width: 90,
        }, {
            title: 'WarningOrFault11',
            dataIndex: 'WarningOrFault11',
            width: 90,
        }, {
            title: 'WarningOrFault12',
            dataIndex: 'WarningOrFault12',
            width: 90,
        }, {
            title: 'WarningOrFault13',
            dataIndex: 'WarningOrFault13',
            width: 90,
        }, {
            title: 'WarningOrFault14',
            dataIndex: 'WarningOrFault14',
            width: 90,
        }, {
            title: 'WarningOrFault15',
            dataIndex: 'WarningOrFault15',
            width: 90,
        }, {
            title: 'WarningOrFault16',
            dataIndex: 'WarningOrFault16',
            width: 90,
        },
    ]

    return (
        <div style={{ width: "100%", height: "100%", display: "flex", flexDirection: "column" }}>
            <div style={{ flex: "none", padding: "10px" }}>
                <span>Select Inverter</span>
                <Select
                    showSearch
                    placeholder="Enter Inverter Name"
                    defaultActiveFirstOption={false}
                    showArrow={true}
                    filterOption={true}
                    onSelect={(value) => handleSelectSN(value)}
                    value={SN}
                    style={{ width: '200px', marginRight: '20px', marginLeft: '10px' }}
                >{inverterSelectOptions}</Select>
                <DatePicker
                    defaultValue={moment()}
                    value={moment(dataHistoryDate)}
                    onChange={(_, ds) => {
                        setDataHistoryDate(ds)
                        setAWeekAgoDate(moment(ds).subtract(7, 'days').format('YYYY-MM-DD'))
                    }}
                    allowClear={false}
                />

                <Button type="primary" disabled={rows.length < 1 ? true : false} onClick={handleExportWeek} style={{ float: 'right', marginRight: '10px', backgroundColor: '#0486be' }}>Export Data({dataHistoryDate} - {aWeekAgoDate})</Button>
                <Button type="primary" disabled={rows.length < 1 ? true : false} onClick={handleExport} style={{ float: 'right', marginRight: '10px', backgroundColor: '#0486be' }}>Export Data</Button>
            </div>
            <div style={{ flex: "auto", overflow: "hidden" }}>
                <Table
                    columns={protocol === 'Megarevo' || protocol === 'Megarevo-CESS' || protocol === 'Sinexcel' ? MegarevoColumns : DefaultColumns}
                    pagination={{
                        pageSizeOptions: [25, 50, 100],
                        current: pageNo + 1,
                        pageSize: pageSize,
                        total: rowTotal,
                        showSizeChanger: true,
                        showTotal: (total) => { return `Total ${total}` },
                        onShowSizeChange: (current, size) => {
                            setPageSize(size);
                        },
                        onChange: (page, pageSize) => {
                            setPageNo(page - 1);
                        }
                    }}
                    tableLayout="fixed"
                    bordered={true}
                    size="small"
                    loading={loading}
                    rowKey="ID"
                    dataSource={rows}
                    scroll={{ x: 'max-content', y: scrollY }}
                >
                </Table>
            </div>

            <a ref={refDownloader1} style={{ display: "none" }} href={config.apiUrl + "data-export/inverter/history/do?Token=" + http.token + "&SerialNumber=" + SN + "&STime=" + dataHistoryDate + "&ETime=" + moment(dataHistoryDate).add(1, 'days').format('YYYY-MM-DD')}></a>
            <a ref={refDownloader2} style={{ display: "none" }} href={config.apiUrl + "data-export/inverter/history/do?Token=" + http.token + "&SerialNumber=" + SN + "&STime=" + aWeekAgoDate + "&ETime=" + dataHistoryDate}></a>
        </div>
    )
}

export default DataHistory;