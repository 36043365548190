import React, { useEffect, useState, useRef, useContext } from 'react';
import { Form, Select, DatePicker, Radio, Button } from 'antd';
import moment from 'moment';
import http from '../../../Http';
import utils from '../../../utils';
import ReactECharts from 'echarts-for-react';
import calc from '../calc.js';

const pvOption = {
    tooltip: {
        trigger: 'axis'
    },
    xAxis: {
        type: 'category',
        axisLabel: {
            formatter: (value) => {
                return moment(value).format('HH:mm')
            }
        }
    },
    yAxis: {
        type: 'value',
    },
    color: '#0486be',
    series: [
        {
            name: '', 
            type: 'line', 
            symbol: 'none',
            lineStyle: {width: 2}, 
            smooth: true, 
            data: [], 
            markLine: {
                symbol: 'none',
                data: [{type: 'average', name: 'Avg'}],
                lineStyle: {color: 'blue', type: 'dashed'},
                label: {formatter: 'AVG: {c}', position: 'insideStartTop', color: '#629e0e', fontWeight: 'bold'}
            }
        }
    ]
}

const batOption = {
    tooltip: {
        trigger: 'axis'
    },
    xAxis: {
        type: 'category',
        data: [],
        axisLabel: {
            formatter: (value) => {
                return moment(value).format('HH:mm')
            }
        }
    },
    yAxis: {
        type: 'value',
    },
    color: '#0486be',
    series: [
        {
            name: '', 
            type: 'line', 
            symbol: 'none',
            lineStyle: {width: 2}, 
            smooth: true, 
            data: [], 
            markLine: {
                symbol: 'none',
                data: [{type: 'average', name: 'Avg'}],
                lineStyle: {color: 'blue', type: 'dashed'},
                label: {formatter: 'AVG: {c}', position: 'insideStartTop', color: '#629e0e', fontWeight: 'bold'}
            }
        }
    ]
}

const acOption = {
    tooltip: {
        trigger: 'axis'
    },
    xAxis: {
        type: 'category',
        axisLabel: {
            formatter: (value) => {
                return moment(value).format('HH:mm')
            }
        }
    },
    yAxis: {
        type: 'value',
    },
    color: '#0486be',
    series: [
        {
            name: '', 
            type: 'line', 
            symbol: 'none',
            lineStyle: {width: 2}, 
            smooth: true, 
            data: [], 
            markLine: {
                symbol: 'none',
                data: [{type: 'average', name: 'Avg'}],
                lineStyle: {color: 'blue', type: 'dashed'},
                label: {formatter: 'AVG: {c}', position: 'insideStartTop', color: '#629e0e', fontWeight: 'bold'}
            }
        }
    ]
}

const epsOption = {
    tooltip: {
        trigger: 'axis'
    },
    xAxis: {
        type: 'category',
        axisLabel: {
            formatter: (value) => {
                return moment(value).format('HH:mm')
            }
        }
    },
    yAxis: {
        type: 'value',
    },
    color: '#0486be',
    series: [
        {
            name: '', 
            type: 'line', 
            symbol: 'none',
            lineStyle: {width: 2}, 
            smooth: true, 
            data: [], 
            markLine: {
                symbol: 'none',
                data: [{type: 'average', name: 'Avg'}],
                lineStyle: {color: 'blue', type: 'dashed'},
                label: {formatter: 'AVG: {c}', position: 'insideStartTop', color: '#629e0e', fontWeight: 'bold'}
            }
        }
    ]
}

function RuntimeParameter(props) {
    const {inverterSelectOptions, SN, protocol, handleSelectSN} = props
    const [invData, setInvData] = useState(null)  //逆变器数据
    const setTip = useContext(utils.tipContext)

    const [parameterDate, setParameterDate] = useState(moment().format('YYYY-MM-DD')) //时间

    const [activePV, setActivePV] = useState(0)
    const [activeBat, setActiveBat] = useState(0)
    const [activeAC, setActiveAC] = useState(0)
    const [activeEps, setActiveEps] = useState(0)
    const [PVTitle, setPVTitle] = useState('Vpv1(V)')
    const [BatTitle, setBatTitle] = useState('SOC(%)')
    const [ACTitle, setACTitle] = useState('Vacr(V)')
    const [EpsTitle, setEpsTitle] = useState('Vepsr(V)')

    const pvChart = useRef(null)
    const batChart = useRef(null)
    const acChart = useRef(null)
    const epsChart = useRef(null)

    //获取日数据
    useEffect(() => {
        if(!SN) return;
        let active = true;
        (async () => {
            let resp = await http.post("integrated-inverter/report/daily/report", {
                SerialNumber: SN,
                Date: parameterDate, 
            }).then(async (r)=>{
                return await r.json();
            }).catch((e)=>{
                return null;
            });

            if (!active) {
                return;
            }
            
            if (resp === null || resp.code !== 200) {  //请求日数据失败
                setTip({open:true, severity:"error", msg:(resp && resp.msg) ? resp.msg : "load inverter record error"});
                return;
            } else { 
                setInvData(resp.data)
            }
        })();

        return () => {
            active = false;
        };

    }, [SN, parameterDate]);

    //draw PV Chart
    useEffect(() => {
        if(!invData) {
            pvOption.xAxis.data = []
            pvOption.series[0].data = []
        } else {
            const xData = []
            const yData = []
            //遍历结果，给坐标和数据赋值
            invData.forEach(v => {
                xData.push(moment(v['YMDH']).format('YYYY-MM-DD HH:mm'))
                if(protocol === 'Thinkpower' || protocol === 'Megarevo' || protocol === 'Sinexcel' || protocol === 'Megarevo-CESS' || protocol === 'Sungery') {
                    yData.push(activePV == 0 ? calc.voltage(v['VMPPT1'], protocol) : 
                                activePV == 1 ? calc.voltage(v['VMPPT2'], protocol) :
                                activePV == 2 ? calc.voltage(v['VMPPT3'], protocol) : 
                                activePV == 3 ? calc.voltage(v['VMPPT4'], protocol) : 
                                activePV == 4 ? calc.power(v['PMPPT1'], protocol) : 
                                activePV == 5 ? calc.power(v['PMPPT2'], protocol) : 
                                activePV == 6 ? calc.power(v['PMPPT3'], protocol) : 
                                activePV == 7 ? calc.power(v['PMPPT4'], protocol) : 
                                null)
                } else {
                    yData.push(activePV == 0 ? calc.voltage(v['VMPPT1'], protocol) : 
                                activePV == 1 ? calc.power(v['PDC'], protocol) : 
                                null)
                }
            })
            //x轴坐标
            pvOption.xAxis.data = xData;
            //数据
            if(protocol === 'Thinkpower' || protocol === 'Megarevo' || protocol === 'Sinexcel' || protocol === 'Megarevo-CESS' || protocol === 'Sungery') {
                pvOption.series[0] =
                activePV==0 ? {...pvOption.series[0], name: 'Vpv1', data: yData} : 
                activePV==1 ? {...pvOption.series[0], name: 'Vpv2', data: yData} :
                activePV==2 ? {...pvOption.series[0], name: 'Vpv3', data: yData} :
                activePV==3 ? {...pvOption.series[0], name: 'Vpv4', data: yData} :
                activePV==4 ? {...pvOption.series[0], name: 'Ppv1', data: yData} : 
                activePV==5 ? {...pvOption.series[0], name: 'Ppv2', data: yData} :
                activePV==6 ? {...pvOption.series[0], name: 'Ppv3', data: yData} : 
                activePV==7 ? {...pvOption.series[0], name: 'Ppv4', data: yData} : 
                null
            } else {
                pvOption.series[0] =
                activePV==0 ? {...pvOption.series[0], name: 'Vpv', data: yData} : 
                activePV==1 ? {...pvOption.series[0], name: 'Ppv', data: yData} : 
                null
            }
        }
        const instance = pvChart.current.getEchartsInstance();
        if (instance) {
            instance.setOption(pvOption, true);
        }
    }, [invData, activePV])

    //draw Battery Chart
    useEffect(() => {
        if(!invData) {
            batOption.xAxis.data = []
            batOption.series[0].data = []
        } else {
            const xData = []
            const yData = []
            //遍历结果，给坐标和数据赋值
            invData.forEach(v => {
                let powerCharge = 0
                let powerDischarge = 0
                if (protocol === 'Sungery') {
                    v['PBat'] = calc.current(v['IBat'], protocol) * calc.voltage(v['VBat'], protocol)
                } 
                if (protocol === 'Megarevo' || protocol === 'Sinexcel' || protocol === 'Megarevo-CESS') {
                    v['PBat'] = calc.convertComplement(v['PBat'], protocol)
                    powerCharge = calc.power(v['PBat'] > 0 ? v['PBat'] : 0, protocol)
                    powerDischarge = calc.power(v['PBat'] < 0 ? Math.abs(v['PBat']) : 0, protocol)
                } else {
                    powerCharge = calc.power(v['PBat'] < 0 ? Math.abs(v['PBat']) : 0, protocol)
                    powerDischarge = calc.power(v['PBat'] > 0 ? v['PBat'] : 0, protocol)
                }
                xData.push(moment(v['YMDH']).format('YYYY-MM-DD HH:mm'))
                yData.push(activeBat==0 ? calc.soc(v['SOC'], protocol) : 
                        activeBat==1 ? calc.voltage(v['VBat'], protocol) :
                        activeBat==2 ? calc.current(v['IBat'], protocol) :
                        activeBat==3 ? powerCharge :
                        activeBat==4 ? powerDischarge : 
                        null)
            })
            //x轴坐标
            batOption.xAxis.data = xData;
            //数据
            batOption.series[0] =
                activeBat==0 ? {...batOption.series[0], name: 'SOC', data: yData} : 
                activeBat==1 ? {...batOption.series[0], name: 'VBat', data: yData} :
                activeBat==2 ? {...batOption.series[0], name: 'IBat', data: yData} :
                activeBat==3 ? {...batOption.series[0], name: 'Charge Power', data: yData} :
                activeBat==4 ? {...batOption.series[0], name: 'Discharge Power', data: yData} : 
                null
        }

        const instance = batChart.current.getEchartsInstance();
        if (instance) {
            instance.setOption(batOption, true);
        }
    }, [invData, activeBat])

    //draw AC Chart
    useEffect(() => {
        if(!invData) {
            acOption.xAxis.data = []
            acOption.series[0].data = []
        } else {
            const xData = []
            const yData = []
            //遍历结果，给坐标和数据赋值
            invData.forEach(v => {
                let PACGRID = 0
                let powerToGrid = 0
                let powerFromGrid = 0
                v['PAC1GRID'] = calc.convertComplement(v['PAC1GRID'], protocol)
                v['PAC2GRID'] = calc.convertComplement(v['PAC2GRID'], protocol)
                v['PAC3GRID'] = calc.convertComplement(v['PAC3GRID'], protocol)
                if(protocol === 'Megarevo' || protocol === 'Thinkpower' || protocol === 'Megarevo-CESS') {
                    PACGRID = v['PAC1GRID'] + v['PAC2GRID'] + v['PAC3GRID']
                    powerToGrid = PACGRID > 0 ? calc.power(PACGRID, protocol) : 0
                    powerFromGrid = PACGRID < 0 ? calc.power(Math.abs(PACGRID), protocol) : 0
                } else {
                    PACGRID = v['PAC1GRID'] + v['PAC2GRID'] + v['PAC3GRID']
                    powerToGrid = PACGRID < 0 ? calc.power(Math.abs(PACGRID), protocol) : 0
                    powerFromGrid = PACGRID > 0 ? calc.power(PACGRID, protocol) : 0
                }
                xData.push(moment(v['YMDH']).format('YYYY-MM-DD HH:mm'))
                yData.push(activeAC==0 ? calc.voltage(v['VAC1GRID'], protocol) : 
                        activeAC==1 ? calc.energy(v['EDayToGRID'], protocol) :
                        activeAC==2 ? powerToGrid : 
                        activeAC==3 ? powerFromGrid :
                        null)
            })
            //x轴坐标
            acOption.xAxis.data = xData
            //数据
            acOption.series[0] = 
                activeAC==0 ? {...acOption.series[0], name: 'Vacr', data: yData} :
                activeAC==1 ? {...acOption.series[0], name: 'Qac', data: yData} :
                activeAC==2 ? {...acOption.series[0], name: 'PToGrid', data: yData} : 
                activeAC==3 ? {...acOption.series[0], name: 'PFromGrid', data: yData} : 
                null
        }

        const instance = acChart.current.getEchartsInstance();
        if (instance) {
            instance.setOption(acOption, true);
        }
    }, [invData, activeAC])

    //draw Eps Chart
    useEffect(() => {
        if(!invData) {
            epsOption.xAxis.data = []
            epsOption.series[0].data = []
        } else {
            const xData = []
            const yData = []
            //遍历结果，给坐标和数据赋值
            invData.forEach(v => {
                xData.push(moment(v['YMDH']).format('YYYY-MM-DD HH:mm'))
                yData.push(activeEps==0 ? calc.voltage(v['VAC1EPS'], protocol) : 
                        activeEps==1 ? calc.frequency(v['FACEPS'], protocol) :
                        activeEps==2 ? calc.power(v['PAC1EPS'], protocol) :
                        activeEps==3 ? Math.round(calc.voltage(v['VAC1EPS'], protocol)*calc.current(v['IAC1EPS'], protocol)) : 
                        null)
            })
            //x轴坐标
            epsOption.xAxis.data = xData;
            //数据
            epsOption.series[0] = 
                activeEps==0 ? {...epsOption.series[0], name: 'Vepsr', data: yData} : 
                activeEps==1 ? {...epsOption.series[0], name: 'Feps', data: yData} : 
                activeEps==2 ? {...epsOption.series[0], name: 'Peps', data: yData} : 
                activeEps==3 ? {...epsOption.series[0], name: 'Seps', data: yData} : 
                null
        }

        const instance = epsChart.current.getEchartsInstance();
        if (instance) {
            instance.setOption(epsOption, true);
        }
    }, [invData, activeEps])

    return (
        <div className="runtime-parameter">
            <div className="runtime-parameter-header">
                <span>Select Inverter</span>
                <Select
                    showSearch
                    placeholder="Enter Inverter Name"
                    defaultActiveFirstOption={false}
                    showArrow={true}
                    filterOption={true}
                    onSelect={(value) => handleSelectSN(value)}
                    value={SN}
                    style={{ width: '200px', marginRight: '20px' }}
                >{inverterSelectOptions}</Select>
                <DatePicker
                    defaultValue={moment()}
                    value={moment(parameterDate)}
                    onChange={(_, ds) => setParameterDate(ds)}
                    allowClear={false}
                /> 
            </div>
            <div className="runtime-parameter-main">
                <div className="side side-left">
                    <div className="title">PV Side</div>
                    <div className="item">
                        <ul>
                            {protocol === 'Thinkpower' || protocol === 'Megarevo' || protocol === 'Sinexcel' || protocol === 'Megarevo-CESS' || protocol === 'Sungery' ? 
                                (
                                    <>
                                        <li className={activePV==0 ? 'active' : null} onClick={() =>{
                                            setActivePV(0)
                                            setPVTitle('Vpv1(V)')
                                        }}>Vpv1(V)</li>
                                        <li className={activePV==1 ? 'active' : null} onClick={() =>{
                                            setActivePV(1)
                                            setPVTitle('Vpv2(V)')
                                        }}>Vpv2(V)</li>
                                        <li className={activePV==2 ? 'active' : null} onClick={() =>{
                                            setActivePV(2)
                                            setPVTitle('Vpv3(V)')
                                        }}>Vpv3(V)</li>
                                        <li className={activePV==3 ? 'active' : null} onClick={() =>{
                                            setActivePV(3)
                                            setPVTitle('Vpv4(V)')
                                        }}>Vpv4(V)</li>
                                        <li className={activePV==4 ? 'active' : null} onClick={() =>{
                                            setActivePV(4)
                                            setPVTitle('Ppv1(W)')
                                        }}>Ppv1(W)</li>
                                        <li className={activePV==5 ? 'active' : null} onClick={() =>{
                                            setActivePV(5)
                                            setPVTitle('Ppv2(W)')
                                        }}>Ppv2(W)</li>
                                        <li className={activePV==6 ? 'active' : null} onClick={() =>{
                                            setActivePV(6)
                                            setPVTitle('Ppv3(W)')
                                        }}>Ppv3(W)</li>
                                        <li className={activePV==7 ? 'active' : null} onClick={() =>{
                                            setActivePV(7)
                                            setPVTitle('Ppv4(W)')
                                        }}>Ppv4(W)</li>
                                    </>
                                ) : 
                                (
                                    <>
                                        <li className={activePV==0 ? 'active' : null} onClick={() =>{
                                            setActivePV(0)
                                            setPVTitle('Vpv(V)')
                                        }}>Vpv(V)</li>
                                        <li className={activePV==1 ? 'active' : null} onClick={() =>{
                                            setActivePV(1)
                                            setPVTitle('Ppv(W)')
                                        }}>Ppv(W)</li>
                                    </>
                                )
                            }
                        </ul>
                    </div>
                </div>
                <div className="box box-left">
                    <div className="title">
                        {PVTitle} - ({SN}, {parameterDate})
                    </div>
                    <div className="chart">
                        <ReactECharts ref={pvChart} option={pvOption} style={{height: 280}} />
                    </div>
                </div>
                <div className="side side-right">
                    <div className="title">Battery</div>
                    <div className="item">
                        <ul>
                        <li className={activeBat==0 ? 'active' : null} onClick={() => {
                            setActiveBat(0)
                            setBatTitle('SOC(%)')
                        }}>SOC(%)</li>
                        <li className={activeBat==1 ? 'active' : null} onClick={() => {
                            setActiveBat(1)
                            setBatTitle('vBat(V)')
                        }}>VBat(V)</li>
                        <li className={activeBat==2 ? 'active' : null} onClick={() => {
                            setActiveBat(2)
                            setBatTitle('IBat(A)')
                        }}>IBat(A)</li>
                        <li className={activeBat==3 ? 'active' : null} onClick={() => {
                            setActiveBat(3)
                            setBatTitle('Charge Power(W)')
                        }}>Charge Power(W)</li>
                        <li className={activeBat==4 ? 'active' : null} onClick={() => {
                            setActiveBat(4)
                            setBatTitle('DisCharge Power(W)')
                        }}>DisCharge Power(W)</li>
                        </ul>
                    </div>
                </div>
                <div className="box">
                    <div className="title">
                        {BatTitle} - ({SN}, {parameterDate})
                    </div>
                    <div className="chart">
                        <ReactECharts ref={batChart} option={batOption} style={{height: 280}} />
                    </div>
                </div>
                <div style={{clear: 'both'}}></div>
                <div className="side side-left">
                    <div className="title">AC Side</div>
                    <div className="item">
                        <ul>
                        <li className={activeAC==0 ? 'active' : null} onClick={() => {
                            setActiveAC(0)
                            setACTitle('Vacr(V)')
                        }}>Vacr(V)</li>
                        <li className={activeAC==1 ? 'active' : null} onClick={() => {
                            setActiveAC(1)
                            setACTitle('Qac(Var)')
                        }}>Qac(Var)</li>
                        <li className={activeAC==2 ? 'active' : null} onClick={() => {
                            setActiveAC(2)
                            setACTitle('pToGrid(W)')
                        }}>pToGrid(W)</li>
                        <li className={activeAC==3 ? 'active' : null} onClick={() => {
                            setActiveAC(3)
                            setACTitle('pFromGrid(W)')
                        }}>pFromGrid(W)</li>
                        </ul>
                    </div>
                </div>
                <div className="box box-left">
                    <div className="title">
                        {ACTitle} - ({SN}, {parameterDate})
                    </div>
                    <div className="chart">
                        <ReactECharts ref={acChart} option={acOption} style={{height: 280}} />
                    </div>
                </div>
                <div className="side side-right">
                    <div className="title">Backup</div>
                    <div className="item">
                        <ul>
                        <li className={activeEps==0 ? 'active' : null} onClick={() => {
                            setActiveEps(0)
                            setEpsTitle('Vepsr(V)')
                        }}>Vepsr(V)</li>
                        <li className={activeEps==1 ? 'active' : null} onClick={() => {
                            setActiveEps(1)
                            setEpsTitle('Feps(Hz)')
                        }}>Feps(Hz)</li>
                        <li className={activeEps==2 ? 'active' : null} onClick={() => {
                            setActiveEps(2)
                            setEpsTitle('Peps(W)')
                        }}>Peps(W)</li>
                        <li className={activeEps==3 ? 'active' : null} onClick={() => {
                            setActiveEps(3)
                            setEpsTitle('Seps(VA)')
                        }}>Seps(VA)</li>
                        </ul>
                    </div>
                </div>
                <div className="box">
                    <div className="title">
                        {EpsTitle} - ({SN}, {parameterDate})
                    </div>
                    <div className="chart">
                        <ReactECharts ref={epsChart} option={epsOption} style={{height: 280}} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RuntimeParameter;