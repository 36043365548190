import React, { useEffect, useState, useContext, useRef } from 'react';
import { Select, DatePicker, Radio, Button, Modal, Table } from 'antd';
import http from '../../Http';
import utils from '../../utils';
import moment from 'moment';
import ReactECharts from 'echarts-for-react';
import { ReloadOutlined } from '@ant-design/icons';
import Monitor from '../../Monitor';
import calc from './calc.js';

const option1 = {
    tooltip: {
        trigger: 'item',
        formatter: '{b}<br/>Brands: <b>{d}%</b>',
        textStyle: {
            fontSize: 12
        },
    },
    color: ['#5CC9A0', '#F2A474'],
    series: [
        {
            type: 'pie',
            radius: '50%',
            showEmptyCircle: true,
            label: { show: false },
            data: [],
        }
    ]
}

const option2 = {
    tooltip: {
        trigger: 'item',
        formatter: '{b}<br/>Brands: <b>{d}%</b>',
        textStyle: {
            fontSize: 12
        },
    },
    color: ['#5CC9A0', '#F2A474'],
    series: [
        {
            type: 'pie',
            radius: '50%',
            showEmptyCircle: true,
            label: { show: false },
            data: [],
        }
    ]
}

const powerOption = {
    tooltip: {
        trigger: 'axis'
    },
    legend: {
        data: ['Solar PV', 'Battery', 'Grid', 'Consumption', 'SOC']
    },
    xAxis: {
        type: 'category',
        data: [],
        name: '(Time)',
        nameLocation: "center",
        nameGap: 25,
        axisLabel: {
            formatter: (value) => {
                return moment(value).format('HH:mm')
            }
        }
    },
    yAxis: {
        type: 'value',
        name: '(W)',
        nameLocation: "middle",
        nameRotate: 90,
        nameGap: 50,
    },
    series: []
}

const energyOption = {
    tooltip: {
        trigger: 'axis'
    },
    legend: {
        data: []
    },
    xAxis: {
        type: 'category',
        data: [],
        name: '(Time)',
        nameLocation: "center",
        nameGap: 25,
    },
    yAxis: {
        type: 'value',
        name: '(kWh)',
        nameLocation: "middle",
        nameRotate: 90,
        nameGap: 40,
    },
    series: []
}

function RealtimeMonitor(props) {
    const { inverterSelectOptions, SN, protocol, model, isBalcony, handleSelectSN } = props
    const setTip = useContext(utils.tipContext)

    const [invData, setInvData] = useState(null)  //当前选择的逆变器的详细数据
    const [solarIndex, setSolarIndex] = useState(0)  //点击solar面板
    const [batteryIndex, setBatteryIndex] = useState(0)  //点击battery面板
    const [gridIndex, setGridIndex] = useState(0)  //点击grid面板

    const [balconyData, setBalconyData] = useState(null)

    const chart1 = useRef(null)
    const chart2 = useRef(null)
    const powerChart = useRef(null)
    const energyChart = useRef(null)

    const [powerDate, setPowerDate] = useState(moment().format('YYYY-MM-DD')) //power的时间
    const [size, setSize] = useState('month')  //月, 年, 总计
    const [energyDate, setEnergyDate] = useState(moment().format('YYYY-MM-DD')) //energy的时间

    const [refresh, setRefresh] = useState(false)

    //阳台经济的流图
    const [balconyPVToBat, setBalconyPVToBat] = useState(false) //PV -> Bat
    const [balconyPVToGrid, setBalconyPVToGrid] = useState(false) //PV -> PCS -> Grid
    const [balconyPVToLoad, setBalconyPVToLoad] = useState(false)  //PV -> PCS -> Load
    const [balconyGridToBat, setBalconyGridToBat] = useState(false) //Grid -> ACDC -> Bat
    const [balconyGridToLoad, setBalconyGridToLoad] = useState(false) //Grid -> ACDC -> Load
    const [balconyBatToLoad, setBalconyBatToLoad] = useState(false) //Bat -> ACDC -> Load

    const [showBatteriesList, setShowBatteriesList] = useState(false)
    const [showInvertersList, setShowInvertersList] = useState(false)

    //获取具体逆变器详细数据
    useEffect(() => {
        if (!SN || isBalcony) {
            return;
        }
        let active = true;

        (async () => {
            let resp = await http.post("integrated-inverter/last/pvi/get", { SerialNumber: SN }).then(async (r) => {
                return await r.json();
            }).catch((e) => {
                return null;
            });

            if (!active) {
                return;
            }

            if (resp === null || resp.code !== 200) {
                setTip({ open: true, severity: "error", msg: (resp && resp.msg) ? resp.msg : "load inverter details error" });
                setInvData(null)
                return;
            }

            //console.log('inverter Data: ', resp.data)
            
            //对Sungery的特殊处理
            if(protocol === 'Sungery') {
                const IBat = calc.current(resp.data.IBat, protocol)
                const VBat = calc.voltage(resp.data.VBat, protocol)
                resp.data.PBat = IBat * VBat
                const relatedInverters = resp.data.relatedInverters
                if (relatedInverters && relatedInverters.length > 0) {
                    for (let i = 0; i < relatedInverters.length; i++) {
                        relatedInverters[i].PBat = calc.current(relatedInverters[i].IBat, protocol) * 
                            calc.voltage(relatedInverters[i].VBat, protocol)
                    }
                }
            }

            //对麦格瑞能带符号数的处理
            if (protocol === 'Megarevo' || protocol === 'Sinexcel' || protocol === 'Megarevo-CESS' || protocol === 'Sungery') {
                resp.data.PBat = calc.convertComplement(resp.data.PBat, protocol)
                resp.data.PAC1GRID = calc.convertComplement(resp.data.PAC1GRID, protocol)
                resp.data.PAC2GRID = calc.convertComplement(resp.data.PAC2GRID, protocol)
                resp.data.PAC3GRID = calc.convertComplement(resp.data.PAC3GRID, protocol)
                const relatedInverters = resp.data.relatedInverters
                if (relatedInverters && relatedInverters.length > 0) {
                    for (let i = 0; i < relatedInverters.length; i++) {
                        relatedInverters[i].PBat = calc.convertComplement(relatedInverters[i].PBat, protocol)
                        relatedInverters[i].PAC1GRID = calc.convertComplement(relatedInverters[i].PAC1GRID, protocol)
                        relatedInverters[i].PAC2GRID = calc.convertComplement(relatedInverters[i].PAC2GRID, protocol)
                        relatedInverters[i].PAC3GRID = calc.convertComplement(relatedInverters[i].PAC3GRID, protocol)
                    }
                }
            }
            setInvData(resp.data);

            //pie图
            option1.series[0].data = [
                { value: resp.data.EDayBatChrg, name: 'Charge Today' },
                { value: resp.data.EDayToGRID, name: 'Export Today' },
            ]
            const instance1 = chart1.current && chart1.current.getEchartsInstance();
            if (instance1) {
                instance1.setOption(option1, true);
            }

            option2.series[0].data = [
                { value: resp.data.ETotalBatChrg, name: 'Charge Total' },
                { value: resp.data.ETotalToGRID, name: 'Export Total' },
            ]
            const instance2 = chart2.current && chart2.current.getEchartsInstance();
            if (instance2) {
                instance2.setOption(option2, true);
            }

        })();

        return () => {
            active = false;
        };
    }, [SN, refresh])

    //获取阳台经济的数据
    useEffect(() => {
        if (!SN || !isBalcony) {
            return;
        }
        let active = true;

        (async () => {
            let resp = await http.post("balcony/detail/get", { SN: SN }).then(async (r) => {
                return await r.json();
            }).catch((e) => {
                return null;
            });

            if (!active) {
                return;
            }

            if (resp === null || resp.code !== 200) {
                setTip({ open: true, severity: "error", msg: (resp && resp.msg) ? resp.msg : "load balcony details error" });
                setBalconyData(null)
                return;
            }

            //console.log('balcony data: ', resp.data)
            setBalconyData(resp.data)

            //流图的方向
            // setBalconyPVToBat(true)
            // setBalconyPVToGrid(true)
            // setBalconyPVToLoad(true)
            // setBalconyGridToBat(true)
            // setBalconyGridToLoad(true)
            //setBalconyBatToLoad(true)

        })();

        return () => {
            active = false;
        }
    }, [SN])

    //Power Chart(日数据)
    useEffect(() => {
        if (!SN) return;
        let active = true;
        (async () => {
            let resp = await http.post("integrated-inverter/report/daily/report", {
                SerialNumber: SN,
                Date: powerDate,
            }).then(async (r) => {
                return await r.json();
            }).catch((e) => {
                return null;
            });

            if (!active) {
                return;
            }

            if (resp === null || resp.code !== 200) {  //请求日数据失败
                setTip({ open: true, severity: "error", msg: (resp && resp.msg) ? resp.msg : "load inverter record error" });
                return;
            } else {
                const xData = []
                const PDC = []
                const PBat = []
                const PGrid = []
                const PLoad = []
                const SOC = []
                //遍历结果，给坐标和数据赋值
                resp.data.forEach(v => {
                    if(protocol === 'Sungery') {
                        v['PBat'] = calc.current(v['IBat'], protocol) * calc.voltage(v['VBat'], protocol)
                    }
                    xData.push(moment(v['YMDH']).format('YYYY-MM-DD HH:mm'))
                    PDC.push(protocol === 'Thinkpower' || protocol === 'Megarevo' || protocol === 'Sinexcel' || protocol === 'Megarevo-CESS' || protocol === 'Sungery' ?
                        calc.power((v['PMPPT1']||0) + (v['PMPPT2']||0) + (v['PMPPT3']||0) + (v['PMPPT4']||0), protocol) :
                        calc.power(v['PDC'], protocol))
                    PBat.push(calc.power(v['PBat'], protocol))
                    PGrid.push(calc.power(v['PAC1GRID'], protocol) + calc.power(v['PAC2GRID'], protocol) + calc.power(v['PAC3GRID'], protocol))
                    PLoad.push(calc.power(v['PAC1LOAD'] + v['PAC2LOAD'] + v['PAC3LOAD'], protocol))
                    SOC.push(calc.soc(v['SOC'], protocol))
                })
                //x轴坐标
                powerOption.xAxis.data = xData;
                //数据
                powerOption.series = [
                    { name: 'Solar PV', type: 'line', smooth: true, symbol: 'none', data: PDC },
                    { name: 'Battery', type: 'line', smooth: true, symbol: 'none', data: PBat },
                    { name: 'Grid', type: 'line', smooth: true, symbol: 'none', data: PGrid },
                    { name: 'Consumption', type: 'line', smooth: true, symbol: 'none', data: PLoad },
                    { name: 'SOC', type: 'line', smooth: true, symbol: 'none', data: SOC },
                ]
                //tooltip
                powerOption.tooltip.formatter = (item) => {
                    if (!item || item.length == 0) {
                        return ''
                    }
                    let tip = `<b>Time: </b>${item[0].name}<br/>`
                    for (const v of item) {
                        var iBat = 0;
                        if (v.seriesName === 'Solar PV') {
                            tip += `<b>Solor PV: </b>${v.value} W<br/>`
                        }
                        if(v.seriesName === 'IBat') {
                            iBat = v.value
                        }
                        if (v.seriesName === 'Battery') {
                            const batPower = v.value
                            let batTip = ''
                            if (protocol === 'Senergy' || protocol === 'Thinkpower' || protocol === 'Sungery') {
                                batTip = batPower > 0 ? 'Battery Discharge' :
                                    batPower < 0 ? 'Battery Charge' : 'Battery'
                            } else {
                                batTip = batPower < 0 ? 'Battery Discharge' :
                                    batPower > 0 ? 'Battery Charge' : 'Battery'
                            }
                            tip += `<b>${batTip}: </b>${Math.abs(batPower)} W<br/>`
                        }
                        if (v.seriesName === 'Grid') {
                            const gridPower = v.value
                            let gridTip = ''
                            if (protocol === 'Senergy' || protocol === 'Sinexcel' || protocol === 'Sungery') {
                                gridTip = gridPower > 0 ? 'Grid Import' :
                                    gridPower < 0 ? 'Grid Export' : 'Grid'
                            } else {
                                gridTip = gridPower < 0 ? 'Grid Import' :
                                    gridPower > 0 ? 'Grid Export' : 'Grid'
                            }
                            tip += `<b>${gridTip}: </b>${Math.abs(gridPower)} W<br/>`
                        }
                        if (v.seriesName === 'Consumption') {
                            tip += `<b>Consumption: </b>${v.value} W<br/>`
                        }
                        if (v.seriesName === 'SOC') {
                            tip += `<b>SOC: </b>${v.value} %<br/>`
                        }
                    }
                    return tip;
                }

            }

            //渲染数据到页面
            //setOption()的第二个参数设为true, 表示组件合并模式为替换合并
            const instance = powerChart.current.getEchartsInstance();
            if (instance) {
                instance.setOption(powerOption, true);
            }
        })();

        return () => {
            active = false;
        };

    }, [SN, powerDate, refresh]);

    //Energy Chart(月、年、总数据)
    useEffect(() => {
        if (!SN) return;
        let active = true;
        if (size == 'month') { //月数据
            //坐标, 计算每个月有多少天
            const y = moment(energyDate).year()
            const m = moment(energyDate).month()
            let days = Array(new Date(y, m+1, 0).getDate())
            days.fill(0).forEach((v, i) => {
                days[i] = i + 1;
            })
            //坐标
            energyOption.xAxis.data = days;
            energyOption.xAxis.name = '(Day)';
            energyOption.yAxis.name = '(kWh)';
            //取月数据
            (async () => {
                let resp = await http.post("integrated-inverter/report/monthly/report", {
                    SerialNumber: SN,
                    Date: moment(energyDate).format('YYYY-MM') + '-01'
                }).then(async (r) => {
                    return await r.json();
                }).catch((e) => {
                    return null;
                });

                if (!active) {
                    return;
                }
                //console.log('Month Energy data:', resp.data)

                if (resp === null || resp.code !== 200) {  //请求月数据失败
                    setTip({ open: true, severity: "error", msg: (resp && resp.msg) ? resp.msg : "load inverter record error" });
                    return;
                } else {  //请求月数据成功
                    let EDay = Array(days.length).fill(0)
                    let EDayBatChrg = Array(days.length).fill(0)
                    let EDayToGRID = Array(days.length).fill(0)
                    let EDayToLoad = Array(days.length).fill(0)
                    resp.data.forEach(v => {
                        EDay[moment(v['YMD']).date() - 1] = calc.energy(v['EDay'], protocol)
                        EDayBatChrg[moment(v['YMD']).date() - 1] = calc.energy(v['EDayBatDischrg'], protocol)
                        EDayToGRID[moment(v['YMD']).date() - 1] = calc.energy(v['EDayToGRID'], protocol)
                        EDayToLoad[moment(v['YMD']).date() - 1] = calc.energy(v['EDayLOAD'], protocol)
                    })
                    //Echart坐标legend
                    energyOption.legend.data = ['Solar Production', 'Battery Discharge', 'Export to Grid', 'Consumption']
                    //Echart数据
                    energyOption.series = [
                        {
                            name: 'Solar Production',
                            type: 'bar',
                            data: EDay
                        },
                        {
                            name: 'Battery Discharge',
                            type: 'bar',
                            data: EDayBatChrg
                        },
                        {
                            name: 'Export to Grid',
                            type: 'bar',
                            data: EDayToGRID
                        },
                        {
                            name: 'Consumption',
                            type: 'bar',
                            data: EDayToLoad
                        },
                    ]

                }

                //渲染数据到页面
                const instance = energyChart.current.getEchartsInstance();
                if (instance) {
                    instance.setOption(energyOption, true);
                }
            })();
        } else if (size == 'year') { //年数据
            //坐标
            energyOption.xAxis.data = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
            energyOption.xAxis.name = '(Month)';
            energyOption.yAxis.name = '(kWh)';
            //请求数据
            (async () => {
                let resp = await http.post("integrated-inverter/report/yearly/report", {
                    SerialNumber: SN,
                    Date: moment(energyDate).format('YYYY-MM') + '-01'
                }).then(async (r) => {
                    return await r.json();
                }).catch((e) => {
                    return null;
                });

                if (!active) {
                    return;
                }

                if (resp === null || resp.code !== 200) { //请求年数据失败
                    setTip({ open: true, severity: "error", msg: (resp && resp.msg) ? resp.msg : "load inverter record error" });
                    return;
                } else {  //请求年数据成功
                    let EMon = Array(12).fill(0)
                    let EMonBatChrg = Array(12).fill(0)
                    let EMonToGRID = Array(12).fill(0)
                    let EMonToLoad = Array(12).fill(0)
                    resp.data.forEach(v => {
                        EMon[moment(v['YM']).month()] = calc.energy(v['EMon'], protocol)
                        EMonBatChrg[moment(v['YM']).month()] = calc.energy(v['EMonBatDischrg'], protocol)
                        EMonToGRID[moment(v['YM']).month()] = calc.energy(v['EMonToGRID'], protocol)
                        EMonToLoad[moment(v['YM']).month()] = calc.energy(v['EMonLOAD'], protocol)
                    })
                    //Echart坐标legend
                    energyOption.legend.data = ['Solar Production', 'Battery Discharge', 'Export to Grid', 'Consumption']
                    //Echart坐标数据
                    energyOption.series = [
                        {
                            name: 'Solar Production',
                            type: 'bar',
                            data: EMon
                        },
                        {
                            name: 'Battery Discharge',
                            type: 'bar',
                            data: EMonBatChrg
                        },
                        {
                            name: 'Export to Grid',
                            type: 'bar',
                            data: EMonToGRID
                        },
                        {
                            name: 'Consumption',
                            type: 'bar',
                            data: EMonToLoad
                        },
                    ]

                }

                //渲染数据到页面
                const instance = energyChart.current.getEchartsInstance();
                if (instance) {
                    instance.setOption(energyOption, true);
                }
            })();
        } else { //总体数据
            //显示近10年的数据
            const curYear = new Date().getFullYear()
            let days = Array(10).fill(0)
            days.fill(0).forEach((v, i) => {
                days[i] = curYear - 9 + i
            })
            //坐标
            energyOption.xAxis.data = days;
            energyOption.xAxis.name = '(Year)';
            energyOption.yAxis.name = '(kWh)';
            //取总体数据
            (async () => {
                let resp = await http.post("integrated-inverter/report/all-years/report", {
                    SerialNumber: SN,
                    Date: moment(energyDate).format('YYYY-MM') + '-01'
                }).then(async (r) => {
                    return await r.json();
                }).catch((e) => {
                    return null;
                });

                if (!active) {
                    return;
                }

                if (resp === null || resp.code !== 200) {  //请求总体数据失败
                    setTip({ open: true, severity: "error", msg: (resp && resp.msg) ? resp.msg : "load inverter record error" });
                    return;
                } else {  //请求总体数据成功
                    let EYear = Array(10)
                    let EYearBatChrg = Array(10)
                    let EYearToGRID = Array(10)
                    let EYearToLoad = Array(10)

                    resp.data.forEach(v => {
                        EYear[moment(v['Y']).year() - curYear + 9] = calc.energy(v['EYear'], protocol)
                        EYearBatChrg[moment(v['Y']).year() - curYear + 9] = calc.energy(v['EYearBatDischrg'], protocol)
                        EYearToGRID[moment(v['Y']).year() - curYear + 9] = calc.energy(v['EYearToGRID'], protocol)
                        EYearToLoad[moment(v['Y']).year() - curYear + 9] = calc.energy(v['EYearLOAD'], protocol)
                    })
                    //Echart坐标legend
                    energyOption.legend.data = ['Solar Production', 'Battery Discharge', 'Export to Grid', 'Consumption']
                    //Echart数据
                    energyOption.series = [
                        {
                            name: 'Solar Production',
                            type: 'bar',
                            data: EYear
                        },
                        {
                            name: 'Battery Discharge',
                            type: 'bar',
                            data: EYearBatChrg
                        },
                        {
                            name: 'Export to Grid',
                            type: 'bar',
                            data: EYearToGRID
                        },
                        {
                            name: 'Consumption',
                            type: 'bar',
                            data: EYearToLoad
                        },
                    ]

                }

                //渲染数据到页面
                const instance = energyChart.current.getEchartsInstance();
                if (instance) {
                    instance.setOption(energyOption, true);
                }
            })();
        }

        return () => {
            active = false;
        };

    }, [invData, size, energyDate, refresh]);

    const showMonitor = () => {
        if (!invData) return;
        props.handleConverToMonitor(invData.Batteries[0].ID, invData.Batteries[0].SN);
        //props.handleConverToMonitor('00195726-b8e6-4e74-a174-92470ce4345f', '1417607SLKOPGG08096');
        props.onNavigate('Monitor', (props) => {
            return <Monitor />
        }, "Inverter")
    }

    const handleClickSolar = () => {
        setSolarIndex((solarIndex + 1) % 3)
    }

    const handleClickBattery = () => {
        setBatteryIndex((batteryIndex + 1) % 2)
    }

    const handleClickGrid = () => {
        setGridIndex((gridIndex + 1) % 2)
    }

    //计算solar面板的百分比
    const calSolarRatio = (x, y) => {
        if (isNaN(x) || isNaN(y) || y == 0) {
            return 0
        }
        let r = 100 * x / y;
        if (r % 1 != 0) {
            r = r.toFixed(1)
        }
        return r
    }

    //图标url
    const pvImgUrl = "images/inverter/solar.png";
    const gridImgUrl = "images/inverter/grid.png";
    const statusImgUrl = "images/inverter/" + (invData && invData.IsOnline == 1 ? 
        invData.IsAlarm ? "flow_notice.png" : "flow_normal.png" : 
        "flow_offline.png")
    const epsImgUrl = "images/inverter/eps.png";
    const loadImgUrl = "images/inverter/load.png";
    // const invImgUrl = "images/inverter/flow_" + 
    //     (protocol=='Thinkpower' ? 'tp_inv_' : 'sen_inv_') + 
    //     (invData&&invData.Inverters ? invData.Inverters.length : 1) + 
    //     '.png';
    const invImgUrl = "images/inverter/" +
        (model == 'Uhome-INVT-3P-4~12kW' ? "H3EHB-" : 'L1EHB-') +
        (invData && invData.Inverters && invData.Inverters.length > 0 ? invData.Inverters.length : 1) +
        '.png';
    const soc = calc.soc(invData && invData.SOC, protocol);
    let soc_level = Math.round(soc / 20);
    if (soc_level > 5) {
        soc_level = 5;
    } else if (soc_level < 0) {
        soc_level = 0;
    }
    const batImgUrl = "images/inverter/flow_bat_" +
        (invData && invData.BatErrorCode ? 'alarm_' : '') +
        (invData && invData.Batteries ? invData.Batteries.length : 1) + "_" +
        (invData && soc_level ? soc_level : 0) +
        '.png';

    //处理数据
    let Ppv1, Ppv2, Ppv3, Ppv4, Vpv1, Vpv2, Vpv3, Vpv4,
        Pbat = 0, Vbat = 0, Ibat = 0,
        Pgrid1 = 0, Pgrid2 = 0, Pgrid3 = 0, Vgrid1 = 0, Vgrid2 = 0, Vgrid3 = 0, Fgrid = 0,
        Pload1 = 0, Pload2 = 0, Pload3 = 0,
        Peps1 = 0, Peps2 = 0, Peps3 = 0;

    let latestTime = invData && invData.DataTime; //多个并机逆变器的最新时间
    invData && invData.Inverters && invData.Inverters.forEach(inv => {
        if (inv.CreateTime > latestTime) latestTime = inv.CreateTime;
    })

    if (invData) {
        if (protocol === 'Senergy') {
            const inverters = invData.Inverters;
            Vpv1 = invData.VMPPT1;
            Vbat = invData.VBat;
            Ibat = invData.IBat;
            Vgrid1 = invData.VAC1GRID;
            Fgrid = invData.FACGRID;
            if (inverters && inverters.length > 1 && new Date(latestTime).getTime() - new Date(invData.DataTime).getTime() < 1000 * 60 * 8) { //多个逆变器并机, 只有数据时间相差8分钟内的才计算
                inverters.forEach(inv => {
                    const interval = new Date(latestTime).getTime() - new Date(inv.CreateTime).getTime();
                    if (interval < 1000 * 60 * 8) { //间隔时间在8分钟内视为同一时刻的数据
                        Ppv1 = inv.PDC != null && (Ppv1 || 0) + (inv.PDC || 0);
                        Pbat = inv.PBat != null && (Pbat || 0) + (inv.PBat || 0);
                        Pgrid1 = inv.PAC1GRID != null && (Pgrid1 || 0) + (inv.PAC1GRID || 0);
                        Pgrid2 = inv.PAC2GRID != null && (Pgrid2 || 0) + (inv.PAC2GRID || 0);
                        Pgrid3 = inv.PAC3GRID != null && (Pgrid3 || 0) + (inv.PAC3GRID || 0);
                        Pload1 = inv.PAC1LOAD != null && (Pload1 || 0) + (inv.PAC1LOAD || 0);
                        Pload2 = inv.PAC2LOAD != null && (Pload2 || 0) + (inv.PAC2LOAD || 0);
                        Pload3 = inv.PAC3LOAD != null && (Pload3 || 0) + (inv.PAC3LOAD || 0);
                        Peps1 = inv.PAC1EPS != null && (Peps1 || 0) + (inv.PAC1EPS || 0);
                    }
                })
            } else { //单个逆变器
                Ppv1 = invData.PDC;
                Pbat = invData.PBat;
                Pgrid1 = invData.PAC1GRID;
                Pload1 = invData.PAC1LOAD;
                Peps1 = invData.PAC1EPS;
            }
        } else if (protocol === 'Thinkpower' || protocol === 'Megarevo' || protocol === 'Sinexcel' || protocol === 'Megarevo-CESS' || protocol === 'Sungery') {
            const inverters = invData.Inverters;
            Vpv1 = invData.VMPPT1;
            Vpv2 = invData.VMPPT2;
            Vpv3 = invData.VMPPT3;
            Vpv4 = invData.VMPPT4;
            Vbat = invData.VBat;
            Ibat = invData.IBat;
            Vgrid1 = invData.VAC1GRID;
            Vgrid2 = invData.VAC2GRID;
            Vgrid3 = invData.VAC3GRID;
            Fgrid = invData.FACGRID;
            if (inverters && inverters.length > 1 && new Date(latestTime).getTime() - new Date(invData.CreateTime).getTime() < 1000 * 60 * 8) { //多个逆变器并机
                inverters.forEach(inv => {
                    const interval = new Date(latestTime).getTime() - new Date(inv.CreateTime).getTime();
                    if (interval < 1000 * 60 * 8) { //间隔时间在8分钟内视为同一时刻的数据
                        Ppv1 = inv.PMPPT1 != null && (Ppv1 || 0) + (inv.PMPPT1 || 0);
                        Ppv2 = inv.PMPPT2 != null && (Ppv2 || 0) + (inv.PMPPT2 || 0);
                        Ppv3 = inv.PMPPT3 != null && (Ppv3 || 0) + (inv.PMPPT3 || 0);
                        Ppv4 = inv.PMPPT4 != null && (Ppv4 || 0) + (inv.PMPPT4 || 0);
                        Pbat = inv.PBAT != null && (Pbat || 0) + (inv.PBAT || 0);
                        Pgrid1 = inv.PAC1GRID != null && (Pgrid1 || 0) + (inv.PAC1GRID || 0);
                        Pgrid2 = inv.PAC2GRID != null && (Pgrid2 || 0) + (inv.PAC2GRID || 0);
                        Pgrid3 = inv.PAC3GRID != null && (Pgrid3 || 0) + (inv.PAC3GRID || 0);
                        Pload1 = inv.PAC1LOAD != null && (Pload1 || 0) + (inv.PAC1LOAD || 0);
                        Pload2 = inv.PAC2LOAD != null && (Pload2 || 0) + (inv.PAC2LOAD || 0);
                        Pload3 = inv.PAC3LOAD != null && (Pload3 || 0) + (inv.PAC3LOAD || 0);
                        Peps1 = inv.PAC1EPS != null && (Peps1 || 0) + (inv.PAC1EPS || 0);
                        Peps2 = inv.PAC2EPS != null && (Peps2 || 0) + (inv.PAC2EPS || 0);
                        Peps3 = inv.PAC3EPS != null && (Peps3 || 0) + (inv.PAC3EPS || 0);
                    }
                })
            } else { //单个逆变器
                Ppv1 = invData.PMPPT1;
                Ppv2 = invData.PMPPT2;
                Ppv3 = invData.PMPPT3;
                Ppv4 = invData.PMPPT4;
                Pbat = invData.PBat;
                Pgrid1 = invData.PAC1GRID;
                Pgrid2 = invData.PAC2GRID;
                Pgrid3 = invData.PAC3GRID;
                Pload1 = invData.PAC1LOAD;
                Pload2 = invData.PAC2LOAD;
                Pload3 = invData.PAC3LOAD;
                Peps1 = invData.PAC1EPS;
                Peps2 = invData.PAC2EPS;
                Peps3 = invData.PAC3EPS;
            }
        }
    }

    //带时区的实时数据的时间
    let dataTime = invData ? invData.DataTime : null,
        gmtOffset = invData ? invData.GmtOffset : null;
    if (dataTime && gmtOffset) {
        let n = parseInt(gmtOffset);
        var tz = "UTC+";
        if(n < 0) {
            tz = "UTC-";
            n = Math.abs(n);
        }
        tz += Math.floor(n / 3600).toString().padStart(2, "0") + ":" + Math.floor((n % 3600) / 60).toString().padStart(2, "0")
        dataTime += '(' + tz + ')';
    }

    //流图的方向   
    /*
    * Senergy: PBat(> 0: 放电, < 0: 充电), PGrid(> 0: 购电, < 0: 馈网)
    * 以下与Senergy比较, √同, ×反
    * Megarevo[-CESS]: Bat ×, Grid ×
    * Thinkpower: Bat √, Grid ×
    * Sinexcel: Bat ×, Grid √
    * Sungery: Bat √, Grid √
    * 注: Senergy单相, Thinkpower与Megarevo三相
    * 注: Charge/Discharge: 充电/放电, Export/Import: 馈网/购电
    */
    let PVToInv = false,        //PV->Inv
        InvToBat = false,       //Inv->Bat
        BatToInv = false,       //Bat->Inv
        InvToEPS = false,       //Inv->Eps
        EPSToInv = false,       //Eps->Inv
        FromGrid = false,       //购电
        ToGrid = false,         //售电
        FromInv = false,        //Inv -> (Grid / Load)
        ToInv = false,          //(Grid) -> Inv
        ToLoad = false;         //Load有功率
    //判断流向
    PVToInv = protocol === 'Senergy' ?
        Ppv1 > 0 :
        (Ppv1||0) + (Ppv2||0) + (Ppv3||0) + (Ppv4||0) > 0
    Ibat = calc.current(Ibat, protocol);  //处理Sungery电池功率的方向
    BatToInv = protocol === 'Senergy' || protocol === 'Thinkpower' || protocol === 'Sungery' ?
                Pbat > 0 :
                Pbat < 0;
    InvToBat = protocol === 'Senergy' || protocol === 'Thinkpower' || protocol === 'Sungery' ?
                Pbat < 0 :
                Pbat > 0;
    EPSToInv = Peps1 + Peps2 + Peps3 > 0;
    InvToEPS = Peps1 + Peps2 + Peps3 < 0;
    ToLoad = Pload1 + Pload2 + Pload3 > 0;
    FromGrid = protocol === 'Senergy' || protocol === 'Sinexcel' || protocol === 'Sungery' ?
        Pgrid1 + Pgrid2 + Pgrid3 > 0 :
        Pgrid1 + Pgrid2 + Pgrid3 < 0;
    ToGrid = protocol === 'Senergy' || protocol === 'Sinexcel' || protocol === 'Sungery' ?
        Pgrid1 + Pgrid2 + Pgrid3 < 0 :
        Pgrid1 + Pgrid2 + Pgrid3 > 0;
    FromInv = ToGrid || ((PVToInv || BatToInv) && Math.abs(Pgrid1+Pgrid2+Pgrid3) < Pload1+Pload2+Pload3);
    ToInv = FromGrid && Math.abs(Pgrid1+Pgrid2+Pgrid3) > Pload1+Pload2+Pload3;

    //inv系统流图
    const bms_sys_flow = (
        <div className="flow-content">

            {/* 流动的箭头 */}
            <img src="images/inverter/flow_down_1.gif" style={{ top: '80px', left: '315px', display: PVToInv ? 'block' : 'none' }} /> {/* pv->inv */}
            <img src="images/inverter/flow_down_1.gif" style={{ top: '268px', left: '315px', display: InvToEPS ? 'block' : 'none' }} /> {/* inv->eps */}
            <img src="images/inverter/flow_up.gif" style={{ top: '272px', left: '315px', display: EPSToInv ? 'block' : 'none' }} /> {/* eps->inv */}
            <img src="images/inverter/flow_right_2.gif" style={{ top: '206px', left: '106px', display: BatToInv ? 'block' : 'none' }} />  {/* bat->inv */}
            <img src="images/inverter/flow_left_2.gif" style={{ top: '206px', left: '106px', display: InvToBat ? 'block' : 'none' }} />  {/* inv->bat */}
            <img src="images/inverter/flow_down_2.gif" style={{ top: '214px', left: '584px', display: (ToLoad && (FromGrid || FromInv)) ? 'block' : 'none' }} /> {/* ->Load */}
            <img src="images/inverter/flow_left_4.gif" style={{ top: '206px', left: '378px', display: FromGrid && ToInv ? 'block' : 'none' }} /> {/* Grid->Inv */}
            <img src="images/inverter/flow_right_4.gif" style={{ top: '206px', left: '380px', display: FromInv && ToGrid ? 'block' : 'none' }} /> {/* inv->grid */}
            <img src="images/inverter/flow_left_1.gif" style={{ top: '206px', left: '596px', display: FromGrid && ToLoad && !ToInv ? 'block' : 'none' }} /> {/* Grid->Load not to Inv */}
            <img src="images/inverter/flow_right_3.gif" style={{ top: '206px', left: '380px', display: FromInv && ToLoad && !ToGrid ? 'block' : 'none' }} /> {/* Inv->Load not to Grid */}
            
            {/* 图标 */}
            <img src={pvImgUrl} alt="" width="84" height="84" style={{ top: '-9px', left: '288px' }} />
            <img src={statusImgUrl} alt="" height="32" style={{ top: '12px', left: '700px', }} />
            <Button icon={<ReloadOutlined />} style={{ position: 'absolute', top: '12px', left: '850px' }} onClick={() => setRefresh(!refresh)} />
            <img src={batImgUrl} alt="" width="84" height="84" style={{ top: '172px', left: '40px', cursor: invData ? 'pointer' : null }} onClick={() => invData && setShowBatteriesList(true)} />
            <img src={invImgUrl} alt="" width="112" style={{ top: '162px', left: '272px', cursor: invData ? 'pointer' : null }} onClick={() => invData && setShowInvertersList(true)} />
            <img src={gridImgUrl} alt="" width="84" height="84" style={{ top: '172px', left: '720px' }} />
            <img src={epsImgUrl} alt="" width="84" height="84" style={{ bottom: '15px', left: '288px' }} />
            <img src={loadImgUrl} alt="" width="84" height="84" style={{ bottom: '20px', left: '550px' }} />

            {/* PV数据 */}
            <div style={{ width: '360px', top: protocol === 'Senergy' ? '12px' : '-25px', left: '386px' }}>
                <div>
                    <span style={{ marginRight: '50px' }}>PV1</span>
                    <span style={{ marginRight: '50px' }}>{calc.power(Ppv1, protocol)} W</span>
                    <span>{calc.voltage(Vpv1, protocol)} V</span>
                </div>
                <div style={{display: protocol === 'Senergy' ? 'none' : 'block'}}>
                    <span style={{ marginRight: '50px' }}>PV2</span>
                    <span style={{ marginRight: '50px' }}>{calc.power(Ppv2, protocol)} W</span>
                    <span>{calc.voltage(Vpv2, protocol)} V</span>
                </div>
                <div style={{display: protocol === 'Senergy' ? 'none' : 'block'}}>
                    <span style={{ marginRight: '50px' }}>PV3</span>
                    <span style={{ marginRight: '50px' }}>{calc.power(Ppv3, protocol)} W</span>
                    <span>{calc.voltage(Vpv3, protocol)} V</span>
                </div>
                <div style={{display: protocol === 'Senergy' ? 'none' : 'block'}}>
                    <span style={{ marginRight: '50px' }}>PV4</span>
                    <span style={{ marginRight: '50px' }}>{calc.power(Ppv4, protocol)} W</span>
                    <span>{calc.voltage(Vpv4, protocol)} V</span>
                </div>
            </div>
            {/* 电池数据 */}
            <div style={{ top: '172px', marginLeft: '-30px' }}>
                <div>{calc.power(Pbat, protocol)} W</div>
                <div>{soc}%</div>
                <div>{calc.voltage(Vbat, protocol)} Vdc</div>
            </div>
            {/* Grid数据 */}
            {/* 若为Senergy且电网B相或C相功率不为0, 则视为一相并为三相, 按三相显示 */}
            {
                protocol === 'Thinkpower' || protocol === 'Megarevo' || protocol === 'Sinexcel' || protocol === 'Megarevo-CESS' || protocol === 'Sungery' ||
                    (protocol === 'Senergy' && Pgrid2 != null && Pgrid2 != 0) ||
                    (protocol === 'Senergy' && Pgrid3 != null && Pgrid3 != 0) ?
                    (
                        <div style={{ top: '160px', left: '805px' }}>
                            <div>
                                <span style={{ marginRight: '20px' }}>{calc.power(Pgrid1, protocol)} W</span>
                                <span style={{ marginRight: '20px' }}>{calc.voltage(Vgrid1, protocol)} Vac</span>
                                <span>{calc.frequency(Fgrid, protocol)} Hz</span>
                            </div>
                            <div>
                                <span style={{ marginRight: '20px' }}>{calc.power(Pgrid2, protocol)} W</span>
                                <span style={{ marginRight: '20px' }}>{calc.voltage(Vgrid2, protocol)} Vac</span>
                                <span>{calc.frequency(Fgrid, protocol)} Hz</span>
                            </div>
                            <div>
                                <span style={{ marginRight: '20px' }}>{calc.power(Pgrid3, protocol)} W</span>
                                <span style={{ marginRight: '20px' }}>{calc.voltage(Vgrid3, protocol)} Vac</span>
                                <span>{calc.frequency(Fgrid, protocol)} Hz</span>
                            </div>
                        </div>
                    ) : (
                        <div style={{ top: '200px', left: '805px' }}>
                            <span style={{ marginRight: '20px' }}>{calc.power(Pgrid1, protocol)} W</span>
                            <span style={{ marginRight: '20px' }}>{calc.voltage(Vgrid1, protocol)} Vac</span>
                            <span>{calc.frequency(Fgrid, protocol)} Hz</span>
                        </div>
                    )
            }
            {/* Eps数据 */}
            {
                protocol === 'Thinkpower' || protocol === 'Megarevo' || protocol === 'Sinexcel' || protocol === 'Megarevo-CESS' || protocol === 'Sungery' ?
                    (
                        <div style={{ top: '320px', left: '376px' }}>
                            <div>{calc.power(Peps1, protocol)} W</div>
                            <div>{calc.power(Peps2, protocol)} W</div>
                            <div>{calc.power(Peps3, protocol)} W</div>
                            <div style={{ color: '#a6b7bf', fontSize: '16px' }}>Backup Power(EPS)</div>
                        </div>
                    ) : (
                        <div style={{ top: '380px', left: '370px' }}>
                            <div>{calc.power(Peps1 + Peps2 + Peps3, protocol)} W</div>
                            <div style={{ color: '#a6b7bf', fontSize: '16px' }}>Backup Power(EPS)</div>
                        </div>
                    )
            }
            {/* Load数据 */}
            {
                protocol === 'Thinkpower' || protocol === 'Megarevo' || protocol === 'Sinexcel' || protocol === 'Megarevo-CESS' || protocol === 'Sungery' ?
                    (
                        <div style={{ top: '315px', left: '640px' }}>
                            <div>{calc.power(Pload1, protocol)} W</div>
                            <div>{calc.power(Pload2, protocol)} W</div>
                            <div>{calc.power(Pload3, protocol)} W</div>
                            <div style={{ color: '#a6b7bf', fontSize: '16px' }}>Consumption Power</div>
                        </div>
                    ) : (
                        <div style={{ top: '375px', left: '640px' }}>
                            <div>{calc.power(Pload1 + Pload2 + Pload3, protocol)} W</div>
                            <div style={{ color: '#a6b7bf', fontSize: '16px' }}>Consumption Power</div>
                        </div>
                    )
            }
            <div style={{ top: '400px', left: '-80px' }}>  {/* Time */}
                <div>{dataTime}</div>
            </div>

        </div>
    )

    //balcony系统流图
    const balcony_sys_flow = (
        <div className="flow-content">
            {/* 图标 */}
            <img src="images/inverter/balcony_pv.png" alt="pv" width="70" style={{ top: '0px', left: '100px' }} />
            <img src="images/inverter/balcony_pcs.png" alt="pcs" width="70" style={{ top: '0px', left: '400px' }} />
            <img src="images/inverter/flow_bat_1.png" alt="bat" width="80" style={{ top: '180px', left: '100px' }} />
            <img src="images/inverter/flow_load.png" alt="grid" width="80" style={{ top: '180px', left: '400px' }} />
            <img src="images/inverter/balcony_acdc.png" alt="acdc" width="70" style={{ top: '360px', left: '400px' }} />
            <img src="images/inverter/balcony_grid.png" alt="load" width="70" style={{ top: '360px', left: '700px' }} />


            {/* 流图 */}
            {/* PV->Bat */}
            <img src="images/inverter/flow_down.gif" style={{ top: '88px', left: '124px', display: balconyPVToBat ? 'block' : 'none' }} />
            {/* PV->PCS->Grid */}
            <img src="images/inverter/flow_right.gif" style={{ top: '36px', left: '180px', display: balconyPVToGrid ? 'block' : 'none' }} />
            <img src="images/inverter/flow_right.gif" style={{ top: '36px', left: '240px', display: balconyPVToGrid ? 'block' : 'none' }} />
            <img src="images/inverter/flow_right.gif" style={{ top: '36px', left: '300px', display: balconyPVToGrid ? 'block' : 'none' }} />
            <img src="images/inverter/flow_right.gif" style={{ top: '36px', left: '480px', display: balconyPVToGrid ? 'block' : 'none' }} />
            <img src="images/inverter/flow_right.gif" style={{ top: '36px', left: '560px', display: balconyPVToGrid ? 'block' : 'none' }} />
            <img src="images/inverter/flow_right.gif" style={{ top: '36px', left: '636px', display: balconyPVToGrid ? 'block' : 'none' }} />
            <img src="images/inverter/flow_down.gif" style={{ top: '56px', left: '716px', display: balconyPVToGrid ? 'block' : 'none' }} />
            <img src="images/inverter/flow_down.gif" style={{ top: '122px', left: '716px', display: balconyPVToGrid ? 'block' : 'none' }} />
            <img src="images/inverter/flow_down.gif" style={{ top: '188px', left: '716px', display: balconyPVToGrid ? 'block' : 'none' }} />
            <img src="images/inverter/flow_down.gif" style={{ top: '254px', left: '716px', display: balconyPVToGrid ? 'block' : 'none' }} />
            {/* PV->PCS->Load */}
            <img src="images/inverter/flow_down.gif" style={{ top: '88px', left: '430px', display: balconyPVToLoad ? 'block' : 'none' }} />
            {/* Bat->ACDC->Load */}
            <img src="images/inverter/flow_down.gif" style={{ top: '268px', left: '124px', display: balconyBatToLoad ? 'block' : 'none' }} />
            <img src="images/inverter/flow_down.gif" style={{ top: '328px', left: '124px', display: balconyBatToLoad ? 'block' : 'none' }} />
            <img src="images/inverter/flow_right.gif" style={{ top: '394px', left: '154px', display: balconyBatToLoad ? 'block' : 'none' }} />
            <img src="images/inverter/flow_right.gif" style={{ top: '394px', left: '224px', display: balconyBatToLoad ? 'block' : 'none' }} />
            <img src="images/inverter/flow_right.gif" style={{ top: '394px', left: '294px', display: balconyBatToLoad ? 'block' : 'none' }} />
            <img src="images/inverter/flow_down.gif" style={{ top: '264px', left: '430px', display: balconyBatToLoad ? 'block' : 'none', transform: 'rotate(180deg)' }} />
            {/* Grid->ACDC->Bat */}
            <img src="images/inverter/flow_left.gif" style={{ top: '394px', left: '600px', display: balconyGridToBat ? 'block' : 'none' }} />
            <img src="images/inverter/flow_left.gif" style={{ top: '394px', left: '540px', display: balconyGridToBat ? 'block' : 'none' }} />
            <img src="images/inverter/flow_left.gif" style={{ top: '394px', left: '480px', display: balconyGridToBat ? 'block' : 'none' }} />
            <img src="images/inverter/flow_left.gif" style={{ top: '394px', left: '294px', display: balconyGridToBat ? 'block' : 'none' }} />
            <img src="images/inverter/flow_left.gif" style={{ top: '394px', left: '224px', display: balconyGridToBat ? 'block' : 'none' }} />
            <img src="images/inverter/flow_left.gif" style={{ top: '394px', left: '154px', display: balconyGridToBat ? 'block' : 'none' }} />
            <img src="images/inverter/flow_down.gif" style={{ top: '268px', left: '124px', display: balconyGridToBat ? 'block' : 'none', transform: 'rotate(180deg)' }} />
            <img src="images/inverter/flow_down.gif" style={{ top: '328px', left: '124px', display: balconyGridToBat ? 'block' : 'none', transform: 'rotate(180deg)' }} />
            {/* Grid->ACDC->Load */}
            <img src="images/inverter/flow_left.gif" style={{ top: '394px', left: '600px', display: balconyGridToLoad ? 'block' : 'none' }} />
            <img src="images/inverter/flow_left.gif" style={{ top: '394px', left: '540px', display: balconyGridToLoad ? 'block' : 'none' }} />
            <img src="images/inverter/flow_left.gif" style={{ top: '394px', left: '480px', display: balconyGridToLoad ? 'block' : 'none' }} />
            <img src="images/inverter/flow_down.gif" style={{ top: '264px', left: '430px', display: balconyGridToLoad ? 'block' : 'none', transform: 'rotate(180deg)' }} />
        </div>
    )

    return (
        <div className="runtime-monitor">
            <div className="search">   {/* 搜索栏 */}
                <span>Select Inverter</span>
                <Select
                    showSearch
                    placeholder="Enter Inverter Name"
                    defaultActiveFirstOption={false}
                    showArrow={true}
                    filterOption={true}
                    onSelect={(value) => handleSelectSN(value)}
                    value={SN}
                    style={{ width: '200px' }}
                >{inverterSelectOptions}</Select>
            </div>
            <div className="panel">    {/* 面板 */}
                <div className="infoBlock">     {/* Solar Yielding */}
                    <div className="infoTitle" style={{ backgroundColor: '#82b2c7' }}>
                        Solar Yielding
                    </div>
                    <div className="infoContent" onClick={handleClickSolar} style={{ display: solarIndex == 0 ? 'block' : 'none' }}>
                        <div className="content-left">
                            <img src="images/inverter/solar.png"></img>
                        </div>
                        <div className="content-right">
                            <div style={{ fontSize: '18px' }}>{calc.energy(invData && invData.EDay, protocol)} kWh</div>
                            <div style={{ fontSize: '14px', color: '#a6b7bf' }}>Today Yielding</div>
                            <div style={{ fontSize: '18px' }}>{calc.energy(invData && invData.ETotal, protocol)} kWh</div>
                            <div style={{ fontSize: '14px', color: '#a6b7bf' }}>Total Yielding</div>
                        </div>
                    </div>
                    <div className="infoContent" onClick={handleClickSolar} style={{ display: solarIndex == 1 ? 'block' : 'none' }}>
                        <div className="content-left">
                            <div style={{ color: '#FF718F', fontSize: '14px', marginBottom: '5px' }}>
                                {invData ? calSolarRatio(invData.EDayLOAD, invData.EDay) : '--'}% Load Today
                            </div>
                            <div style={{ color: '#5CC9A0', fontSize: '14px', marginBottom: '5px' }}>
                                {invData ? calSolarRatio(invData.EDayBatChrg, invData.EDay) : '--'}% Charge Today
                            </div>
                            <div style={{ color: '#F2A474', fontSize: '14px', marginBottom: '5px' }}>
                                {invData ? calSolarRatio(invData.EDayToGrid, invData.EDay) : '--'}% Export Today
                            </div>
                            <div style={{ fontWeight: 'bold', fontSize: '16px' }}>Today {calc.energy(invData && invData.EDay, protocol)} kWh</div>
                        </div>
                        <div className="content-right">
                            {invData && invData.EDay != 0 ? <ReactECharts ref={chart1} option={option1} className="chart" /> : null}
                        </div>
                    </div>
                    <div className="infoContent" onClick={handleClickSolar} style={{ display: solarIndex == 2 ? 'block' : 'none' }}>
                        <div className="content-left">
                            <div style={{ color: '#FF718F', fontSize: '14px', marginBottom: '5px' }}>
                                {invData ? calSolarRatio(invData.ETotalLOAD, invData.ETotal) : '--'}% Load Total
                            </div>
                            <div style={{ color: '#5CC9A0', fontSize: '14px', marginBottom: '5px' }}>
                                {invData ? calSolarRatio(invData.ETotalBatChrg, invData.ETotal) : '--'}% Charge Total
                            </div>
                            <div style={{ color: '#F2A474', fontSize: '14px', marginBottom: '5px' }}>
                                {invData ? calSolarRatio(invData.ETotalToGrid, invData.ETotal) : '--'}% Export Total
                            </div>
                            <div style={{ fontWeight: 'bold', fontSize: '16px' }}>Total {calc.energy(invData && invData.ETotal, protocol)} kWh</div>
                        </div>
                        <div className="content-right">
                            {invData && invData.ETotal != 0 ? <ReactECharts ref={chart2} option={option2} className="chart" /> : null}
                        </div>
                    </div>
                </div>
                <div className="infoBlock">     {/* battery Charge/Discharge */}
                    <div className="infoTitle" style={{ backgroundColor: '#d6a2ae' }}>
                        {batteryIndex == 0 ? 'Battery Charge' : 'Battery Discharge'}
                    </div>
                    <div className="infoContent" onClick={handleClickBattery} style={{ display: batteryIndex == 0 ? 'block' : 'none' }}>
                        <div className="content-left">
                            <img src="images/inverter/battery_charge.png"></img>
                        </div>
                        <div className="content-right">
                            <div style={{ fontSize: '18px' }}>{calc.energy(invData && invData.EDayBatChrg, protocol)} kWh</div>
                            <div style={{ fontSize: '14px', color: '#a6b7bf' }}>Today Charge</div>
                            <div style={{ fontSize: '18px' }}>{calc.energy(invData && invData.ETotalBatChrg, protocol)} kWh</div>
                            <div style={{ fontSize: '14px', color: '#a6b7bf' }}>Total Charge</div>
                        </div>
                    </div>
                    <div className="infoContent" onClick={handleClickBattery} style={{ display: batteryIndex == 1 ? 'block' : 'none' }}>
                        <div className="content-left">
                            <img src="images/inverter/battery_discharge.png"></img>
                        </div>
                        <div className="content-right">
                            <div style={{ fontSize: '18px' }}>{calc.energy(invData && invData.EDayBatDischrg, protocol)} kWh</div>
                            <div style={{ fontSize: '14px', color: '#a6b7bf' }}>Today Discharge</div>
                            <div style={{ fontSize: '18px' }}>{calc.energy(invData && invData.ETotalBatDischrg, protocol)} kWh</div>
                            <div style={{ fontSize: '14px', color: '#a6b7bf' }}>Total Discharge</div>
                        </div>
                    </div>
                </div>
                <div className="infoBlock">     {/* Feed-in Energy / Import */}
                    <div className="infoTitle" style={{ backgroundColor: '#92b5c9' }}>
                        {gridIndex == 0 ? 'Feed-in Energy' : 'Import'}
                    </div>
                    <div className="infoContent" onClick={handleClickGrid} style={{ display: gridIndex == 0 ? 'block' : 'none' }}>
                        <div className="content-left">
                            <img src="images/inverter/grid_export.png"></img>
                        </div>
                        <div className="content-right">
                            <div style={{ fontSize: '18px' }}>{calc.energy(invData && invData.EDayToGrid, protocol)} kWh</div>
                            <div style={{ fontSize: '14px', color: '#a6b7bf' }}>Today Export</div>
                            <div style={{ fontSize: '18px' }}>{calc.energy(invData && invData.ETotalToGrid, protocol)} kWh</div>
                            <div style={{ fontSize: '14px', color: '#a6b7bf' }}>Total Export</div>
                        </div>
                    </div>
                    <div className="infoContent" onClick={handleClickGrid} style={{ display: gridIndex == 1 ? 'block' : 'none' }}>
                        <div className="content-left">
                            <img src="images/inverter/grid_import.png"></img>
                        </div>
                        <div className="content-right">
                            <div style={{ fontSize: '18px' }}>{calc.energy(invData && invData.EDayFromGrid, protocol)} kWh</div>
                            <div style={{ fontSize: '14px', color: '#a6b7bf' }}>Today Import</div>
                            <div style={{ fontSize: '18px' }}>{calc.energy(invData && invData.ETotalFromGrid, protocol)} kWh</div>
                            <div style={{ fontSize: '14px', color: '#a6b7bf' }}>Total Import</div>
                        </div>
                    </div>
                </div>
                <div className="infoBlock">     {/* Consumption */}
                    <div className="infoTitle" style={{ backgroundColor: '#6fad92' }}>
                        Consumption
                    </div>
                    <div className="infoContent">
                        <div className="content-left">
                            <img src="images/inverter/load.png"></img>
                        </div>
                        <div className="content-right">
                            <div style={{ fontSize: '18px' }}>{calc.energy(invData && invData.EDayLOAD, protocol)} kWh</div>
                            <div style={{ fontSize: '14px', color: '#a6b7bf' }}>Today Usage</div>
                            <div style={{ fontSize: '18px' }}>{calc.energy(invData && invData.ETotalLOAD, protocol)} kWh</div>
                            <div style={{ fontSize: '14px', color: '#a6b7bf' }}>Total Usage</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="flow">     {/* System Information */}
                <div className="flow-title">System Information</div>
                {bms_sys_flow}
            </div>
            {/* related-inverters */}
            {invData && invData.Inverters && invData.Inverters.length > 1 &&
                <div className="related-inverters">
                    <Table
                        columns={[{
                            title: 'SN',
                            width: 250,
                            dataIndex: 'SN',
                        }].concat(
                            protocol === 'Senergy' ?
                                [{
                                    title: 'Vpv',
                                    width: 150,
                                    dataIndex: 'VMPPT1',
                                    render: (v, r) => {
                                        return v != null ? calc.voltage(v, protocol) + 'V' : '--'
                                    }
                                }, {
                                    title: 'Ppv',
                                    width: 150,
                                    dataIndex: 'PDC',
                                    render: (v, r) => {
                                        return v != null ? calc.power(v, protocol) + 'W' : '--'
                                    }
                                }] :
                                [{
                                    title: 'Vpv1',
                                    width: 150,
                                    dataIndex: 'VMPPT1',
                                    render: (v, r) => {
                                        return v != null ? calc.voltage(v, protocol) + 'V' : '--'
                                    }
                                }, {
                                    title: 'Ppv1',
                                    width: 150,
                                    dataIndex: 'PMPPT1',
                                    render: (v, r) => {
                                        return v != null ? calc.power(v, protocol) + 'W' : '--'
                                    }
                                }, {
                                    title: 'Vpv2',
                                    width: 150,
                                    dataIndex: 'VMPPT2',
                                    render: (v, r) => {
                                        return v != null ? calc.voltage(v, protocol) + 'V' : '--'
                                    }
                                }, {
                                    title: 'Ppv2',
                                    width: 150,
                                    dataIndex: 'PMPPT2',
                                    render: (v, r) => {
                                        return v != null ? calc.power(v, protocol) + 'W' : '--'
                                    }
                                }, {
                                    title: 'Vpv3',
                                    width: 150,
                                    dataIndex: 'VMPPT3',
                                    render: (v, r) => {
                                        return v != null ? calc.voltage(v, protocol) + 'V' : '--'
                                    }
                                }, {
                                    title: 'Ppv3',
                                    width: 150,
                                    dataIndex: 'PMPPT3',
                                    render: (v, r) => {
                                        return v != null ? calc.power(v, protocol) + 'W' : '--'
                                    }
                                }]).concat([{
                                    title: 'SOC',
                                    width: 150,
                                    dataIndex: 'SOC',
                                    render: (v, r) => {
                                        return v != null ? calc.soc(v, protocol) + '%' : '--'
                                    }
                                }, {
                                    title: 'Vbat',
                                    width: 150,
                                    dataIndex: 'VBat',
                                    render: (v, r) => {
                                        return v != null ? calc.voltage(v, protocol) + 'V' : '--'
                                    }
                                }, {
                                    title: 'Pcharge',
                                    width: 150,
                                    dataIndex: 'PBat',
                                    render: (v, r) => {
                                        if (protocol === 'Senergy' || protocol === 'Thinkpower' || protocol === 'Sungery') {
                                            return v < 0 ? Math.abs(calc.power(v, protocol)) + 'W' : '0W'
                                        } else if (protocol === 'Megarevo' || protocol === 'Sinexcel' || protocol === 'Megarevo-CESS') {
                                            return v > 0 ? calc.power(v, protocol) + 'W' : '0W'
                                        } else {
                                            return '0W'
                                        }
                                    }
                                }, {
                                    title: 'Pdischarge',
                                    width: 150,
                                    dataIndex: 'PBat',
                                    render: (v, r) => {
                                        if (protocol === 'Senergy' || protocol === 'Thinkpower' || protocol === 'Sungery') {
                                            return v > 0 ? calc.power(v, protocol) + 'W' : '0W'
                                        } else if (protocol === 'Megarevo' || protocol === 'Sinexcel' || protocol === 'Megarevo-CESS') {
                                            return v < 0 ? Math.abs(calc.power(v, protocol)) + 'W' : '0W'
                                        } else {
                                            return '0W'
                                        }
                                    }
                                }, {
                                    title: 'Peps',
                                    width: 150,
                                    dataIndex: 'PAC1EPS',
                                    render: (v, r) => {
                                        return v != null ? calc.power(r['PAC1EPS'] + r['PAC2EPS'] + r['PAC3EPS'], protocol) + 'W' : '0W'
                                    }
                                }, {
                                    title: 'DataTime',
                                    width: 250,
                                    dataIndex: 'CreateTime',
                                    render: (v, r) => {
                                        return <span>{moment(v).format('YYYY-MM-DD HH:mm:ss')}</span>
                                    }
                                }])}
                        dataSource={invData && invData.Inverters}
                        size='small'
                        pagination={false}
                    />
                </div>
            }
            <div>
                <div className="power">    {/* Input&Output Power */}
                    <div className="power-title">Input&Output Power</div>
                    <div className="power-powerDate">
                        <DatePicker
                            defaultValue={moment()}
                            value={moment(powerDate)}
                            onChange={(_, ds) => setPowerDate(ds)}
                            allowClear={false}
                        />
                    </div>
                    <div className="power-chart">
                        <ReactECharts ref={powerChart} option={powerOption} style={{ height: 320 }} />
                    </div>
                </div>
                <div className="siderbar">  {/* notice 和 revenue */}
                    <div className="notice">
                        <div className="notice-title">Notice</div>
                        <div className="notice-content">None</div>
                    </div>
                    <div className="revenue">
                        <div className="revenue-title">Other Revenue</div>
                        <div className="revenue-box CO2">
                            <img height="72" src="images/inverter/co2.png"></img>
                            <div className="box-num">
                                <span>{invData && invData.ETotal > 0 ? ((protocol == 'Thinkpower' ? invData.ETotal / 10 : invData.ETotal) / 1.003).toFixed(2) : 0} kG</span><br />
                                <span>CO<sub>2</sub> Reduction</span>
                            </div>
                        </div>
                        <div className="revenue-box coal">
                            <img height="72" src="images/inverter/coal.png"></img>
                            <div className="box-num">
                                <span>{invData && invData.ETotal > 0 ? ((protocol == 'Thinkpower' ? invData.ETotal / 10 : invData.ETotal) / 2.5).toFixed(2) : 0} kG</span><br />
                                <span>Coal Saving</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="energy">    {/* Energy Overview */}
                <div className="energy-title">
                    Energy Overview {size == 'month' ? '(' + moment(energyDate).format('YYYY-MM') + ')' : size == 'year' ? '(' + moment(energyDate).format('YYYY') + ')' : null}
                </div>
                <div className="energy-date" style={{ display: size == 'total' ? 'none' : 'block' }}>
                    <DatePicker
                        picker={size == "month" ? 'month' : 'year'}
                        onChange={(_, ds) => setEnergyDate(ds)}
                        defaultValue={moment()}
                        value={moment(energyDate)}
                        allowClear={false}
                    />
                </div>
                <div className="energy-btn">
                    <Radio.Group value={size} optionType='button' buttonStyle='solid' onChange={e => setSize(e.target.value)}>
                        <Radio.Button value="month">Month</Radio.Button>
                        <Radio.Button value="year">Year</Radio.Button>
                        <Radio.Button value="total">Total</Radio.Button>
                    </Radio.Group>
                </div>
                <div className="energy-chart">
                    <ReactECharts ref={energyChart} option={energyOption} style={{ height: 360 }} />
                </div>
            </div>

            {/* 点击电池时显示电池列表 */}
            <Modal
                title="Batteries List"
                visible={showBatteriesList}
                cancelText="cancel"
                okText="OK"
                mask={true}
                maskClosable={false}
                width={420}
                footer={null}
                onCancel={() => setShowBatteriesList(false)}
            >
                <Table
                    columns={[{
                        title: "",
                        width: 20,
                        fixed: 'left',
                        render: (v, r, idx) => {
                            return idx + 1
                        }
                    }, {
                        title: "SN",
                        dataIndex: "SN",
                        width: 100,
                    }, {
                        title: 'Action',
                        key: 'Action',
                        width: 40,
                        render: (v, r, idx) => {
                            return (
                                <Button type="link" onClick={() => {
                                    props.handleConverToMonitor(r.ID, r.SN);
                                    props.onNavigate('Monitor', () => {
                                        return <Monitor />
                                    }, "Inverter")
                                }}>Monitor</Button>
                            )
                        }
                    }]}
                    tableLayout="fixed"
                    bordered={true}
                    size="small"
                    pagination={false}
                    rowKey="ID"
                    dataSource={invData && invData.Batteries}
                />
            </Modal>
            {/* 点击逆变器时显示逆变器列表 */}
            <Modal
                title="Inverters List"
                visible={showInvertersList}
                cancelText="cancel"
                okText="OK"
                mask={true}
                maskClosable={false}
                width={420}
                footer={null}
                onCancel={() => setShowInvertersList(false)}
            >
                <Table
                    columns={[{
                        title: "",
                        width: 20,
                        fixed: 'left',
                        render: (v, r, idx) => {
                            return idx + 1
                        }
                    }, {
                        title: "SN",
                        dataIndex: "SN",
                        width: 100,
                    }]}
                    tableLayout="fixed"
                    bordered={true}
                    size="small"
                    pagination={false}
                    rowKey="ID"
                    dataSource={invData && invData.Inverters}
                />
            </Modal>
        </div>
    )
}

export default RealtimeMonitor;