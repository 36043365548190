import http from '../Http';
import { useContext, useState, useRef, useEffect } from 'react';
import utils from '../utils';
import { Modal, Button, Input, Form, Select, Switch, Space } from 'antd';
import { PlusOutlined, MinusCircleOutlined } from '@ant-design/icons';
import InputPrefix from '../utils/InputPrefix';

function DeviceAdd(props) {
    const setTip = useContext(utils.tipContext);

    const { show, onClose } = props;

    const [form] = Form.useForm();

    const [productSelectOptions, setProductSelectOptions] = useState([]);
    const [distributorSelectOptions, setDistributorSelectOptions] = useState([]);
    const [inverterManufacturerSelectOptions, setInverterManufacturerSelectOptions] = useState([]);
    const [productCategory, setProductCategory] = useState(0);
    const [timezoneSelectOptions, setTimezoneSelectOptions] = useState([]);
    const [inverterManufacturer, setInverterManufacturer] = useState(null)
    const [modelOptions, setModelOptions] = useState([]);

    const refTimerProductSearcher = useRef(null);
    const refProductSearchValue = useRef(null);
    const refTimerDistributorSearcher = useRef(null);
    const refDistributorSearchValue = useRef(null);
    const refTimerInverterManufacturerSearcher = useRef(null);
    const refInverterManufacturerSearchValue = useRef(null);
    const refTimerTimezoneSearcher = useRef(null);
    const refTimezoneSearchValue = useRef(null);

    useEffect(() => {
        if(inverterManufacturer !== 'Uhome') {
            setModelOptions([])
            return;
        }

        let active = true;

        (async () => {
            let resp = await http.post("integrated-inverter/uhome-model/list", {}).then(async (r) => {
                return await r.json();
            }).catch((e) => {
                return null;
            });

            if (!active) {
                return;
            }

            if (resp === null || resp.code !== 200) {
                setTip({ open: true, severity: "error", msg: (resp && resp.msg) ? resp.msg : "load data error" });
                setModelOptions([])
                return;
            }

            const ls = resp.data.map(({ Name, ID }) => {
                return (
                    <Select.Option key={ID} value={Name}>{Name}</Select.Option>
                );
            });
            setModelOptions(ls);
        })();

        return () => {
            active = false;
            console.log("DeviceManagement Effect Cancel");
        };
    }, [inverterManufacturer]);

    const handleCancle = () => {
        onClose(null);
        form.resetFields();
    }

    const handleSubmit = async () => {
        const ps = await form.validateFields().catch(() => { return null });

        if (ps === null) {
            return;
        }

        ps.Enabled = ps.Enabled ? 1 : 0;
        ps.DistributorName = ps.DistributorName || ""; 
        ps.Inverters = ps.Inverters && ps.Inverters.length > 0 ? ps.Inverters.map((v) => {
            return `${v.InverterManufacturer},${v.InverterModel},${v.InverterSN}`;
        }).join(";") : "";
        ps.Inverters = encodeURIComponent(ps.Inverters)

        const resp = await http.post("device/management/add", ps).then(async (r) => {
            return await r.json();
        }).catch((e) => {
            console.log("[DeviceAdd]", e);
            return null;
        });

        try {
            if (resp.code !== 200) {
                setTip({ open: true, severity: "error", msg: (resp && resp.msg) ? resp.msg : "add error" });
                return
            }

            setTip({ open: true, severity: 'success', msg: 'add success' });

            ps.ID = resp.data.ID;
            ps.CreateTime = resp.data.CreateTime;
            ps.GmtOffset = resp.data.GmtOffset;
            onClose(ps);
        } catch (e) {
            console.log("[DeviceAdd]", e);

            setTip({ open: true, severity: "error", msg: "add error" });
        }
    }

    const handleSearchProduct = (v) => {
        if (refTimerProductSearcher.current != null) {
            clearTimeout(refTimerProductSearcher.current);
        }
        refProductSearchValue.current = v;

        refTimerProductSearcher.current = setTimeout(async () => {
            const resp = await http.post("product/name/list", {
                Value: v,
            }).then(async (r) => {
                return await r.json();
            }).catch((e) => {
                console.log("[DeviceAdd]", e);
                return null;
            });

            if (refProductSearchValue.current !== v) {
                return;
            }

            try {
                if (resp.code !== 200) {
                    setTip({ open: true, severity: "error", msg: (resp && resp.msg) ? resp.msg : "load product list error" });
                    return;
                }

                const ls = resp.data.map(({ Name, ID, Category }) => {
                    return (
                        <Select.Option key={ID} value={Name} category={Category}>{Name}</Select.Option>
                    );
                });
                setProductSelectOptions(ls);
            } catch (e) {
                console.log("[DeviceAdd]", e);

                setTip({ open: true, severity: "error", msg: "load product list error" });
            }
        }, 300);
    }

    const handleSearchDistributor = (v) => {
        if (refTimerDistributorSearcher.current != null) {
            clearTimeout(refTimerDistributorSearcher.current);
        }
        refDistributorSearchValue.current = v;

        refTimerDistributorSearcher.current = setTimeout(async () => {
            const resp = await http.post("distributor/name/list", {
                Value: v,
            }).then(async (r) => {
                return await r.json();
            }).catch((e) => {
                console.log("[DeviceAdd]", e);
                return null;
            });

            if (refDistributorSearchValue.current !== v) {
                return;
            }

            try {
                if (resp.code !== 200) {
                    setTip({ open: true, severity: "error", msg: (resp && resp.msg) ? resp.msg : "load distributor list error" });
                    return;
                }

                const ls = resp.data.map(({ Name, ID }) => {
                    return (
                        <Select.Option key={ID} value={Name}>{Name}</Select.Option>
                    );
                });
                setDistributorSelectOptions(ls);
            } catch (e) {
                console.log("[DeviceAdd]", e);

                setTip({ open: true, severity: "error", msg: "load distributor list error" });
            }
        }, 300);
    }

    const handleSearchInverterManufacturer = (v) => {
        if (refTimerInverterManufacturerSearcher.current != null) {
            clearTimeout(refTimerInverterManufacturerSearcher.current);
        }
        refInverterManufacturerSearchValue.current = v;

        refTimerInverterManufacturerSearcher.current = setTimeout(async () => {
            const resp = await http.post("inverter-manufacturer/name/list", {
                Value: v,
            }).then(async (r) => {
                return await r.json();
            }).catch((e) => {
                console.log("[DeviceAdd]", e);
                return null;
            });

            if (refInverterManufacturerSearchValue.current !== v) {
                return;
            }

            try {
                if (resp.code !== 200) {
                    setTip({ open: true, severity: "error", msg: (resp && resp.msg) ? resp.msg : "load inverter-manufacturer list error" });
                    return;
                }

                const ls = resp.data.map(({ Name, ID }) => {
                    return (
                        <Select.Option key={ID} value={Name}>{Name}</Select.Option>
                    );
                });
                setInverterManufacturerSelectOptions(ls);
            } catch (e) {
                console.log("[DeviceAdd]", e);

                setTip({ open: true, severity: "error", msg: "load inverter-manufacturer list error" });
            }
        }, 300);
    }

    const handleProductSelected = (value, option) => {
        setProductCategory(option.category);
        // if (option.category == 2) {
        //     form.setFieldsValue({
        //         "Inverters": [{
        //             "InverterManufacturer": "Aobo",
        //             "InverterModel": "Uhome 5KHB-120",
        //             "InverterSN": "",
        //         }]
        //     })
        // } else {
        //     form.setFieldsValue({
        //         "Inverters": []
        //     })
        // }

        form.setFieldsValue({
            "Inverters": []
        })
    }

    const handleSearchTimezone = (v) => {
        if (refTimerTimezoneSearcher.current != null) {
            clearTimeout(refTimerTimezoneSearcher.current);
        }
        refTimezoneSearchValue.current = v;


        refTimerTimezoneSearcher.current = setTimeout(async () => {
            const resp = await http.post("timezone/list", {
                Timezone: v,
            }).then(async (r) => {
                return await r.json();
            }).catch((e) => {
                console.log("[DeviceAdd]", e);
                return null;
            });

            if (refTimezoneSearchValue.current !== v) {
                return;
            }

            try {
                if (resp.code !== 200) {
                    setTip({ open: true, severity: "error", msg: (resp && resp.msg) ? resp.msg : "load timezone list error" });
                    return;
                }

                const ls = resp.data.map(({ ZoneName, GmtOffset }) => {
                    var n = parseInt(GmtOffset);
                    var tz = "UTC+";
                    if (n < 0) {
                        tz = "UTC-";
                        n = Math.abs(n);
                    }
                    tz += Math.floor(n / 3600).toString().padStart(2, "0") + ":" + Math.floor((n % 3600) / 60).toString().padStart(2, "0")
                    return (
                        <Select.Option key={ZoneName} value={ZoneName}>{ZoneName}({tz})</Select.Option>
                    );
                });
                setTimezoneSelectOptions(ls);
            } catch (e) {
                console.log("[Device-Adder]", e);

                setTip({ open: true, severity: "error", msg: "load device list error" });
            }
        }, 300);
    }

    return (<Modal
        title="Add Device"
        visible={show}
        cancelText="Cancel"
        okText="Submit"
        mask={true}
        maskClosable={false}
        width={600}
        onCancel={handleCancle}
        onOk={handleSubmit}
        footer={[
            <Button
                key="Clear"
                shape="round"
                onClick={() => {
                    form.resetFields();
                }}
            >Clear</Button>,
            <Button key="Cancel" shape="round" onClick={handleCancle}>Cancel</Button>,
            <Button key="Submit" shape="round" type="primary" onClick={handleSubmit}>Submit</Button>
        ]}
    >
        <Form
            labelAlign="right"
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 25 }}
            form={form}
            initialValues={{
                Enabled: true,
            }}
        >
            <Form.Item
                name="SN"
                label=""
                rules={[{ required: true }]} 
            >
                <Input prefix={<InputPrefix label="SN" required />} placeholder="Enter Device SN" style={{ borderRadius: '20px' }} />
            </Form.Item>
            <Input.Group compact>
                <Button><span style={{color: 'red', verticalAlign: 'middle'}}>*&nbsp;</span>Product</Button>
                <Form.Item
                    name="ProductName"
                    label=""
                    rules={[{ required: true }]}
                    style={{width: 'calc(100% - 90px'}}
                >
                    <Select
                        showSearch
                        placeholder="Enter Product Name"
                        defaultActiveFirstOption={false}
                        showArrow={false}
                        filterOption={false}
                        onSearch={handleSearchProduct}
                        onSelect={handleProductSelected}
                    >{productSelectOptions}</Select>
                </Form.Item>
            </Input.Group>
            <Input.Group compact>
                <Button>Distributor</Button>
                <Form.Item
                    name="DistributorName"
                    label=""
                    style={{width: 'calc(100% - 98px'}}
                >
                    <Select
                        showSearch
                        placeholder="Enter Distributor Name"
                        defaultActiveFirstOption={false}
                        showArrow={false}
                        filterOption={false}
                        onSearch={handleSearchDistributor}
                    >{distributorSelectOptions}</Select>
                </Form.Item>
            </Input.Group>
            <Input.Group compact>
                <Button><span style={{color: 'red', verticalAlign: 'middle'}}>*&nbsp;</span>Timezone</Button>
                <Form.Item
                    name="Timezone"
                    label=""
                    rules={[{ required: true, type: 'string' }]}
                    style={{width: 'calc(100% - 102px'}}
                >
                    <Select
                        showSearch
                        placeholder="Timezone"
                        defaultActiveFirstOption={false}
                        showArrow={false}
                        filterOption={false}
                        onSearch={handleSearchTimezone}
                    >{timezoneSelectOptions}</Select>
                </Form.Item>
            </Input.Group>
            
            <Form.Item label="Inverters">
                <Form.List name="Inverters" label="Inverters">
                    {(fields, { add, remove }) => (
                        <>
                            {fields.map(({ key, name, fieldKey, ...restField }) => (
                                <Space key={key} style={{ display: 'flex', marginBottom: 2 }} align="baseline">
                                    <Form.Item
                                        {...restField}
                                        name={[name, 'InverterManufacturer']}
                                        fieldKey={[fieldKey, 'InverterManufacturer']}
                                        rules={[{ required: true, message: 'Missing Inverter Manufacturer name' }]
                                        }
                                    >
                                        {productCategory == 2222
                                            ?
                                            <Input readOnly={true} />
                                            :
                                            <Select
                                                style={{ width: 150 }}
                                                showSearch
                                                placeholder="Inverter Manufacturer Name"
                                                defaultActiveFirstOption={false}
                                                showArrow={false}
                                                filterOption={false}
                                                onSearch={handleSearchInverterManufacturer}
                                                onSelect={value => setInverterManufacturer(value)}
                                            >{inverterManufacturerSelectOptions}</Select>}
                                    </Form.Item>
                                    <Form.Item
                                        {...restField}
                                        name={[name, 'InverterModel']}
                                        fieldKey={[fieldKey, 'InverterModel']}
                                        rules={[{ required: true, message: 'Missing Inverter Model' }]}
                                    >
                                        {productCategory == 2222
                                            ?
                                            <Input readOnly={true} style={{width: 150}} />
                                            :
                                            (modelOptions.length > 0 ? 
                                                <Select
                                                    showSearch
                                                    placeholder="Inverter Model"
                                                    defaultActiveFirstOption={false}
                                                    showArrow={true}
                                                    style={{width: 150}}
                                                >{modelOptions}</Select> :
                                                <Input key={inverterManufacturer} placeholder="Inverter Model" style={{width: 150}} />)
                                        }
                                    </Form.Item>
                                    <Form.Item
                                        {...restField}
                                        name={[name, 'InverterSN']}
                                        fieldKey={[fieldKey, 'InverterSN']}
                                        rules={[{ required: true, message: 'Missing Inverter SN' }]}
                                    >
                                        <Input placeholder="Inverter SN" />
                                    </Form.Item>
                                    <MinusCircleOutlined onClick={() => remove(name)} hidden={productCategory == 2222} />
                                </Space>
                            ))}
                            <Form.Item hidden={productCategory == 2222}>
                                <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />} style={{borderRadius: '20px'}}>
                                    Add Inverter
                                </Button>
                            </Form.Item>
                        </>
                    )}
                </Form.List>
            </Form.Item>
            <Form.Item
                name="Enabled"
                label="Enabled"
                style={{marginTop: '-30px'}}
            >
                <Switch defaultChecked={true} />
            </Form.Item>
        </Form>
    </Modal>)
}

export default DeviceAdd;