import TitleBar from '../utils/TitleBar.js';

function Alarm(props) {
    const { data } = props;

    return (
        <div style={{
            padding: '10px 15px',
        }}>
            <TitleBar title="Warn&Fault" data={data} />
            <div style={{marginTop: '20px'}}>
                <div style={{backgroundColor: '#f0f0f0', width: '30%',height: '140px', borderRadius: '10px', float: 'left', marginRight: '10px', padding: '4px 6px'}}>
                    <div style={{fontSize: '12px', fontWeight: 'bold', borderBottom: '1px solid #1B8AC5', marginBottom: '6px'}}>
                        Sensor&Link Faults
                    </div>
                    <div>
                        <div style={{fontSize: '10px'}}>BMU Communication: {data ? AlarmFomatter(data["CommunicationFault"]) : "-"}</div>
                        <div style={{fontSize: '10px'}}>Cell Voltage Sensor Link: {data ? AlarmFomatter(data["CLF_CellVoltage"]) : "-"}</div>
                        <div style={{fontSize: '10px'}}>Temperature Sensor Link: {data ? AlarmFomatter(data["CLF_T"]) : "-"}</div>
                        <div style={{fontSize: '10px'}}>Current Sensor Link: {data ? AlarmFomatter(data["CurrentCollectorFault"]) : "-"}</div>
                        <div style={{fontSize: '10px'}}>Battary Sensor: {data ? AlarmFomatter(data["BattaryCollectorFault"]) : "-"}</div>
                    </div>
                </div>
                <div style={{backgroundColor: '#f0f0f0', width: '30%',height: '140px', borderRadius: '10px', float: 'left', marginRight: '10px', padding: '4px 6px'}}>
                    <div style={{fontSize: '12px', fontWeight: 'bold', borderBottom: '1px solid #1B8AC5', marginBottom: '6px'}}>
                        Charge Faults
                    </div>
                    <div>
                        <div style={{fontSize: '10px'}}>Temperature Difference: {data ? AlarmFomatter(data["T_DiffExcess"]) : "-"}</div>
                        <div style={{fontSize: '10px'}}>Temperature Over: {data ? AlarmFomatter(data["T_Over"]) : "-"}</div>
                        <div style={{fontSize: '10px'}}>Temperature Under: {data ? AlarmFomatter(data["T_Under"]) : "-"}</div>
                        <div style={{fontSize: '10px'}}>Current Over: {data ? AlarmFomatter(data["OverCurrentCharge"]) : "-"}</div>
                    </div>
                </div>
                <div style={{backgroundColor: '#f0f0f0', width: 'calc(40% - 20px)',height: '280px', borderRadius: '10px', float: 'right', padding: '4px 6px'}}>
                    <div style={{fontSize: '12px', fontWeight: 'bold', borderBottom: '1px solid #1B8AC5', marginBottom: '6px'}}>
                        Other Faults
                    </div>
                    <div>
                        <div style={{fontSize: '9px'}}>Power Voltage Over: {data ? AlarmFomatter(data["PowerVoltageOver"]) : "-"}</div>
                        <div style={{fontSize: '9px'}}>Power Voltage Under: {data ? AlarmFomatter(data["PowerVoltageUnder"]) : "-"}</div>
                        <div style={{fontSize: '9px'}}>Insulation Resistance Low: {data ? AlarmFomatter(data["InsulationResistanceLow"]) : "-"}</div>
                        <div style={{fontSize: '9px'}}>Total Voltage Over: {data ? AlarmFomatter(data["VoltageOver"]) : "-"}</div>
                        <div style={{fontSize: '9px'}}>Total Voltage Under: {data ? AlarmFomatter(data["VoltageUnder"]) : "-"}</div>
                        <div style={{fontSize: '9px'}}>SOC: {data ? AlarmFomatter(data["SOCLower"]) : "-"}</div>
                        <div style={{fontSize: '9px'}}>Total Voltage Difference: {data ? AlarmFomatter(data["VoltageDiffExcess"]) : "-"}</div>
                        <div style={{fontSize: '9px'}}>Capacity Inconsistency: {data ? AlarmFomatter(data["CapacityInconsistency"]) : "-"}</div>
                        <div style={{fontSize: '9px'}}>MOS Temperature Fault: {data ? AlarmFomatter(data["MOSTemperatureFault"]) : "-"}</div>
                        <div style={{fontSize: '9px'}}>ContactRod Temperature Fault: {data ? AlarmFomatter(data["ContactRodTemperatureFault"]) : "-"}</div>
                        <div style={{fontSize: '9px'}}>Relay or MOS Sticking: {data ? AlarmFomatter(data["RelayOrMOSSticking"]) : "-"}</div>
                        <div style={{fontSize: '9px'}}>Internal Fault: {data ? AlarmFomatter(data["InternalFault"]) : "-"}</div>
                        <div style={{fontSize: '9px'}}>Power Voltage Over: {data ? AlarmFomatter(data["PowerVoltageOver"]) : "-"}</div>
                        <div style={{fontSize: '9px'}}>Power Voltage Under: {data ? AlarmFomatter(data["PowerVoltageUnder"]) : "-"}</div>
                        <div style={{fontSize: '9px'}}>Insulation Resistance Low: {data ? AlarmFomatter(data["InsulationResistanceLow"]) : "-"}</div>
                        <div style={{fontSize: '9px'}}>Inter-pack Balancing Fault: {data ? AlarmFomatter(data["InterPackBalancingFault"]) : "-"}</div>
                    </div>
                </div>
                <div style={{backgroundColor: '#f0f0f0', width: '30%',height: '130px', borderRadius: '10px', float: 'left', marginTop: '10px', marginRight: '10px', padding: '4px 6px'}}>
                    <div style={{fontSize: '12px', fontWeight: 'bold', borderBottom: '1px solid #1B8AC5', marginBottom: '6px'}}>
                        Cell Faults
                    </div>
                    <div>
                        <div style={{fontSize: '10px'}}>Excessive Voltage Difference: {data ? AlarmFomatter(data["CellVoltageDiffExcess"]) : "-"}</div>
                        <div style={{fontSize: '10px'}}>Voltage Over: {data ? AlarmFomatter(data["CellVoltageOver"]) : "-"}</div>
                        <div style={{fontSize: '10px'}}>Voltage Under: {data ? AlarmFomatter(data["CellVoltageUnder"]) : "-"}</div>
                    </div>
                </div>
                <div style={{backgroundColor: '#f0f0f0', width: '30%',height: '130px', borderRadius: '10px', float: 'left', marginTop: '10px', marginRight: '10px', padding: '4px 6px'}}>
                    <div style={{fontSize: '12px', fontWeight: 'bold', borderBottom: '1px solid #1B8AC5', marginBottom: '6px'}}>
                        Discharge Faults
                    </div>
                    <div>
                        <div style={{fontSize: '10px'}}>Temperature Difference: {data ? AlarmFomatter(data["Discharge_T_DiffExcess"]) : "-"}</div>
                        <div style={{fontSize: '10px'}}>Temperature Over: {data ? AlarmFomatter(data["Discharge_T_Over"]) : "-"}</div>
                        <div style={{fontSize: '10px'}}>Temperature Under: {data ? AlarmFomatter(data["Discharge_T_Under"]) : "-"}</div>
                        <div style={{fontSize: '10px'}}>Current Over: {data ? AlarmFomatter(data["OverCurrentDischarge"]) : "-"}</div>
                    </div>
                </div>
            </div>
        </div>
    );
}

function AlarmFomatter(lvl) {
    return (<span style={{
        fontSize: 9,
        color: lvl == 1 ? "#ffc107" : lvl == 2 ? "red" : lvl == 3 ? "#e040fb" : "lime",
    }}>&nbsp;{lvl >= 1 && lvl <= 3 ? `level ${lvl} fault` : "OK"}</span>)
}
export default Alarm;