import config from '../Config';
import http from '../Http';
import { useContext, useState, useRef } from 'react';
import utils from '../utils';
import { Modal, Button, Input, Form, Select, DatePicker, Upload  } from 'antd';
import defs from "./defs"

function InverterFirmwareAdd (props) {
    const setTip = useContext(utils.tipContext);

    const {show, onClose} = props;

    const [form] = Form.useForm();

    const [inverterModelSelectOptions, setInverterModelSelectOptions] = useState([]);
    const [protocols, setProtocols] = useState(null);
    const [protocol, setProtocol] = useState(null);

    const refTimerInverterModelSearcher = useRef(null);
    const refInverterModelSearchValue = useRef(null);
    

    const handleCancle = () => {
        onClose(null);
    }

    const handleSearchInverterModel = (v) => {
        if (refTimerInverterModelSearcher.current != null) {
            clearTimeout(refTimerInverterModelSearcher.current);
        }
        refInverterModelSearchValue.current = v;

        refTimerInverterModelSearcher.current = setTimeout(async () => {
            const resp = await http.post("integrated-inverter/model/list", {
                Value: v
            }).then(async (r) => {
                return await r.json();
            }).catch((e) => {
                return null;
            });

            if (refInverterModelSearchValue.current !== v) {
                return;
            }

            try {
                if (resp.code !== 200) {
                    setTip({ open: true, severity: "error", msg: (resp && resp.msg) ? resp.msg : "load inverter model error" });
                    return;
                }
                let protocols2 = {}
                const ls = resp.data.map(({ ID, Name, Protocol }) => {
                    protocols2[ID] = Protocol
                    return (
                        <Select.Option key={ID} value={ID}>{Name}</Select.Option>
                    );
                });
                setProtocols(protocols2);
                setInverterModelSelectOptions(ls);
            } catch (e) {
                setTip({ open: true, severity: "error", msg: "load inverter model error" });
            }
        }, 300);
    }

    const handleSubmit = async () => {
        const ps = await form.validateFields().catch(() => {return null});
        if (ps === null) {
            return;
        }

        if (! ps.FirmwareFile.file || ! ps.FirmwareFile.file.response || ps.FirmwareFile.file.response.code !== 200) {
            setTip({open:true, severity:'error', msg:"Firmware File Upload error"});
            return
        }

        ps.Name = ps.Name || "";
        ps.FirmwareFile = ps.FirmwareFile.file.response.data[ps.FirmwareFile.file.name];
        ps.Note = ps.Note  || "";

        const resp = await http.post("integrated-inverter/firmware/add", ps).then(async (r)=>{
            return await r.json();
        }).catch((e) => {
            console.log("[FirmwareAdd]", e);
            return null;
        });

        try {
            if (resp.code !== 200) {
                setTip({open:true, severity:"error", msg:(resp && resp.msg) ? resp.msg : "add error"});
                return
            }

            setTip({open: true, severity: 'success', msg: 'add success'});

            ps.ID = resp.data.ID;
            ps.CreateTime = resp.data.CreateTime;
            onClose(ps);
        } catch (e) {
            console.log("[FirmwareAdd]", e);

            setTip({open:true, severity:"error", msg: "add error"});
        }
    }
    
    var ts = [];
    for (let k in defs.inverterProtocolTypes[protocol]) {
        ts.push(<Select.Option key={k} value={k}>{defs.inverterProtocolTypes[protocol][k]}</Select.Option>)
    }

    return (<Modal 
        title="Add Inverter Firmware"
        visible={show}
        cancelText="Cancel"
        okText="Submit"
        mask={true}
        maskClosable={false}
        width={600}
        onCancel={handleCancle}
        onOk={handleSubmit}
        footer={[
            <Button 
                key="Clear" 
                shape="round"
                onClick={() => {
                    form.resetFields();
                }}
            >Clear</Button>,
            <Button key="Cancel" shape="round" onClick={handleCancle}>Cancel</Button>,
            <Button key="Submit" type="primary" shape="round" onClick={handleSubmit}>Submit</Button>
        ]}
    >
        <Form
            labelAlign="right"
            labelCol={{ span: 5 }}
            wrapperCol={{ span: 17 }}
            form={form}
        >
            <Form.Item
                name="InverterModel"
                label="InverterModel"
                rules={[{ required: true }]}
            >
                <Select
                    showSearch
                    placeholder="Enter Inverter Model"
                    defaultActiveFirstOption={false}
                    showArrow={false}
                    filterOption={false}
                    onSearch={handleSearchInverterModel}
                    onSelect={value => setProtocol(protocols[value])}
                >{inverterModelSelectOptions}</Select>
            </Form.Item>
            <Form.Item 
                name="Name"
                label="Name"
            >
                <Input />
            </Form.Item>
            <Form.Item 
                name="Type"
                label="Type"
                rules={[{ required: true }]}
            >
                <Select>
                    {ts}
                </Select>
            </Form.Item>
            <Form.Item 
                name="FirmwareFile"
                label="FirmwareFile"
                valuePropName="file"
                rules={[{ required: true }]}
            >
                <Upload 
                    maxCount={1}
                    accept=".hex,.bin"
                    name="FirmwareFile"
                    action={config.apiUrl+"uploader/do?Token="+http.token}
                    headers={{Token:http.token}}
                    showUploadList={{showRemoveIcon: false}}
                >
                    <Button>Choose Data File (HEX,BIN) to Import</Button>
                </Upload>
            </Form.Item>
            <Form.Item 
                name="Version"
                label="Version"
                rules={[{ required: true }]}
            >
                <Input placeholder="e.g.: 1.8.8"/>
            </Form.Item>
            <Form.Item 
                name="Note"
                label="Note"
                rules={[{ required: false }]}
            >
                <Input.TextArea rows={6} maxLength={1500} showCount={true}/>
            </Form.Item>
        </Form>
    </Modal>)
}

export default InverterFirmwareAdd;