import http from '../../../Http';
import { useContext, useState, useRef, useEffect } from 'react';
import utils from '../../../utils';
import { Modal, Button, Input, Form, Select, Table, Row, Col, Popover, Checkbox } from 'antd';

function AddDTUBurning(props) {
    const setTip = useContext(utils.tipContext);

    const {showAddBurning, onClose} = props;

    //Firmware
    const [pageNoFirmware, setPageNoFirmware] = useState(0);
    const [pageSizeFirmware, setPageSizeFirmware] = useState(25);
    const [paramsFirmware, setParamsFirmware] = useState({
        Version: '',
    });
    const [loadingFirmware, setLoadingFirmware] = useState(false);
    const [rowsFirmware, setRowsFirmware] = useState([]);
    const [rowTotalFirmware, setRowTotalFirmware] = useState(0);
    const [tableBodyHeightFirmware, setTableBodyHeightFirmware] = useState(240);
    const [selectedKeysFirmware, setSelectedKeysFirmware] = useState([]);
    const [selectedRowsFirmware, setSelectedRowsFirmware] = useState([]);
    const [formSearcherFirmware] = Form.useForm();
    const refTableContainerFirmware = useRef();

    //DTU
    const [pageNoDTU, setPageNoDTU] = useState(0);
    const [pageSizeDTU, setPageSizeDTU] = useState(25);
    const [paramsDTU, setParamsDTU] = useState({
        SN: '',
    });
    const [loadingDTU, setLoadingDTU] = useState(false);
    const [rowsDTU, setRowsDTU] = useState([]);
    const [rowTotalDTU, setRowTotalDTU] = useState(0);
    const [tableBodyHeightDTU, setTableBodyHeightDTU] = useState(240);
    const [selectedKeysDTU, setSelectedKeysDTU] = useState([]);
    const [selectedRowsDTU, setSelectedRowsDTU] = useState([]);
    const [formSearcherDTU] = Form.useForm();
    const refTableContainerDTU = useRef();

    //get Firmware list
    useEffect(() => {
        let active = true;
        (async () => {
            setLoadingFirmware(true);
            let resp = await http.post("dtu/firmware/list", {
                PageNo: pageNoFirmware,
                PageSize: pageSizeFirmware,
                ...paramsFirmware
            }).then(async (r)=>{
                return await r.json();
            }).catch((e)=>{
                return null;
            });

            if (!active) {
                return;
            }
            
            if (resp === null || resp.code !== 200) {
                setTip({open:true, severity:"error", msg:(resp && resp.msg) ? resp.msg : "load inverter record error"});
                setRowTotalFirmware(0)
                setRowsFirmware([]);
                return;
            }

            setRowTotalFirmware(resp.data.RowTotal);
            setRowsFirmware(resp.data.Rows);
            setLoadingFirmware(false);
        })();

        return () => {
            active = false;
        };

    }, [pageNoFirmware, pageSizeFirmware, paramsFirmware]);

    //get dtu list
    useEffect(() => {

        let active = true;

        (async () => {
            setLoadingDTU(true);
            let resp = await http.post("dtu/list", {
                PageNo: pageNoDTU,
                PageSize: pageSizeDTU, 
                ...paramsDTU,
            }).then(async (r)=>{
                return await r.json();
            }).catch((e)=>{
                return null;
            });

            if (!active) {
                return;
            }
            
            if (resp === null || resp.code !== 200) {
                setTip({open:true, severity:"error", msg:(resp && resp.msg) ? resp.msg : "load dtu list error"});
                setRowTotalDTU(0)
                setRowsDTU([]);
                return;
            }

            setRowTotalDTU(resp.data.RowTotal);
            setRowsDTU(resp.data.Rows);
            setLoadingDTU(false);
        })();

        return () => {
            active = false;
        };

    }, [pageNoDTU, pageSizeDTU, paramsDTU]);

    //search firmware
    const handleSearchFirmware = () => {
        setPageNoFirmware(0);
        const ps = formSearcherFirmware.getFieldsValue();
        ps.Version = ps.Version || '';
        setParamsFirmware(ps)
    }

    //search dtu
    const handleSearchDTU = () => {
        setPageNoDTU(0);
        const ps = formSearcherDTU.getFieldsValue();
        ps.SN = ps.SN || '';
        setParamsDTU(ps)
    }

    const handleSelectFirmware = (selectedRowKeys, selectedRows) => {
        setSelectedKeysFirmware([...selectedRowKeys]);
        setSelectedRowsFirmware(selectedRows);
    }

    const handleSelectInverter = (selectedRowKeys, selectedRows) => {
        setSelectedKeysDTU([...selectedRowKeys]);
        setSelectedRowsDTU(selectedRows);
    }

    useEffect(() => {
        const el = refTableContainerFirmware.current;

        if (!el || el.objResizer) {
            return;
        }
        
        let obj = document.createElement('object');
        el.objResizer = obj; 
        obj.setAttribute('style',
            'display: block; position: absolute; top: 0; left: 0; height: 100%; width: 100%; overflow: hidden;opacity: 0; pointer-events: none; z-index: -1;');
        obj.onload = function () {
            setTableBodyHeightFirmware(calcTableBodyHeight(refTableContainerFirmware));

            this.contentDocument.defaultView.addEventListener('resize', (evt) => {
                setTableBodyHeightFirmware(calcTableBodyHeight(refTableContainerFirmware));
            });
        };
        obj.type = 'text/html';
        el.appendChild(obj);
        obj.data = 'about:blank';

        return () => {
            if (! el) {
                return
            }

            //
        }
    }, [refTableContainerFirmware.current]);

    useEffect(() => {
        const el = refTableContainerDTU.current;

        if (!el || el.objResizer) {
            return;
        }
        
        let obj = document.createElement('object');
        el.objResizer = obj; 
        obj.setAttribute('style',
            'display: block; position: absolute; top: 0; left: 0; height: 100%; width: 100%; overflow: hidden;opacity: 0; pointer-events: none; z-index: -1;');
        obj.onload = function () {
            setTableBodyHeightDTU(calcTableBodyHeight(refTableContainerDTU));

            this.contentDocument.defaultView.addEventListener('resize', (evt) => {
                setTableBodyHeightDTU(calcTableBodyHeight(refTableContainerDTU));
            });
        };
        obj.type = 'text/html';
        el.appendChild(obj);
        obj.data = 'about:blank';

        return () => {
            if (! el) {
                return
            }

            //
        }
    }, [refTableContainerDTU.current]);

    const calcTableBodyHeight = (ref) => {
        if (! ref.current) {
            return 200;
        }

        const hd = ref.current.parentElement.getElementsByClassName("ant-table-thead")[0];

        const h = ref.current.getBoundingClientRect().bottom - hd.getBoundingClientRect().bottom;

        return  h>=54 ? h - 54 : h /2;
    }

    const handleCancle = () => {
        onClose();
    }

    const handleSubmit = async () => {
        if (selectedKeysFirmware.length < 1 ) {
            setTip({ open: true, severity: 'error', msg: "The Firmare must be selected one" });
            return;
        }
        if (selectedKeysDTU.length < 1) {
            setTip({ open: true, severity: 'error', msg: "The DTU SN must be selected some" });
            return;
        }

        const ps = {
            FirmwareID: selectedKeysFirmware[0],
            DTUs: selectedRowsDTU.map(d => `${d.ID},${d.SN}`).join("|"),
        };

        const resp = await http.post("dtu/burning/add", ps).then(async (r) => {
            return await r.json();
        }).catch((e) => {
            console.log("[DTUBurningAdd]", e);
            return null;
        });

        try {
            if (resp.code !== 200) {
                setTip({ open: true, severity: "error", msg: (resp && resp.msg) ? resp.msg : "add error" });
                return
            }

            setTip({ open: true, severity: 'success', msg: 'add success' });

            const rds = {};
            resp.data.IDs.forEach((v) => {
                rds[v.DTUID] = v.ID
            });

            const rows = [];
            const f = selectedRowsFirmware[0];
            for (let d of selectedRowsDTU) {
                const row = {
                    ID: rds[d.ID],
                    BurningID: rds[d.ID],
                    SN: d.SN,
                    BindingID: d.ID,
                    Version: f.Version,
                    DataLength: f.DataLength,
                    Progress: 0,
                    Note: f.Note,
                    CreateTime: resp.data.CreateTime,
                };
                rows.push(row); 
            }
            
            onClose(rows);
        } catch (e) {
            console.log("[DTUBurningAdd]", e);

            setTip({ open: true, severity: "error", msg: "add error" });
        }
    }

    const handleClose = () => {
        onClose();
    }


    return (
        <Modal 
            forceRender={true}
            title='Add DTU Burning'
            visible={showAddBurning}
            //cancelText="Cancel"
            //okText="OK"
            mask={true}
            maskClosable={false}
            width={1000}
            onCancel={handleClose}
            onOk={handleClose}
            destroyOnClose={true}
            footer={null}
        >
            <div style={{
                display: "flex",
                flexDirection: "row",
                minHeight: "450px",
                height: "450px",
                position: "relative",
                alignItems: "stretch",
            }}>
                <div style={{
                    flex: "0 0 500",
                    width: "55%",
                    maxWidth: "55%",
                    display: "flex",
                    flexDirection: "column",
                }}>
                    <div style={{
                        flex: '0 0 auto',
                        fontWeight: "bold",
                    }}><label style={{color:"red"}}>*</label>Firmware:</div>
                    <div style={{
                        flex: "0 1 auto",
                    }}>
                        <Form
                            form={formSearcherFirmware}
                            preserve={false}
                            style={{
                                padding: 10,
                                background: '#fbfbfb',
                                border: '1px solid #d9d9d9',
                                borderRadius: 2
                            }}
                        >
                            <Row gutter={24}>
                                <Col span={12} key="Version">
                                    <Form.Item
                                        name="Version"
                                        label="Version"
                                    >
                                        <Input placeholder="Enter Version" onPressEnter={handleSearchFirmware} />
                                    </Form.Item>
                                </Col>
                                <Col span={12} style={{ textAlign: 'right' }}>
                                    <Button type="primary" shape="round" onClick={handleSearchFirmware}>
                                        Search
                                    </Button>
                                    <Button
                                        shape="round"
                                        style={{ margin: '0 8px' }}
                                        onClick={() => {
                                            formSearcherFirmware.resetFields();
                                        }}
                                    >
                                        Clear
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    </div>
                    <div style={{
                        flex: "1 1 1px",
                        position: "relative",
                    }}>
                        <div ref={refTableContainerFirmware} style={{ position: "absolute", height: '100%', width: "100%", overflow: "hidden" }}>
                            <Table
                                columns={[{
                                    title: "",
                                    width: 50,
                                    fixed: 'left',
                                    render: (v, r, idx) => {
                                        return (pageNoFirmware * pageSizeFirmware + idx + 1);
                                    }
                                }, {
                                    title: 'Version',
                                    dataIndex: 'Version',
                                    width: 160,
                                }, {
                                    title: 'DataLength',
                                    dataIndex: 'DataLength',
                                    width: 160,
                                }, {
                                    title: 'Create Time',
                                    dataIndex: 'CreateTime',
                                    width: 200,
                                }]}
                                pagination={{
                                    pageSizeOptions: [25, 50, 100],
                                    current: pageNoFirmware + 1,
                                    pageSize: pageSizeFirmware,
                                    total: rowTotalFirmware,
                                    showSizeChanger: true,
                                    showTotal: (total) => { return `Total ${total}` },
                                    onShowSizeChange: (current, size) => {
                                        setPageSizeFirmware(size);
                                    },
                                    onChange: (page, pageSize) => {
                                        setPageNoFirmware(page - 1);
                                    }
                                }}
                                tableLayout="fixed"
                                bordered={true}
                                size="middle"
                                loading={loadingFirmware}
                                rowKey="ID"
                                dataSource={rowsFirmware}
                                scroll={{ x: 40, y: tableBodyHeightFirmware }}
                                rowSelection={{
                                    type: "radio",
                                    selectedRowKeys: selectedKeysFirmware,
                                    onChange: handleSelectFirmware,
                                    selections: [Table.SELECTION_ALL, Table.SELECTION_INVERT, Table.SELECTION_NONE]
                                }}
                            />
                        </div>
                    </div>
                </div>
                <div style={{
                    flex: "1",
                    width: "45%",
                    height: '100%',
                    maxWidth: "45%",
                    display: "flex",
                    flexDirection: "column",
                }}>
                    <div style={{
                        flex: '0 0 auto',
                        fontWeight: "bold",
                    }}><label style={{color:"red"}}>*</label>DTU:</div>
                    <div style={{
                        flex: "0 1 auto",
                    }}>
                        <Form
                            form={formSearcherDTU}
                            preserve={false}
                            style={{
                                padding: 10,
                                background: '#fbfbfb',
                                border: '1px solid #d9d9d9',
                                borderRadius: 2
                            }}
                            initialValues={{
                                SN: "",
                                Enabled: ["Enabled", "Disabled"]
                            }}
                        >
                            <Row gutter={24}>
                                <Col span={12} key="SN">
                                    <Form.Item
                                        name="SN"
                                        label="SN"
                                    >
                                        <Input placeholder="Enter DTU SN"
                                            onPressEnter={handleSearchDTU} />
                                    </Form.Item>
                                </Col>
                                <Col span={12} style={{ textAlign: 'right' }}>
                                    <Button type="primary" shape="round" onClick={handleSearchDTU}>
                                        Search
                                    </Button>
                                    <Button
                                        shape="round"
                                        style={{ margin: '0 8px' }}
                                        onClick={() => {
                                            formSearcherDTU.resetFields();
                                        }}
                                    >
                                        Clear
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    </div>
                    <div style={{
                        flex: "1 1 1px",
                        position: "relative",
                    }}>
                        <div ref={refTableContainerDTU} style={{ position: "absolute", height: '100%', width: "100%", overflow: "hidden", }}>
                            <Table
                                columns={[{
                                    title: "",
                                    width: 50,
                                    render: (v, r, idx) => {
                                        return (pageNoDTU * pageSizeDTU + idx + 1);
                                    }
                                }, {
                                    title: "SN",
                                    dataIndex: 'SN',
                                    width: 200,
                                }, {
                                    title: "Create Time",
                                    dataIndex: "CreateTime",
                                    width: 200,
                                }]}
                                pagination={{
                                    pageSizeOptions: [25, 50, 100],
                                    current: pageNoDTU + 1,
                                    pageSize: pageSizeDTU,
                                    total: rowTotalDTU,
                                    showSizeChanger: true,
                                    showTotal: (total) => { return `Total ${total}` },
                                    onShowSizeChange: (current, size) => {
                                        setPageSizeDTU(size);
                                    },
                                    onChange: (page, pageSize) => {
                                        setPageNoDTU(page - 1);
                                    }
                                }}
                                tableLayout="fixed"
                                bordered={true}
                                size="middle"
                                loading={loadingDTU}
                                rowKey="ID"
                                dataSource={rowsDTU}
                                scroll={{ y: tableBodyHeightDTU }}
                                rowSelection={{
                                    type: "checkbox",
                                    selectedRowKeys: selectedKeysDTU,
                                    onChange: handleSelectInverter,
                                    selections: [Table.SELECTION_ALL, Table.SELECTION_INVERT, Table.SELECTION_NONE]
                                }}
                            />
                        </div>
                    </div>

                </div>
            </div>
            <div style={{textAlign: 'center', marginTop: '30px'}}>
                <Button key="Cancel" shape="round" onClick={handleCancle}>Cancel</Button>
                <span style={{display: 'inline-block', width: '30px'}}></span>
                <Button key="Submit" shape="round" type="primary" onClick={handleSubmit}>Submit</Button>
            </div>
        </Modal>
    )

}

export default AddDTUBurning;