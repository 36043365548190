import http from '../Http';
import { useContext, useState, useRef, useEffect } from 'react';
import utils from '../utils';
import defs from "./defs"
import { Modal, Button, Input, Form, Select, Table, Row, Col, Popover, Checkbox } from 'antd';
import { UpOutlined, DownOutlined, CheckOutlined, CloseOutlined } from '@ant-design/icons';


function BurningAddGeneralPassThrough(props) {
    const setTip = useContext(utils.tipContext);

    const { show, onClose } = props;

    //Firmware
    const [pageNoFirmware, setPageNoFirmware] = useState(0);
    const [pageSizeFirmware, setPageSizeFirmware] = useState(25);
    const [paramsFirmware, setParamsFirmware] = useState({
        Name: '',
        ProductName: '',
        BurningType: '',
        Version: '',
    });
    const [loadingFirmware, setLoadingFirmware] = useState(false);
    const [rowsFirmware, setRowsFirmware] = useState([]);
    const [rowTotalFirmware, setRowTotalFirmware] = useState(0);
    const [tableBodyHeightFirmware, setTableBodyHeightFirmware] = useState(240);
    const [selectedKeysFirmware, setSelectedKeysFirmware] = useState([]);
    const [selectedRowsFirmware, setSelectedRowsFirmware] = useState([]);
    const [expandFirmware, setExpandFirmware] = useState(false);
    const [formSearcherFirmware] = Form.useForm();
    const refTableContainerFirmware = useRef();
    //Device
    const [pageNoDevice, setPageNoDevice] = useState(0);
    const [pageSizeDevice, setPageSizeDevice] = useState(25);
    const [paramsDevice, setParamsDevice] = useState({
        SN: '',
        DistributorName: '',
        Enabled: ""
    });
    const [loadingDevice, setLoadingDevice] = useState(false);
    const [rowsDevice, setRowsDevice] = useState([]);
    const [rowTotalDevice, setRowTotalDevice] = useState(0);
    const [tableBodyHeightDevice, setTableBodyHeightDevice] = useState(240);
    const [selectedKeysDevice, setSelectedKeysDevice] = useState([]);
    const [selectedRowsDevice, setSelectedRowsDevice] = useState([]);
    const [expandDevice, setExpandDevice] = useState(false);
    const [formSearcherDevice] = Form.useForm();
    const refTableContainerDevice = useRef();


    const [productSelectOptions, setProductSelectOptions] = useState([]);
    const [distributorSelectOptions, setDistributorSelectOptions] = useState([]);
    const [productID, setProductID] = useState(null);

    const refTimerProductSearcher = useRef(null);
    const refProductSearchValue = useRef(null);
    const refTimerDistributorSearcher = useRef(null);
    const refDistributorSearchValue = useRef(null);

    useEffect(() => {
        let active = true;

        (async () => {
            setLoadingFirmware(true);

            let resp = await http.post("firmware/management/list", {
                PageNo: pageNoFirmware,
                PageSize: pageSizeFirmware,
                ...paramsFirmware,
                ProductCategory: 0,
            }).then(async (r) => {
                return await r.json();
            }).catch((e) => {
                console.log("[BurningAddGeneralPassThrough]", e);
                return null;
            });

            if (!active) {
                return;
            }

            if (resp === null || resp.code !== 200) {
                setTip({ open: true, severity: "error", msg: (resp && resp.msg) ? resp.msg : "load data error" });

                setRowTotalFirmware(0)
                setRowsFirmware([]);

                return;
            }
            setRowTotalFirmware(resp.data.RowTotal);
            setRowsFirmware(resp.data.Rows);
            setLoadingFirmware(false);
        })();

        return () => {
            active = false;
        };
    }, [pageNoFirmware, pageSizeFirmware, paramsFirmware]);

    useEffect(() => {
        if (productID !== null) {
            let active = true;

            (async () => {
                setLoadingDevice(true);

                let resp = await http.post("device/management/list", {
                    PageNo: pageNoDevice,
                    PageSize: pageSizeDevice,
                    ...paramsDevice,
                    ProductID: productID,
                    MasterAndSubtree: 1,
                }).then(async (r) => {
                    return await r.json();
                }).catch((e) => {
                    console.log("[BurningAddGeneralPassThrough]", e);
                    return null;
                });

                if (!active) {
                    return;
                }

                if (resp === null || resp.code !== 200) {
                    setTip({ open: true, severity: "error", msg: (resp && resp.msg) ? resp.msg : "load data error" });

                    setRowTotalDevice(0)
                    setRowsDevice([]);

                    return;
                }
                resp.data.Rows.forEach((v) => {
                    if (v.children) {
                        v.children = JSON.parse(v.children);
                        v.children.forEach(v2 => {
                            v2.IsSlave = 1;
                        });
                    }
                })
                setRowTotalDevice(resp.data.RowTotal);
                setRowsDevice(resp.data.Rows);
                setLoadingDevice(false);
            })();

            return () => {
                active = false;
            };
        }
    }, [pageNoDevice, pageSizeDevice, paramsDevice, productID]);

    useEffect(() => {
        const el = refTableContainerFirmware.current;

        if (!el || el.objResizer) {
            return;
        }

        let obj = document.createElement('object');
        el.objResizer = obj;
        obj.setAttribute('style',
            'display: block; position: absolute; top: 0; left: 0; height: 100%; width: 100%; overflow: hidden;opacity: 0; pointer-events: none; z-index: -1;');
        obj.onload = function () {
            setTableBodyHeightFirmware(calcTableBodyHeight(refTableContainerFirmware));

            this.contentDocument.defaultView.addEventListener('resize', (evt) => {
                setTableBodyHeightFirmware(calcTableBodyHeight(refTableContainerFirmware));
            });
        };
        obj.type = 'text/html';
        el.appendChild(obj);
        obj.data = 'about:blank';

        return () => {
            if (!el) {
                return
            }

            //
        }
    }, [refTableContainerFirmware.current]);

    useEffect(() => {
        const el = refTableContainerDevice.current;

        if (!el || el.objResizer) {
            return;
        }

        let obj = document.createElement('object');
        el.objResizer = obj;
        obj.setAttribute('style',
            'display: block; position: absolute; top: 0; left: 0; height: 100%; width: 100%; overflow: hidden;opacity: 0; pointer-events: none; z-index: -1;');
        obj.onload = function () {
            setTableBodyHeightDevice(calcTableBodyHeight(refTableContainerDevice));

            this.contentDocument.defaultView.addEventListener('resize', (evt) => {
                setTableBodyHeightDevice(calcTableBodyHeight(refTableContainerDevice));
            });
        };
        obj.type = 'text/html';
        el.appendChild(obj);
        obj.data = 'about:blank';

        return () => {
            if (!el) {
                return
            }

            //
        }
    }, [refTableContainerDevice.current]);

    const handleCancle = () => {
        onClose(null);
    }

    const handleSubmit = async () => {
        if (selectedKeysFirmware.length < 1) {
            setTip({ open: true, severity: 'error', msg: "The Firmare must be select one" });
            return;
        }
        if (selectedKeysDevice.length < 1) {
            setTip({ open: true, severity: 'error', msg: "The Device SN must be select some" });
            return;
        }

        const masters = {};
        selectedRowsDevice.forEach(v => {
            if (v.IsSlave === 1) {
                masters[v.MasterID] = [v.SN, ...(masters[v.MasterID] || [])];
            } else {
                masters[v.ID] = [v.SN, ...(masters[v.ID] || [])];
            }
        });
        const ms = [];
        for (let id in masters) {
            ms.push(`${id}*${masters[id].join(",")}`)
        }

        const ps = {
            FirmwareID: selectedKeysFirmware[0],
            Masters: ms.join("|"),
        };

        const resp = await http.post("burning/management/general/pass-through/add", ps).then(async (r) => {
            return await r.json();
        }).catch((e) => {
            console.log("[BurningAddGeneralPassThrough]", e);
            return null;
        });

        try {
            if (resp.code !== 200) {
                setTip({ open: true, severity: "error", msg: (resp && resp.msg) ? resp.msg : "add error" });
                return
            }

            setTip({ open: true, severity: 'success', msg: 'add success' });

            const rds = {};
            resp.data.IDs.forEach((v) => {
                rds[v.DeviceID] = v.ID
            });

            const rows = [];
            const f = selectedRowsFirmware[0];
            for (let d of selectedRowsDevice) {
                const row = {
                    ID: rds[d.ID],
                    BurningCategory: 1,
                    CreateTime: resp.data.CreateTime,
                    SN: d.SN,
                    ProductCategory: 0,
                    ProductName: f.ProductName,
                    DistributorName: d.DistributorName,
                    Users: d.Users,
                    FirmwareName: f.Name,
                    FirmwareVersion: f.Version,
                    BurningType: f.BurningType,
                    DataLength: f.DataLength,
                    ExpiredTime: f.ExpiredTime,
                    Note: f.Note,
                    UpgradingDeviceSNs: d.SN,
                    EventCode: "99999",
                };
                rows.push(row);
            }

            onClose(rows);
        } catch (e) {
            console.log("[BurningAddGeneralPassThrough]", e);

            setTip({ open: true, severity: "error", msg: "add error" });
        }
    }

    const handleSearchProduct = (v) => {
        if (refTimerProductSearcher.current != null) {
            clearTimeout(refTimerProductSearcher.current);
        }
        refProductSearchValue.current = v;

        refTimerProductSearcher.current = setTimeout(async () => {
            const resp = await http.post("product/name/list", {
                Value: v,
            }).then(async (r) => {
                return await r.json();
            }).catch((e) => {
                console.log("[BurningAddGeneralPassThrough]", e);
                return null;
            });

            if (refProductSearchValue.current !== v) {
                return;
            }

            try {
                if (resp.code !== 200) {
                    setTip({ open: true, severity: "error", msg: (resp && resp.msg) ? resp.msg : "load product list error" });
                    return;
                }

                const ls = resp.data.map(({ Name, ID }) => {
                    return (
                        <Select.Option key={ID} value={Name}>{Name}</Select.Option>
                    );
                });
                setProductSelectOptions(ls);
            } catch (e) {
                console.log("[BurningAddGeneralPassThrough]", e);

                setTip({ open: true, severity: "error", msg: "load product list error" });
            }
        }, 300);
    }

    const handleSearchDistributor = (v) => {
        if (refTimerDistributorSearcher.current != null) {
            clearTimeout(refTimerDistributorSearcher.current);
        }
        refDistributorSearchValue.current = v;

        refTimerDistributorSearcher.current = setTimeout(async () => {
            const resp = await http.post("distributor/name/list", {
                Value: v,
            }).then(async (r) => {
                return await r.json();
            }).catch((e) => {
                console.log("[BurningAddGeneralPassThrough]", e);
                return null;
            });

            if (refDistributorSearchValue.current !== v) {
                return;
            }

            try {
                if (resp.code !== 200) {
                    setTip({ open: true, severity: "error", msg: (resp && resp.msg) ? resp.msg : "load distributor list error" });
                    return;
                }

                const ls = resp.data.map(({ Name, ID }) => {
                    return (
                        <Select.Option key={ID} value={Name}>{Name}</Select.Option>
                    );
                });
                setDistributorSelectOptions(ls);
            } catch (e) {
                console.log("[BurningAddGeneralPassThrough]", e);

                setTip({ open: true, severity: "error", msg: "load distributor list error" });
            }
        }, 300);
    }

    const handleSelectFirmware = (selectedRowKeys, selectedRows) => {
        setSelectedKeysFirmware([...selectedRowKeys]);
        setSelectedRowsFirmware(selectedRows);
        setProductID(selectedRows[0].ProductID)
    }

    const handleSelectDevice = (selectedRowKeys, selectedRows) => {
        setSelectedKeysDevice([...selectedRowKeys]);
        setSelectedRowsDevice(selectedRows);
    }

    const handleSearchFirmware = () => {
        setSelectedKeysFirmware([]);
        setPageNoFirmware(0);

        const ps = formSearcherFirmware.getFieldsValue();
        ps.Name = ps.Name || "";
        ps.ProductName = ps.ProductName || "";
        ps.BurningType = ps.BurningType || "";
        ps.Version = ps.Version || "";
        ps.NotExpired = 1
        setParamsFirmware(ps);
    }

    const handleSearchDevice = () => {
        setSelectedKeysDevice([]);
        setPageNoDevice(0);

        const ps = formSearcherDevice.getFieldsValue();
        ps.SN = ps.SN || "";
        ps.DistributorName = ps.DistributorName || "";
        ps.Enabled = ps.Enabled ? (ps.Enabled.indexOf("Enabled") > -1 && ps.Enabled.indexOf("Enabled") > -1 ? "" : ps.Enabled.indexOf("Enabled") > -1 ? 1 : 0) : "";
        setParamsDevice(ps);
    }

    const calcTableBodyHeight = (ref) => {
        if (!ref.current) {
            return 200;
        }

        const hd = ref.current.parentElement.getElementsByClassName("ant-table-thead")[0];

        const h = ref.current.getBoundingClientRect().bottom - hd.getBoundingClientRect().bottom;

        return h >= 54 ? h - 54 : h / 2;
    }

    //按下Enter键时进行查找
    const searchFirmwareByEnter = (e) => { 
        if(e.code == "Enter") {
            handleSearchFirmware()
        }
    }

    const searchDeviceByEnter = (e) => { 
        if(e.code == "Enter") {
            handleSearchDevice()
        }
    }

    return (<Modal
        title="Add General Upgrade"
        visible={show}
        cancelText="Cancel"
        okText="Submit"
        mask={true}
        maskClosable={false}
        width={1000}
        onCancel={handleCancle}
        onOk={handleSubmit}
        footer={[
            <Button key="Cancel" onClick={handleCancle}>Cancel</Button>,
            <Button key="Submit" type="primary" onClick={handleSubmit}>Submit</Button>
        ]}
    >
        <div style={{
            display: "flex",
            flexDirection: "row",
            minHeight: "450px",
            height: "450px",
            position: "relative",
            alignItems: "stretch",
        }}>
            <div style={{
                flex: "0 0 500",
                width: "50%",
                maxWidth: "50%",
                display: "flex",
                flexDirection: "column",
            }}>
                <div style={{
                    flex: '0 0 auto',
                    fontWeight: "bold",
                }}><label style={{ color: "red" }}>*</label>Firmware:</div>
                <div style={{
                    flex: "0 1 auto",
                }}>
                    <Form
                        form={formSearcherFirmware}
                        style={{
                            padding: 10,
                            background: '#fbfbfb',
                            border: '1px solid #d9d9d9',
                            borderRadius: 2
                        }}
                    >
                        <Row gutter={24}>
                            <Col span={12} key="Name">
                                <Form.Item
                                    name="Name"
                                    label="Name"
                                >
                                    <Input placeholder="Enter Firmware Name" onPressEnter={handleSearchFirmware} />
                                </Form.Item>
                            </Col>
                            <Col span={12} key="Product">
                                <Form.Item
                                    name="ProductName"
                                    label="Product"
                                >
                                    <Select
                                        showSearch
                                        placeholder="Enter Product Name"
                                        defaultActiveFirstOption={false}
                                        showArrow={false}
                                        filterOption={false}
                                        onSearch={handleSearchProduct}
                                        onInputKeyDown={searchFirmwareByEnter}
                                    >{productSelectOptions}</Select>
                                </Form.Item>
                            </Col>
                            <Col span={12} key="BurningType" style={{ display: expandFirmware ? "block" : "none" }}>
                                <Form.Item
                                    name="BurningType"
                                    label="UpgradeType"
                                >
                                    <Select>
                                        <Select.Option value={1}>APP</Select.Option>
                                        <Select.Option value={2}>BOOT</Select.Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={12} key="Version" style={{ display: expandFirmware ? "block" : "none" }}>
                                <Form.Item
                                    name="Version"
                                    label="Version"
                                >
                                    <Input placeholder="e.g.: 1.8.8" onPressEnter={handleSearchFirmware} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24} style={{ textAlign: 'right' }}>
                                <Button type="primary" onClick={handleSearchFirmware}>
                                    Search
                                </Button>
                                <Button
                                    style={{ margin: '0 8px' }}
                                    onClick={() => {
                                        formSearcherFirmware.resetFields();
                                    }}
                                >
                                    Clear
                                </Button>
                                <a
                                    style={{ fontSize: 12 }}
                                    onClick={() => {
                                        setExpandFirmware(!expandFirmware);
                                    }}
                                >
                                    {expandFirmware ? <UpOutlined /> : <DownOutlined />} {expandFirmware ? 'Collapse' : 'Expand'}
                                </a>
                            </Col>
                        </Row>
                    </Form>
                </div>
                <div style={{
                    flex: "1 1 1px",
                    position: "relative",
                }}>
                    <div ref={refTableContainerFirmware} style={{ position: "absolute", height: '100%', width: "100%", overflow: "hidden" }}>
                        <Table
                            columns={[{
                                title: "",
                                width: 40,
                                fixed: 'left',
                                render: (v, r, idx) => {
                                    return (pageNoFirmware * pageSizeFirmware + idx + 1);
                                }
                            }, {
                                title: "Product",
                                dataIndex: 'ProductName',
                                width: 150,
                            }, {
                                title: "Name",
                                dataIndex: "Name",
                                width: 150,
                            }, {
                                title: "Version",
                                dataIndex: "Version",
                                width: 80,
                            }, {
                                title: "UpgradeType",
                                dataIndex: "BurningType",
                                width: 100,
                                render: (v, r, idx) => {
                                    return defs.burningTypes[v] || "";
                                }
                            }, {
                                title: "DataLength",
                                dataIndex: "DataLength",
                                width: 140,
                                render: (v) => {
                                    return v ? Math.round(v/1024) + 'KB (' + v + 'B)' : ''
                                }
                            }, {
                                title: "ExpiredTime",
                                dataIndex: "ExpiredTime",
                                width: 150,
                                render: (v) => {
                                    return v && v != "0000-00-00 00:00:00" ? v : "";
                                }
                            }, {
                                title: "Note",
                                dataIndex: "Note",
                                width: 200,
                                render: (v) => {
                                    if (!v) {
                                        return v;
                                    }

                                    if (v.indexOf("\n") > -1) {
                                        const content = (
                                            <Input.TextArea rows={5} value={v} readOnly />
                                        )
                                        return (
                                            <Popover title="Note" content={content}>
                                                <div>{v}</div>
                                            </Popover>
                                        );
                                    }

                                    return v;
                                }
                            }]}
                            pagination={{
                                pageSizeOptions: [25, 50, 100],
                                current: pageNoFirmware + 1,
                                pageSize: pageSizeFirmware,
                                total: rowTotalFirmware,
                                showSizeChanger: true,
                                showTotal: (total) => { return `Total ${total}` },
                                onShowSizeChange: (current, size) => {
                                    setPageSizeFirmware(size);
                                },
                                onChange: (page, pageSize) => {
                                    setPageNoFirmware(page - 1);
                                }
                            }}
                            tableLayout="fixed"
                            bordered={true}
                            size="middle"
                            loading={loadingFirmware}
                            rowKey="ID"
                            dataSource={rowsFirmware}
                            scroll={{ x: 40, y: tableBodyHeightFirmware }}
                            rowSelection={{
                                type: "radio",
                                selectedRowKeys: selectedKeysFirmware,
                                onChange: handleSelectFirmware,
                                selections: [Table.SELECTION_ALL, Table.SELECTION_INVERT, Table.SELECTION_NONE]
                            }}
                        />
                    </div>
                </div>
            </div>
            <div style={{
                flex: "1",
                width: "50%",
                height: '100%',
                maxWidth: "50%",
                display: "flex",
                flexDirection: "column",
            }}>
                <div style={{
                    flex: '0 0 auto',
                    fontWeight: "bold",
                }}><label style={{ color: "red" }}>*</label>Device SN:</div>
                <div style={{
                    flex: "0 1 auto",
                }}>
                    <Form
                        form={formSearcherDevice}
                        style={{
                            padding: 10,
                            background: '#fbfbfb',
                            border: '1px solid #d9d9d9',
                            borderRadius: 2
                        }}
                        initialValues={{
                            SN: "",
                            DistributorName: "",
                            UserID: "",
                            Enabled: ["Enabled", "Disabled"]
                        }}
                    >
                        <Row gutter={24}>
                            <Col span={12} key="SN">
                                <Form.Item
                                    name="SN"
                                    label="SN"
                                >
                                    <Input placeholder="Enter Device SN" onPressEnter={handleSearchDevice} />
                                </Form.Item>
                            </Col>
                            <Col span={12} key="Distributor">
                                <Form.Item
                                    name="DistributorName"
                                    label="Distributor"
                                >
                                    <Select
                                        showSearch
                                        placeholder="Enter Distributor Name"
                                        defaultActiveFirstOption={false}
                                        showArrow={false}
                                        filterOption={false}
                                        onSearch={handleSearchDistributor}
                                        onInputKeyDown={searchDeviceByEnter}
                                    >{distributorSelectOptions}</Select>
                                </Form.Item>
                            </Col>
                            <Col span={12} key="UserID" style={{ display: expandDevice ? "block" : "none" }}>
                                <Form.Item
                                    name="UserID"
                                    label="UserID"
                                >
                                    <Input placeholder="Enter User ID" onPressEnter={handleSearchDevice} />
                                </Form.Item>
                            </Col>
                            <Col span={12} key="Enabled" style={{ display: expandDevice ? "block" : "none" }}>
                                <Form.Item
                                    name="Enabled"
                                    label=""
                                >
                                    <Checkbox.Group options={["Enabled", 'Disabled']} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24} style={{ textAlign: 'right' }}>
                                <Button type="primary" onClick={handleSearchDevice}>
                                    Search
                                </Button>
                                <Button
                                    style={{ margin: '0 8px' }}
                                    onClick={() => {
                                        formSearcherDevice.resetFields();
                                    }}
                                >
                                    Clear
                                </Button>
                                <a
                                    style={{ fontSize: 12 }}
                                    onClick={() => {
                                        setExpandDevice(!expandDevice);
                                    }}
                                >
                                    {expandDevice ? <UpOutlined /> : <DownOutlined />} {expandDevice ? 'Collapse' : 'Expand'}
                                </a>
                            </Col>
                        </Row>
                    </Form>
                </div>
                <div style={{
                    flex: "1 1 1px",
                    position: "relative",
                }}>
                    <div ref={refTableContainerDevice} style={{ position: "absolute", height: '100%', width: "100%", overflow: "hidden", }}>
                        <Table
                            columns={[{
                                title: "",
                                width: 50,
                                render: (v, r, idx) => {
                                    if (r.IsSlave === 1) {
                                        return `(${idx + 1})`;
                                    }
                                    return (pageNoDevice * pageSizeDevice + idx + 1);
                                }
                            }, {
                                title: "SN",
                                dataIndex: 'SN',
                                width: 250,
                            }, {
                                title: "Enabled",
                                dataIndex: "Enabled",
                                width: 80,
                                render: (v, r, idx) => {
                                    return v === 1 || v === "1" ? (<CheckOutlined style={{ color: "#5b8c00" }} />)
                                        : (<CloseOutlined style={{ color: "#ad2102" }} />)
                                }
                            }, {
                                title: "Distributor",
                                dataIndex: "DistributorName",
                                width: 200,
                            }, {
                                title: "Users",
                                dataIndex: "Users",
                                width: 200,
                            }]}
                            pagination={{
                                pageSizeOptions: [25, 50, 100],
                                current: pageNoDevice + 1,
                                pageSize: pageSizeDevice,
                                total: rowTotalDevice,
                                showSizeChanger: true,
                                showTotal: (total) => { return `Total ${total}` },
                                onShowSizeChange: (current, size) => {
                                    setPageSizeDevice(size);
                                },
                                onChange: (page, pageSize) => {
                                    setPageNoDevice(page - 1);
                                }
                            }}
                            tableLayout="fixed"
                            bordered={true}
                            size="middle"
                            loading={loadingDevice}
                            rowKey="ID"
                            dataSource={rowsDevice}
                            scroll={{ y: tableBodyHeightDevice }}
                            rowSelection={{
                                type: "checkbox",
                                selectedRowKeys: selectedKeysDevice,
                                onChange: handleSelectDevice,
                                selections: [Table.SELECTION_ALL, Table.SELECTION_INVERT, Table.SELECTION_NONE]
                            }}
                            expandable={{
                                defaultExpandAllRows: true,
                                expandIconColumnIndex: 3,
                            }}
                        />
                    </div>
                </div>

            </div>
        </div>
    </Modal>)
}

export default BurningAddGeneralPassThrough;