import http from '../Http';
import { ThemeContext } from '../Http';
import { useEffect, useState, useContext, useRef } from 'react';
import utils from '../utils';
import config from '../Config';
import { Table, Input, Button, Form, Row, Col, Image, Select, Tooltip, DatePicker, Modal } from 'antd';
import { PlusOutlined, UpOutlined, DownOutlined, CloudDownloadOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import InputPrefix from '../utils/InputPrefix';
import moment from 'moment';
import DeviceIssueAdd from './DeviceIssueAdd';
import DeviceIssueSolutionAdd from './DeviceIssueSolutionAdd';

function AfterSale(props) {
    const setTip = useContext(utils.tipContext);
    const session = useContext(ThemeContext);

    const [pageNo, setPageNo] = useState(0);
    const [pageSize, setPageSize] = useState(25);
    const [params, setParams] = useState({
        SN: '',
        DistributorName: '',
        ProductName: '',
        Enabled: '',
        UserID: '',
        STime: moment().format("YYYY-MM-DD"),
        ETime: moment().add(1, "days").format("YYYY-MM-DD")
    });
    const [loading, setLoading] = useState(false);
    const [rows, setRows] = useState([]);
    const [rowTotal, setRowTotal] = useState(0);
    const [tableBodyHeight, setTableBodyHeight] = useState(240);
    const [productSelectOptions, setProductSelectOptions] = useState([]);
    const [distributorSelectOptions, setDistributorSelectOptions] = useState([]);
    const [expand, setExpand] = useState(false);

    const refTableContainer = useRef();
    const refTimerProductSearcher = useRef(null);
    const refProductSearchValue = useRef(null);
    const refTimerDistributorSearcher = useRef(null);
    const refDistributorSearchValue = useRef(null);

    const [issueIDofSolution, setIssueIDofSolution] = useState(null);
    const [showAddDeviceIssue, setShowAddDeviceIssue] = useState(false);
    const [showAddDeviceIssueSolution, setShowAddDeviceIssueSolution] = useState(false);

    const refDownloader = useRef(null)

    const [form] = Form.useForm();

    useEffect(() => {
        let active = true;

        (async () => {
            setLoading(true);


            let resp = await http.post("after-sale/device/list", {
                PageNo: pageNo,
                PageSize: pageSize,
                Timezone: -(new Date()).getTimezoneOffset() * 60,
                ...params,
            }).then(async (r) => {
                return await r.json();
            }).catch((e) => {
                return null;
            });

            if (!active) {
                return;
            }

            //console.log(resp && resp.data);

            if (resp === null || resp.code !== 200) {
                setTip({ open: true, severity: "error", msg: (resp && resp.msg) ? resp.msg : "load data error" });

                setRowTotal(0)
                setRows([]);

                return;
            }
            setRowTotal(resp.data.RowTotal);
            setRows(resp.data.Rows);
            setLoading(false);
        })();

        return () => {
            active = false;
        };
    }, [pageNo, pageSize, params]);

    useEffect(() => {
        const el = refTableContainer.current;

        if (el.objResizer) {
            return;
        }

        let obj = document.createElement('object');
        el.objResizer = obj;
        obj.setAttribute('style',
            'display: block; position: absolute; top: 0; left: 0; height: 100%; width: 100%; overflow: hidden;opacity: 0; pointer-events: none; z-index: -1;');
        obj.onload = function () {
            setTableBodyHeight(calcTableBodyHeight());

            this.contentDocument.defaultView.addEventListener('resize', (evt) => {
                setTableBodyHeight(calcTableBodyHeight());
            });
        };
        obj.type = 'text/html';
        el.appendChild(obj);
        obj.data = 'about:blank';

        return () => {
            if (!el) {
                return
            }

            //
        }
    }, [refTableContainer.current]);

    const handleSearchProduct = (v) => {
        if (refTimerProductSearcher.current != null) {
            clearTimeout(refTimerProductSearcher.current);
        }
        refProductSearchValue.current = v;

        refTimerProductSearcher.current = setTimeout(async () => {
            const resp = await http.post("product/name/list", {
                Value: v,
            }).then(async (r) => {
                return await r.json();
            }).catch((e) => {
                return null;
            });

            if (refProductSearchValue.current !== v) {
                return;
            }

            try {
                if (resp.code !== 200) {
                    setTip({ open: true, severity: "error", msg: (resp && resp.msg) ? resp.msg : "load product list error" });
                    return;
                }

                const ls = resp.data.map(({ Name, ID }) => {
                    return (
                        <Select.Option key={ID} value={Name}>{Name}</Select.Option>
                    );
                });
                setProductSelectOptions(ls);
            } catch (e) {
                setTip({ open: true, severity: "error", msg: "load product list error" });
            }
        }, 300);
    }

    const handleSearchDistributor = (v) => {
        if (refTimerDistributorSearcher.current != null) {
            clearTimeout(refTimerDistributorSearcher.current);
        }
        refDistributorSearchValue.current = v;

        refTimerDistributorSearcher.current = setTimeout(async () => {
            const resp = await http.post("distributor/name/list", {
                Value: v,
            }).then(async (r) => {
                return await r.json();
            }).catch((e) => {
                console.log("[DeviceManagement]", e);
                return null;
            });

            if (refDistributorSearchValue.current !== v) {
                return;
            }

            try {
                if (resp.code !== 200) {
                    setTip({ open: true, severity: "error", msg: (resp && resp.msg) ? resp.msg : "load distributor list error" });
                    return;
                }

                const ls = resp.data.map(({ Name, ID }) => {
                    return (
                        <Select.Option key={ID} value={Name}>{Name}</Select.Option>
                    );
                });
                setDistributorSelectOptions(ls);
            } catch (e) {
                console.log("[DeviceManagement]", e);

                setTip({ open: true, severity: "error", msg: "load distributor list error" });
            }
        }, 300);
    }

    const handleSearch = () => {
        setPageNo(0);

        const ps = form.getFieldsValue();
        ps.SN = ps.SN || "";
        ps.ProductName = ps.ProductName || "";
        ps.DistributorName = ps.DistributorName || "";
        ps.UserID = ps.UserID || "";
        //ps.Timezone = ps.Timezone || "";
        ps.STime = ps.DateRange && ps.DateRange.length > 0 ? ps.DateRange[0].format("YYYY-MM-DD") : "";
        ps.ETime = ps.DateRange && ps.DateRange.length > 1 ? moment(ps.DateRange[1]).add(1, "days").format("YYYY-MM-DD") : "";
        setParams(ps);
    }

    const handleAddIssue = () => {
        setShowAddDeviceIssue(true);
    }

    const handleAddSolution = (id) => {
        setIssueIDofSolution(id);
        setShowAddDeviceIssueSolution(true);
    }

    const calcTableBodyHeight = () => {
        if (!refTableContainer.current) {
            return 240;
        }

        const hd = document.getElementsByClassName("ant-table-thead")[0];

        const h = refTableContainer.current.getBoundingClientRect().bottom - hd.getBoundingClientRect().bottom;

        return h >= 54 ? h - 54 : h / 2;
    }

    //按下Enter键时进行查找
    const searchByEnter = (e) => {
        if (e.code == "Enter") {
            handleSearch()
        }
    }

    //Solution列表
    const IssueTable = (r) => {
        const columns = [{
            title: "Solution",
            dataIndex: 'Content',
            key: 'Content',
            width: 300,
            ellipsis: {
                showTitle: false,
            },
            render: v => {
                return <Tooltip placement='topLeft' title={v}>{v}</Tooltip>
            }
        }, {
            title: "Solver",
            dataIndex: 'Solver',
            width: 120,
            key: 'Solver',
        }, {
            title: "Recorder",
            dataIndex: 'Recorder',
            key: 'Recorder',
            width: 120,
        }, {
            title: 'Images',
            dataIndex: 'SolveImage',
            width: 320,
            render: v => {
                if (v == null) return null;
                const images = v.split(',');
                const len = images.length;
                const maxWidth = len <= 5 ? 60 : 300/len;
                const maxHeight = len <= 5 ? 60 : 300/len;
                return <Image.PreviewGroup>
                    {images.map((item) => {
                        return <Image src={config.apiUrl + item} style={{ maxWidth: maxWidth, maxHeight: maxHeight }} />
                    })}
                </Image.PreviewGroup>
            }
        }, {
            title: "Solve Time",
            dataIndex: 'CreateTime',
            key: 'CreateTime',
            width: 220,
            render: (v, r, idx) => {
                if (r["GmtOffset"] == null) return v;
                var n = parseInt(r["GmtOffset"])
                var tz = "UTC+"
                if (n < 0) {
                    tz = "UTC-"
                    n = Math.abs(n)
                }
                tz += Math.floor(n / 3600).toString().padStart(2, "0") + ":" + Math.floor((n % 3600) / 60).toString().padStart(2, "0")
                return v + '(' + tz + ')'
            }
        }, {
            title: "Action",
            key: 'Action',
            width: 200,
            render: (v, r, idx) => {
                return <Button type="link" onClick={() => { handleDeleteSolution(r.ID, r.IssueID, idx) }}>Delete</Button>
            }
        }]
        return <Table
            columns={columns}
            dataSource={r["Solutions"]}
            pagination={false}
            size="small"
        />
    }

    const closeDeviceIssueAdd = (row) => {
        setShowAddDeviceIssue(false)
        if (!row) {
            return
        }
        setRowTotal(rowTotal + 1)
        setRows([row, ...rows])
    }

    const closeDeviceIssueSolutionAdd = (row) => {
        setShowAddDeviceIssueSolution(false)
        if (!row) {
            return
        }
        const rows2 = rows.map(r => {
            if (r["ID"] === row["IssueID"]) {
                return { ...r, Solutions: r.Solutions ? [row, ...r["Solutions"]] : [row] }
            } else {
                return r;
            }
        })
        setRows(rows2)
    }

    const handleDeleteIssue = async (id, idx) => {
        Modal.confirm({
            title: 'Do you Want to delete the issue?',
            icon: <ExclamationCircleOutlined />,
            content: '',
            onOk: async () => {
                try {
                    const resp = await http.post("after-sale/device/issue/delete", { ID: id }).then(async (r) => {
                        return await r.json();
                    }).catch((e) => {
                        return null;
                    });

                    if (resp.code !== 200) {
                        setTip({ open: true, severity: "error", msg: (resp && resp.msg) ? resp.msg : "delete error" });
                        return
                    }
                    setTip({ open: true, severity: 'success', msg: 'delete success' });

                    setRows([...rows.slice(0, idx), ...rows.slice(idx + 1)]);
                    setRowTotal(rowTotal - 1);
                } catch (e) {
                    setTip({ open: true, severity: "error", msg: "delete error" });
                }
            },
            onCancel() {
                //
            },
        });
    }

    const handleDeleteSolution = (id, issueID, idx) => {
        Modal.confirm({
            title: 'Do you Want to delete this solution?',
            icon: <ExclamationCircleOutlined />,
            content: '',
            onOk: async () => {
                try {
                    const resp = await http.post("after-sale/device/issue/solution/delete", { ID: id }).then(async (r) => {
                        return await r.json();
                    }).catch((e) => {
                        return null;
                    });

                    if (resp.code !== 200) {
                        setTip({ open: true, severity: "error", msg: (resp && resp.msg) ? resp.msg : "delete error" });
                        return
                    }
                    setTip({ open: true, severity: 'success', msg: 'delete success' });

                    const rows2 = rows.map(r => {
                        if (r.ID == issueID) {
                            return { ...r, Solutions: r.Solutions.filter((s, i) => i !== idx) };
                        } else {
                            return r;
                        }
                    })
                    setRows(rows2);
                } catch (e) {
                    setTip({ open: true, severity: "error", msg: "delete error" });
                }
            },
            onCancel() {
                //
            },
        });
    }

    //导出
    const handleExportDeviceIssueSolution = () => {
        refDownloader.current && refDownloader.current.click()
    }

    return (
        <div style={{
            flex: "1 1 auto",
            display: "flex",
            flexDirection: "column",
            backgroundColor: '#EFF8FE',
            padding: 8,
        }}>
            <div style={{
                flex: "0 1 auto",
            }}>
                <Form
                    form={form}
                    initialValues={{
                        DateRange: [moment(), moment()]
                    }}
                    style={{
                        padding: 24,
                        background: '#fbfbfb',
                        border: '1px solid #d9d9d9',
                        borderRadius: 2
                    }}
                >
                    <Row gutter={24}>
                        <Col span={8} key="SN">
                            <Form.Item
                                name="SN"
                                label=""
                            >
                                <Input prefix={<InputPrefix label="SN" />} placeholder="Enter Device SN" onPressEnter={handleSearch} style={{ borderRadius: '20px' }} />
                            </Form.Item>
                        </Col>
                        <Col span={6} offset={2} key="Handled" >
                            <Button type="primary" shape='round' onClick={handleSearch} style={{ marginRight: '8px' }}>
                                Search
                            </Button>
                            <a
                                style={{ fontSize: 12, marginRight: '20px' }}
                                onClick={() => {
                                    setExpand(!expand);
                                }}
                            >
                                {expand ? <UpOutlined /> : <DownOutlined />} {expand ? 'Collapse' : 'Expand'}
                            </a>
                        </Col>
                        <Col span={8} key="DateRange" >
                            <Form.Item
                                name="DateRange"
                                label=""
                            >
                                <DatePicker.RangePicker style={{ borderRadius: '20px' }} />
                            </Form.Item>
                        </Col>
                        <Col span={8} key="Product" style={{ display: expand ? "block" : "none" }}>
                            <Input.Group compact>
                                <Button>Product</Button>
                                <Form.Item
                                    name="ProductName"
                                    label=""
                                    style={{ display: 'inline-block', width: 'calc(100% - 80px)' }}
                                >
                                    <Select
                                        showSearch
                                        placeholder="Enter Product Name"
                                        defaultActiveFirstOption={false}
                                        showArrow={false}
                                        filterOption={false}
                                        onSearch={handleSearchProduct}
                                        onInputKeyDown={searchByEnter}
                                    >{productSelectOptions}</Select>
                                </Form.Item>
                            </Input.Group>
                        </Col>
                        <Col span={8} key="Distributor" style={{ display: expand && session != null && (session.IsAdmin == 1 || session.IsDistributorUser == 1) ? "block" : "none" }}>
                            <Input.Group compact>
                                <Button>Distributor</Button>
                                <Form.Item
                                    name="DistributorName"
                                    label=""
                                    style={{ display: 'inline-block', width: 'calc(100% - 100px)' }}
                                >
                                    <Select
                                        showSearch
                                        placeholder="Enter Distributor Name"
                                        defaultActiveFirstOption={false}
                                        showArrow={false}
                                        filterOption={false}
                                        onSearch={handleSearchDistributor}
                                        onInputKeyDown={searchByEnter}
                                    >{distributorSelectOptions}</Select>
                                </Form.Item>
                            </Input.Group>
                        </Col>
                        <Col span={8} key="UserID" style={{ display: expand && session != null && (session.IsAdmin == 1 || session.IsDistributorUser == 1) ? "block" : "none" }}>
                            <Form.Item
                                name="UserID"
                                label=""
                            >
                                <Input prefix={<InputPrefix label='UserID' />} placeholder="Enter User ID" onPressEnter={handleSearch} style={{ borderRadius: '20px' }} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24} style={{ textAlign: 'right' }}>
                            <Button
                                shape='round'
                                onClick={() => {
                                    form.resetFields();
                                }}
                            >
                                Clear
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </div>
            <div style={{
                flex: '0 0 auto',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end',
                paddingTop: 8,
                paddingBottom: 8,
            }}>
                <Button onClick={handleAddIssue} type="primary" icon={<PlusOutlined />} size="middle" shape="round">Add Issue</Button>
                <Button onClick={handleExportDeviceIssueSolution} disabled={rowTotal === 0} type="primary" icon={<CloudDownloadOutlined />} size="middle" shape="square" style={{ marginLeft: 5 }} />
            </div>
            <div style={{
                flex: "1 1 1px",
                position: "relative",
            }}>
                <div ref={refTableContainer} style={{ position: "absolute", height: '100%', width: "100%", overflow: "hidden" }}>
                    <Table
                        columns={[{
                            title: "",
                            width: 40,
                            fixed: 'left',
                            render: (v, r, idx) => {
                                return (pageNo * pageSize + idx + 1);
                            }
                        }, {
                            title: "SN",
                            dataIndex: "SN",
                            width: 180,
                            fixed: 'left',
                        }, {
                            title: "Product",
                            dataIndex: 'ProductName',
                            width: 120,
                        }, {
                            title: "Distributor",
                            dataIndex: "DistributorName",
                            width: 120,
                        }, {
                            title: 'Issue',
                            dataIndex: 'Content',
                            width: 320,
                            ellipsis: {
                                showTitle: false,
                            },
                            render: v => {
                                return <Tooltip placement='topLeft' title={v}>{v}</Tooltip>
                            }
                        }, {
                            title: 'Asker',
                            dataIndex: 'Asker',
                            width: 160,
                        }, {
                            title: 'Images',
                            dataIndex: 'Image',
                            width: 320,
                            render: v => {
                                if (v == null) return null;
                                const images = v.split(',');
                                const len = images.length;
                                const maxWidth = len <= 5 ? 60 : 300/len;
                                const maxHeight = len <= 5 ? 60 : 300/len;
                                return <Image.PreviewGroup>
                                    {images.map((item) => {
                                        return <Image src={config.apiUrl + item} style={{ maxWidth: maxWidth, maxHeight: maxHeight }} />
                                    })}
                                </Image.PreviewGroup>
                            }
                        }, {
                            title: 'CreateTime',
                            dataIndex: 'CreateTime',
                            width: 220,
                            render: (v, r, idx) => {
                                //return moment(v).format('YYYY-MM-DD HH:mm:ss')
                                if (r["GmtOffset"] == null) return v;
                                var n = parseInt(r["GmtOffset"])
                                var tz = "UTC+"
                                if (n < 0) {
                                    tz = "UTC-"
                                    n = Math.abs(n)
                                }
                                tz += Math.floor(n / 3600).toString().padStart(2, "0") + ":" + Math.floor((n % 3600) / 60).toString().padStart(2, "0")
                                return v + '(' + tz + ')'
                            }
                        }, {
                            title: 'Action',
                            width: 200,
                            render: (v, r, idx) => {
                                return (<>
                                    <Button type="link" onClick={() => handleAddSolution(r['ID'])}>Add Solution</Button>
                                    <Button type="link" onClick={() => { handleDeleteIssue(r.ID, idx) }}>Delete</Button>
                                </>)

                            }
                        }
                        ]}
                        pagination={{
                            pageSizeOptions: [25, 50, 100],
                            current: pageNo + 1,
                            pageSize: pageSize,
                            total: rowTotal,
                            showSizeChanger: true,
                            showTotal: (total) => { return `Total ${total}` },
                            onShowSizeChange: (current, size) => {
                                setPageSize(size);
                            },
                            onChange: (page, pageSize) => {
                                setPageNo(page - 1);
                            }
                        }}
                        tableLayout="fixed"
                        bordered={true}
                        size="middle"
                        loading={loading}
                        rowKey="ID"
                        expandable={{
                            expandedRowRender: r => IssueTable(r)
                        }}
                        dataSource={rows}
                        scroll={{ x: 1500, y: tableBodyHeight }}
                    />
                </div>
            </div>

            {/* Add Device Issue */}
            <DeviceIssueAdd show={showAddDeviceIssue} onClose={closeDeviceIssueAdd} />

            {/* Add Device Issue Solution */}
            <DeviceIssueSolutionAdd show={showAddDeviceIssueSolution} IssueID={issueIDofSolution} onClose={closeDeviceIssueSolutionAdd} />

            {/* download */}
            <a ref={refDownloader} style={{ display: "none" }} href={config.apiUrl + "data-export/device/issue/download/do?Token=" + http.token + "&SN=" + params.SN + "&DistributorName=" + params.DistributorName + "&ProductName=" + params.ProductName + "&UserID=" + params.UserID + "&STime=" + params.STime + "&ETime=" + params.ETime + "&Timezone=" + (-(new Date()).getTimezoneOffset() * 60)}></a>

        </div>
    );
}

export default AfterSale;