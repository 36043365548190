import React, { useEffect, useState, useContext } from 'react';
import RuntimeParameter from './RuntimeParameter';
import EnergyStatistics from './EnergyStatistics';
import DataHistory from './DataHistory';
import AlarmHistory from './AlarmHistory';
import DryContactData from './DryContactData';
import AirConditionerData from './AirConditionerData';

function DataAnalysis(props) {
    const {inverterSelectOptions, SN, protocol, handleSelectSN} = props
    const [activeMenu, setActiveMenu] = useState(0) 

    return (
        <div className="data-analysis">
            <div className="data-nav"> {/* 左侧菜单栏 */}
                <ul>
                    <li className={activeMenu==0 ? 'active' : null} onClick={() => setActiveMenu(0)}>Runtime Parameter</li>
                    <li className={activeMenu==1 ? 'active' : null} onClick={() => setActiveMenu(1)}>Energy Statistics</li>
                    <li className={activeMenu==2 ? 'active' : null} onClick={() => setActiveMenu(2)}>Historical Data</li>
                    <li className={activeMenu==3 ? 'active' : null} onClick={() => setActiveMenu(3)}>Historical Event</li>
                    <li className={activeMenu==4 ? 'active' : null} onClick={() => setActiveMenu(4)}>DryContact Data</li>
                    <li className={activeMenu==5 ? 'active' : null} onClick={() => setActiveMenu(5)}>AirConditioner Data</li>
                </ul>
            </div>
            <div className="data-content">  {/* 主体内容 */}
                { 
                    activeMenu == 0 ? <RuntimeParameter inverterSelectOptions={inverterSelectOptions} SN={SN} protocol={protocol} handleSelectSN={handleSelectSN} /> : 
                    activeMenu == 1 ? <EnergyStatistics inverterSelectOptions={inverterSelectOptions} SN={SN} protocol={protocol} handleSelectSN={handleSelectSN} /> :
                    activeMenu == 2 ? <DataHistory inverterSelectOptions={inverterSelectOptions} SN={SN} protocol={protocol} handleSelectSN={handleSelectSN} /> :
                    activeMenu == 3 ? <AlarmHistory inverterSelectOptions={inverterSelectOptions} SN={SN} protocol={protocol} handleSelectSN={handleSelectSN} /> : 
                    activeMenu == 4 ? <DryContactData inverterSelectOptions={inverterSelectOptions} SN={SN} protocol={protocol} handleSelectSN={handleSelectSN} /> :
                    activeMenu == 5 ? <AirConditionerData inverterSelectOptions={inverterSelectOptions} SN={SN} protocol={protocol} handleSelectSN={handleSelectSN} /> :
                    null
                }
            </div>
        </div>
    )
}

export default DataAnalysis;