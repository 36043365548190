import http from '../../../Http';
import { useEffect, useState, useContext, useRef } from 'react';
import utils from '../../../utils';
import { DatePicker , Input, Button, Form, Row, Col, Select,Collapse} from 'antd';
import { EditOutlined, ClockCircleOutlined } from '@ant-design/icons';
import WS from "../WS";
import CRC from './ModbusCRC';
import moment from 'moment';


function ThinkPowerSetting(props) {
    const setTip = useContext(utils.tipContext);
    const {SN, SettingDefs} = props;
   
    const [online, setOnline] = useState(false);
    const [pendingValues, setPendingValues] = useState({});
    const [doingsResult, setDoingsResult] = useState({});
    const [writeErrors, setWriteErrors] = useState({});
    const [turnOn, setTurnOn] = useState(null)
    

    const refComfirmedValues = useRef({});
    const refPendingValues = useRef({});
    const refDoingsResult = useRef({});
    


    const [form] = Form.useForm();

    const defMap = {};
    const defs = [];
    var subDefs = [];
    var lastCategory = null;
    var vs = {}
    for (var k in SettingDefs) {
        var def = SettingDefs[k];
        var address = def.RegisterAddressNumber;
        defMap[address] = def;
        if (lastCategory != def.Category) {
            subDefs = [];
            defs.push(subDefs);
            lastCategory = def.Category;
        }
        subDefs.push(def);
        //
        vs[address] = def.Value;
        if (def.Scale && def.Value != null && !isNaN(def.Value)) {
            vs[address] = def.Value * def.Scale;
            vs[address] = vs[address].toFixed(def.Scale.toString().length-2 > 0 ? def.Scale.toString().length-2 : 0);
        }
    }
    if (Object.keys(vs).length != Object.keys(refComfirmedValues.current).length) {
        refComfirmedValues.current = {...vs};
        // form.setFieldsValue(vs);
        setTimeout(()=>setFormFieldsValue(vs), 50);
        setTurnOn(vs[44001])
    }

    const hex2Array = (hex) => {
        var arr = new Uint8Array(hex.match(/[\da-f]{2}/gi).map((h)=>parseInt(h, 16)));
        return Array.prototype.slice.call(new Uint8Array(arr.buffer )); 
    }

    const Array2hex = (arr) => {
        return arr.map((v) => v.toString(16).padStart(2, "0")).join("");
    }

    const setFormFieldsValue = (vs) => {
        for (var k in vs) {
            var v = vs[k]
            if (defMap[k].RegisterAddressNumber == 42028) {
                v = moment.unix(v);
                vs[k] = v;
                console.log("unix to memont", v)
            }
        }
        //form.setFieldsValue(vs)
        Object.keys(refPendingValues.current).length == 0 && form.setFieldsValue(vs)   //2024-5-31
    }

    const handleWSConnect = () => {
        setOnline(true);
    }

    const handleWSData = (data) => {
        if (!data || data.InverterSN != SN) {
            return;
        }  
        
        var req = hex2Array(data.Request);
        var resp = hex2Array(data.Response);
        // console.log("[Setting-Read-Response]", req, resp)
        if (req[1] == 0x03) {
            var address = (req[2]<<8) + req[3];
            var count = (req[4]<<8) + req[5];
            var vs = {};
            for (var i=0; i<count; i++) {
                var addr = address + i;
                var idx = i*2 + 3;
                if (defMap.hasOwnProperty(addr)) {
                    var def = defMap[addr];
                    switch (def.RegisterType) {
                        case "U16":
                            var v = (resp[idx]<<8) + resp[idx + 1];
                            vs[addr] = v;
                            break;
                        case "I16":
                            var v = (resp[idx]<<8) + resp[idx + 1];
                            if (v >0x7fff) {
                                v -= 0xffff + 1;
                            }
                            vs[addr] = v;
                            break;
                        case "U32":
                            var v = (resp[idx]<<24) + (resp[idx+1]<<16) + (resp[idx+2]<<8) + resp[idx + 3];
                            vs[addr] = v;
                            break;
                        case "I32":
                            var v = (resp[idx]<<24) + (resp[idx+1]<<16) + (resp[idx+2]<<8) + resp[idx + 3];
                            if (v >0x7fffffff) {
                                v -= 0xffffffff + 1;
                            }
                            vs[addr] = v;
                            break;
                    }
                    i += def.RegisterNumber - 1;
                }
            }
            if (Object.keys(vs).length > 0) {
                for (var k in vs) {
                    var def = defMap[k];
                    if (def.Scale) {
                        vs[k] *= def.Scale;
                        vs[k] = vs[k].toFixed(def.Scale.toString().length-2 > 0 ? def.Scale.toString().length-2 : 0);
                    }
                }
                refComfirmedValues.current = {...refComfirmedValues.current, ...vs}
                // form.setFieldsValue(refComfirmedValues.current);
                setFormFieldsValue({...refComfirmedValues.current});

                var pvs = refPendingValues.current;
                var pvs_changed = false;
                for (var k in vs) {
                    if (pvs.hasOwnProperty(k)) {
                        pvs_changed = true;
                        delete pvs[k];
                    }
                }
                if (pvs_changed) {
                    setPendingValues({...pvs});
                }
            }
        } else if (req[1] == 0x06 ) {
            var address = (req[2]<<8) + req[3];
            if (address >= 44002 && address <= 44004) {
                if (resp[1] == 0x06) {
                    refDoingsResult.current[address] = "OK";
                } else {
                    refDoingsResult.current[address] = "Error";
                }
                setDoingsResult({...refDoingsResult.current})
            } else if (address == 44001) {
                if (resp[1] == 0x06) {
                    var v = (resp[4]<<8) + resp[5];
                    setTurnOn(v);
                }
            }            
        } else if (req[1] == 0x10 && resp[1] == 0x10) {
            var address = (req[2]<<8) + req[3];
            var count = (req[4]<<8) + req[5];
            var vs = {};
            for (var i=0; i<count; i++) {
                var addr = address + i;
                var idx = i*2 + 7;
                if (defMap.hasOwnProperty(addr)) {
                    var def = defMap[addr];
                    switch (def.RegisterType) {
                        case "U16":
                            var v = (req[idx]<<8) + req[idx + 1];
                            vs[addr] = v;
                            break;
                        case "I16":
                            var v = (req[idx]<<8) + req[idx + 1];
                            if (v >0x7fff) {
                                v -= 0xffff + 1;
                            }
                            vs[addr] = v;
                            break;
                        case "U32":
                            var v = (req[idx]<<24) + (req[idx+1]<<16) + (req[idx+2]<<8) + req[idx + 3];
                            vs[addr] = v;
                            break;
                        case "I32":
                            var v = (req[idx]<<24) + (req[idx+1]<<16) + (req[idx+2]<<8) + req[idx + 3];
                            if (v >0x7fffffff) {
                                v -= 0xffffffff + 1;
                            }
                            vs[addr] = v;
                            break;
                    }
                    i += def.RegisterNumber - 1;
                }
            }
            if (Object.keys(vs).length > 0) {
                for (var k in vs) {
                    var def = defMap[k];
                    if (def.Scale) {
                        vs[k] *= def.Scale;
                        vs[k] = vs[k].toFixed(def.Scale.toString().length-2 > 0 ? def.Scale.toString().length-2 : 0);
                    }
                }
                refComfirmedValues.current = {...refComfirmedValues.current, ...vs}
                // form.setFieldsValue(refComfirmedValues.current);
                setFormFieldsValue(refComfirmedValues.current);

                var pvs = refPendingValues.current;
                var pvs_changed = false;
                for (var k in vs) {
                    if (pvs.hasOwnProperty(k)) {
                        pvs_changed = true;
                        delete pvs[k];
                    }
                }
                if (pvs_changed) {
                    setPendingValues({...pvs});
                }
            }
        }
    }

    const handleWSClose = () => {
        setOnline(false)
    }

    const handleWSError = () => {
        //
    }

    const handleReset = (Category) => {
        var vs = {};
        var pvs = refPendingValues.current;
        var cvs = refComfirmedValues.current;
        var had = false;
        for (var k in pvs) {
            var def = defMap[k];
            if ( defMap.hasOwnProperty(k) && def.Category == Category) {
                had = true;
                vs[k] = cvs[k];
                delete pvs[k];
            }
        } 
        if (had) {
            // form.setFieldsValue(vs);
            setFormFieldsValue(vs);
            setPendingValues({...pvs});
        }
    }

    const handleRead = async (Category) => {
        var address = 0xffff;
        var count = 0;

        console.log("[Setting-Read]", Category);

        for (var i in SettingDefs) {
            var def = SettingDefs[i];
            if (def.Category == Category) {
                if (address > def.RegisterAddressNumber) {
                    address = def.RegisterAddressNumber;
                }
                count += def.RegisterNumber;
            } else {
                if (address != 0xffff) {
                    break;
                }
            }
        }

        if (address == 0xffff) {
            return;
        }

        console.log("[Setting-Read]", address.toString(16), count);

        try {
            var SettingRead = {}
            SettingRead[address.toString(16)] = count
            const resp = await http.postJSON("integrated-inverter/setting/request", {
                SerialNumber: SN,
                SettingRead: SettingRead,
            }).then(async (r) => {
                return await r.json();
            }).catch((e) => {
                console.log("[Setting]", e);
                return null;
            });
        } catch (e) {
            console.log("[Setting]", e);

            setTip({ open: true, severity: "error", msg: "Read error" });
        }
    }

    const handleWrite = async (Category) => {
        var pvs = refPendingValues.current;
        if (Object.keys(pvs).length <= 0) {
            return;
        } 

        setWriteErrors({});

        var cvs = refComfirmedValues.current;
        var address = 0xffff;
        var count = 0;
        var bs = [];
        var ok = true;
        for (var i in SettingDefs) {
            var def = SettingDefs[i];
            if (def.Category == Category) {
                if (address > def.RegisterAddressNumber) {
                    address = def.RegisterAddressNumber;
                }
                count += def.RegisterNumber;
                
                var v = 0;
                if (pvs.hasOwnProperty(def.RegisterAddressNumber)) {
                    v = pvs[def.RegisterAddressNumber];
                } else if (cvs.hasOwnProperty(def.RegisterAddressNumber)) {
                    v = cvs[def.RegisterAddressNumber];
                } else {
                    ok = false;
                    break;
                }
                if (isNaN(v)) {
                    ok = false;
                    break;
                }

                if (def.Scale) {
                    v /= def.Scale;
                    v = Math.round(v);
                }

                switch (def.RegisterType) {
                    case "U16":
                        bs.push((v>>8) & 0xff, v & 0xff);
                        break;
                    case "I16":
                        if (v < 0) {
                            v += 0xffff + 1;
                        }
                        bs.push((v>>8) & 0xff, v & 0xff);
                        break;
                    case "U32":
                        bs.push((v>>24) & 0xff, (v>>16) & 0xff, (v>>8) & 0xff, v & 0xff);
                        break;
                    case "I32":
                        if (v < 0) {
                            v += 0xffffffff + 1;
                        }
                        bs.push((v>>24) & 0xff, (v>>16) & 0xff, (v>>8) & 0xff, v & 0xff);
                        break;
                }
            }
        }

        if (!ok || bs.length != count*2) {
            return;
        }

        bs = [0x01, 0x10, (address>>8)&0xff, address&0xff, (count>>8)&0xff, count&0xff, count*2, ...bs];
        var str = CRC.ToModbusCRC16(bs);
        str = Array2hex(bs) + str;
        console.log("MODBUS", str);

        try {
            const resp = await http.postJSON("integrated-inverter/setting/request", {
                SerialNumber: SN,
                Setting: {
                    [address.toString(16)]: str,
                },
            }).then(async (r) => {
                return await r.json();
            }).catch((e) => {
                console.log("[Setting]", e);
                return null;
            });
        } catch (e) {
            console.log("[Setting]", e);

            setTip({ open: true, severity: "error", msg: "Write error" });
        }
    }

    const handleTurnOn = async (RegisterAddressNumber, Action) => {
        console.log("[RegisterAddressNumber]", RegisterAddressNumber, Action);
        try {
            const resp = await http.postJSON("integrated-inverter/setting/request", {
                SerialNumber: SN,
                Setting: {
                    [parseInt(RegisterAddressNumber).toString(16)]: Action,
                },
            }).then(async (r) => {
                return await r.json();
            }).catch((e) => {
                console.log("[Setting]", e);
                return null;
            });
        } catch (e) {
            console.log("[Setting]", e);

            setTip({ open: true, severity: "error", msg: "Write error" });
        }
    }

    const handleDo = async (RegisterAddressNumber) => {
        console.log("[RegisterAddressNumber]", RegisterAddressNumber);
        try {
            const resp = await http.postJSON("integrated-inverter/setting/request", {
                SerialNumber: SN,
                Setting: {
                    [parseInt(RegisterAddressNumber).toString(16)]: 1,
                },
            }).then(async (r) => {
                return await r.json();
            }).catch((e) => {
                console.log("[Setting]", e);
                return null;
            });
        } catch (e) {
            console.log("[Setting]", e);

            setTip({ open: true, severity: "error", msg: "Write error" });
        }
    }

    const editor_onchange_handler = (props) => {
        console.log("[Setting]", props);
        var cvs = refComfirmedValues.current;
        var pvs = refPendingValues.current;
        var pvs_changed = false;
        for (var k in props) {
            var v = props[k];
            if (defMap[k].RegisterAddressNumber == 42028) {
                if (v) { 
                    v = v.unix();

                }
                console.log("unix", v)
            }
            if (cvs[k] == v) {
                if (pvs.hasOwnProperty(k)) {
                    delete pvs[k];
                    pvs_changed = true;
                }
            } else {
                pvs[k] = v;
                pvs_changed = true;
            }
        }
        if (pvs_changed) {
            setPendingValues({...pvs});
        }
    } 

    var elems = [];
    const toRow = (cells, rows) => {
        var cols = [];
        for (var i in cells) {
            var cell = cells[i];
            // console.log("[Cell]==>", cell);
            cols.push(<Col span={12}>
                {cell}
            </Col>)
        }
        // console.log("[Row]==>", cols);
        rows.push(<Row>
            {cols}
        </Row>); 
    }
    if (SettingDefs == null) {
        elems.push("waiting...");
    } else {
        for (var i in defs) {
            subDefs = defs[i];
            var rows = [];
            var cells = []; 
            for (var j in subDefs) {
                def = subDefs[j];
                if (cells.length >= 2) {
                    toRow(cells, rows);
                    cells = [];
                }
                
                var editor = null;
                if (def.RegisterAddressNumber == 44001) {
                    editor = (<>
                        <Button 
                            type="primary" 
                            size="medium"
                            shape="round"
                            style={{
                                backgroundColor: "#40a9ff",
                                color: "white",
                                textTransform: "none"
                            }}
                            data-register-address={def.RegisterAddressNumber} 
                            onClick={(e) => {handleTurnOn(e.currentTarget.getAttribute("data-register-address"), 0); e.stopPropagation()}}
                        >Turn&nbsp;<span style={{color:(turnOn==0?"#00FF7F":"white")}}>On</span></Button>
                        <span>&nbsp;</span>
                        <Button 
                            type="primary" 
                            size="medium"
                            shape="round"
                            style={{
                                backgroundColor: "#40a9ff",
                                color: "white",
                                textTransform: "none",
                            }}
                            data-register-address={def.RegisterAddressNumber} 
                            onClick={(e) => {handleTurnOn(e.currentTarget.getAttribute("data-register-address"), 1); e.stopPropagation()}}
                        >Turn &nbsp;<span style={{color:(turnOn==1?"#C1FFC1":"white")}}>Off</span></Button>
                    </>)
                } else if (def.ReadWrite == "W") {
                    var doing = doingsResult[def.RegisterAddressNumber];
                    editor = <Button 
                        type="primary" 
                        size="medium"
                        shape="round"
                        style={{
                            backgroundColor: "#40a9ff",
                            color: "white",
                            textTransform: "none"

                        }}
                        data-register-address={def.RegisterAddressNumber} 
                        onClick={(e) => {handleDo(e.currentTarget.getAttribute("data-register-address")); e.stopPropagation()}}
                    >Do{doing ? (doing=="OK"?<span style={{color:"#C1FFC1"}}>&nbsp;√</span>:<span style={{color:"#FF6461"}}>&nbsp;×</span>) : ""}</Button>;
                } else {
                    if (def.RegisterAddressNumber == 42028) {
                        if (pendingValues.hasOwnProperty(def.RegisterAddressNumber)) {
                            editor = <DatePicker 
                                showTime 
                                placeholder='Select datetime'
                                suffixIcon={<EditOutlined />}
                            />
                        } else {
                            editor = <DatePicker 
                                showTime 
                                placeholder='Select datetime'
                            />
                        }
                    } else if (def.Enums) {
                        var Enums = def.Enums.split(";");
                        var opts = [];
                        for (var k in Enums) {
                            var vn = Enums[k].split(":");
                            opts.push(<Select.Option
                                key={vn[0]}
                                value={parseInt(vn[0])}
                            >{vn[1]}</Select.Option>);
                        }
                        editor = <Select
                            suffixIcon={pendingValues.hasOwnProperty(def.RegisterAddressNumber) ? <EditOutlined /> : undefined}
                        >{opts}</Select>;
                    } else {
                        editor = <Input  
                            suffix={pendingValues.hasOwnProperty(def.RegisterAddressNumber) ? <EditOutlined /> : <span />}
                            // precision={def.Scale ? def.Scale.toString().length-2 : undefined} 
                        />;
                    }
                }
                cells.push(<Form.Item
                    key={def.RegisterAddress}
                    label={def.Intls["English"]+(def.Unit?` (${def.Unit})`:"")}
                    name={def.RegisterAddressNumber}
                >
                    {editor}
                </Form.Item>);
            }
            if (cells.length > 0) {
                toRow(cells, rows);
            }
            elems.push(<Collapse.Panel
                key={i}
                header={<span style={{fontWeight: "bold"}}>{subDefs[0].Category}</span>}
                extra={subDefs[0].Category == "Setting-Instruction" ? "" : (<div style={{display:"flex"}}>
                    <Button 
                        type="primary" 
                        size="medium"
                        shape="round"
                        style={{
                            backgroundColor: "white",
                            color: "#888",
                            textTransform: "none",
                            border: "1px solid #eee"

                        }}
                        data-category={subDefs[0].Category} 
                        onClick={(e) => {handleReset(e.currentTarget.getAttribute("data-category")); e.stopPropagation()}}
                    >Reset</Button>
                    <div style={{ width: "30px" }}></div>
                    <Button 
                        type="primary" 
                        size="medium"
                        shape="round"
                        style={{
                            backgroundColor: "#40a9ff",
                            color: "white",
                            textTransform: "none"

                        }}
                        data-category={subDefs[0].Category} 
                        onClick={(e) => {handleRead(e.currentTarget.getAttribute("data-category")); e.stopPropagation()}}
                    >Read</Button>
                    <div style={{ width: "15px" }}></div>
                    <Button 
                        type="primary" 
                        size="medium"
                        shape="round"
                        style={{
                            backgroundColor: "#40a9ff",
                            color: "white",
                            textTransform: "none"

                        }} 
                        data-category={subDefs[0].Category} 
                        onClick={(e) => {handleWrite(e.currentTarget.getAttribute("data-category")); e.stopPropagation()}}
                    >Write</Button>
                </div>)}
            >
                {rows}
            </Collapse.Panel>);
        }
    }
    

    return (
        <div
            style={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
                overflow: "hidden",
            }}
        >{SettingDefs==null ? "waiting..." : (<>
            <div style={{
                flex: '1 1 auto',
                backgroundColor: 'white',
                overflowY: "scroll"
            }}>
                <Form
                    form={form}
                    labelCol={{ span: 12 }}
                    wrapperCol={{ span: 8 }}
                    layout="horizontal"
                    size="small"
                    style={{
                        width: "100%",
                    }}
                    onValuesChange={editor_onchange_handler}
                >
                    <Collapse defaultActiveKey={['0','1','2','3','4','5','6','7','8','9','10','11','12','13','14','15','16','17','18','19']}>{elems}</Collapse>
                </Form>
            </div>
            <WS
                SerialNumber={SN}
                onConnect={handleWSConnect}
                onData={handleWSData}
                onClose={handleWSClose}
                onError={handleWSError}
            ></WS>
        </>)}
        </div>
    );
}

export default ThinkPowerSetting;