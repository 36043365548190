import config from './Config';
import PhoneOutlinedIcon from '@material-ui/icons/PhoneOutlined';
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';
import VpnKeyOutlinedIcon from '@material-ui/icons/VpnKeyOutlined';
import Lock from '@material-ui/icons/Lock';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';
import { InputAdornment, TextField, Grid, IconButton, Button, Fab } from '@material-ui/core';
import { Dialog, DialogTitle, DialogContent, DialogActions, Checkbox } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import React, { useState } from 'react';
import cookie from 'react-cookies'
import http from './Http'
import MuiAlert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import useMediaQuery from '@material-ui/core/useMediaQuery';

function Login(props) {
    const isPortrait = useMediaQuery("(orientation:portrait)");

    let [userID, setUserID] = useState(cookie.load('userID'));
    let [userIDError, setUserIDError] = useState("");
    let [savePassword, setSavePassword] = useState(cookie.load('savePassword')==="true");
    let [password, setPassword] = useState(savePassword===true?cookie.load('password'):"");
    let [passwordError, setPasswordError] = useState("");
    let [showPassword, setShowPassword] = useState(false);
    let [openResetPasswordDialog, setOpenResetPasswordDialog] = useState(false); 
    let [tip, setTip] = useState({open:false, severity:'', msg:''});

    const {onLoginSuccess} = props;

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            width: "100vw",
            height: '100vh',
        }}>
            <div style={{
                height: "70px",
                backgroundColor: 'white',
                display: 'flex',
                flexDirection: 'row',
                justifyContent:'space-between',
                alignItems: 'center',
            }}>
                <div>
                    &nbsp;&nbsp;<span style={{fontSize:"24pt", fontWeight:"bolder", color:"darkslateblue"}}>{config.appName}</span>
                </div>
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                }}>
                    <PhoneOutlinedIcon/>
                    <span>+86-22-59901199</span>&nbsp;&nbsp;&nbsp;&nbsp;
                </div>
            </div>
            <div style={{
                flex: '1 1 auto',
                backgroundImage: 'url("images/bg-2.jpg")',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                display: 'flex',
                flexDirection: 'column',
            }}>
                <div style={{
                    flex: '1 1 auto',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: "center",
                    alignItems: 'center',
                }}>
                    <div style={{
                        // marginRight: isPortrait ? 0 : '50px',
                        border: '0px solid #888',
                        borderRadius: '10px',
                        padding: '30px',
                        backgroundColor: '#eee',
                        boxShadow: '4px 7px 5px rgba(35,25,22, 0.25)',
                    }}>
                        <div>
                            <Grid container spacing={1} alignItems="flex-end">
                                <Grid item>
                                    <PersonOutlineOutlinedIcon htmlColor='#888'/>
                                </Grid>
                                <Grid item>
                                    <div style={{width:300}}>
                                        <TextField
                                            label="User ID" 
                                            value={userID}
                                            error={userIDError!==""}
                                            helperText={userIDError}
                                            onChange={(e)=>{
                                                let v = e.target.value;
                                                setUserID(v);
                                                setUserIDError("")
                                                cookie.save('userID', v);
                                            }}
                                            fullWidth={true}
                                        />
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                        <div>
                            <Grid container spacing={1} alignItems="flex-end">
                                <Grid item>
                                    <Lock htmlColor="#888"/>
                                </Grid>
                                <Grid item>
                                    <div style={{width:300}}>
                                        <TextField
                                            label="Password" 
                                            value={password}
                                            type={showPassword===true?"text":"password"}
                                            error={passwordError !== ""}
                                            helperText={passwordError}
                                            onChange={(e)=>{
                                                let v = e.target.value;
                                                setPassword(v);
                                                setPasswordError("");
                                                if (savePassword===true) {
                                                    cookie.save('password', v)
                                                }
                                            }}
                                            fullWidth={true}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            onClick={()=>{
                                                                setShowPassword(!showPassword);
                                                            }}
                                                            edge="end"
                                                        >
                                                            {showPassword ? <VisibilityOutlinedIcon /> : <VisibilityOffOutlinedIcon />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                        <div style={{
                            marginTop: 10,
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                        }}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        color="primary"
                                        checked={savePassword}
                                        onChange={(e)=>{
                                            let v = e.target.checked;
                                            setSavePassword(v);
                                            cookie.save("savePassword", v);
                                            if (v) {
                                                cookie.save("password", password);
                                            } else {
                                                cookie.remove("password");
                                            }
                                        }}
                                    />
                                }
                                label="Remeber Pwd"
                            />
                            <Button 
                                color="primary"
                                style={{
                                    color: "#1C8AC5",
                                }}
                                onClick={()=>{
                                    if (userID === "") {
                                        setUserIDError("UserID can not be empty");
                                        return;
                                    }

                                    setOpenResetPasswordDialog(true);
                                }}
                            >
                                    Reset PWD
                            </Button>
                            <ResetPasswordDialog 
                                userID={userID}
                                open={openResetPasswordDialog}
                                onClose={()=>{
                                    setOpenResetPasswordDialog(false);
                                }}
                                onTip={(t)=>{
                                    setTip(t);
                                }}
                            />
                        </div>
                        <div style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-around",
                        }}>
                            {/* Login To BMS */}
                            <div style={{
                                marginTop: 20,
                            }}>
                                <Fab
                                    variant="extended"
                                    size="medium"
                                    style={{
                                        backgroundColor: "#1C8AC5",
                                        color: "white",
                                        textTransform: "none"

                                    }}
                                    onClick={async() =>{
                                        if (userID === "") {
                                            setUserIDError("UserID can not be empty");
                                            return;
                                        }
                                        
                                        if (password === "") {
                                            setPasswordError("Password can not be empty");
                                            return;
                                        }
                                        
                                        let resp = await http.post("login", {
                                            "UserID": userID,
                                            "Password": password,
                                        }).then(async (r)=>{
                                            return await r.json();
                                        }).catch((e)=>{
                                            console.log("yy", e);
                                            return null;
                                        });
                                        
                                        if (resp == null || resp.code !== 200) {
                                            setTip({
                                                open: true,
                                                severity: "error",
                                                msg: resp && resp.msg ? resp.msg : "Login failure"
                                            });

                                            return;
                                        }

                                        onLoginSuccess(resp.data, "BMS");
                                    }}
                                >Login to BMS</Fab>
                            </div>
                            {/* Login To IMS */}
                            <div style={{
                                marginTop: 20,
                            }}>
                                <Fab
                                    variant="extended"
                                    size="medium"
                                    style={{
                                        backgroundColor: "#1C8AC5",
                                        color: "white",
                                        textTransform: "none"

                                    }}
                                    onClick={async() =>{
                                        if (userID === "") {
                                            setUserIDError("UserID can not be empty");
                                            return;
                                        }
                                        
                                        if (password === "") {
                                            setPasswordError("Password can not be empty");
                                            return;
                                        }
                                        
                                        let resp = await http.post("login", {
                                            "UserID": userID,
                                            "Password": password,
                                        }).then(async (r)=>{
                                            return await r.json();
                                        }).catch((e)=>{
                                            console.log("yy", e);
                                            return null;
                                        });
                                        
                                        if (resp == null || resp.code !== 200) {
                                            setTip({
                                                open: true,
                                                severity: "error",
                                                msg: resp && resp.msg ? resp.msg : "Login failure"
                                            });

                                            return;
                                        }

                                        onLoginSuccess(resp.data, "IMS");
                                    }}
                                >Login to IMS</Fab>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{
                    flex: 'none',
                    height: '200px',
                    backgroundColor: "rgba(85, 85, 85, 0.4)",
                    // opacity: 0.4
                    color: "white",
                    position:"relative",
                }}>
                    <div style={{
                        position: "absolute",
                        right: 0,
                        bottom: 0,
                        padding: 20,
                    }}>
                    RAYSTECH (TIANJIN) PV ENERGY CO., LTD 
                    </div>
                </div>
            </div>
            <Snackbar
                autoHideDuration={3000}
                open={tip.open} 
                anchorOrigin={{
                    horizontal:"right",
                    vertical:"bottom",
                }}
                onClose={()=>{
                    setTip({open:false, severity:tip.severity});
                }}
            >
                <MuiAlert 
                    elevation={6} 
                    variant="filled" 
                    severity={tip.severity}
                    onClose={()=>{
                        setTip({open:false, severity:tip.severity});
                    }}
                >
                    {tip.msg}
                </MuiAlert>
            </Snackbar>
        </div>
    )
}

function ResetPasswordDialog(props) {
    let [sn, setSN] = useState("");
    let [snError, setSNError] = useState("");

    return (
        <Dialog 
            fullWidth={true}
            maxWidth="xs"
            open={props.open} 
        >
            <DialogTitle>Reset Password</DialogTitle>
            <DialogContent dividers>
                <TextField 
                    value={sn}
                    label= "Enter any one of your device SN"
                    fullWidth={true}
                    error={snError!==""}
                    helperText={snError}
                    onChange={(e)=>{
                        let v = e.target.value;
                        setSNError("");
                        setSN(v);
                    }}
                />
            </DialogContent>
            <DialogActions>
                <Button 
                    color="primary"
                    onClick={async() =>{
                        if (sn === "") {
                            setSNError("SN can not be empty");
                            return;
                        }

                        let resp = await http.post("user/recover-password",{
                            "userID": props.userID,
                            "deviceSN": sn,
                        });
                        
                        let data = await resp.json();
                        if (data && data["code"] === 200) {
                            props.onClose();

                            props.onTip({open:true, severity:"success", msg: "Reset success and the new password is 123456"});
                        } else {
                            props.onTip({open:true, severity:"error", msg: data && data["msg"]!=null ? data["msg"] : "Reset error"});
                        }
                    }}
                >
                    Go Reset
                </Button>
                <Button
                    color="primary"
                    onClick={()=>{
                        props.onClose();
                    }}
                >
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default Login;