import http, { ThemeContext } from '../../../Http';
import { useEffect, useState, useContext, useRef } from 'react';
import utils from '../../../utils';
import { Table, Input, Button, Modal, Form, Row, Col } from 'antd';
import { PlusOutlined, DeleteOutlined, EditOutlined, ExclamationCircleOutlined, ImportOutlined, RetweetOutlined } from '@ant-design/icons';
import InputPrefix from '../../../utils/InputPrefix';
import DTUAdd from './DTUAdd.js';
import DTUModify from './DTUModify.js';
import { now } from 'moment/moment.js';


function DTUConfiguration(props) {
    const setTip = useContext(utils.tipContext);
 
    const session = useContext(ThemeContext);

    const [pageNo, setPageNo] = useState(0);
    const [pageSize, setPageSize] = useState(25);
    const [params, setParams] = useState({
        SN: '',
        HardwareVersion: '',
        FirmwareVersion: '',
    });
    const [loading, setLoading] = useState(false);
    const [rows, setRows] = useState([]);
    const [rowTotal, setRowTotal] = useState(0);
    const [tableBodyHeight, setTableBodyHeight] = useState(240);
    const [selectedKeys, setSelectedKeys] = useState([]);
    const [showAdd, setShowAdd] = useState(false);
    const [showModify, setShowModify] = useState(false);
    const [batchImportShow, setBatchImportShow] = useState(false);
    const [transferShow, setTransferShow] = useState(false);
    const [modifyRowIndex, setModifyRowIndex] = useState(null);

    const refTableContainer = useRef();

    const [form] = Form.useForm();

    useEffect(() => {
        let active = true;

        (async () => {
            setLoading(true);


            let resp = await http.post("dtu/list", {
                PageNo: pageNo,
                PageSize: pageSize,
                ...params,
            }).then(async (r) => {
                return await r.json();
            }).catch((e) => {
                console.log("[DTUConfiguration]", e);
                return null;
            });

            if (!active) {
                return;
            }

            if (resp === null || resp.code !== 200) {
                setTip({ open: true, severity: "error", msg: (resp && resp.msg) ? resp.msg : "load data error" });

                setRowTotal(0)
                setRows([]);

                return;
            }

            //console.log('DTU list: ', resp.data);

            setRowTotal(resp.data.RowTotal);
            setRows(resp.data.Rows);
            setLoading(false);
        })();

        return () => {
            active = false;
        };
    }, [pageNo, pageSize, params]);

    useEffect(() => {
        const el = refTableContainer.current;

        if (el.objResizer) {
            return;
        }

        let obj = document.createElement('object');
        el.objResizer = obj;
        obj.setAttribute('style',
            'display: block; position: absolute; top: 0; left: 0; height: 100%; width: 100%; overflow: hidden;opacity: 0; pointer-events: none; z-index: -1;');
        obj.onload = function () {
            setTableBodyHeight(calcTableBodyHeight());

            this.contentDocument.defaultView.addEventListener('resize', (evt) => {
                setTableBodyHeight(calcTableBodyHeight());
            });
        };
        obj.type = 'text/html';
        el.appendChild(obj);
        obj.data = 'about:blank';

        return () => {
            if (!el) {
                return
            }

            //
        }
    }, [refTableContainer.current]);

    const handleSearch = () => {
        setSelectedKeys([]);
        setPageNo(0);

        const ps = form.getFieldsValue();
        ps.SN = ps.SN || "";
        ps.HardwareVersion = ps.HardwareVersion || "";
        ps.FirmwareVersion = ps.FirmwareVersion || "";
        setParams(ps);
    }

    const renderAction = (v, r, idx) => {
        if(session != null && (session.IsAdmin == 1 || session.IsDistributorUser == 1)) {
            return (
                <>
                    <Button onClick={() => { handleModify(idx) }} type="link" icon={<EditOutlined size="small" />} />
                    <Button onClick={() => { handleDelete(r.ID, r.SN, idx) }} type="link" icon={<DeleteOutlined size="small" />} />
                </>
            )
        } else {
            return ''
        }
        
    }

    const handleAdd = () => {
        setShowAdd(true);
    }

    const handleBatchImport = () => {
        setBatchImportShow(true);
    }

    const handleTransfer = () => {
        setTransferShow(true);
    }

    const handleModify = (rowIndex) => {
        setModifyRowIndex(rowIndex);
        setShowModify(true);
    }

    const handleDelete = async (ID, Name, idx) => {
        Modal.confirm({
            title: 'Do you Want to delete this item?',
            icon: <ExclamationCircleOutlined />,
            content: Name,
            onOk: async () => {
                try {
                    const resp = await http.post("dtu/delete", { ID: ID }).then(async (r) => {
                        return await r.json();
                    }).catch((e) => {
                        return null;
                    });

                    if (resp.code !== 200) {
                        setTip({ open: true, severity: "error", msg: (resp && resp.msg) ? resp.msg : "delete error" });
                        return
                    }
                    setTip({ open: true, severity: 'success', msg: 'delete success' });

                    setRows([...rows.slice(0, idx), ...rows.slice(idx + 1)]);
                    setRowTotal(rowTotal - 1);
                    selectedKeys.every((v, i) => {
                        if (v === ID) {
                            setSelectedKeys([...selectedKeys.slice(0, i), ...selectedKeys.slice(i + 1)]);
                            return false;
                        }

                        return true;
                    })
                } catch (e) {
                    setTip({ open: true, severity: "error", msg: "delete error" });
                }
            },
            onCancel() {
                //
            },
        });
    }

    const handleDeleteAllOfSelected = async () => {
        if (selectedKeys.length < 1) {
            return;
        }

        let ns = [];
        selectedKeys.forEach((ID) => {
            rows.every((r) => {
                if (ID === r.ID) {
                    ns.push(<label>{r.SN}</label>);
                    return false;
                }

                return true;
            });
        })
        if (ns.length > 10) {
            ns = ns.slice(0, 10);
            ns.push(<lable>...</lable>);
        }

        Modal.confirm({
            title: 'Do you Want to delete these items?',
            icon: <ExclamationCircleOutlined />,
            content: (<div style={{ display: 'flex', flexDirection: 'column' }}>{ns}</div>),
            onOk: async () => {
                try {
                    const resp = await http.post("dtu/multi/delete", { IDs: selectedKeys.join(",") }).then(async (r) => {
                        return await r.json();
                    }).catch((e) => {
                        console.log("[DeviceManagement]", e);
                        return null;
                    });

                    if (resp.code !== 200) {
                        setTip({ open: true, severity: "error", msg: (resp && resp.msg) ? resp.msg : "delete error" });
                        return
                    }
                    setTip({ open: true, severity: 'success', msg: 'delete success' });

                    let rs = [];
                    rows.forEach((v, i) => {
                        const ID = v.ID;
                        let ok = false;
                        selectedKeys.every((ID2) => {
                            if (ID === ID2) {
                                ok = true;
                                return false;
                            }

                            return true;
                        });
                        if (!ok) {
                            rs.push(v);
                        }
                    });
                    setRows(rs);
                    setRowTotal(rowTotal - selectedKeys.length);
                    setSelectedKeys([]);
                } catch (e) {
                    setTip({ open: true, severity: "error", msg: "delete error" });
                }
            },
            onCancel() {
                //
            },
        });
    }

    const handleSelect = (selectedRowKeys, selectedRows) => {
        setSelectedKeys([...selectedRowKeys]);
    }

    const handleAddingClose = (row) => { 
        setShowAdd(false);

        if (!row) {
            return;
        }

        document.getElementsByClassName("ant-table-body")[0].scrollTo(0, 0);
        setSelectedKeys([row.ID]);
        setRows([row, ...rows]);
        setRowTotal(rowTotal + 1);
    }

    const handleModifyingClose = (row) => {
        setShowModify(false);
        setModifyRowIndex(null);

        if (!row) {
            return;
        }

        setSelectedKeys([row.ID]);
        const rw = { ...rows[modifyRowIndex], ...row }
        setRows([...rows.slice(0, modifyRowIndex), rw, ...rows.slice(modifyRowIndex + 1)]);
    }

    const calcTableBodyHeight = () => {
        if (!refTableContainer.current) {
            return 240;
        }

        const hd = document.getElementsByClassName("ant-table-thead")[0];

        const h = refTableContainer.current.getBoundingClientRect().bottom - hd.getBoundingClientRect().bottom;

        return h >= 54 ? h - 54 : h / 2;
    }

    //按下Enter键时进行查找
    const searchByEnter = (e) => { 
        if(e.code == "Enter") {
            handleSearch()
        }
    }

    return (
        <div style={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            backgroundColor: '#EFF8FE',
            padding: 8,
        }}>
            <div style={{
                flex: "0 1 auto",
            }}>
                <Form
                    form={form}
                    style={{
                        padding: 24,
                        background: '#fbfbfb',
                        border: '1px solid #d9d9d9',
                        borderRadius: 2
                    }}
                >
                    <Row gutter={24}>
                        <Col span={8} key="SN">
                            <Form.Item
                                name="SN"
                                label=""
                            >
                                <Input prefix={<InputPrefix label="SN" />} placeholder="Enter Device SN" onPressEnter={handleSearch} style={{ borderRadius: '20px' }} />
                            </Form.Item>
                        </Col>
                        <Col span={8} key="HardwareVersion">
                            <Form.Item
                                name="HardwareVersion"
                                label=""
                            >
                                <Input prefix={<InputPrefix label="HardwareVersion" />} placeholder="Enter HardWare Version" onPressEnter={handleSearch} style={{ borderRadius: '20px' }} />
                            </Form.Item>
                        </Col>
                        <Col span={8} key="FirmwareVersion">
                            <Form.Item
                                name="FirmwareVersion"
                                label=""
                            >
                                <Input prefix={<InputPrefix label="FirmwareVersion" />} placeholder="Enter Firmware Version" onPressEnter={handleSearch} style={{ borderRadius: '20px' }} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24} style={{ textAlign: 'right' }}>
                            <Button 
                                type='primary'
                                shape='round' 
                                onClick={handleSearch} 
                                style={{marginRight: '8px'}}>
                                Search
                            </Button>
                            <Button
                                shape='round'
                                onClick={() => {
                                    form.resetFields();
                                }}
                            >
                                Clear
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </div>
            <div style={{
                flex: '0 0 auto',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end',
                paddingTop: 8,
                paddingBottom: 8,
            }}>
                {session != null && (session.IsAdmin == 1 || session.IsDistributorUser == 1) ? (
                    <>
                        <Button onClick={handleAdd} type="primary" icon={<PlusOutlined />} size="middle" shape="circle" />
                        <Button onClick={handleDeleteAllOfSelected} disabled={selectedKeys.length === 0} type="primary" icon={<DeleteOutlined />} size="middle" shape="circle" style={{ marginLeft: 5 }} />
                        {/* <div style={{ flex: "0 0 20px" }}></div>
                        <Button onClick={handleBatchImport} type="primary" icon={<ImportOutlined />} size="middle" shape='round'>Import</Button>
                        <div style={{ flex: "0 0 8px" }}></div> */}
                    </>
                ) : ""}
                {/* <Button onClick={handleTransfer} type="primary" icon={<RetweetOutlined />} size="middle" shape='round'>Transfer</Button> */}
            </div>
            <div style={{
                flex: "1 1 1px",
                position: "relative",
                overflow: 'hidden',
            }}>
                <div ref={refTableContainer} style={{ position: "absolute", height: '100%', width: "100%", overflow: "hidden" }}>
                    <Table
                        columns={[{
                            title: "",
                            width: 70,
                            fixed: 'left',
                            render: (v, r, idx) => {
                                return (pageNo * pageSize + idx + 1);
                            }
                        }, {
                            title: "SN",
                            dataIndex: "SN",
                            width: 150,
                            fixed: 'left',
                        }, {
                            title: "Inverter",
                            dataIndex: 'InverterSN',
                            width: 200,
                        }, {
                            title: "HardwareVersion",
                            dataIndex: 'HardwareVersion',
                            width: 120,
                        },  {
                            title: "FirmwareVersion",
                            dataIndex: 'FirmwareVersion',
                            width: 120,
                        }, {
                            title: "Online Time",
                            dataIndex: 'OnlineTime',
                            width: 180,
                            render: (v, r, idx) => {
                                if(r["GmtOffset"] == null) return v;
                                var n = parseInt(r["GmtOffset"])
                                var tz = "UTC+"
                                if(n < 0){
                                    tz = "UTC-"
                                    n = Math.abs(n)
                                }
                                tz += Math.floor(n / 3600).toString().padStart(2, "0") + ":" + Math.floor((n % 3600) / 60).toString().padStart(2, "0")
                                return v + '(' + tz + ')'
                            }
                        }, {
                            title: "Create Time(UTC+0)",
                            dataIndex: 'CreateTime',
                            width: 150,
                        }, {
                            title: 'Action',
                            key: 'Action',
                            width: 90,
                            render: renderAction,
                        }]}
                        pagination={{
                            pageSizeOptions: [25, 50, 100],
                            current: pageNo + 1,
                            pageSize: pageSize,
                            total: rowTotal,
                            showSizeChanger: true,
                            showTotal: (total) => { return `Total ${total}` },
                            onShowSizeChange: (current, size) => {
                                setPageSize(size);
                            },
                            onChange: (page, pageSize) => {
                                setPageNo(page - 1);
                            }
                        }}
                        tableLayout="fixed"
                        bordered={true}
                        size="middle"
                        loading={loading}
                        rowKey="ID"
                        dataSource={rows}
                        scroll={{ x: 1500, y: tableBodyHeight }}
                        rowSelection={{
                            selectedRowKeys: selectedKeys,
                            onChange: handleSelect,
                            selections: [Table.SELECTION_ALL, Table.SELECTION_INVERT, Table.SELECTION_NONE]
                        }}
                    />
                </div>
            </div>
                        
            <DTUAdd show={showAdd} onClose={handleAddingClose} />
            {modifyRowIndex !== null && <DTUModify show={showModify} row={rows[modifyRowIndex]} onClose={handleModifyingClose} />}
        </div>
    );
}

export default DTUConfiguration;