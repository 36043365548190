import { useEffect, useRef } from 'react';
import ReactECharts from 'echarts-for-react';
import TitleBar from '../utils/TitleBar.js';

const opt1 = {
    backgroundColor: 'white',
    xAxis: {
        show: false,
    },
    yAxis: {
        show: false,
    },
    series: [
        {
            name: 'SOC',
            type: 'gauge',
            splitNumber: 0,
            axisLine: {            // 坐标轴线
                lineStyle: {       // 属性lineStyle控制线条样式
                    color: [[0, '#1B8AC5'], [1, '#f0f0f0']],
                    width: 8,
                }
            },
            axisTick: {
                show: false,
            },
            axisLabel: {
                show: false,
            },
            splitLine: {
                show: false,
            },
            pointer: {           // 分隔线
                width: 0,
            },
            title: {
                show: false,
            },
            detail: {
                offsetCenter: [0, 0],       // x, y，单位px
                textStyle: {       // 其余属性默认使用全局文本样式
                    fontWeight: 'bold',
                    color: 'black',
                    fontSize: 12,
                    lineHeight: 14,
                },
                show: true,
                formatter: (v) => {
                    return "SOC\n"+v.toFixed(1)+"%";
                }
            },
            data: [{value: 0, name: '%'}]
        },
    ],
};

const opt2 = {
    backgroundColor: 'white',
    xAxis: {
        show: false,
    },
    yAxis: {
        show: false,
    },
    series: [
        {
            name: 'Current',
            type: 'gauge',
            splitNumber: 0,
            axisLine: {            // 坐标轴线
                lineStyle: {       // 属性lineStyle控制线条样式
                    color: [[0, '#1B8AC5'], [1, '#f0f0f0']],
                    width: 8,
                }
            },
            axisTick: {
                show: false,
            },
            axisLabel: {
                show: false,
            },
            splitLine: {
                show: false,
            },
            pointer: {
                width: 0,
            },
            title: {
                show: false,
            },
            detail: {
                offsetCenter: [0, 0],       // x, y，单位px
                textStyle: {       // 其余属性默认使用全局文本样式
                    fontWeight: 'bold',
                    color: 'black',
                    fontSize: 12,
                    lineHeight: 14,
                },
                formatter: (v) => {
                    return "Current\n"+v.toFixed(1)+"A";
                }
            },
            data: [{value: 0, name: 'A'}]
        },
    ],
};

const opt3 = {
    backgroundColor: 'white',
    xAxis: {
        show: false,
    },
    yAxis: {
        show: false,
    },
    series: [
        {
            name: 'Voltage',
            type: 'gauge',
            splitNumber: 0,
            axisLine: {            // 坐标轴线
                lineStyle: {       // 属性lineStyle控制线条样式
                    color: [[0, '#1B8AC5'], [1, '#f0f0f0']],
			        width: 8,
                }
            },
            axisTick: {
                show: false,
            },
            axisLabel: {
                show: false,
            },
            splitLine: {
                show: false,
            },
            pointer: {
                width: 0,
            },
            title: {
                show: false
            },
            detail: {
                offsetCenter: [0, 0],       // x, y，单位px
                textStyle: {       // 其余属性默认使用全局文本样式
                    fontWeight: 'bold',
                    color: 'black',
                    fontSize: 12,
                    lineHeight: 14,
                },
                formatter: (v) => {
                    return "Voltage\n"+v.toFixed(1)+"V";
                }
            },
            data: [{value: 0, name: 'V'}]
        },
    ],
};

const packStatuses = {
    0: "static",
    1: "charge",
    2: "discharge",
    3: "fault",
    4: "sleep"
};

const sdStatuses = {
    0: "no-inserted",
    1: "inserted",
};

function DeviceSummary(props) {
    const { data } = props;
    const chart1 = useRef(null);
    const chart2 = useRef(null);
    const chart3 = useRef(null);

    if (opt1.series) {
        let s = opt1.series[0];
        let value = data ? (data["SOC"]/10): 0
        s.data = [{...s.data[0], value: value}];
        s.axisLine.lineStyle.color[0][0] = value/100
    }

    if (opt2.series) {
        let s = opt2.series[0];
        let value = data ? ((data["Current"]-32767)/10): 0
        s.data = [{...s.data[0], value: value}];
        s.axisLine.lineStyle.color[0][0] = Math.abs(value)/100
    }

    if (opt3.series) {
        let s = opt3.series[0];
        let value = data ? (data["Voltage"]/10): 0
        s.data = [{...s.data[0], value: value}];
        s.axisLine.lineStyle.color[0][0] = Math.abs(value)/60
    }

    useEffect(() => {
        const instance1 = chart1.current.getEchartsInstance();
        if (instance1) {
            instance1.setOption(opt1);
        }
        const instance2 = chart2.current.getEchartsInstance();
        if (instance2) {
            instance2.setOption(opt2);
        }
        const instance3 = chart3.current.getEchartsInstance();
        if (instance3) {
            instance3.setOption(opt3);
        }
    }, [data])

    return (
        <div style={{
            padding: '10px 15px'
        }}>
            <TitleBar title="Summary" data={data} />
            <div style={{
                display: 'flex' 
            }}>
                <div style={{flex: '1 1 100px'}}>
                    <ReactECharts ref={chart1} option={opt1} style={{height: '150px'}} />
                </div>
                <div style={{flex: '1 1 100px'}}>
                    <ReactECharts ref={chart2} option={opt2} style={{height: '150px'}} />
                </div>
                <div style={{flex: '1 1 100px'}}>
                    <ReactECharts ref={chart3} option={opt3} style={{height: '150px'}} />
                </div>
            </div>
            <div style={{
                flex: "1 1 auto",
                display: "flex",
                flexDirection: "row",
                flexWrap: "nowrap",
            }}>
                <div style={{flex: '120px', backgroundColor: '#f0f0f0', borderRadius: '10px', padding: '2px 4px', fontSize: '12px', margin: '5px 8px'}}>
                    Daily Charge: <span style={{fontWeight: 'bold'}}>{data ? (data["DailyCharging"]/10).toFixed(1): '0.0'}{'kWh'}</span>
                </div>
                <div style={{flex: '120px', backgroundColor: '#f0f0f0', borderRadius: '10px', padding: '2px 4px', fontSize: '12px', margin: '5px 8px'}}>
                    Daily Discharge: <span style={{fontWeight: 'bold'}}>{data ? (data["DailyDischarging"]/10).toFixed(1): '0.0'}{'kWh'}</span>
                </div>
            </div>
            <div style={{
                flex: "1 1 auto",
                display: "flex",
                flexDirection: "row",
                flexWrap: "nowrap",
            }}>
                <div style={{flex: '120px', backgroundColor: '#f0f0f0', borderRadius: '10px', padding: '2px 4px', fontSize: '12px', margin: '5px 8px'}}>
                    Pack Status: <span style={{fontWeight: 'bold'}}>{data ? packStatuses[data["Status"]/10]||'-': '-'}</span>
                </div>
                <div style={{flex: '120px', backgroundColor: '#f0f0f0', borderRadius: '10px', padding: '2px 4px', fontSize: '12px', margin: '5px 8px'}}>
                    SD Status: <span style={{fontWeight: 'bold'}}>{data ? sdStatuses[data["SDStatus"]/10]||'-': '-'}</span>
                </div>
            </div>
        </div>
    )
}

export default DeviceSummary;