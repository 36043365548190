import React, { useEffect, useState, useContext } from 'react';
import { Form, Select, DatePicker, Radio, Button } from 'antd';
import InvSetting from './InvSetting';
import { Hidden } from '@material-ui/core';

function RemoteSetting(props) {
    const {inverterSelectOptions, SN, protocol, handleSelectSN} = props

    return (
        <div style={{
            flex: "auto",
            display: "flex",
            flexDirection: "column",
            overflow: "hidden",
        }}>
            <div style={{
                flex: "none",
                padding: "10px"
            }}>
                <span style={{paddingRight: "10px"}}>Select Inverter</span>
                <Select
                    showSearch
                    placeholder="Enter Inverter Name"
                    defaultActiveFirstOption={false}
                    showArrow={true}
                    filterOption={true}
                    onSelect={(value) => handleSelectSN(value)}
                    value={SN}
                    style={{ width: '200px', marginRight: '20px' }}
                >{inverterSelectOptions}</Select>
            </div>
            <div style={{
                flex: "auto",
                display: "flex",
                flexDirection: "column",
                overflow: "hidden",
            }}>
                <InvSetting SN={SN} protocol={protocol} />
            </div>
        </div>
    )
}

export default RemoteSetting;