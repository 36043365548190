import http from '../Http';
import { useEffect, useState, useContext, useRef } from 'react';
import utils from '../utils';
import { Modal, Button, Input, InputNumber, Form, Select } from 'antd';
import { MinusCircleOutlined } from '@ant-design/icons';

function ProductTypeModify (props) {
    const setTip = useContext(utils.tipContext);

    const {show, onClose, row} = props;

    const [form] = Form.useForm();

    const handleCancle = () => {
        onClose(null);
    }

    const handleSubmit = async () => {
        const ps = await form.validateFields().catch(() => {return null});
        
        if (ps === null) {
            return;
        }

        const ps1 = {}  //发送到数据库
        const ps2 = {}  //返回给上级组件

        ps1['ID'] = row['ID']
        ps1['Name'] = `RangeX:1,[${ps['Name']}],Unit:`
        ps1['Category'] = `RangeX:2,{General BMS@0,Energy Port@1,Master-Control Unit@2:${ps['Category']}},Unit:`
        ps1['VoltageType'] = `RangeX:2,{H@H,L@L:${ps['VoltageType']}},Unit:`
        
        for(let key in ps) {
            if(['Name', 'Category', 'VoltageType'].includes(key)) continue
            if('1-Unit' in ps[key]) {  //Single Value
                ps2[key] = `RangeX:1,[${ps[key]['FieldValue'].trim()}],Unit:${ps[key]['1-Unit'].trim()}`
            } else if('2-Unit' in ps[key]) {  //Range
                ps2[key] = `RangeX:1,[${ps[key]['FieldValue1'].trim()},${ps[key]['FieldValue2'].trim()}],Unit:${ps[key]['2-Unit'].trim()}`
            } else if('3-Unit' in ps[key]) {  //Enum
                ps2[key] = `RangeX:2,{${ps[key]['3-Unit']}:${ps[key]['FieldValue']}},Unit:`
            } else if('4-Unit' in ps[key]) {  //2 Dimentions
                ps2[key] = `RangeX:3,${ps[key]['FieldValue1'].trim()}*${ps[key]['FieldValue2'].trim()},Unit:${ps[key]['4-Unit'].trim()}`
            } else if('5-Unit' in ps[key]) {  //3 Dimentions
                ps2[key] = `RangeX:3,${ps[key]['FieldValue1'].trim()}*${ps[key]['FieldValue2'].trim()}*${ps[key]['FieldValue3']},Unit:${ps[key]['5-Unit'].trim()}`
            }
            ps1[key] = encodeURIComponent(ps2[key])
        }

        const resp = await http.post("product/modify", ps1).then(async (r)=>{
            return await r.json();
        }).catch((e) => {
            return null;
        });

        try {
            if (resp.code !== 200) {
                setTip({open:true, severity:"error", msg:(resp && resp.msg) ? resp.msg : "modify error"});
                return
            }

            setTip({open: true, severity: 'success', msg: 'modify success'});
            ps2['Name'] = ps1['Name']
            ps2['Category'] = ps1['Category']
            ps2['VoltageType'] = ps1['VoltageType']
            onClose(ps2);
        } catch (e) {

            setTip({open:true, severity:"error", msg: "modify error"});
        }
    }

    //删除类型项
    // const handleRemoveField = (v) => {
    //     alert(v)
    // }


    let fieldItems = [] //列表项
    let rowValue = {}  //数据
    for(let k in row) {
        let v = row[k], 
            data = v.substring(v.indexOf('RangeX:')+7, v.indexOf('Unit:')-1),
            unit = v.substring(v.indexOf('Unit:')+5)
        let fieldItemValue = null
        if(k == 'Name') {
            rowValue[k] = data.substring(data.indexOf('[')+1, data.indexOf(']'))
        } else if(k == 'Category') {
            let selected = data.substring(data.indexOf(':')+1, data.indexOf('}'))
            rowValue[k] = parseInt(selected)
        } else if(k == 'VoltageType') {
            rowValue[k] = data.substring(data.indexOf(':')+1, data.indexOf('}'))
        }
        if(['ID', 'CreateTime', 'Name', 'Category', 'VoltageType'].includes(k)) continue
        if(v.startsWith('RangeX:1')) {
            let s = data.substring(data.indexOf('[')+1, data.indexOf(']'))
            if(!s.includes(',')) {  //Single Value
                rowValue[k] = {
                    'FieldValue': s,
                    '1-Unit': unit
                }
                fieldItemValue = <Input.Group compact>
                    <Form.Item name={[k, 'FieldValue']} noStyle rules={[{required: true}]}>
                        <Input style={{width: 300}} />
                    </Form.Item>
                    <Form.Item name={[k, '1-Unit']} noStyle>
                        <Input placeholder='Unit' style={{width: 70, marginLeft: 10}} />
                    </Form.Item>
                    {/* <Form.Item noStyle>
                        <Button type="link" icon={<MinusCircleOutlined />} onClick={() => handleRemoveField(k)} />
                    </Form.Item> */}
                </Input.Group>
            } else { //Range
                let min = s.substring(0, s.indexOf(','))
                let max = s.substring(s.indexOf(',')+1)
                rowValue[k] = {
                    'FieldValue1': min,
                    'FieldValue2': max,
                    '2-Unit': unit
                }
                fieldItemValue = <Input.Group compact>
                    <Form.Item name={[k, 'FieldValue1']} noStyle rules={[{ required: true, type:"number", transform: function(v) {return parseFloat(v) } }]}>
                        <Input placeholder="Min" style={{width: 100}} />
                    </Form.Item>
                    <Form.Item noStyle>
                        <Input placeholder="~" disabled style={{
                            width: 30,
                            borderLeft: 0,
                            borderRight: 0,
                            pointerEvents: 'none',
                        }} />
                    </Form.Item>
                    <Form.Item name={[k, 'FieldValue2']} noStyle rules={[{ required: true, type:"number", transform: function(v) {return parseFloat(v) } }]}>
                        <Input placeholder="Max" style={{width: 100}} />
                    </Form.Item>
                    <Form.Item name={[k, '2-Unit']} noStyle>
                        <Input placeholder='Unit' style={{width: 70, marginLeft: 10}} />
                    </Form.Item>
                </Input.Group>
            }
        } else if(v.startsWith('RangeX:2')) { //Enum
            let opts = data.substring(data.indexOf('{')+1, data.indexOf(':')).split(',').map(v => ({
                label: v.substring(0, v.indexOf('@')),
                value: v.substring(v.indexOf('@')+1)
            }))
            let selected = data.substring(data.indexOf(':')+1, data.indexOf('}'))
            rowValue[k] = {
                'FieldValue': selected,
            }
            const text = opts.map(v => v.label+'@'+v.value).join(',')
            fieldItemValue = <Input.Group compot>
                <Form.Item name={[k, 'FieldValue']} noStyle rules={[{ required: true }]}>
                    <Select style={{width: 380}}>
                        {opts.map(v => (
                            <Select.Option key={v.value} value={v.value}>{v.label}</Select.Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item hidden initialValue={text} name={[k, '3-Unit']}>
                    <Input />
                </Form.Item>
            </Input.Group>
        } else if(v.startsWith('RangeX:3')) {
            let s = data.substring(data.indexOf(',')+1)
            if(s.indexOf('*') == s.lastIndexOf('*')) {  //2D
                let w = s.substring(s.indexOf(',')+1, s.indexOf('*'))
                let h = s.substring(s.indexOf('*')+1)
                rowValue[k] = {
                    'FieldValue1': w,
                    'FieldValue2': h,
                    '4-Unit': unit
                }
                fieldItemValue = <Input.Group compact>
                    <Form.Item name={[k, 'FieldValue1']} noStyle rules={[{ required: true }]}>
                        <Input placeholder="D1" style={{width: 100}} />
                    </Form.Item>
                    <Form.Item noStyle>
                        <Input placeholder="*" disabled style={{
                            width: 30,
                            borderLeft: 0,
                            borderRight: 0,
                            pointerEvents: 'none',
                        }} />
                    </Form.Item>
                    <Form.Item name={[k, 'FieldValue2']} noStyle rules={[{ required: true }]}>
                        <Input placeholder="D2" style={{width: 100}} />
                    </Form.Item>
                    <Form.Item name={[k, '4-Unit']} noStyle>
                        <Input placeholder='Unit' style={{width: 70, marginLeft: 10}} />
                    </Form.Item>
                </Input.Group>
            } else { //3D
                let w = s.substring(s.indexOf(',')+1, s.indexOf('*'))
                let h = s.substring(s.indexOf('*')+1, s.lastIndexOf('*'))
                let d = s.substring(s.lastIndexOf('*')+1)
                rowValue[k] = {
                    'FieldValue1': w,
                    'FieldValue2': h,
                    'FieldValue3': d,
                    '5-Unit': unit
                }
                fieldItemValue = <Input.Group compact>
                    <Form.Item name={[k, 'FieldValue1']} noStyle rules={[{ required: true }]}>
                        <Input placeholder="D1" style={{width: 80}} />
                    </Form.Item>
                    <Form.Item noStyle>
                        <Input placeholder="*" disabled style={{
                            width: 30,
                            borderLeft: 0,
                            borderRight: 0,
                            pointerEvents: 'none',
                        }} />
                    </Form.Item>
                    <Form.Item name={[k, 'FieldValue2']} noStyle rules={[{ required: true }]}>
                        <Input placeholder="D2" style={{width: 80}} />
                    </Form.Item>
                    <Form.Item noStyle>
                        <Input placeholder="*" disabled style={{
                            width: 30,
                            borderLeft: 0,
                            borderRight: 0,
                            pointerEvents: 'none',
                        }} />
                    </Form.Item>
                    <Form.Item name={[k, 'FieldValue3']} noStyle rules={[{ required: true }]}>
                        <Input placeholder="D3" style={{width: 80}} />
                    </Form.Item>
                    <Form.Item name={[k, '5-Unit']} noStyle>
                        <Input placeholder='Unit' style={{width: 70, marginLeft: 10}} />
                    </Form.Item>
                </Input.Group>
            }
        }
        const fieldItem = <Form.Item 
            key={k}
            name={k}
            label={k}
            rules={[{ required: true }]}
        >
            {fieldItemValue}
        </Form.Item>
        fieldItems = [...fieldItems, fieldItem]
    }

    return (<Modal 
        title="Modify Product Type"
        visible={show}
        cancelText="Cancel"
        okText="Submit"
        mask={true}
        maskClosable={false}
        width={700}
        onCancel={handleCancle}
        onOk={handleSubmit}
    >
        <Form
            labelAlign="right"
            labelCol={{ span: 9 }}
            wrapperCol={{ span: 14 }}
            form={form}
            //initialValues={params}
            initialValues={rowValue}
        >
            <Form.Item 
                name="Name"
                label="Name"
                rules={[{ required: true }]}
            >
                <Input />
            </Form.Item>
            <Form.Item 
                name="Category"
                label="Category"
                rules={[{ required: true }]}
            >
                <Select >
                    <Select.Option value={0}>General BMS</Select.Option>
                    <Select.Option value={1}>Energy Port</Select.Option>
                    <Select.Option value={2}>Master-Control Unit</Select.Option>
                </Select>
            </Form.Item>
            <Form.Item 
                name="VoltageType"
                label="VoltageType"
                rules={[{ required: true }]}
            >
                <Select>
                    <Select.Option value="H">High Voltage</Select.Option>
                    <Select.Option value="L">Low Voltage</Select.Option>
                </Select>
            </Form.Item>

            {fieldItems}

        </Form>
    </Modal>)
}

export default ProductTypeModify;