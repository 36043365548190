var Config = {
    apiUrl: "https://api.uhomeenergy.com:7443/",
    //apiUrl: "http://127.0.0.1:8086/",
    wssUrl: "wss://api.uhomeenergy.com:7443/ws/pusher",
    wssInverterUrl: "wss://api.uhomeenergy.com:7443/ws-inverter/pusher",
    appName_General: "BMS",
    appName_IMS: "IMS",
    appName_Tip_General: "Batteries Management System",
    appName_Tip_IMS: "Inverters Management System",
    appIcon: "images/Raystech.png",
    burningPacketSize: 200,
    burningPacketSize_inverter: 240,
    bingMapKey: "AslkTsmeO4zglWKa9lKy9Z8moDUPDa-HsWSVcPD0AhjyXZAMrnecBgLIkyeuZLL_",
}

export default Config;
