import http from '../Http';
import { useEffect, useState, useContext, useRef } from 'react';
import utils from '../utils';
import { Table, Input, Button, DatePicker, Form, Row, Col, Checkbox, Select, Popover } from 'antd';
import { EditOutlined, UpOutlined, DownOutlined } from '@ant-design/icons';
import moment from 'moment';
import Handler from './Handler';
import InputPrefix from '../utils/InputPrefix';

const AlarmTypes = {
    "Communication Fault": 1,
    "Cell Voltage Collect Line Fault": 2,
    "Temperature Collect Line Fault": 3,
    "Current Collect Line Fault": 4,
    "Excessive Cell Voltage Difference": 5,
    "Cell Voltage Over": 6,
    "Cell Voltage Under": 7,
    "Total Voltage Over": 8,
    "Total Voltage Under": 9,
    "Excessive Charge Temperature Difference": 10,
    "Charge Temperature Over": 11,
    "Charge Temperature Under": 12,
    "Charge Current Over": 13,
    "Discharge Current Over": 14,
    "SOC Lower": 15,
    "Battery Data Collect Abnormal": 16,
    "Excessive Total Voltage Difference": 17,
    "Pack Capacity Inconsistency": 18,
    "Excessive Discharge Temperature Difference": 19,
    "Discharge Temperature Over": 20,
    "Discharge Temperature Under": 21,
    'MOS Temperature Over': 22,
    'ContactRod Temperature Over': 23,
    'Relay or MOS Sticking': 24,
    'Internal Fault': 25,
    "PowerVoltageOver":           26,
    "PowerVoltageUnder":          27,
    "InsulationResistanceLow":    28,
    "InterPackBalancingFault":    29,
};

const AlarmTypes2 = [
    "Communication Fault",
    "Cell Voltage Collect Line Fault",
    "Temperature Collect Line Fault",
    "Current Collect Line Fault",
    "Excessive Cell Voltage Difference",
    "Cell Voltage Over",
    "Cell Voltage Under",
    "Total Voltage Over",
    "Total Voltage Under",
    "Excessive Charge Temperature Difference",
    "Charge Temperature Over",
    "Charge Temperature Under",
    "Charge Current Over",
    "Discharge Current Over",
    "SOC Lower",
    "Battery Data Collect Abnormal",
    "Excessive Total Voltage Difference",
    "Pack Capacity Inconsistency",
    "Excessive Discharge Temperature Difference",
    "Discharge Temperature Over",
    "Discharge Temperature Under",
    'MOS Temperature Over',
    'ContactRod Temperature Over',
    'Relay or MOS Sticking',
    'Internal Fault',
    "PowerVoltageOver",
    "PowerVoltageUnder",
    "InsulationResistanceLow",
    "InterPackBalancingFault",
];

const AlarmTypes3 = {
    1: "Communication Fault",
    2: "Cell Voltage Collect Line Fault",
    3: "Temperature Collect Line Fault",
    4: "Current Collect Line Fault",
    5: "Excessive Cell Voltage Difference",
    6: "Cell Voltage Over",
    7: "Cell Voltage Under",
    8: "Total Voltage Over",
    9: "Total Voltage Under",
    10: "Excessive Charge Temperature Difference",
    11: "Charge Temperature Over",
    12: "Charge Temperature Under",
    13: "Charge Current Over",
    14: "Discharge Current Over",
    15: "SOC Lower",
    16: "Battery Data Collect Abnormal",
    17: "Excessive Total Voltage Difference",
    18: "Pack Capacity Inconsistency",
    19: "Excessive Discharge Temperature Difference",
    20: "Discharge Temperature Over",
    21: "Discharge Temperature Under",
    22: 'MOS Temperature Over',
    23: 'ContactRod Temperature Over',
    24: 'Relay or MOS Sticking',
    25: 'Internal Fault',
    26: "PowerVoltageOver",
	27: "PowerVoltageUnder",
	28: "InsulationResistanceLow",
	29: "InterPackBalancingFault",
};

function AlarmManagement(props) {
    const setTip = useContext(utils.tipContext);

    const [pageNo, setPageNo] = useState(0);
    const [pageSize, setPageSize] = useState(25);
    const [params, setParams] = useState({
        SN: '',
        DistributorID: '',
        ProductID: '',
        UserID: '',
        AlarmTypes: '',
        Handled: "",
        STime: moment().format("YYYY-MM-DD"),
        ETime: moment().add(1, "days").format("YYYY-MM-DD"),
    });
    const [loading, setLoading] = useState(false);
    const [rows, setRows] = useState([]);
    const [rowTotal, setRowTotal] = useState(0);
    const [tableBodyHeight, setTableBodyHeight] = useState(240);
    const [selectedKeys, setSelectedKeys] = useState([]);
    const [showHandler, setShowHandler] = useState(false);
    const [modifyRowIndex, setModifyRowIndex] = useState(null);
    const [productSelectOptions, setProductSelectOptions] = useState([]);
    const [distributorSelectOptions, setDistributorSelectOptions] = useState([]);
    const [expand, setExpand] = useState(false);

    const refTableContainer = useRef();
    const refTimerProductSearcher = useRef(null);
    const refProductSearchValue = useRef(null);
    const refTimerDistributorSearcher = useRef(null);
    const refDistributorSearchValue = useRef(null);

    const [form] = Form.useForm();

    useEffect(() => {
        let active = true;

        (async () => {
            setLoading(true);

            let resp = await http.post("alarm/management/list", {
                PageNo: pageNo,
                PageSize: pageSize,
                Timezone: -(new Date()).getTimezoneOffset() * 60,
                ...params,
            }).then(async (r) => {
                return await r.json();
            }).catch((e) => {
                console.log("[AlarmManagement]", e);
                return null;
            });

            if (!active) {
                return;
            }

            if (resp === null || resp.code !== 200) {
                setTip({ open: true, severity: "error", msg: (resp && resp.msg) ? resp.msg : "load data error" });

                setRowTotal(0)
                setRows([]);

                return;
            }
            setRowTotal(resp.data.RowTotal);
            setRows(resp.data.Rows);
            setLoading(false);
        })();

        return () => {
            active = false;
        };
    }, [pageNo, pageSize, params]);

    useEffect(() => {
        const el = refTableContainer.current;

        if (el.objResizer) {
            return;
        }

        let obj = document.createElement('object');
        el.objResizer = obj;
        obj.setAttribute('style',
            'display: block; position: absolute; top: 0; left: 0; height: 100%; width: 100%; overflow: hidden;opacity: 0; pointer-events: none; z-index: -1;');
        obj.onload = function () {
            setTableBodyHeight(calcTableBodyHeight());

            this.contentDocument.defaultView.addEventListener('resize', (evt) => {
                setTableBodyHeight(calcTableBodyHeight());
            });
        };
        obj.type = 'text/html';
        el.appendChild(obj);
        obj.data = 'about:blank';

        return () => {
            if (!el) {
                return
            }

            //
        }
    }, [refTableContainer.current]);

    const handleSearchProduct = (v) => {
        if (refTimerProductSearcher.current != null) {
            clearTimeout(refTimerProductSearcher.current);
        }
        refProductSearchValue.current = v;

        refTimerProductSearcher.current = setTimeout(async () => {
            const resp = await http.post("product/name/list", {
                Value: v,
            }).then(async (r) => {
                return await r.json();
            }).catch((e) => {
                console.log("[AlarmManagement]", e);
                return null;
            });

            if (refProductSearchValue.current !== v) {
                return;
            }

            try {
                if (resp.code !== 200) {
                    setTip({ open: true, severity: "error", msg: (resp && resp.msg) ? resp.msg : "load product list error" });
                    return;
                }

                const ls = resp.data.map(({ Name, ID }) => {
                    return (
                        <Select.Option key={ID} value={ID}>{Name}</Select.Option>
                    );
                });
                setProductSelectOptions(ls);
            } catch (e) {
                console.log("[AlarmManagement]", e);

                setTip({ open: true, severity: "error", msg: "load product list error" });
            }
        }, 300);
    }

    const handleSearchDistributor = (v) => {
        if (refTimerDistributorSearcher.current != null) {
            clearTimeout(refTimerDistributorSearcher.current);
        }
        refDistributorSearchValue.current = v;

        refTimerDistributorSearcher.current = setTimeout(async () => {
            const resp = await http.post("distributor/name/list", {
                Value: v,
            }).then(async (r) => {
                return await r.json();
            }).catch((e) => {
                console.log("[AlarmManagement]", e);
                return null;
            });

            if (refDistributorSearchValue.current !== v) {
                return;
            }

            try {
                if (resp.code !== 200) {
                    setTip({ open: true, severity: "error", msg: (resp && resp.msg) ? resp.msg : "load distributor list error" });
                    return;
                }

                const ls = resp.data.map(({ Name, ID }) => {
                    return (
                        <Select.Option key={ID} value={ID}>{Name}</Select.Option>
                    );
                });
                setDistributorSelectOptions(ls);
            } catch (e) {
                console.log("[AlarmManagement]", e);

                setTip({ open: true, severity: "error", msg: "load distributor list error" });
            }
        }, 300);
    }

    const handleSearch = () => {
        setSelectedKeys([]);
        setPageNo(0);

        const ps = form.getFieldsValue();
        ps.SN = ps.SN || "";
        ps.ProductID = ps.ProductID || "";
        ps.DistributorID = ps.DistributorID || "";
        ps.UserID = ps.UserID || "";
        ps.AlarmTypes = ps.AlarmTypes ? ps.AlarmTypes.join(",") : "";
        ps.STime = ps.DateRange && ps.DateRange.length > 0 ? ps.DateRange[0].format("YYYY-MM-DD") : "";
        ps.ETime = ps.DateRange && ps.DateRange.length > 1 ? moment(ps.DateRange[1]).add(1, "days").format("YYYY-MM-DD") : "";
        ps.Handled = ps.Handled ? (ps.Handled.indexOf("Handled") > -1 && ps.Handled.indexOf("Unhandled") > -1 ? "" : ps.Handled.indexOf("Handled") > -1 ? 1 : 0) : "";
        setParams(ps);
    }

    const renderAction = (v, r, idx) => {
        return (
            <>
                <Button onClick={() => { handleHandle(idx) }} type="link" icon={<EditOutlined size="small" />} />
                {r.LatestHandleTime ? <><br />{"Latest Handle Time: " + r.LatestHandleTime}</> : ""}
            </>
        )
    }

    const handleHandle = (index) => {
        setModifyRowIndex(index);
        setShowHandler(true);
    }

    const handleBatchHandle = () => {
        setShowHandler(true);
    }


    const handleSelect = (selectedRowKeys, selectedRows) => {
        setSelectedKeys([...selectedRowKeys]);
    }

    const handleHandlerClose = (row) => {
        setShowHandler(false);
        setModifyRowIndex(null);

        if (!row) {
            return;
        }

        setSelectedKeys([row.ID]);
        const rw = { ...rows[modifyRowIndex], ...row }
        setRows([...rows.slice(0, modifyRowIndex), rw, ...rows.slice(modifyRowIndex + 1)]);
    }

    const calcTableBodyHeight = () => {
        if (!refTableContainer.current) {
            return 240;
        }

        const hd = document.getElementsByClassName("ant-table-thead")[0];

        const h = refTableContainer.current.getBoundingClientRect().bottom - hd.getBoundingClientRect().bottom;

        return h >= 54 ? h - 54 : h / 2;
    }

    //按下Enter键时进行查找
    const searchByEnter = (e) => {
        if (e.code == "Enter") {
            handleSearch()
        }
    }

    return (
        <div style={{
            flex: "1 1 auto",
            display: "flex",
            flexDirection: "column",
            backgroundColor: '#EFF8FE',
            padding: 8,
        }}>
            <div style={{
                flex: "0 1 auto",
            }}>
                <Form
                    form={form}
                    initialValues={{
                        //AlarmTypes: AlarmTypes2,  
                        DateRange: [moment(), moment()],
                        Handled: ["Handled", "Unhandled"]
                    }}
                    style={{
                        padding: 24,
                        background: '#fbfbfb',
                        border: '1px solid #d9d9d9',
                        borderRadius: 2
                    }}
                >
                    <Row gutter={24}>
                        <Col span={8} key="SN">
                            <Form.Item
                                name="SN"
                                label=""
                            >
                                <Input prefix={<InputPrefix label="SN" />} placeholder="Enter Device SN" onPressEnter={handleSearch} style={{ borderRadius: '20px' }} />
                            </Form.Item>
                        </Col>
                        <Col span={8} key="Handled" >
                            <Button type="primary" shape='round' style={{marginRight: '8px'}} onClick={handleSearch}>
                                Search
                            </Button>
                            <a
                                style={{ fontSize: 12, marginRight: '20px' }}
                                onClick={() => {
                                    setExpand(!expand);
                                }}
                            >
                                {expand ? <UpOutlined /> : <DownOutlined />} {expand ? 'Collapse' : 'Expand'}
                            </a>
                            <Form.Item
                                name="Handled"
                                label=""
                                style={{display: 'inline-block'}}
                            >
                                <Checkbox.Group options={["Handled", 'Unhandled']} />
                            </Form.Item>
                        </Col>
                        <Col span={8} key="DateRange" >
                            <Form.Item
                                name="DateRange"
                                label=""
                            >
                                <DatePicker.RangePicker style={{ borderRadius: '20px' }} />
                            </Form.Item>
                        </Col>
                        <Col span={8} key="ProductID" style={{ display: expand ? "block" : "none" }}>
                            <Input.Group compact>
                                <Button>Product</Button>
                                <Form.Item
                                    name="ProductID"
                                    label=""
                                    style={{display: 'inline-block', width: 'calc(100% - 80px)'}}
                                >
                                    <Select
                                        showSearch
                                        placeholder="Enter Product Name"
                                        defaultActiveFirstOption={false}
                                        showArrow={false}
                                        filterOption={false}
                                        onSearch={handleSearchProduct}
                                        onInputKeyDown={searchByEnter}
                                    >{productSelectOptions}</Select>
                                </Form.Item>
                            </Input.Group>
                        </Col>
                        <Col span={8} key="DistributorID" style={{ display: expand ? "block" : "none" }}>
                            <Input.Group compact>
                                <Button>Distributor</Button>
                                <Form.Item
                                    name="DistributorID"
                                    label=""
                                    style={{display: 'inline-block', width: 'calc(100% - 100px)'}}
                                >
                                    <Select
                                        showSearch
                                        placeholder="Enter Distributor Name"
                                        defaultActiveFirstOption={false}
                                        showArrow={false}
                                        filterOption={false}
                                        onSearch={handleSearchDistributor}
                                        onInputKeyDown={searchByEnter}
                                    >{distributorSelectOptions}</Select>
                                </Form.Item>
                            </Input.Group>
                        </Col>
                        <Col span={8} key="UserID" style={{ display: expand ? "block" : "none" }}>
                            <Form.Item
                                name="UserID"
                                label=""
                            >
                                <Input prefix={<InputPrefix label='UserID'/>} placeholder="Enter User ID" onPressEnter={handleSearch} style={{ borderRadius: '20px' }} />
                            </Form.Item>
                        </Col>
                        <Col span={24} key="AlarmTypes" style={{ display: expand ? "block" : "none" }}>
                            <Form.Item
                                name="AlarmTypes"
                                label=""
                            >
                                <Checkbox.Group options={AlarmTypes2} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24} style={{ textAlign: 'right' }}>
                            <Button
                                shape='round'
                                onClick={() => {
                                    form.resetFields();
                                }}
                            >
                                Clear
                            </Button>
                            {/* <a
                                style={{ fontSize: 12 }}
                                onClick={() => {
                                    setExpand(!expand);
                                }}
                            >
                                {expand ? <UpOutlined /> : <DownOutlined />} {expand ? 'Collapse' : 'Expand'}
                            </a> */}
                        </Col>
                    </Row>
                </Form>
            </div>
            <div style={{
                flex: '0 0 auto',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end',
                paddingTop: 8,
                paddingBottom: 8,
            }}>
                {/* <Button disabled={selectedKeys.length === 1 ? false : true} onClick={handleHandle} type="primary" icon={<EditOutlined />} size="middle" ></Button> */}
                {/* <div style={{ flex: "0 0 20px" }}></div> */}
                {/* <Button disabled={selectedKeys.length > 0 ? false : true} onClick={handleBatchHandle} type="primary" icon={<EditOutlined />} size="middle" >Batch</Button> */}
            </div>
            <div style={{
                flex: "1 1 1px",
                position: "relative",
            }}>
                <div ref={refTableContainer} style={{ position: "absolute", height: '100%', width: "100%", overflow: "hidden" }}>
                    <Table
                        columns={[{
                            title: "",
                            width: 70,
                            fixed: 'left',
                            render: (v, r, idx) => {
                                return (pageNo * pageSize + idx + 1);
                            }
                        }, {
                            title: "SN",
                            dataIndex: "SN",
                            width: 200,
                            fixed: 'left',
                            render: (v, r) => {
                                const content = (
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "column",
                                    }}>
                                        <div style={{
                                            display: "flex"
                                        }}>
                                            <div style={{
                                                flex: "0 0 90px",
                                                textAlign: "right",
                                                fontWeight: "bold",
                                            }}>Product:&nbsp;</div>
                                            <div style={{ flex: "1" }}>{r.ProductName}</div>
                                        </div>
                                        <div style={{
                                            display: "flex"
                                        }}>
                                            <div style={{
                                                flex: "0 0 90px",
                                                textAlign: "right",
                                                fontWeight: "bold",
                                            }}>Distributor:&nbsp;</div>
                                            <div style={{ flex: "1" }}>{r.DistributorName}</div>
                                        </div>
                                        <div style={{
                                            display: "flex"
                                        }}>
                                            <div style={{
                                                flex: "0 0 90px",
                                                textAlign: "right",
                                                fontWeight: "bold",
                                            }}>Users:&nbsp;</div>
                                            <div style={{ flex: "1" }}>{r.Users}</div>
                                        </div>
                                    </div>
                                );
                                return (
                                    <Popover title="Note" content={content}>
                                        <div>{v}</div>
                                    </Popover>
                                );
                            }
                        }, {
                            title: "Name",
                            dataIndex: "CategoryID",
                            width: 200,
                            render: (v, r, idx) => {
                                return (AlarmTypes3[v] || "") + "[Level" + (r["Level"]) + "]";
                            }
                        }, {
                            title: "Timezone",
                            dataIndex: "Timezone",
                            width: 250,
                            render: (v, r, idx) => {
                                var n = parseInt(r["GmtOffset"]);
                                var tz = "UTC+";
                                if (n < 0) {
                                    tz = "UTC-";
                                    n = Math.abs(n);
                                }
                                tz += Math.floor(n / 3600).toString().padStart(2, "0") + ":" + Math.floor((n % 3600) / 60).toString().padStart(2, "0")
                                tz += "(" + v + ")"
                                return tz;
                            }
                        }, {
                            title: "STime",
                            dataIndex: "STime",
                            width: 150,
                        }, {
                            title: "ETime",
                            dataIndex: "ETime",
                            width: 150,
                        }, {
                            title: "End User Read",
                            dataIndex: 'EndUserRead',
                            width: 150,
                            render: (v, r, idx) => {
                                if (r["EndUserReadTime"]) {
                                    return (r["EndUserReadTime"] || "") + ", " + v
                                }
                                return "";
                            }
                        }, {
                            title: 'Action',
                            key: 'Action',
                            width: 250,
                            render: renderAction,
                        }]}
                        pagination={{
                            pageSizeOptions: [25, 50, 100],
                            current: pageNo + 1,
                            pageSize: pageSize,
                            total: rowTotal,
                            showSizeChanger: true,
                            showTotal: (total) => { return `Total ${total}` },
                            onShowSizeChange: (current, size) => {
                                setPageSize(size);
                            },
                            onChange: (page, pageSize) => {
                                setPageNo(page - 1);
                            }
                        }}
                        tableLayout="fixed"
                        bordered={true}
                        size="middle"
                        loading={loading}
                        rowKey="ID"
                        dataSource={rows}
                        scroll={{ y: tableBodyHeight }}
                        rowSelection={{
                            selectedRowKeys: selectedKeys,
                            onChange: handleSelect,
                            selections: [Table.SELECTION_ALL, Table.SELECTION_INVERT, Table.SELECTION_NONE]
                        }}
                    />
                </div>
            </div>
            {showHandler === true ? <Handler row={rows[modifyRowIndex]} AlarmTypes3={AlarmTypes3} show={showHandler} onClose={handleHandlerClose} /> : ""}
        </div>
    );
}

export default AlarmManagement;