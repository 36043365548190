import http from '../../../Http';
import { useEffect, useState, useContext, useRef } from 'react';
import utils from '../../../utils';
import { DatePicker , Input, Button, Form, Row, Col, Select,Collapse} from 'antd';
import { EditOutlined, ClockCircleOutlined } from '@ant-design/icons';
import WS from "../WS";
import CRC from './ModbusCRC';
import moment from 'moment';
import BitsEditor from './BitsEditor';
import HourMinuteEditor from './HourMinuteEditor';
import dayjs from 'dayjs';

const HMAddresses = [0x3446,0x3447,0x344A,0x344B,0x344E,0x344F,0x3452,0x3453,0x3456,0x3457,0x345A,0x345B,0x3504,0x3505,0x3506,0x3507,0x3508,0x3509,
    0x350A,0x350B,0x350C,0x350D,0x350E,0x350F];

function MegarevoSetting(props) {
    const setTip = useContext(utils.tipContext);
    const {SN, SettingDefs} = props;

    //给SettingDefs按Category排序
    const catPriority = {
        'Setting-Basis': 0,
        'Setting-Battery': 1,
        'Setting-PV': 2,
        'Setting-Grid': 3,
        'Setting-Inversion': 4,
        'Setting-Load': 5,
        'Setting-Battery485': 6,
        'Setting-Generator': 7,
        'Setting-Parallel': 8,
        'Setting-ACCouple': 9,
        'Setting-PeakCut': 10,
        'Setting-Advanced': 11,
        'Setting-Other': 12,
    };
    SettingDefs && SettingDefs.sort((a, b) => catPriority[a.Category] -catPriority[b.Category]);
   
    const [online, setOnline] = useState(false);
    const [pendingValues, setPendingValues] = useState({});
    const [doingsResult, setDoingsResult] = useState({});
    const [writeErrors, setWriteErrors] = useState({});
    const [turnOn, setTurnOn] = useState(null)
    
    const refComfirmedValues = useRef({});
    const refPendingValues = useRef({});
    const refDoingsResult = useRef({});
    const refPendingCmds = useRef([]);
    const refExecutingCmd = useRef(null); 


    const [form] = Form.useForm();

    const defMap = {};
    const defs = [];
    var subDefs = [];
    var lastCategory = null;
    var vs = {}
    for (var k in SettingDefs) {
        var def = SettingDefs[k];
        var address = def.RegisterAddressNumber;
        defMap[address] = def;
        if (lastCategory != def.Category) {
            subDefs = [];
            defs.push(subDefs);
            lastCategory = def.Category;
        }
        subDefs.push(def);
        //
        vs[address] = def.Value;
        if (def.Scale && def.Value != null && !isNaN(def.Value)) {
            vs[address] = def.Value * def.Scale;
            vs[address] = vs[address].toFixed(def.Scale.toString().length-2 > 0 ? def.Scale.toString().length-2 : 0)
        }
    }
    if (Object.keys(vs).length != Object.keys(refComfirmedValues.current).length) {
        // vs[0x343C] = 0xffff; // test
        refComfirmedValues.current = {...vs};
        // form.setFieldsValue(vs);
        setTimeout(()=>setFormFieldsValue(vs), 50);
        setTurnOn(vs[44001])
    }

    const hex2Array = (hex) => {
        var arr = new Uint8Array(hex.match(/[\da-f]{2}/gi).map((h)=>parseInt(h, 16)));
        return Array.prototype.slice.call(new Uint8Array(arr.buffer )); 
    }

    const Array2hex = (arr) => {
        return arr.map((v) => v.toString(16).padStart(2, "0")).join("");
    }

    const setFormFieldsValue = (vs) => {
        vs = {...vs};
        for (var k in vs) {
            var v = vs[k]
            if (defMap[k].RegisterAddressNumber == 0x3500) {
                // v = moment.unix(v);
                const ym = vs[0x3500];
                const day = vs[0x3501];
                const hm = vs[0x3502];
                const second = vs[0x3503];
                const dt = ((ym>>8)&0xff).toString().padStart(2, "0").concat("-",
                    (ym&0xff).toString().padStart(2, "0"),
                    "-",
                    ((day>>8)&0xff).toString().padStart(2, "0"),
                    " ",
                    ((hm>>8)&0xff).toString().padStart(2, "0"),
                    ":",
                    (hm&0xff).toString().padStart(2, "0"),
                    ":",
                    ((second>>8)&0xff).toString().padStart(2, "0")
                )
                vs[k] = moment(dt, "YYYY-MM-DD HH:mm:ss");
                if (!ym) {
                    vs[k] = null;
                }
            }
        }
        //form.setFieldsValue(vs)
        Object.keys(refPendingValues.current).length == 0 && form.setFieldsValue(vs)   //2024-5-31
    }

    const handleWSConnect = () => {
        setOnline(true);
    }

    const handleWSData = (data) => {
        if (!data || data.InverterSN != SN) {
            return;
        }  

        var resp = hex2Array(data.Response);

        let cmd = null;
        let cmds = refPendingCmds.current;
        for (var i in cmds) {
            if (cmds[i].SessionID == data.SessionID) {
                cmd = cmds[i];
                cmds.splice(i, 1);
                break
            }
        }
        if (refExecutingCmd.current != null && refExecutingCmd.current.SessionID== data.SessionID) {
            cmd = refExecutingCmd.current;
            refExecutingCmd.current = null;
        }
        if (!cmd) {
            return;
        }
        if (resp[1] != 0x03 && resp[1] != 0x06 && resp[1] != 0x10) {
            setTip({ open: true, severity: "info", msg: "command(s) error!" });
            return;
        }
        if (cmd.Code == 0x03) {
            var address = cmd.Address;
            var count = cmd.Count;
            var vs = {};
            for (var i=0; i<count; i++) {
                var addr = address + i;
                var idx = i*2 + 3;
                if (defMap.hasOwnProperty(addr)) {
                    var def = defMap[addr];
                    switch (def.RegisterType) {
                        case "U16":
                            var v = (resp[idx]<<8) + resp[idx + 1];
                            vs[addr] = v;
                            break;
                        case "I16":
                            var v = (resp[idx]<<8) + resp[idx + 1];
                            if (v >0x7fff) {
                                v -= 0xffff + 1;
                            }
                            vs[addr] = v;
                            break;
                        case "U32":
                            var v = (resp[idx]<<24) + (resp[idx+1]<<16) + (resp[idx+2]<<8) + resp[idx + 3];
                            vs[addr] = v;
                            break;
                        case "I32":
                            var v = (resp[idx]<<24) + (resp[idx+1]<<16) + (resp[idx+2]<<8) + resp[idx + 3];
                            if (v >0x7fffffff) {
                                v -= 0xffffffff + 1;
                            }
                            vs[addr] = v;
                            break;
                    }
                    i += def.RegisterNumber - 1;
                }
            }
            if (Object.keys(vs).length > 0) {
                for (var k in vs) {
                    var def = defMap[k];
                    if (def.Scale) {
                        vs[k] *= def.Scale;
                        vs[k] = vs[k].toFixed(def.Scale.toString().length-2 > 0 ? def.Scale.toString().length-2 : 0);
                    }
                }
                refComfirmedValues.current = {...refComfirmedValues.current, ...vs}
                // form.setFieldsValue(refComfirmedValues.current);
                setFormFieldsValue({...refComfirmedValues.current});

                var pvs = refPendingValues.current;
                var pvs_changed = false;
                for (var k in vs) {
                    if (pvs.hasOwnProperty(k)) {
                        pvs_changed = true;
                        delete pvs[k];
                    }
                }
                if (pvs_changed) {
                    setPendingValues({...pvs});
                }
            }
        } else if (cmd.Code == 0x06) {
            var address = cmd.Address;
            if (resp[1] == 0x06) {
                var v = (resp[4]<<8) + resp[5];
                if (defMap.hasOwnProperty(address)) {
                    var def = defMap[address];
                    if (def.RegisterType == "I16") {
                        if (v >0x7fff) {
                            v -= 0xffff + 1;
                        }
                    }
                    if (def.Scale) {
                        v *= def.Scale;
                        v = v.toFixed(def.Scale.toString().length-2 > 0 ? def.Scale.toString().length-2 : 0);
                    }

                    refComfirmedValues.current = {...refComfirmedValues.current, ...{[address]:v}}
                    setFormFieldsValue(refComfirmedValues.current);

                    var pvs = refPendingValues.current;
                    delete pvs[address];
                    setPendingValues({...pvs});

                    if ([0x3416,0x3417,0x341d,0x362a,0x362b,0x362c].indexOf(address) > -1) {
                        setTip({ open: true, severity: "success", msg: "command "+def.Intls["English"]+" success" });
                    }
                }
                
            } 
        } else if (cmd.Code == 0x10) {
            var address = cmd.Address;
            if (resp[1] == 0x10) {//0x3500~0x3503
                var v = (resp[4]<<8) + resp[5];
                if (defMap.hasOwnProperty(address)) {
                    var def = defMap[address];
                   
                    var pvs = refPendingValues.current;
                    refComfirmedValues.current = {...refComfirmedValues.current, ...{0x3500:pvs[0x3500],0x3501:pvs[0x3501],0x3502:pvs[0x3502],0x3503:pvs[0x3503],}}
                    setFormFieldsValue(refComfirmedValues.current);

                    delete pvs[0x3500];
                    delete pvs[0x3501];
                    delete pvs[0x3502];
                    delete pvs[0x3503];
                    setPendingValues({...pvs});
                }
                
            } 
        }


        
        // var req = hex2Array(data.Request);
        // var resp = hex2Array(data.Response);
        // console.log("[Setting-Read-Response]", req, resp)
        /*if (req[1] == 0x03) {
            
        } else if (req[1] == 0x06 ) {
                  
        } else if (req[1] == 0x10 && resp[1] == 0x10) {
            var address = (req[2]<<8) + req[3];
            var count = (req[4]<<8) + req[5];
            var vs = {};
            for (var i=0; i<count; i++) {
                var addr = address + i;
                var idx = i*2 + 7;
                if (defMap.hasOwnProperty(addr)) {
                    var def = defMap[addr];
                    switch (def.RegisterType) {
                        case "U16":
                            var v = (req[idx]<<8) + req[idx + 1];
                            vs[addr] = v;
                            break;
                        case "I16":
                            var v = (req[idx]<<8) + req[idx + 1];
                            if (v >0x7fff) {
                                v -= 0xffff + 1;
                            }
                            vs[addr] = v;
                            break;
                        case "U32":
                            var v = (req[idx]<<24) + (req[idx+1]<<16) + (req[idx+2]<<8) + req[idx + 3];
                            vs[addr] = v;
                            break;
                        case "I32":
                            var v = (req[idx]<<24) + (req[idx+1]<<16) + (req[idx+2]<<8) + req[idx + 3];
                            if (v >0x7fffffff) {
                                v -= 0xffffffff + 1;
                            }
                            vs[addr] = v;
                            break;
                    }
                    i += def.RegisterNumber - 1;
                }
            }
            if (Object.keys(vs).length > 0) {
                for (var k in vs) {
                    var def = defMap[k];
                    if (def.Scale) {
                        vs[k] *= def.Scale;
                        vs[k] = vs[k].toFixed(def.Scale.toString().length-2);
                    }
                }
                refComfirmedValues.current = {...refComfirmedValues.current, ...vs}
                // form.setFieldsValue(refComfirmedValues.current);
                setFormFieldsValue(refComfirmedValues.current);

                var pvs = refPendingValues.current;
                var pvs_changed = false;
                for (var k in vs) {
                    if (pvs.hasOwnProperty(k)) {
                        pvs_changed = true;
                        delete pvs[k];
                    }
                }
                if (pvs_changed) {
                    setPendingValues({...pvs});
                }
            }
        }*/
    }

    const handleWSClose = () => {
        setOnline(false)
    }

    const handleWSError = () => {
        //
    }

    const handleReset = (Category) => {
        var vs = {};
        var pvs = refPendingValues.current;
        var cvs = refComfirmedValues.current;
        var had = false;
        for (var k in pvs) {
            var def = defMap[k];
            if ( defMap.hasOwnProperty(k) && def.Category == Category) {
                had = true;
                vs[k] = cvs[k];
                delete pvs[k];
            }
        } 
        if (had) {
            // form.setFieldsValue(vs);
            setFormFieldsValue(vs);
            setPendingValues({...pvs});
        }
    }

    //command dispatcher
    setInterval(async ()=>{
        let cmd = refExecutingCmd.current;
        let cmds = refPendingCmds.current;
        let dt = Date.now();
        if (cmd == null) {
            if (cmds.length < 1) {
                return;
            } 

            cmd = cmds[0];
            cmd.TimeoutStart = dt;
            cmds.splice(0,1);
            refExecutingCmd.current = cmd;
        }
        if (dt != cmd.TimeoutStart && dt-cmd.TimeoutStart < 5000) {
            return;
        }
        console.log("[Timeout]", cmd, cmd.TimeoutStart, dt, cmd.RetryCount);
        cmd.TimeoutStart = dt;
        let cmd2 = cmd.cmd2;

        if (cmd.RetryCount != undefined && cmd.RetryCount >= 2) {
            setTip({ open: true, severity: "error", msg: "Command sending times exceed the limit or timeout" });
            refExecutingCmd.current = null;
            return;
        }

        //
        if (cmd.RetryCount == undefined) {
            cmd.RetryCount = 0;
        } else {
            cmd.RetryCount ++;
        }
        const resp = await http.postJSON("integrated-inverter/setting/request", cmd2).then(async (r) => {
            return await r.json();
        }).catch((e) => {
            console.log("[Setting]", e);
            return null;
        });
        console.log("[+++]", Date.now());
    }, 200)

    const postCmd = async (cmd1, cmd2) => {
        try {
            const resp2 = await http.postJSON("business-session/new-id/get", {
            }).then(async (r) => {
                return await r.json();
            }).catch((e) => {
                console.log("[Setting]", e);
                return null;
            });
            const SessionID = resp2.data.SessionID % 0xffffffff;
            if (isNaN(SessionID) || SessionID == null) {
                setTip({ open: true, severity: "error", msg: "Unable to get SessionID" });
                return;
            }
            cmd1["SessionID"] = SessionID;
            cmd2["SessionID"] = SessionID;

            cmd1.cmd2 = cmd2;
            refPendingCmds.current.push(cmd1);

        } catch (e) {
            console.log("[Setting]", e);
            setTip({ open: true, severity: "error", msg: "Read-Write error" });
        }        
    }

    const handleRead = async (Category) => {
        var address = 0xffff;
        var addressTrack = address;
        var count = 0;
        
        if (Object.keys(refPendingCmds.current).length > 0 || refExecutingCmd.current != null) {
            setTip({ open: true, severity: "info", msg: "waiting for pending command(s)..." });
            return;
        }

        for (var i in SettingDefs) {
            var def = SettingDefs[i];
            if (def.Category == Category) {
                if (address > def.RegisterAddressNumber) {
                    address = def.RegisterAddressNumber;
                    addressTrack = address;
                    count = 0;
                }
                if (
                    (def.RegisterAddressNumber != addressTrack && def.RegisterAddressNumber != addressTrack+1 && count > 0) || 
                    (def.ReadWrite.indexOf("R") == -1 && count > 0) || 
                    count >=100
                ) {
                    console.log("[Setting-Read]", address.toString(16), count);

                    const cmd1 = {
                        "Code": 0x03,
                        "Address": address,
                        "Count": count,
                    };
                    const cmd2 = {
                        SerialNumber: SN,
                        SettingRead: {
                            [(address).toString(16)]: cmd1.Count,
                        },
                    }
                    postCmd(cmd1, cmd2);
                    if (def.ReadWrite.indexOf("R") == -1) {
                        address = 0xffff;
                        count = 0;
                    } else {
                        address = def.RegisterAddressNumber;
                        addressTrack = address;
                        count = 1;
                    }
                } else if (def.ReadWrite.indexOf("R") == -1) {
                    address = 0xffff;
                    count = 0;
                } else {
                    addressTrack = def.RegisterAddressNumber;
                    count ++;
                }
            } else {
                if (address != 0xffff) {
                    break;
                }
            }
        }
        if (count > 0) {
            console.log("[Setting-Read]", address.toString(16), count);

            const cmd1 = {
                "Code": 0x03,
                "Address": address,
                "Count": count,
            };
            const cmd2 = {
                SerialNumber: SN,
                SettingRead: {
                    [(address).toString(16)]: cmd1.Count,
                },
            }
            postCmd(cmd1, cmd2);
        }
    }

    const handleWrite = async (Category) => {
        var pvs = refPendingValues.current;
        if (Object.keys(pvs).length <= 0) {
            return;
        } 

        if (Object.keys(refPendingCmds.current).length > 0 || refExecutingCmd.current != null) {
            setTip({ open: true, severity: "info", msg: "waiting for pending command(s)..." });
            return;
        }

        setWriteErrors({});

        var cvs = refComfirmedValues.current;
        var ok = true;
        for (var i in SettingDefs) {
            var def = SettingDefs[i];
            var bs = [];
            const address = def.RegisterAddressNumber;
            if ([0x3501,0x3502,0x3503].indexOf(address) > -1) {
                continue;
            }
            var v = pvs[address];
            if (def.Category == Category && v != undefined) {
                if (address == 0x3500) { //设置时间，必须批量设置
                    bs.push((v>>8) & 0xff, v & 0xff);
                    v = pvs[0x3501];
                    bs.push((v>>8) & 0xff, v & 0xff);
                    v = pvs[0x3502];
                    bs.push((v>>8) & 0xff, v & 0xff);
                    v = pvs[0x3503];
                    bs.push((v>>8) & 0xff, v & 0xff);

                    bs = [0x01, 0x10, (address>>8)&0xff, address&0xff, 0,4,bs.length, ...bs];
                    var str = CRC.ToModbusCRC16(bs);
                    str = Array2hex(bs) + str;

                    const cmd1 = {
                        "Code": 0x10,
                        "Address": address,
                        "Request": str,
                    };
                    const cmd2 = {
                        SerialNumber: SN,
                        Setting: {
                            [address.toString(16)]: str,
                        },
                    }

                    postCmd(cmd1, cmd2);
                } else {
                    //v = parseInt(v)
                    v = parseFloat(v)
                    if (isNaN(v) || v === null) {
                        continue;
                    }

                    if (def.Scale) {
                        v /= def.Scale;
                        v = Math.round(v);
                    }

                    switch (def.RegisterType) {
                        case "U16":
                            bs.push((v>>8) & 0xff, v & 0xff);
                            break;
                        case "I16":
                            if (v < 0) {
                                v += 0xffff + 1;
                            }
                            bs.push((v>>8) & 0xff, v & 0xff);
                            break;
                        case "U32":
                            bs.push((v>>24) & 0xff, (v>>16) & 0xff, (v>>8) & 0xff, v & 0xff);
                            break;
                        case "I32":
                            if (v < 0) {
                                v += 0xffffffff + 1;
                            }
                            bs.push((v>>24) & 0xff, (v>>16) & 0xff, (v>>8) & 0xff, v & 0xff);
                            break;
                    }
                    bs = [0x01, 0x06, (address>>8)&0xff, address&0xff, ...bs];
                    var str = CRC.ToModbusCRC16(bs);
                    str = Array2hex(bs) + str;

                    const cmd1 = {
                        "Code": 0x06,
                        "Address": address,
                        "Request": str,
                    };
                    const cmd2 = {
                        SerialNumber: SN,
                        Setting: {
                            [address.toString(16)]: str,
                        },
                    }
                    postCmd(cmd1, cmd2);
                }
            }
        }
    }

    const handleDo = async (RegisterAddressNumber) => {
        console.log("[RegisterAddressNumber]", RegisterAddressNumber.toString(16));
        // try {
        //     const resp = await http.postJSON("integrated-inverter/setting/request", {
        //         SerialNumber: SN,
        //         Setting: {
        //             [parseInt(RegisterAddressNumber).toString(16)]: 1,
        //         },
        //     }).then(async (r) => {
        //         return await r.json();
        //     }).catch((e) => {
        //         console.log("[Setting]", e);
        //         return null;
        //     });
        // } catch (e) {
        //     console.log("[Setting]", e);

        //     setTip({ open: true, severity: "error", msg: "Write error" });
        // }
        const address = parseInt(RegisterAddressNumber);
        const cmd1 = {
            "Code": 0x06,
            "Address": address,
            "Request": "",
        };
        const cmd2 = {
            SerialNumber: SN,
            Setting: {
                [address.toString(16)]: 1,
            },
        }
        await postCmd(cmd1, cmd2);
    }

    const editor_onchange_handler = (props) => {
        console.log("[Setting]", props);
        var cvs = refComfirmedValues.current;
        var pvs = refPendingValues.current;
        var pvs_changed = false;
        for (var k in props) {
            var v = props[k];
            if (defMap[k].RegisterAddressNumber == 0x3500) {
                if (v) { 
                    // v = v.unix();
                    const year = v.year() % 100;
                    const month = v.month()+1;
                    const day = v.date();
                    const hour = v.hour();
                    const minute = v.minute();
                    const second = v.second();
                    let week = v.day();
                    if (week == 0) {
                        week = 7;
                    }
                    pvs_changed = true;
                    pvs[0x3500] = (year << 8) + month;
                    pvs[0x3501] = (day << 8);
                    pvs[0x3502] = (hour << 8) + minute;
                    pvs[0x3503] = (second << 8) + week;
                }
            } else {
                if (cvs[k] == v) {
                    if (pvs.hasOwnProperty(k)) {
                        delete pvs[k];
                        pvs_changed = true;
                    }
                } else {
                    pvs[k] = v;
                    pvs_changed = true;
                }
            }
        }
        if (pvs_changed) {
            setPendingValues({...pvs});
        }
    } 

    var elems = [];
    const toRow = (cells, rows) => {
        var cols = [];
        for (var i in cells) {
            var cell = cells[i];
            // console.log("[Cell]==>", cell);
            cols.push(<Col span={12}>
                {cell}
            </Col>)
        }
        // console.log("[Row]==>", cols);
        rows.push(<Row>
            {cols}
        </Row>); 
    }
    if (SettingDefs == null) {
        elems.push("waiting...");
    } else {
        for (var i in defs) {
            subDefs = defs[i];
            var rows = [];
            var cells = []; 
            for (var j in subDefs) {
                def = subDefs[j];

                if(def.RegisterAddressNumber == 0x347c) {  //非标机的参数不显示
                    continue;
                }

                if (cells.length >= 2 || def.RegisterAddressNumber == 0x3446) {
                    toRow(cells, rows);
                    cells = [];
                }
                
                var editor = null;
                if (def.ReadWrite == "W") {
                    var doing = doingsResult[def.RegisterAddressNumber];
                    editor = <Button 
                        type="primary" 
                        size="medium"
                        shape="round"
                        style={{
                            backgroundColor: "#40a9ff",
                            color: "white",
                            textTransform: "none"

                        }}
                        data-register-address={def.RegisterAddressNumber} 
                        onClick={(e) => {handleDo(e.currentTarget.getAttribute("data-register-address")); e.stopPropagation()}}
                    >Do{doing ? (doing=="OK"?<span style={{color:"#C1FFC1"}}>&nbsp;√</span>:<span style={{color:"#FF6461"}}>&nbsp;×</span>) : ""}</Button>;
                } else {
                    if (def.RegisterAddressNumber == 0x3500) {
                        if (pendingValues.hasOwnProperty(def.RegisterAddressNumber)) {
                            editor = <DatePicker 
                                showTime 
                                allowClear={false}
                                placeholder='Select datetime'
                                suffixIcon={<EditOutlined />}
                            />
                        } else {
                            editor = <DatePicker 
                                showTime 
                                allowClear={false}
                                placeholder='Select datetime'
                            />
                        }
                    } else if (def.Enums) {
                        if (def.Enums[0] == "{") {
                            var Enums = JSON.parse(def.Enums);
                            editor = (<BitsEditor key="BitEditor" BitsDef={Enums} pendingValue={pendingValues[def.RegisterAddressNumber]}></BitsEditor>);
                        } else if (def.Enums[0] == "[") {
                            //
                        } else {
                            var Enums = def.Enums.split(";");
                            var opts = [];
                            for (var k in Enums) {
                                var vn = Enums[k].split(":");
                                opts.push(<Select.Option
                                    key={vn[0]}
                                    value={parseInt(vn[0])}
                                >{vn[1]}</Select.Option>);
                            }
                            editor = <Select
                                suffixIcon={pendingValues.hasOwnProperty(def.RegisterAddressNumber) ? <EditOutlined /> : undefined}
                            >{opts}</Select>;
                        }
                    } else if (HMAddresses.indexOf(def.RegisterAddressNumber) > -1) {
                        editor = <HourMinuteEditor 
                            key={def.RegisterAddressNumber}
                            pendingValue={pendingValues[def.RegisterAddressNumber]}
                        />;
                    } else {
                        editor = <Input  
                            suffix={pendingValues.hasOwnProperty(def.RegisterAddressNumber) ? <EditOutlined /> : <span />}
                            placeholder={def["Ranges"] ?? ""}
                            // precision={def.Scale ? def.Scale.toString().length-2 : undefined} 
                        />;
                    }
                }
                cells.push(<Form.Item
                    key={def.RegisterAddress}
                    label={def.RegisterAddressNumber==0x3500 ? "Date Time" : (def.Intls["English"]+(def.Unit?` (${def.Unit})`:""))}
                    name={def.RegisterAddressNumber}
                    hidden={[0x3501,0x3502,0x3503].indexOf(def.RegisterAddressNumber)>-1 ? true : false}
                >
                    {editor}
                </Form.Item>);
            }
            if (cells.length > 0) {
                toRow(cells, rows);
            }
            elems.push(<Collapse.Panel
                key={i}
                header={<span style={{fontWeight: "bold"}}>{subDefs[0].Category}</span>}
                extra={subDefs[0].Category == "Setting-Instruction" ? "" : (<div key={i} style={{display:"flex"}}>
                    <Button 
                        type="primary" 
                        size="medium"
                        shape="round"
                        style={{
                            backgroundColor: "white",
                            color: "#888",
                            textTransform: "none",
                            border: "1px solid #eee"

                        }}
                        data-category={subDefs[0].Category} 
                        onClick={(e) => {handleReset(e.currentTarget.getAttribute("data-category")); e.stopPropagation()}}
                    >Reset</Button>
                    <div style={{ width: "30px" }}></div>
                    <Button 
                        type="primary" 
                        size="medium"
                        shape="round"
                        style={{
                            backgroundColor: "#40a9ff",
                            color: "white",
                            textTransform: "none"

                        }}
                        data-category={subDefs[0].Category} 
                        onClick={(e) => {handleRead(e.currentTarget.getAttribute("data-category")); e.stopPropagation()}}
                    >Read</Button>
                    <div style={{ width: "15px" }}></div>
                    <Button 
                        type="primary" 
                        size="medium"
                        shape="round"
                        style={{
                            backgroundColor: "#40a9ff",
                            color: "white",
                            textTransform: "none"

                        }} 
                        data-category={subDefs[0].Category} 
                        onClick={(e) => {handleWrite(e.currentTarget.getAttribute("data-category")); e.stopPropagation()}}
                    >Write</Button>
                </div>)}
            >
                {rows}
            </Collapse.Panel>);
        }
    }
    

    return (
        <div
            style={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
                overflow: "hidden",
            }}
        >{SettingDefs==null ? "waiting..." : (<>
            <div style={{
                flex: '1 1 auto',
                backgroundColor: 'white',
                overflowY: "scroll"
            }}>
                <Form
                    form={form}
                    labelCol={{ span: 12 }}
                    wrapperCol={{ span: 8 }}
                    layout="horizontal"
                    size="small"
                    style={{
                        width: "100%",
                    }}
                    onValuesChange={editor_onchange_handler}
                >
                    <Collapse defaultActiveKey={['0','1','2','3','4','5','6','7','8','9','10','11','12','13','14','15','16','17','18','19']}>{elems}</Collapse>
                </Form>
            </div>
            <WS
                SerialNumber={SN}
                onConnect={handleWSConnect}
                onData={handleWSData}
                onClose={handleWSClose}
                onError={handleWSError}
            ></WS>
        </>)}
        </div>
    );
}

export default MegarevoSetting;