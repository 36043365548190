import http from '../Http';
import { useEffect, useState, useContext, useRef } from 'react';
import utils from '../utils';
import { Table, Input, Button, Modal, Form, Row, Col, Select, Popover, DatePicker } from 'antd';
import { UpOutlined, DownOutlined } from '@ant-design/icons';
import moment from 'moment';
import defs from "./defs"
import SettingDetail from './SettingDetail';
import InputPrefix from '../utils/InputPrefix';

function SettingHistory (props) {
    const setTip = useContext(utils.tipContext);

    const [pageNo, setPageNo] = useState(0);
    const [pageSize, setPageSize] = useState(25);
    const [params, setParams] = useState({
        SN: '',
        ProductName: '',
        DistributorName: '',
        UserID: '',
        SettingID: '',
        STime: '',
        ETime: '',
    });
    const [loading, setLoading] = useState(false);
    const [rows, setRows] = useState([]);
    const [rowTotal, setRowTotal] = useState(0);
    const [tableBodyHeight, setTableBodyHeight] = useState(240);
    const [productSelectOptions, setProductSelectOptions] = useState([]);
    const [distributorSelectOptions, setDistributorSelectOptions] = useState([]);
    const [expand, setExpand] = useState(false);
    const [showDetails, setShowDetails] = useState(false);
    const [rowForDetails, setRowForDetails] = useState({});

    const refTableContainer = useRef();
    const refTimerProductSearcher = useRef(null);
    const refProductSearchValue = useRef(null);
    const refTimerDistributorSearcher = useRef(null);
    const refDistributorSearchValue = useRef(null);

    const [form] = Form.useForm();

    useEffect(() => {
        let active = true;
        console.log("SettingHistory Effect");

        (async () => {
            setLoading(true);
            
            
            let resp = await http.post("setting/history/list", {
                PageNo: pageNo,
                PageSize: pageSize,
                ...params,
            }).then(async (r)=>{
                return await r.json();
            }).catch((e)=>{
                console.log("[SettingHistory]", e);
                return null;
            });

            if (! active) {
                return;
            }
            
            if (resp === null || resp.code !== 200) {
                setTip({open:true, severity:"error", msg:(resp && resp.msg) ? resp.msg : "load data error"});
                
                setRowTotal(0)
                setRows([]);
        
                return;
            }
            setRowTotal(resp.data.RowTotal);
            setRows(resp.data.Rows);
            setLoading(false);
        })();

        return () => {
            active = false;
            console.log("SettingHistory Effect Cancel");
        };
    }, [pageNo, pageSize, params]);

    useEffect(() => {
        const el = refTableContainer.current;

        if (el.objResizer) {
            return;
        }
        
        let obj = document.createElement('object');
        el.objResizer = obj; 
        obj.setAttribute('style',
            'display: block; position: absolute; top: 0; left: 0; height: 100%; width: 100%; overflow: hidden;opacity: 0; pointer-events: none; z-index: -1;');
        obj.onload = function () {
            setTableBodyHeight(calcTableBodyHeight());

            this.contentDocument.defaultView.addEventListener('resize', (evt) => {
                setTableBodyHeight(calcTableBodyHeight());
            });
        };
        obj.type = 'text/html';
        el.appendChild(obj);
        obj.data = 'about:blank';

        return () => {
            if (! el) {
                return
            }

            //
        }
    }, [refTableContainer.current]);

    const calcTableBodyHeight = () => {
        if (! refTableContainer.current) {
            return 240;
        }

        const hd = document.getElementsByClassName("ant-table-thead")[0];

        const h = refTableContainer.current.getBoundingClientRect().bottom - hd.getBoundingClientRect().bottom;

        return  h>=54 ? h - 54 : h /2;
    }

    const handleSearchProduct = (v) => {
        if (refTimerProductSearcher.current != null) {
            clearTimeout(refTimerProductSearcher.current);
        }
        refProductSearchValue.current = v;

        refTimerProductSearcher.current = setTimeout(async () => {
            const resp = await http.post("product/name/list", {
                Value: v,
            }).then(async(r) => {
                return await r.json();
            }).catch((e) => {
                console.log("[DeviceManagement]", e);
                return null;
            });

            if (refProductSearchValue.current !== v) {
                return;
            }

            try {
                if (resp.code !== 200) {
                    setTip({open:true, severity:"error", msg:(resp && resp.msg) ? resp.msg : "load product list error"});
                    return;
                }

                const ls = resp.data.map(({Name, ID}) => {
                    return (
                        <Select.Option key={ID} value={Name}>{Name}</Select.Option>
                    );
                });
                setProductSelectOptions(ls);
            } catch(e) {
                console.log("[DeviceManagement]", e);

                setTip({open:true, severity:"error", msg: "load product list error"});
            }
        }, 300);
    }
    
    const handleSearchDistributor = (v) => {
        if (refTimerDistributorSearcher.current != null) {
            clearTimeout(refTimerDistributorSearcher.current);
        }
        refDistributorSearchValue.current = v;

        refTimerDistributorSearcher.current = setTimeout(async () => {
            const resp = await http.post("distributor/name/list", {
                Value: v,
            }).then(async(r) => {
                return await r.json();
            }).catch((e) => {
                console.log("[DeviceManagement]", e);
                return null;
            });

            if (refDistributorSearchValue.current !== v) {
                return;
            }

            try {
                if (resp.code !== 200) {
                    setTip({open:true, severity:"error", msg:(resp && resp.msg) ? resp.msg : "load distributor list error"});
                    return;
                }

                const ls = resp.data.map(({Name, ID}) => {
                    return (
                        <Select.Option key={ID} value={Name}>{Name}</Select.Option>
                    );
                });
                setDistributorSelectOptions(ls);
            } catch(e) {
                console.log("[DeviceManagement]", e);

                setTip({open:true, severity:"error", msg: "load distributor list error"});
            }
        }, 300);
    }

    const handleSearch = () => {
        setPageNo(0);

        const ps = form.getFieldsValue();
        ps.SN = ps.SN || "";
        ps.ProductName = ps.ProductName || "";
        ps.DistributorName = ps.DistributorName || "";
        ps.UserID = ps.UserID || "";
        ps.SettingID = ps.SettingID || "";
        ps.STime = ps.DateRange&&ps.DateRange.length>0 ? ps.DateRange[0].format("YYYY-MM-DD") : "";
        ps.ETime = ps.DateRange&&ps.DateRange.length>1 ? moment(ps.DateRange[1]).add(1, "days").format("YYYY-MM-DD") : "";
        setParams(ps);
    }

    const handleSettingDetail = (row) => {
        setRowForDetails(row);
        setShowDetails(true);
    }

    //按下Enter键时进行查找
    const searchByEnter = (e) => { 
        if(e.code == "Enter") {
            handleSearch()
        }
    }

    return (
        <div style={{
            flex: "1 1 auto",
            display: "flex",
            flexDirection: "column",
            backgroundColor: '#EFF8FE',
            padding: 8,
        }}>
            <div style={{
                flex: "0 1 auto",
            }}>
                <Form 
                    form={form}
                    initialValues={{
                        Enabled: ["Enabled", 'Disabled'],  
                    }}
                    style={{
                        padding: 24,
                        background: '#fbfbfb',
                        border: '1px solid #d9d9d9',
                        borderRadius: 2
                    }}
                >
                    <Row gutter={24}>
                        <Col span={6} key="SN">
                            <Form.Item 
                                name="SN"
                                label=""
                            >
                                <Input prefix={<InputPrefix label="SN" />} placeholder="Enter Device SN" onPressEnter={handleSearch} style={{ borderRadius: '20px' }} />
                            </Form.Item>
                        </Col>
                        <Col span={6} key="Product">
                            <Input.Group compact>
                                <Button>Product</Button>
                                <Form.Item 
                                    name="ProductName"
                                    label=""
                                    style={{display: 'inline-block', width: 'calc(100% - 80px)'}}
                                >
                                    <Select 
                                        showSearch
                                        placeholder="Enter Product Name"
                                        defaultActiveFirstOption={false}
                                        showArrow={false}
                                        filterOption={false}
                                        onSearch={handleSearchProduct}
                                        onInputKeyDown={searchByEnter}
                                    >{productSelectOptions}</Select>
                                </Form.Item>
                            </Input.Group>
                        </Col>
                        <Col span={6} key="Distributor">
                            <Input.Group compact>
                                <Button>Distributor</Button>
                                <Form.Item 
                                    name="DistributorName"
                                    label=""
                                    style={{display: 'inline-block', width: 'calc(100% - 100px)'}}
                                >
                                    <Select 
                                        showSearch
                                        placeholder="Enter Distributor Name"
                                        defaultActiveFirstOption={false}
                                        showArrow={false}
                                        filterOption={false}
                                        onSearch={handleSearchDistributor}
                                        onInputKeyDown={searchByEnter}
                                    >{distributorSelectOptions}</Select>
                                </Form.Item>
                            </Input.Group>
                        </Col>
                        <Col span={6} key="UserID" >
                            <Form.Item 
                                name="UserID"
                                label=""
                            >
                                <Input prefix={<InputPrefix label='UserID'/>} placeholder="Enter User ID" onPressEnter={handleSearch} style={{ borderRadius: '20px' }} />
                            </Form.Item>
                        </Col>
                        <Col span={6} key="SettingID" style={{display:expand?"block":"none"}}>
                            <Input.Group compact>
                                <Button>SettingType</Button>
                                <Form.Item 
                                    name="SettingID"
                                    label=""
                                    style={{display: 'inline-block', width: 'calc(100% - 104px)'}}
                                >
                                    <Select onInputKeyDown={searchByEnter}>
                                        {defs.setting_metas.map((st) => {
                                            if (st.WriteID) {
                                                return (
                                                    <Select.Option key={st.WriteID} value={st.WriteID}>[Write] {st.Title}</Select.Option>
                                                )
                                            }
                                        })}
                                        {defs.setting_metas.map((st) => {
                                            return (
                                                <Select.Option key={st.ReadID} value={st.ReadID}>[Read] {st.Title}</Select.Option>
                                            )
                                        })}
                                    </Select>
                                </Form.Item>
                            </Input.Group>
                        </Col>
                        <Col span={6} key="DateRange" style={{display:expand?"block":"none"}}>
                            <Form.Item 
                                name="DateRange"
                                label=""
                            >
                                <DatePicker.RangePicker style={{ borderRadius: '20px' }} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24} style={{ textAlign: 'right' }}>
                            <Button type="primary" shape="round" onClick={handleSearch}>
                                Search
                            </Button>
                            <Button
                                shape="round"
                                style={{ margin: '0 8px' }}
                                onClick={() => {
                                    form.resetFields();
                                }}
                            >
                                Clear
                            </Button>
                            <a
                                style={{ fontSize: 12 }}
                                onClick={() => {
                                    setExpand(!expand);
                                }}
                            >
                                {expand ? <UpOutlined /> : <DownOutlined />} {expand ? 'Collapse' : 'Expand'}
                            </a>
                        </Col>
                    </Row>
                </Form>
            </div>
            <div style={{
                flex:"1 1 1px",
                position: "relative",
            }}>
                <div ref={refTableContainer} style={{position: "absolute", height: '100%', width: "100%", overflow: "hidden"}}>
                    <Table 
                        columns={[{
                            title: "",
                            width: 70,
                            fixed: 'left',
                            render: (v, r, idx) => {
                                return (pageNo*pageSize+idx+1);
                            }
                        },{
                            title: "SN",
                            dataIndex: "SN",
                            width: 200,
                            fixed: 'left',
                            render: (v, r) => {
                                const content = (
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "column",
                                    }}>
                                        <div style={{
                                            display: "flex"
                                        }}>
                                            <div style={{
                                                flex: "0 0 80px",
                                                textAlign: "right",
                                                fontWeight: "bold",
                                            }}>Product:&nbsp;</div>
                                            <div style={{flex: "1"}}>{r.ProductName}</div>
                                        </div>
                                        <div style={{
                                            display: "flex"
                                        }}>
                                            <div style={{
                                                flex: "0 0 80px",
                                                textAlign: "right",
                                                fontWeight: "bold",
                                            }}>Distributor:&nbsp;</div>
                                            <div style={{flex: "1"}}>{r.DistributorName}</div>
                                        </div>
                                    </div>
                                );
                                return (
                                    <Popover title="Note" content={content}>
                                        <div>{v}</div>
                                    </Popover>
                                );
                            }
                        },{
                            title: "SettingType",
                            dataIndex: "SettingID",
                            width: 200,
                            render: (v, r, idx) => {
                                return (v > 0x3000 ? "[Read] " : "[Write] ") + (defs.setting_meta_map[v] || {Title: ""}).Title;
                            }
                        },{
                            title: "Request Data",
                            dataIndex: 'Data',
                            width: 100,
                            render: (v, r, idx) => {
                               if (v) {
                                   return (<Button type="link" onClick={() => {handleSettingDetail(r)}}>Details</Button>)
                               }
                            }
                        },{
                            title: "Request User",
                            dataIndex: 'CreatorID',
                            width: 100,
                        },{
                            title: "Request Time",
                            dataIndex: 'CreateTime',
                            width: 180,
                        },{
                            title: "Response Data",
                            dataIndex: 'RspData',
                            width: 100,
                            render: (v, r, idx) => {
                               if (v) {
                                   return (<Button type="link" onClick={() => {handleSettingDetail(r)}}>Details</Button>)
                               }
                            }
                        },{
                            title: "Response Time",
                            dataIndex: 'RspTime',
                        }]}
                        pagination={{
                            pageSizeOptions: [25,50,100],
                            current: pageNo+1,
                            pageSize: pageSize,
                            total: rowTotal,
                            showSizeChanger: true,
                            showTotal: (total) => {return `Total ${total}`},
                            onShowSizeChange: (current, size) => {
                                setPageSize(size);
                            },
                            onChange: (page, pageSize) => {
                                setPageNo(page-1);                                
                            }
                        }}
                        tableLayout="fixed"
                        bordered={true}
                        size="middle"
                        loading={loading}
                        rowKey="ID"
                        dataSource={rows}
                        scroll={{ x: 1500, y: tableBodyHeight }}
                    />
                </div>
            </div>
            <SettingDetail 
                show={showDetails} 
                onClose={()=>{setShowDetails(false)}}
                {...rowForDetails}
            />
        </div>
    );
}

export default SettingHistory;