import config from '../Config';
import http from '../Http';
import { useEffect, useState, useContext, useRef } from 'react';
import utils from '../utils';
import { Table, Button, Form, Row, Col, DatePicker, Select, Modal, Space, Input } from 'antd';
import { CloudDownloadOutlined } from '@ant-design/icons';
import moment from 'moment';


function TemperatureDataExporter(props) {
    const setTip = useContext(utils.tipContext);

    const [pageNo, setPageNo] = useState(0);
    const [pageSize, setPageSize] = useState(100);
    const [params, setParams] = useState({
        SN: '',
        STime: moment().format("YYYY-MM-DD"),
        ETime: moment().add(1, "days").format("YYYY-MM-DD"),
    });
    const [loading, setLoading] = useState(false);
    const [rows, setRows] = useState([]);
    const [rowTotal, setRowTotal] = useState(0);
    const [timezoneStr, setTimezoneStr] = useState("");
    const [tableBodyHeight, setTableBodyHeight] = useState(240);
    const [deviceSelectOptions, setDeviceSelectOptions] = useState([]);

    const refTableContainer = useRef();
    const refTimerDeviceSearcher = useRef(null);
    const refDeviceSearchValue = useRef(null);
    const refDownloader = useRef(null);

    const [form] = Form.useForm();

    const [downloadListOpen, setDownloadListOpen] = useState(false)  //是否打开下载列表窗口
    const [downloadList, setDownloadList] = useState([])  //下载列表
    const [updateDownloadList, setUpdateDownloadList] = useState(false)  //更新下载列表
    const [isDownloading, setIsdownloading] = useState(false)

    const [dates, setDates] = useState(null)
    const [value, setValue] = useState(null)

    useEffect(() => {
        let active = true;

        (async () => {
            setLoading(true);

            let resp = await http.post("data-export/temperature/list", {
                PageNo: pageNo,
                PageSize: pageSize,
                ...params,
            }).then(async (r) => {
                return await r.json();
            }).catch((e) => {
                console.log("[DataExporter]", e);
                return null;
            });

            if (!active) {
                return;
            }

            if (resp === null || resp.code !== 200) {
                setTip({ open: true, severity: "error", msg: (resp && resp.msg) ? resp.msg : "load data error" });

                setRowTotal(0)
                setRows([]);

                return;
            }
            setRowTotal(resp.data.RowTotal);
            setRows(resp.data.Rows);
            setTimezoneStr(resp.data.TimezoneStr);
            setLoading(false);
        })();

        return () => {
            active = false;
        };
    }, [pageNo, pageSize, params]);

    useEffect(() => {
        const el = refTableContainer.current;

        if (el.objResizer) {
            return;
        }

        let obj = document.createElement('object');
        el.objResizer = obj;
        obj.setAttribute('style',
            'display: block; position: absolute; top: 0; left: 0; height: 100%; width: 100%; overflow: hidden;opacity: 0; pointer-events: none; z-index: -1;');
        obj.onload = function () {
            setTableBodyHeight(calcTableBodyHeight());

            this.contentDocument.defaultView.addEventListener('resize', (evt) => {
                setTableBodyHeight(calcTableBodyHeight());
            });
        };
        obj.type = 'text/html';
        el.appendChild(obj);
        obj.data = 'about:blank';

        return () => {
            if (!el) {
                return
            }

            //
        }
    }, [refTableContainer.current]);

    const handleSearchDevice = (v) => {
        if (refTimerDeviceSearcher.current != null) {
            clearTimeout(refTimerDeviceSearcher.current);
        }
        refDeviceSearchValue.current = v;

        const id = form.getFieldValue("DeviceID");

        refTimerDeviceSearcher.current = setTimeout(async () => {
            const resp = await http.post("device/id-name/list", {
                DistributorIsTheSameAsTheDeviceID: id || "",
                SN: v,
            }).then(async (r) => {
                return await r.json();
            }).catch((e) => {
                console.log("[TemperatureDataExporter]", e);
                return null;
            });

            if (refDeviceSearchValue.current !== v) {
                return;
            }

            try {
                if (resp.code !== 200) {
                    setTip({ open: true, severity: "error", msg: (resp && resp.msg) ? resp.msg : "load device list error" });
                    return;
                }

                const ls = resp.data.map(({ SN, ID }) => {
                    return (
                        <Select.Option key={ID} value={ID}>{SN}</Select.Option>
                    );
                });
                setDeviceSelectOptions(ls);
            } catch (e) {
                console.log("[TemperatureDataExporter]", e);

                setTip({ open: true, severity: "error", msg: "load device list error" });
            }
        }, 300);
    }

    const handleSearch = () => {
        setPageNo(0);

        const ps = form.getFieldsValue();
        ps.DeviceID = ps.DeviceID || "";
        ps.STime = ps.DateRange && ps.DateRange.length > 0 ? ps.DateRange[0].format("YYYY-MM-DD") : "";
        ps.ETime = ps.DateRange && ps.DateRange.length > 1 ? moment(ps.DateRange[1]).add(1, "days").format("YYYY-MM-DD") : "";
        setParams(ps);
    }

    // const handleDownload = () => {
    //     if (!refDownloader.current) {
    //         return;
    //     }

    //     refDownloader.current.click()
    // }

    useEffect(() => {
        var timer = null
        //列举所有下载任务
        let fn = async () => {
            let resp = await http.post("data-export/download/doc-job/list", {
                Category: 'temperature'
            }).then(async (r) => {
                return await r.json()
            }).catch((e) => {
                console.log("[DataExporter]", e)
                return null
            })
            if(resp === null || resp.code !== 200) {
                setTip({ open: true, severity: "error", msg: (resp && resp.msg) ? resp.msg : "load data error" })
                return
            }
            setDownloadList(resp.data)
            let jobID = 0
            resp.data.forEach(v => {
                if(v.Progress < 100) {
                    jobID = v.ID
                }
            })
            if(jobID > 0) {  //如果有任务正在下载，则定时查询任务是否完成
                setIsdownloading(true)
                timer = setTimeout(fn, 1000)
            } else {
                clearTimeout(timer)
                setIsdownloading(false)
            }
        }
        fn()
    }, [updateDownloadList])

    const handleDownload = () => {
        //新建下载任务
        (async () => {
            let resp = await http.post("data-export/temperature/download/request", {
                DeviceID: params.DeviceID,
                STime: params.STime,
                ETime: params.ETime,
            }).then(async (r) => {
                return await r.json()
            }).catch((e) => {
                console.log("[DataExporter]", e)
                return null
            })
            if(resp === null || resp.code !== 200) {
                setTip({ open: true, severity: "error", msg: (resp && resp.msg) ? resp.msg : "load data error" })
                return
            }
            setIsdownloading(true)
            setUpdateDownloadList(updateDownloadList => !updateDownloadList)
        })()
    }

    const showDownloadList = () => {
        setUpdateDownloadList(updateDownloadList => !updateDownloadList)
        setDownloadListOpen(true)
    }

    const IgnoreDownload = (r) => {
        //取消下载任务
        (async () => {
            let resp = await http.post("data-export/download/doc-job-cancel/do", {
                JobID: r.ID
            }).then(async (r) => {
                return await r.json()
            }).catch((e) => {
                console.log("[DataExporter]", e)
                return null
            })
            if(resp === null || resp.code !== 200) {
                return
            }
            setUpdateDownloadList(updateDownloadList => !updateDownloadList)
            if(r.Progress < 100) {
                setIsdownloading(false)
            }
        })()
    }

    const SeeDownload = (r) => {
        //查看下载的文件
        if(r.DisabledBtn || r.Progress < 100) return;
        setDownloadList(downloadList.map((item) => {
            if (item.ID === r.ID) {
                item.DisabledBtn = true
            }
            return item
        }))
        
        refDownloader.current.href = config.apiUrl + "data-export/download/do?JobID=" + r.ID
        refDownloader.current.click()
        IgnoreDownload(r)
    }

    const handleOk = () => {
        setDownloadListOpen(false)
    }

    const handleCancel = () => {
        setDownloadListOpen(false)
    }

    const calcTableBodyHeight = () => {
        if (!refTableContainer.current) {
            return 240;
        }

        const hd = document.getElementsByClassName("ant-table-thead")[0];

        const h = refTableContainer.current.getBoundingClientRect().bottom - hd.getBoundingClientRect().bottom;

        return h >= 54 ? h - 54 : h / 2;
    }

    const cs = [{
        title: "",
        width: 80,
        fixed: 'left',
        render: (v, r, idx) => {
            return (pageNo * pageSize + idx + 1);
        }
    }, {
        title: "Upload Time" + (timezoneStr != null ? "(" + timezoneStr + ")" : ""),
        dataIndex: "CreateTime",
        width: 160,
        fixed: 'left',
    }];
    let max = 0;
    if (rows.length > 0) {
        rows.every((r) => {
            for (let i = max; i < 32; i++) {
                const x = `V` + (i + 1).toString().padStart(3, '0');
                if (r.hasOwnProperty(x) && r[x] !== null) {
                    max = i+1;
                    continue
                }
                break;
            }

            if (max === 32) {
                return false;
            }

            return true;
        });
    } else {
        max = 16;
    }
    for (let i = 0; i < max; i++) {
        const x = `V` + (i + 1).toString().padStart(3, '0');
        cs.push({
            title: x.replace('V', 'T') + "(℃)",
            dataIndex: x,
            width: 90,
            render: (v, r, index) => {
                if (v !== null) {
                    return v - 50;
                }

                return v;
            }
        });
    }

    //按下Enter键时进行查找
    const searchByEnter = (e) => {
        if (e.code == "Enter") {
            handleSearch()
        }
    }

    return (
        <div style={{
            flex: "1 1 auto",
            display: "flex",
            flexDirection: "column",
            backgroundColor: '#EFF8FE',
            padding: 8,
        }}>
            <div style={{
                flex: "0 1 auto",
            }}>
                <Form
                    form={form}
                    initialValues={{
                        DateRange: [moment(), moment()]
                    }}
                    style={{
                        padding: 24,
                        background: '#fbfbfb',
                        border: '1px solid #d9d9d9',
                        borderRadius: 2
                    }}
                >
                    <Row gutter={24}>
                        <Col span={8} key="DeviceID">
                            <Input.Group compact>
                                <Button>Device SN</Button>
                                <Form.Item
                                    name="DeviceID"
                                    label=""
                                    style={{display: 'inline-block', width: 'calc(100% - 120px)'}}
                                >
                                    <Select
                                        // style={{minWidth: 150}}
                                        showSearch
                                        placeholder="Device SN"
                                        defaultActiveFirstOption={false}
                                        showArrow={false}
                                        filterOption={false}
                                        onSearch={(value) => {
                                            handleSearchDevice(value);
                                        }}
                                        onInputKeyDown={searchByEnter}
                                    >{deviceSelectOptions}</Select>
                                </Form.Item>
                            </Input.Group>
                        </Col>
                        <Col span={10} key="DateRange" >
                            <Form.Item
                                name="DateRange"
                                label=""
                            >
                                <DatePicker.RangePicker
                                    style={{ borderRadius: '20px'}}
                                    value={dates || value}
                                    disabledDate={(current) => {
                                        if(!dates) {
                                            return false;
                                        }
                                        const tooLate = dates[0] && current.diff(dates[0], 'days')>=7;
                                        const tooEarly = dates[1] && dates[1].diff(current, 'days')>=7;
                                        return !!tooEarly || !!tooLate;
                                    }}
                                    onCalendarChange={(val) => setDates(val)}
                                    onChange={(val) => setValue(val)}
                                    onOpenChange={(open) => {
                                        if (open) {
                                            setDates([null, null])
                                            form.setFieldsValue({'DateRange': null})
                                        } else {
                                            setDates(null)
                                            if(!form.getFieldValue('DateRange')) {
                                                form.setFieldsValue({'DateRange': value || [moment(), moment()]})
                                            }
                                        }
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={6} style={{ textAlign: 'right' }}>
                            <Button type="primary" shape="round" onClick={handleSearch}>
                                Search
                            </Button>
                            <Button
                                shape="round"
                                style={{ margin: '0 8px' }}
                                onClick={() => {
                                    form.resetFields();
                                }}
                            >
                                Clear
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </div>
            <div style={{
                flex: '0 0 auto',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end',
                paddingTop: 8,
                paddingBottom: 8,
            }}>
                {/* <Button disabled={rows.length < 1 ? true : false} onClick={handleDownload} type="primary" icon={<CloudDownloadOutlined />} size="middle" /> */}
                {isDownloading ? 
                    <Button disabled={true} type="primary" icon={<CloudDownloadOutlined />}>Preparing</Button> : 
                    <Button disabled={rows.length < 1 ? true : false} onClick={handleDownload} type="primary" icon={<CloudDownloadOutlined />} size="middle" />}
                {downloadList.length > 0 ? 
                    <Button onClick={showDownloadList}>Download List</Button> :
                    null}
            </div>
            <div style={{
                flex: "1 1 1px",
                position: "relative",
            }}>
                <div ref={refTableContainer} style={{ position: "absolute", height: '100%', width: "100%", overflow: "hidden" }}>
                    <Table
                        columns={cs}
                        pagination={{
                            pageSizeOptions: [100, 200, 500],
                            current: pageNo + 1,
                            pageSize: pageSize,
                            total: rowTotal,
                            showSizeChanger: true,
                            showTotal: (total) => { return `Total ${total}` },
                            onShowSizeChange: (current, size) => {
                                setPageSize(size);
                            },
                            onChange: (page, pageSize) => {
                                setPageNo(page - 1);
                            }
                        }}
                        tableLayout="fixed"
                        bordered={true}
                        size="middle"
                        loading={loading}
                        rowKey="ID"
                        dataSource={rows}
                        scroll={{ y: tableBodyHeight }}
                    />
                </div>
            </div>
            {/* <a ref={refDownloader} style={{ display: "none" }} href={config.apiUrl + "data-export/temperature/download/do?Token=" + http.token + "&DeviceID=" + params.DeviceID + "&STime=" + params.STime + "&ETime=" + params.ETime}></a> */}
            <a ref={refDownloader} style={{ display: "none" }}></a>
            {/* 下载提示信息 */}
            <Modal 
                title='Download List'
                visible={downloadListOpen}
                centered={true}
                onOk={handleOk}
                onCancel={handleCancel}
                destroyOnClose={true}
                footer={null}
                width={800}
                style={{marginTop: '100px'}}
            >
                <Table 
                    columns={[
                        {
                            title: "",
                            width: 40,
                            fixed: 'left',
                            render: (v, r, idx) => {
                                return (idx + 1);
                            }
                        },
                        {
                            title: 'Device SN',
                            dataIndex: "DeviceSN",
                            width: 100,
                        },
                        {
                            title: 'Progress',
                            width: 80,
                            render: (v, r) => {
                                return r.Progress == 100 ? 'Finished' : 'Preparing'
                            }
                        },
                        {
                            title: 'STime',
                            dataIndex: "STime",
                            width: 80,
                        },
                        {
                            title: 'ETime',
                            dataIndex: "ETime",
                            width: 80,
                        },
                        {
                            title: 'Create Time',
                            dataIndex: "CreateTime",
                            width: 120,
                        },
                        {
                            title: 'Action',
                            width: 120,
                            render: (v, r, idx) => {
                                return (
                                    <Space size="small">
                                        <a onClick={()=>IgnoreDownload(r)}>Cancel</a>
                                        <a disabled={r.Progress < 100 || r.DisabledBtn ? true : false} onClick={()=>SeeDownload(r)}>Download</a>
                                    </Space>
                                )
                            }
                        },
                    ]}
                    tableLayout="fixed"
                    bordered={true}
                    size="small"
                    rowKey="ID"
                    dataSource={downloadList}
                    pagination={false}
                />
            </Modal>
        </div>
    );
}

export default TemperatureDataExporter;