import React, { useState } from 'react';
import { Select } from 'antd';
import { EditOutlined } from '@ant-design/icons';



const BitsEditor = ({value, pendingValue, onChange, BitsDef={}}) => {
    const  bits = {}
    for (var k in BitsDef) {
        k = parseInt(k);
        var bitV = 0;
        for (var i=0; i<(BitsDef[k].Bits??1); i++) {
            bitV += ((value >> (k+i))&1) << i;
        }
        bits[k] = bitV;
    }

    const triggerChange = (newValue) => {
        var bs = {...bits, ...newValue};
        let v = 0;
        for (var k in bs) {
            v += (bs[k] << parseInt(k));
        }
        
        onChange?.(v);
    }

    const onBitChange = (bitIndex, value) => {
        triggerChange({[bitIndex]: value});
    }

    return (
        <div
            style={{
                display:"flex",
                flexDirection:"column",
            }}
        >
            {Object.keys(BitsDef).map((k) => <div
                key={"Bit_"+k}
                style={{
                    display:"flex",
                    flexDirection:"row",
                    paddingTop: 1,
                    paddingBottom: 1,
                }}
            >
                <div>{BitsDef[k].Title}:</div>
                <div style={{marginLeft:4}}>
                    <Select
                        suffixIcon={pendingValue!=undefined&&pendingValue!=null ? <EditOutlined /> : undefined}
                        value={bits[k]}
                        onChange={(value)=>{onBitChange(k, value)}}
                    >
                        {Object.keys(BitsDef[k].Values).map((kk) => <Select.Option 
                            key={kk}
                            value={parseInt(kk, 10)}
                        >{BitsDef[k].Values[kk]}</Select.Option>)}
                    </Select>
                </div>
            </div>)}
        </div>
    );
};

export default BitsEditor;