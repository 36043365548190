import config from '../Config';
import http from '../Http';
import { useContext, useState, useRef } from 'react';
import utils from '../utils';
import { Modal, Button, Input, Form, Select, DatePicker, Upload  } from 'antd';
import defs from "./defs"

function FirmwareAdd (props) {
    const setTip = useContext(utils.tipContext);

    const {show, onClose} = props;

    const [form] = Form.useForm();

    const [productSelectOptions, setProductSelectOptions] = useState([]);
    const [distributorSelectOptions, setDistributorSelectOptions] = useState([]);
    const [burningTypeSelectOptions, setBurningTypeSelectOptions] = useState([<Select.Option key={1} value={1}>1:APP</Select.Option>,<Select.Option key={2} value={2}>2:BOOT</Select.Option>]);
    
    const refTimerProductSearcher = useRef(null);
    const refProductSearchValue = useRef(null);
    const refTimerDistributorSearcher = useRef(null);
    const refDistributorSearchValue = useRef(null);
    
    const refSelectedProductID = useRef(null); 
    const refSelectedType = useRef(null);

    const handleCancle = () => {
        onClose(null);
    }

    const handleSubmit = async () => {
        const ps = await form.validateFields().catch(() => {return null});
        if (ps === null) {
            return;
        }

        if (! ps.FirmwareFile.file || ! ps.FirmwareFile.file.response || ps.FirmwareFile.file.response.code !== 200) {
            setTip({open:true, severity:'error', msg:"Firmware File Upload error"});
            return
        }

        ps.Enabled = ps.Enabled ? 1 : 0;
        ps.DistributorName = ps.DistributorName  || "";
        ps.FirmwareFile = ps.FirmwareFile.file.response.data[ps.FirmwareFile.file.name];
        ps.ExpiredTime = ps.ExpiredTime ? ps.ExpiredTime.format("YYYY-MM-DD") : "";
        ps.Note = ps.Note  || "";

        const resp = await http.post("firmware/management/add", ps).then(async (r)=>{
            return await r.json();
        }).catch((e) => {
            console.log("[FirmwareAdd]", e);
            return null;
        });

        try {
            if (resp.code !== 200) {
                setTip({open:true, severity:"error", msg:(resp && resp.msg) ? resp.msg : "add error"});
                return
            }

            setTip({open: true, severity: 'success', msg: 'add success'});

            ps.ID = resp.data.ID;
            ps.CreateTime = resp.data.CreateTime;
            onClose(ps);
        } catch (e) {
            console.log("[FirmwareAdd]", e);

            setTip({open:true, severity:"error", msg: "add error"});
        }
    }

    const handleSearchProduct = (v) => {
        if (refTimerProductSearcher.current != null) {
            clearTimeout(refTimerProductSearcher.current);
        }
        refProductSearchValue.current = v;

        refTimerProductSearcher.current = setTimeout(async () => {
            const resp = await http.post("product/name/list", {
                Value: v,
            }).then(async(r) => {
                return await r.json();
            }).catch((e) => {
                console.log("[FirmwareAdd]", e);
                return null;
            });

            if (refProductSearchValue.current !== v) {
                return;
            }

            try {
                if (resp.code !== 200) {
                    setTip({open:true, severity:"error", msg:(resp && resp.msg) ? resp.msg : "load product list error"});
                    return;
                }

                const ls = resp.data.map(({Name, ID}) => {
                    return (
                        <Select.Option key={ID} value={Name}>{Name}</Select.Option>
                    );
                });
                setProductSelectOptions(ls);
            } catch(e) {
                console.log("[FirmwareAdd]", e);

                setTip({open:true, severity:"error", msg: "load product list error"});
            }
        }, 300);
    }

    const handleSearchDistributor = (v) => {
        if (refTimerDistributorSearcher.current != null) {
            clearTimeout(refTimerDistributorSearcher.current);
        }
        refDistributorSearchValue.current = v;

        refTimerDistributorSearcher.current = setTimeout(async () => {
            const resp = await http.post("distributor/name/list", {
                Value: v,
            }).then(async(r) => {
                return await r.json();
            }).catch((e) => {
                console.log("[FirmwareAdd]", e);
                return null;
            });

            if (refDistributorSearchValue.current !== v) {
                return;
            }

            try {
                if (resp.code !== 200) {
                    setTip({open:true, severity:"error", msg:(resp && resp.msg) ? resp.msg : "load distributor list error"});
                    return;
                }

                const ls = resp.data.map(({Name, ID}) => {
                    return (
                        <Select.Option key={ID} value={Name}>{Name}</Select.Option>
                    );
                });
                setDistributorSelectOptions(ls);
            } catch(e) {
                console.log("[FirmwareAdd]", e);

                setTip({open:true, severity:"error", msg: "load distributor list error"});
            }
        }, 300);
    }

    const handleProductSelect = (v, option) => {
        refSelectedProductID.current = option.key;

        
        var bts = [];
        for (let k in defs.burningTypes) {
            bts.push(<Select.Option key={k} value={k}>{k.toString() + ":" + defs.burningTypes[k]}</Select.Option>);
        }
        if (refSelectedProductID.current == "2b7f7c7e-66cd-46e0-9dbb-20193cb740dd" && refSelectedType.current == "23") {
            bts.push(<Select.Option key={3} value={3}>3:stm32 bootloader</Select.Option>);
            bts.push(<Select.Option key={4} value={4}>4:stm32 application</Select.Option>);
            bts.push(<Select.Option key={5} value={5}>5:280049 application</Select.Option>);
            bts.push(<Select.Option key={6} value={6}>6:280049 flash</Select.Option>);
        }
        setBurningTypeSelectOptions(bts);
    }

    const handleTypeSelect = (v, option) => {
        refSelectedType.current = option.key;

        var bts = [];
        for (let k in defs.burningTypes) {
            bts.push(<Select.Option key={k} value={k}>{k.toString() + ":" + defs.burningTypes[k]}</Select.Option>);
        }
        if (refSelectedProductID.current == "2b7f7c7e-66cd-46e0-9dbb-20193cb740dd" && refSelectedType.current == "23") {
            bts.push(<Select.Option key={3} value={3}>3:stm32 bootloader</Select.Option>);
            bts.push(<Select.Option key={4} value={4}>4:stm32 application</Select.Option>);
            bts.push(<Select.Option key={5} value={5}>5:280049 application</Select.Option>);
            bts.push(<Select.Option key={6} value={6}>6:280049 flash</Select.Option>);
        }
        setBurningTypeSelectOptions(bts);
    }

    var ts = [];
    for (let k in defs.types) {
        //defs.types.map((t, k) => {return (<Select.Option key={k} value={k}>t</Select.Option>);})
        ts.push(<Select.Option key={k} value={k}>{k.toString() + ":" + defs.types[k]}</Select.Option>)
    }

    return (<Modal 
        title="Add Firmware"
        visible={show}
        cancelText="Cancel"
        okText="Submit"
        mask={true}
        maskClosable={false}
        width={600}
        onCancel={handleCancle}
        onOk={handleSubmit}
        footer={[
            <Button 
                key="Clear" 
                onClick={() => {
                    form.resetFields();
                }}
            >Clear</Button>,
            <Button key="Cancel" onClick={handleCancle}>Cancel</Button>,
            <Button key="Submit" type="primary" onClick={handleSubmit}>Submit</Button>
        ]}
    >
        <Form
            labelAlign="right"
            labelCol={{ span: 5 }}
            wrapperCol={{ span: 17 }}
            form={form}
            initialValues={{
                BurningType: 1,  
            }}
        >
            <Form.Item 
                name="ProductName"
                label="Product"
                rules={[{ required: true }]}
            >
                <Select 
                    showSearch
                    placeholder="Enter Product Name"
                    defaultActiveFirstOption={false}
                    showArrow={false}
                    filterOption={false}
                    onSearch={handleSearchProduct}
                    onSelect={handleProductSelect}
                >{productSelectOptions}</Select>
            </Form.Item>
            <Form.Item 
                name="Name"
                label="Name"
                rules={[{ required: true }]}
            >
                <Input />
            </Form.Item>
            <Form.Item 
                name="Type"
                label="Type"
                rules={[{ required: true }]}
            >
                <Select
                    onSelect={handleTypeSelect}
                >
                    {ts}
                </Select>
            </Form.Item>
            <Form.Item 
                name="BurningType"
                label="BurningType"
                rules={[{ required: true }]}
            >
                <Select>{burningTypeSelectOptions}</Select>
            </Form.Item>
            <Form.Item 
                name="FirmwareFile"
                label="FirmwareFile"
                valuePropName="file"
                rules={[{ required: true }]}
            >
                <Upload 
                    maxCount={1}
                    accept=".hex,.bin"
                    name="FirmwareFile"
                    action={config.apiUrl+"uploader/do?Token="+http.token}
                    headers={{Token:http.token}}
                    showUploadList={{showRemoveIcon: false}}
                >
                    <Button>Choose Data File (HEX,BIN) to Import</Button>
                </Upload>
            </Form.Item>
            <Form.Item 
                name="Version"
                label="Version"
                rules={[{ required: true }]}
            >
                <Input placeholder="e.g.: 1.8.8"/>
            </Form.Item>
            <Form.Item 
                name="ExpiredTime"
                label="ExpiredTime"
                rules={[{ required: true }]}
            >
                <DatePicker placeholder="Expired Time"/>
            </Form.Item>
            <Form.Item 
                name="DistributorName"
                label="Distributor"
            >
                <Select 
                    showSearch
                    placeholder="Enter Distributor Name"
                    defaultActiveFirstOption={false}
                    showArrow={false}
                    filterOption={false}
                    onSearch={handleSearchDistributor}
                >{distributorSelectOptions}</Select>
            </Form.Item>
            <Form.Item 
                name="Note"
                label="Note"
                rules={[{ required: false }]}
            >
                <Input.TextArea rows={6} maxLength={1500} showCount={true}/>
            </Form.Item>
        </Form>
    </Modal>)
}

export default FirmwareAdd;