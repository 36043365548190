import http from './Http';
import { ThemeContext } from './Http';
import { useEffect, useState, useContext, useRef } from 'react';
import { createTheme } from '@material-ui/core/styles';
import utils from './utils';
import { Table, Input, Button, Modal, Form, Row, Col, Checkbox, Select } from 'antd';
import { PlusOutlined, DeleteOutlined, EditOutlined, ExclamationCircleOutlined, CheckOutlined, CloseOutlined, UpOutlined, DownOutlined, ImportOutlined, RetweetOutlined } from '@ant-design/icons';
import DeviceAdd from "./DeviceManagement/DeviceAdd";
import DeviceModify from "./DeviceManagement/DeviceModify";
import DeviceImporter from "./DeviceManagement/DeviceImporter";
import DeviceTransfer from "./DeviceManagement/DeviceTransfer";
import timezone from './utils/timezone';
import InputPrefix from './utils/InputPrefix';

const theme = createTheme({});

function DeviceManagement(props) {
    const setTip = useContext(utils.tipContext);
 
    const session = useContext(ThemeContext);

    const [pageNo, setPageNo] = useState(0);
    const [pageSize, setPageSize] = useState(25);
    const [params, setParams] = useState({
        SN: '',
        DistributorName: '',
        ProductName: '',
        Enabled: '',
        UserID: '',
        Timezone: '',
    });
    const [loading, setLoading] = useState(false);
    const [rows, setRows] = useState([]);
    const [rowTotal, setRowTotal] = useState(0);
    const [tableBodyHeight, setTableBodyHeight] = useState(240);
    const [selectedKeys, setSelectedKeys] = useState([]);
    const [showAdd, setShowAdd] = useState(false);
    const [showModify, setShowModify] = useState(false);
    const [batchImportShow, setBatchImportShow] = useState(false);
    const [transferShow, setTransferShow] = useState(false);
    const [modifyRowIndex, setModifyRowIndex] = useState(null);
    const [productSelectOptions, setProductSelectOptions] = useState([]);
    const [distributorSelectOptions, setDistributorSelectOptions] = useState([]);
    const [expand, setExpand] = useState(false);

    const refTableContainer = useRef();
    const refTimerProductSearcher = useRef(null);
    const refProductSearchValue = useRef(null);
    const refTimerDistributorSearcher = useRef(null);
    const refDistributorSearchValue = useRef(null);

    const [timezoneSelectOptions, setTimezoneSelectOptions] = useState([]);
    const refTimerTimezoneSearcher = useRef(null);
    const refTimezoneSearchValue = useRef(null);

    const [form] = Form.useForm();

    useEffect(() => {
        let active = true;
        console.log("DeviceManagement Effect");

        (async () => {
            setLoading(true);


            let resp = await http.post("device/management/list", {
                PageNo: pageNo,
                PageSize: pageSize,
                ...params,
            }).then(async (r) => {
                return await r.json();
            }).catch((e) => {
                console.log("[DeviceManagement]", e);
                return null;
            });

            if (!active) {
                return;
            }

            if (resp === null || resp.code !== 200) {
                setTip({ open: true, severity: "error", msg: (resp && resp.msg) ? resp.msg : "load data error" });

                setRowTotal(0)
                setRows([]);

                return;
            }
            setRowTotal(resp.data.RowTotal);
            setRows(resp.data.Rows);
            setLoading(false);
        })();

        return () => {
            active = false;
            console.log("DeviceManagement Effect Cancel");
        };
    }, [pageNo, pageSize, params]);

    useEffect(() => {
        const el = refTableContainer.current;

        if (el.objResizer) {
            return;
        }

        let obj = document.createElement('object');
        el.objResizer = obj;
        obj.setAttribute('style',
            'display: block; position: absolute; top: 0; left: 0; height: 100%; width: 100%; overflow: hidden;opacity: 0; pointer-events: none; z-index: -1;');
        obj.onload = function () {
            setTableBodyHeight(calcTableBodyHeight());

            this.contentDocument.defaultView.addEventListener('resize', (evt) => {
                setTableBodyHeight(calcTableBodyHeight());
            });
        };
        obj.type = 'text/html';
        el.appendChild(obj);
        obj.data = 'about:blank';

        return () => {
            if (!el) {
                return
            }

            //
        }
    }, [refTableContainer.current]);

    const handleSearchProduct = (v) => {
        if (refTimerProductSearcher.current != null) {
            clearTimeout(refTimerProductSearcher.current);
        }
        refProductSearchValue.current = v;

        refTimerProductSearcher.current = setTimeout(async () => {
            const resp = await http.post("product/name/list", {
                Value: v,
            }).then(async (r) => {
                return await r.json();
            }).catch((e) => {
                console.log("[DeviceManagement]", e);
                return null;
            });

            if (refProductSearchValue.current !== v) {
                return;
            }

            try {
                if (resp.code !== 200) {
                    setTip({ open: true, severity: "error", msg: (resp && resp.msg) ? resp.msg : "load product list error" });
                    return;
                }

                const ls = resp.data.map(({ Name, ID }) => {
                    return (
                        <Select.Option key={ID} value={Name}>{Name}</Select.Option>
                    );
                });
                setProductSelectOptions(ls);
            } catch (e) {
                console.log("[DeviceManagement]", e);

                setTip({ open: true, severity: "error", msg: "load product list error" });
            }
        }, 300);
    }

    const handleSearchDistributor = (v) => {
        if (refTimerDistributorSearcher.current != null) {
            clearTimeout(refTimerDistributorSearcher.current);
        }
        refDistributorSearchValue.current = v;

        refTimerDistributorSearcher.current = setTimeout(async () => {
            const resp = await http.post("distributor/name/list", {
                Value: v,
            }).then(async (r) => {
                return await r.json();
            }).catch((e) => {
                console.log("[DeviceManagement]", e);
                return null;
            });

            if (refDistributorSearchValue.current !== v) {
                return;
            }

            try {
                if (resp.code !== 200) {
                    setTip({ open: true, severity: "error", msg: (resp && resp.msg) ? resp.msg : "load distributor list error" });
                    return;
                }

                const ls = resp.data.map(({ Name, ID }) => {
                    return (
                        <Select.Option key={ID} value={Name}>{Name}</Select.Option>
                    );
                });
                setDistributorSelectOptions(ls);
            } catch (e) {
                console.log("[DeviceManagement]", e);

                setTip({ open: true, severity: "error", msg: "load distributor list error" });
            }
        }, 300);
    }

    const handleSearch = () => {
        setSelectedKeys([]);
        setPageNo(0);

        const ps = form.getFieldsValue();
        ps.SN = ps.SN || "";
        ps.ProductName = ps.ProductName || "";
        ps.DistributorName = ps.DistributorName || "";
        ps.UserID = ps.UserID || "";
        ps.Timezone = ps.Timezone || "";
        ps.Enabled = ps.Enabled ? (ps.Enabled.indexOf("Enabled") > -1 && ps.Enabled.indexOf("Disabled") > -1 ? "" : ps.Enabled.indexOf("Enabled") > -1 ? 1 : 0) : "";
        setParams(ps);
    }

    const renderAction = (v, r, idx) => {
        return (
            <>
                <Button onClick={() => { handleModify(idx) }} type="link" icon={<EditOutlined size="small" />} />
                {session != null && session.IsAdmin == 1 ?
                    <Button onClick={() => { handleDelete(r.ID, r.SN, idx) }} type="link" icon={<DeleteOutlined size="small" />} />
                    : ""
                }
            </>
        )
    }

    const handleAdd = () => {
        setShowAdd(true);
    }

    const handleBatchImport = () => {
        setBatchImportShow(true);
    }

    const handleTransfer = () => {
        setTransferShow(true);
    }

    const handleModify = (rowIndex) => {
        setModifyRowIndex(rowIndex);
        setShowModify(true);
    }

    const handleDelete = async (ID, Name, idx) => {
        Modal.confirm({
            title: 'Do you Want to delete this item?',
            icon: <ExclamationCircleOutlined />,
            content: Name,
            onOk: async () => {
                try {
                    const resp = await http.post("device/management/delete", { ID: ID }).then(async (r) => {
                        return await r.json();
                    }).catch((e) => {
                        console.log("[DeviceManagement]", e);
                        return null;
                    });

                    if (resp.code !== 200) {
                        setTip({ open: true, severity: "error", msg: (resp && resp.msg) ? resp.msg : "delete error" });
                        return
                    }
                    setTip({ open: true, severity: 'success', msg: 'delete success' });

                    setRows([...rows.slice(0, idx), ...rows.slice(idx + 1)]);
                    setRowTotal(rowTotal - 1);
                    selectedKeys.every((v, i) => {
                        if (v === ID) {
                            setSelectedKeys([...selectedKeys.slice(0, i), ...selectedKeys.slice(i + 1)]);
                            return false;
                        }

                        return true;
                    })
                } catch (e) {
                    console.log("[DeviceManagement]", e);

                    setTip({ open: true, severity: "error", msg: "delete error" });
                }
            },
            onCancel() {
                //
            },
        });
    }

    const handleDeleteAllOfSelected = async () => {
        if (selectedKeys.length < 1) {
            return;
        }

        let ns = [];
        selectedKeys.forEach((ID) => {
            rows.every((r) => {
                if (ID === r.ID) {
                    ns.push(<label>{r.SN}</label>);
                    return false;
                }

                return true;
            });
        })
        if (ns.length > 10) {
            ns = ns.slice(0, 10);
            ns.push(<lable>...</lable>);
        }

        Modal.confirm({
            title: 'Do you Want to delete these items?',
            icon: <ExclamationCircleOutlined />,
            content: (<div style={{ display: 'flex', flexDirection: 'column' }}>{ns}</div>),
            onOk: async () => {
                try {
                    const resp = await http.post("device/management/multi/delete", { IDs: selectedKeys.join(",") }).then(async (r) => {
                        return await r.json();
                    }).catch((e) => {
                        console.log("[DeviceManagement]", e);
                        return null;
                    });

                    if (resp.code !== 200) {
                        setTip({ open: true, severity: "error", msg: (resp && resp.msg) ? resp.msg : "delete error" });
                        return
                    }
                    setTip({ open: true, severity: 'success', msg: 'delete success' });

                    let rs = [];
                    rows.forEach((v, i) => {
                        const ID = v.ID;
                        let ok = false;
                        selectedKeys.every((ID2) => {
                            if (ID === ID2) {
                                ok = true;
                                return false;
                            }

                            return true;
                        });
                        if (!ok) {
                            rs.push(v);
                        }
                    });
                    setRows(rs);
                    setRowTotal(rowTotal - selectedKeys.length);
                    setSelectedKeys([]);
                } catch (e) {
                    console.log("[DeviceManagement]", e);

                    setTip({ open: true, severity: "error", msg: "delete error" });
                }
            },
            onCancel() {
                //
            },
        });
    }

    const handleSelect = (selectedRowKeys, selectedRows) => {
        setSelectedKeys([...selectedRowKeys]);
    }

    const handleAddingClose = (row) => { 
        setShowAdd(false);

        if (!row) {
            return;
        }

        document.getElementsByClassName("ant-table-body")[0].scrollTo(0, 0);
        setSelectedKeys([row.ID]);
        setRows([row, ...rows]);
        setRowTotal(rowTotal + 1);
    }

    const handleModifyingClose = (row) => {
        setShowModify(false);
        setModifyRowIndex(null);

        if (!row) {
            return;
        }

        setSelectedKeys([row.ID]);
        const rw = { ...rows[modifyRowIndex], ...row }
        setRows([...rows.slice(0, modifyRowIndex), rw, ...rows.slice(modifyRowIndex + 1)]);
    }

    const calcTableBodyHeight = () => {
        if (!refTableContainer.current) {
            return 240;
        }

        const hd = document.getElementsByClassName("ant-table-thead")[0];

        const h = refTableContainer.current.getBoundingClientRect().bottom - hd.getBoundingClientRect().bottom;

        return h >= 54 ? h - 54 : h / 2;
    }

    //按下Enter键时进行查找
    const searchByEnter = (e) => { 
        if(e.code == "Enter") {
            handleSearch()
        }
    }

    const handleSearchTimezone = (v) => {
        if (refTimerTimezoneSearcher.current != null) {
            clearTimeout(refTimerTimezoneSearcher.current);
        }
        refTimezoneSearchValue.current = v;


        refTimerTimezoneSearcher.current = setTimeout(async () => {
            const resp = await http.post("timezone/list", {
                Timezone: v,
            }).then(async (r) => {
                return await r.json();
            }).catch((e) => {
                console.log("[DeviceAdd]", e);
                return null;
            });

            if (refTimezoneSearchValue.current !== v) {
                return;
            }

            try {
                if (resp.code !== 200) {
                    setTip({ open: true, severity: "error", msg: (resp && resp.msg) ? resp.msg : "load timezone list error" });
                    return;
                }

                const ls = resp.data.map(({ ZoneName, GmtOffset }) => {
                    var n = parseInt(GmtOffset);
                    var tz = "UTC+";
                    if (n < 0) {
                        tz = "UTC-";
                        n = Math.abs(n);
                    }
                    tz += Math.floor(n / 3600).toString().padStart(2, "0") + ":" + Math.floor((n % 3600) / 60).toString().padStart(2, "0")
                    return (
                        <Select.Option key={ZoneName} value={ZoneName}>{ZoneName}({tz})</Select.Option>
                    );
                });
                setTimezoneSelectOptions(ls);
            } catch (e) {
                console.log("[Device-Adder]", e);

                setTip({ open: true, severity: "error", msg: "load device list error" });
            }
        }, 300);
    }

    return (
        <div style={{
            flex: "1 1 auto",
            display: "flex",
            flexDirection: "column",
            backgroundColor: '#EFF8FE',
            padding: 8,
        }}>
            <div style={{
                flex: "0 1 auto",
            }}>
                <Form
                    form={form}
                    initialValues={{
                        Enabled: ["Enabled", 'Disabled'],
                    }}
                    style={{
                        padding: 24,
                        background: '#fbfbfb',
                        border: '1px solid #d9d9d9',
                        borderRadius: 2
                    }}
                >
                    <Row gutter={24}>
                        <Col span={8} key="SN">
                            <Form.Item
                                name="SN"
                                label=""
                            >
                                <Input prefix={<InputPrefix label="SN" />} placeholder="Enter Device SN" onPressEnter={handleSearch} style={{ borderRadius: '20px' }} />
                            </Form.Item>
                        </Col>
                        <Col span={8} key="Handled" >
                            <Button type="primary" shape='round' onClick={handleSearch} style={{marginRight: '8px'}}>
                                Search
                            </Button>
                            <a
                                style={{ fontSize: 12, marginRight: '20px' }}
                                onClick={() => {
                                    setExpand(!expand);
                                }}
                            >
                                {expand ? <UpOutlined /> : <DownOutlined />} {expand ? 'Collapse' : 'Expand'}
                            </a>
                            <Form.Item
                                name="Enabled"
                                label=""
                                style={{display: 'inline-block'}}
                            >
                                <Checkbox.Group options={["Enabled", 'Disabled']} />
                            </Form.Item>
                        </Col>
                        <Col span={8} key="Timezone">
                        <Input.Group compact>
                            <Button>Timezone</Button>
                                <Form.Item
                                    name="Timezone"
                                    label=""
                                    style={{display: 'inline-block', width: 'calc(100% - 92px)'}}
                                >
                                    <Select
                                        showSearch
                                        placeholder="Timezone"
                                        defaultActiveFirstOption={false}
                                        showArrow={false}
                                        filterOption={false}
                                        onSearch={handleSearchTimezone}
                                    >{timezoneSelectOptions}</Select>
                                </Form.Item>
                            </Input.Group>
                        </Col>
                        <Col span={8} key="Product" style={{ display: expand ? "block" : "none" }}>
                            <Input.Group compact>
                                <Button>Product</Button>
                                <Form.Item
                                    name="ProductName"
                                    label=""
                                    style={{display: 'inline-block', width: 'calc(100% - 80px)'}}
                                >
                                    <Select
                                        showSearch
                                        placeholder="Enter Product Name"
                                        defaultActiveFirstOption={false}
                                        showArrow={false}
                                        filterOption={false}
                                        onSearch={handleSearchProduct}
                                        onInputKeyDown={searchByEnter}
                                    >{productSelectOptions}</Select>
                                </Form.Item>
                            </Input.Group>
                        </Col>
                        <Col span={8} key="Distributor" style={{ display: expand ? "block" : "none" }}>
                        <Input.Group compact>
                            <Button>Distributor</Button>
                                <Form.Item
                                    name="DistributorName"
                                    label=""
                                    style={{display: 'inline-block', width: 'calc(100% - 100px)'}}
                                >
                                    <Select
                                        showSearch
                                        placeholder="Enter Distributor Name"
                                        defaultActiveFirstOption={false}
                                        showArrow={false}
                                        filterOption={false}
                                        onSearch={handleSearchDistributor}
                                        onInputKeyDown={searchByEnter}
                                    >{distributorSelectOptions}</Select>
                                </Form.Item>
                            </Input.Group>
                        </Col>
                        <Col span={8} key="UserID" style={{ display: expand ? "block" : "none" }}>
                            <Form.Item
                                name="UserID"
                                label=""
                            >
                                <Input prefix={<InputPrefix label='UserID'/>} placeholder="Enter User ID" onPressEnter={handleSearch} style={{ borderRadius: '20px' }} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24} style={{ textAlign: 'right' }}>
                            <Button
                                shape='round'
                                onClick={() => {
                                    form.resetFields();
                                }}
                            >
                                Clear
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </div>
            <div style={{
                flex: '0 0 auto',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end',
                paddingTop: 8,
                paddingBottom: 8,
            }}>
                {session != null && session.IsAdmin == 1 ? (
                    <>
                        <Button onClick={handleAdd} type="primary" icon={<PlusOutlined />} size="middle" shape="circle" />
                        <Button onClick={handleDeleteAllOfSelected} disabled={selectedKeys.length === 0} type="primary" icon={<DeleteOutlined />} size="middle" shape="circle" style={{ marginLeft: 5 }} />
                        <div style={{ flex: "0 0 20px" }}></div>
                        <Button onClick={handleBatchImport} type="primary" icon={<ImportOutlined />} size="middle" shape='round'>Import</Button>
                        <div style={{ flex: "0 0 8px" }}></div>
                    </>
                ) : ""}
                <Button onClick={handleTransfer} type="primary" icon={<RetweetOutlined />} size="middle" shape='round'>Transfer</Button>
            </div>
            <div style={{
                flex: "1 1 1px",
                position: "relative",
            }}>
                <div ref={refTableContainer} style={{ position: "absolute", height: '100%', width: "100%", overflow: "hidden" }}>
                    <Table
                        columns={[{
                            title: "",
                            width: 70,
                            fixed: 'left',
                            render: (v, r, idx) => {
                                return (pageNo * pageSize + idx + 1);
                            }
                        }, {
                            title: "SN",
                            dataIndex: "SN",
                            width: 200,
                            fixed: 'left',
                        }, {
                            title: "Product",
                            dataIndex: 'ProductName',
                            width: 200,
                        }, {
                            title: "FirmwareVersion",
                            dataIndex: 'FirmwareVersion',
                            width: 200,
                        }, {
                            title: "Distributor",
                            dataIndex: "DistributorName",
                            width: 200,
                        }, {
                            title: "Users",
                            dataIndex: "Users",
                            width: 200,
                        }, {
                            title: "Inverters",
                            dataIndex: "Inverters",
                            width: 200,
                            render: (v, r, idx) => {
                                return v ? v.replace(/;/g, "; ") : "";
                            }
                        }, {
                            title: "Enabled",
                            dataIndex: "Enabled",
                            width: 80,
                            render: (v, r, idx) => {
                                return v === 1 || v === "1" ? (<CheckOutlined style={{ color: "#5b8c00" }} />)
                                    : (<CloseOutlined style={{ color: "#ad2102" }} />)
                            }
                        }, {
                            title: "GPS",
                            width: 200,
                            render: (v, r, idx) => {
                                if (!r["Longitude"] && !r["Latitude"]) {
                                    return ""
                                }

                                return `(${r["Longitude"] !== null ? (r["Longitude"] / 1000000).toFixed(6) : "-"}, ${r["Latitude"] !== null ? (r["Latitude"] / 1000000).toFixed(6) : "-"})`
                            }
                        }, {
                            title: "Timezone",
                            dataIndex: "Timezone",
                            width: 250,
                            render: (v, r, idx) => {
                                var n = parseInt(r["GmtOffset"]);
                                var tz = "UTC+";
                                if (n < 0) {
                                    tz = "UTC-";
                                    n = Math.abs(n);
                                }
                                tz += Math.floor(n / 3600).toString().padStart(2, "0") + ":" + Math.floor((n % 3600) / 60).toString().padStart(2, "0")
                                tz +="("+v+")"
                                return tz;
                            }
                        }, {
                            title: "Create Time(UTC+0)",
                            dataIndex: 'CreateTime',
                            width: 150,
                        }, {
                            title: 'Action',
                            key: 'Action',
                            width: 90,
                            render: renderAction,
                        }]}
                        pagination={{
                            pageSizeOptions: [25, 50, 100],
                            current: pageNo + 1,
                            pageSize: pageSize,
                            total: rowTotal,
                            showSizeChanger: true,
                            showTotal: (total) => { return `Total ${total}` },
                            onShowSizeChange: (current, size) => {
                                setPageSize(size);
                            },
                            onChange: (page, pageSize) => {
                                setPageNo(page - 1);
                            }
                        }}
                        tableLayout="fixed"
                        bordered={true}
                        size="middle"
                        loading={loading}
                        rowKey="ID"
                        dataSource={rows}
                        scroll={{ x: 1500, y: tableBodyHeight }}
                        rowSelection={{
                            selectedRowKeys: selectedKeys,
                            onChange: handleSelect,
                            selections: [Table.SELECTION_ALL, Table.SELECTION_INVERT, Table.SELECTION_NONE]
                        }}
                    />
                </div>
            </div>
            <DeviceAdd show={showAdd} onClose={handleAddingClose} />
            {modifyRowIndex !== null && <DeviceModify show={showModify} row={rows[modifyRowIndex]} onClose={handleModifyingClose} />}
            <DeviceImporter show={batchImportShow} onClose={() => {
                setBatchImportShow(false);

                handleSearch();
            }} />
            <DeviceTransfer show={transferShow} onClose={() => {
                setTransferShow(false);

                handleSearch();
            }} />
        </div>
    );
}

export default DeviceManagement;