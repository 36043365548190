import Thermometer from "./Thermometer";
import TitleBar from '../utils/TitleBar.js';

function Temperature (props) {
    const {data} = props;

    const bs = [];
    let ln = 3;
    const ds = data ? data.Temperatures : [];
    const ss = data && data.Status ? data.Status: [];
    //var ss = [1,2,3,0,3];
    if (data) {
        ln = data.Temperatures.length;
    }
    for (let i=0; i<ln; i++) {
        var t = i<ds.length ? (ds[i]-50) : 0;
        var status = 0;
        if (i < ss.length) {
            status = ss[i];
            if (ss[i] == 2 || ss[i] == 3) {
                t = -50;
            }
        }
        bs.push(
            <div style={{
                margin: 0,
                display: "flex",
                flexDirection: 'column',
            }}>
                <div style={{
                    width: 80,
                    height: 200,
                    display: 'flex',
                }}>
                    <Thermometer id_x={i.toString()} width={80} height={200} steps={6} minValue={-50} maxValue={100} currentValue={t}/>
                </div>
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: "center",
                }}>
                    <div style={{
                        color: status == 0 ? "black" : 
                            status == 1 ? "red" : "gray",
                    }}>T{(i+1).toString().padStart(2, 0)}:&nbsp;{
                        status==2 ? "R" : 
                        status==3 ? "D" : (i<ds.length ? (ds[i]-50):"-")}</div>
                </div>
            </div>
        );
    }

    return (
        <div style={{
            padding: '10px 15px'
        }}>
            <TitleBar title="Temperature" data={data} />
            <div style={{
                marginTop: '20px',
                backgroundColor: "white",
                flex: "1, 1 auto",
                display: "flex",
                flexDirection: 'row',
                padding: 0,
            }} >
                {bs}
            </div>
        </div>
    );
}

export default Temperature;