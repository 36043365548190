const current = (v, protocol, extra) => {
    if (v == null) return '--';
    if (v == 0) return 0;
    if(v > 0x7fff) {
        v -= 0xffff + 1
    }
    switch (protocol) {
        case 'Senergy':
            v = v && (v / 100).toFixed(2)
            break
        case 'Thinkpower':
        case 'Megarevo':
        case 'Sinexcel':
        case 'Megarevo-CESS':
        case 'Sungery':
        case 'Uhome':
            v = v && (v / 10).toFixed(1)
            break
        default:

            break
    }
    return v
}

const voltage = (v, protocol, extra) => {
    if (v == null) return '--';
    if (v == 0) return 0;
    if(extra === 'BatCellMaxVoltage' || extra === 'BatCellMinVoltage') {
        v = (v / 1000).toFixed(3)
    } else {
        v = v && (v / 10).toFixed(1)
    }
    return v
}

const power = (v, protocol,extra) => {
    if (v == null) return '--';
    if (v == 0) return 0;
    v = convertComplement(v, protocol)
    switch (protocol) {
        case 'Senergy':
            v = v && (v / 10).toFixed(1)
            break
        case 'Thinkpower':
        case 'Megarevo':
        case 'Sinexcel':
        case 'Megarevo-CESS':
        case 'Sungery':
            
            break
        case 'Uhome':
            v = v && (v / 100).toFixed(2)
            break
        default:

            break
    }
    return format(v)
}

const energy = (v, protocol, extra) => {
    if (v == null) return '--';
    if (v == 0) return 0;
    switch (protocol) {
        case 'Senergy':

            break
        case 'Thinkpower':
        case 'Sungery':
            v = (v && v / 10).toFixed(1)
            break
        case 'Megarevo':
        case 'Sinexcel':
        case 'Megarevo-CESS':
            v = v && (v / 1000).toFixed(2)
            break
        case 'Uhome':

            break
        default:

            break
    }
    return v
}

const frequency = (v, protocol, extra) => {
    if (v == null) return '--';
    if (v == 0) return 0;
    return v && (v / 100).toFixed(2)
}

const soc = (v, protocol, extra) => {
    if (v == null) return '--';
    if (v == 0) return 0;
    switch (protocol) {
        case 'Senergy':
        case 'Sungery':
            
            break
        case 'Thinkpower':
        case 'Megarevo':
        case 'Sinexcel':
        case 'Megarevo-CESS':
            v = v && (v / 10).toFixed(1)
            break
        case 'Uhome':

            break
        default:

            break
    }
    return v
}

const soh = (v, protocol, extra) => {
    if (v == null) return '--';
    if (v == 0) return 0;
    switch (protocol) {
        case 'Thinkpower':
            v = v && (v / 10).toFixed(1)
            break
        case 'Senergy':
        case 'Sungery':
        case 'Megarevo':
        case 'Sinexcel':
        case 'Megarevo-CESS':
            
            break
        case 'Uhome':

            break
        default:

            break
    }
    return v
}

const temperature = (v, protocol, extra) => {
    if (v == null) return '--';
    if (v == 0) return 0;
    switch (protocol) {
        case 'Thinkpower':
        case 'Sungery':
            v = v && (v / 10).toFixed(1)
            break
        case 'Senergy':
        case 'Megarevo':
        case 'Sinexcel':
        case 'Megarevo-CESS':
            
            break   
        case 'Uhome':
            v = v && (v / 10).toFixed(1)
            break
        default:

            break
    }
    return v
}

const convertComplement = (v, protocol) => {
    if(protocol === 'Sinexcel' || protocol === 'Megarevo-CESS') {
        if(v > 0x7fffffff) {
            v -= 0xffffffff + 1
        }
        return v
    } else if(protocol === 'Megarevo' || protocol === 'Sungery') {
        if(v > 0x7fff) {
            v -= 0xffff + 1
        }
    } 
    return v
}

//保留小数点后最多两位
const format = (v) => {
    if(isNaN(v)) {
        return '--'
    } 
    const count = v.toString().split('.')[1] && v.toString().split('.')[1].length
    if(count > 2) {
        return v.toFixed(2)
    }
    return v
}

const all = {
    current,
    voltage,
    power,
    energy,
    frequency,
    soc,
    soh,
    temperature,
    convertComplement,
}

export default all;