import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    caption: {
        color: "#2182B8",
        fontSize: "24px",
    },
    value: {
        fontSize: '24px',
    }
})
function CustomerService (props) {

    const classes = useStyles();

    return (
        <div style={{
            width: '100%',
            height: '100%',
            position: 'relative',
        }}>
            <img src="/images/customer_bg.png" alt="" width="100%" height="100%" />
            <div style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                zIndex: 100,
                transform: 'translate(-50%, -50%)',
                backgroundColor: 'rgba(239, 248, 254, 0.8)',
                borderRadius: '20px',
                padding: '80px',
                textAlign: 'center',
            }}>
                {/* {cs} */}
                <div style={{
                    margin: "5px",
                }}>
                    <div>
                        <span className={classes.caption}>Manufacturer:&nbsp;</span>
                        <span className={classes.value}>Raystech</span>
                    </div>
                    <div>
                        <span className={classes.caption}>Phone:&nbsp;</span>
                        <span className={classes.value}>+86-22-59901199</span>
                    </div>
                    <div>
                        <span className={classes.caption}>E-Mail:&nbsp;</span>
                        <span className={classes.value}>service@raystechgroup.com</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CustomerService;