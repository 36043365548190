import http from '../Http';
import config from '../Config'
import { useContext, useState, useEffect, useRef } from 'react';
import utils from '../utils';
import { Modal, Button, Input, Form, Radio, Switch, TreeSelect, Select } from 'antd';
import { ReactBingmaps } from 'react-bingmaps';
import axios from "axios";
import { AlternateEmail } from '@material-ui/icons';


function GPSGetter(props) {
    const setTip = useContext(utils.tipContext);

    const { show, onClose, centerPoint, centerPin } = props;

    const [gps, setGps] = useState({});
    //const [pushPins, setPushPins] = useState([{ "location": [13.0827, 80.2707] }]);
    const [pushPins, setPushPins] = useState([]);
    //const [value, setValue] = useState('');

    const [pins, setPins] = useState([]); 
    const [addressSelectOptions, setAddressSelectOptions] = useState([])
    const refTimerAddressSearcher = useRef(null)
    const refAddressSearchValue = useRef(null)
    const [center, setCenter] = useState([])

    const handleCancle = () => {
        setCenter([])
        setPins([])
        setPushPins([])
        setGps({})
        onClose(null);
    }

    const handleOK = () => {
        if (gps.Latitude && gps.Longitude) {
            onClose(gps)
            return;
        }

        setTip({ open: true, severity: "warning", msg: "No latitude and longitude were selected" });
    }

    const handleMapClick = (e) => {
        //console.log(e);

        setPushPins([{
            location: [e.latitude, e.longitude],
        }]);

        setGps({
            Longitude: e.longitude,
            Latitude: e.latitude,
        });
    }


    const handleSearchAddress = (value) => {
        if(refTimerAddressSearcher.current != null) {
            clearTimeout(refTimerAddressSearcher.current);
        }
        refAddressSearchValue.current = value;
        refTimerAddressSearcher.current = setTimeout(() => {
            if(value == '') {
                return
            }
            const url = `https://dev.virtualearth.net/REST/v1/Locations?query=${value}&key=${config.bingMapKey}`;
            const pins = [];
            const addresses = [];
            axios.get(url).then(response => {
                const results = response.data.resourceSets[0].resources;
                for(let i = 0; i < results.length; i++) {
                    const location = [results[i].point.coordinates[0], results[i].point.coordinates[1]];
                    pins.push({
                        location: location,
                        option: {
                            text: i + 1 + ''
                        }
                    });
                    addresses.push({
                        name: (i + 1) + ': ' + results[i].name,
                        location: location,
                    })
                }
                setCenter(pins[0].location)
                setPins(pins);
                setPushPins(pins);
                const ls = addresses.map((item, index) => {
                    return (
                        <Select.Option key={index} value={item.name}>{item.name}</Select.Option>
                    );
                });
                setAddressSelectOptions(ls);
            }).catch(err => {
                console.log(err)
            });
        }, 300);
    }

    const handleSelectAddress = (value) => {
        const optionIndex = value[0] - 1;
        setPushPins([{
            location: pins[optionIndex].location
        }])
        setCenter(pins[optionIndex].location)
        setGps({
            Longitude: pins[optionIndex].location[1],
            Latitude: pins[optionIndex].location[0],
        });
    }

    return (<Modal
        title="GPS Location"
        visible={show}
        cancelText="Cancel"
        okText="Submit"
        mask={true}
        maskClosable={false}
        width={600}
        onCancel={handleCancle}
        onOk={handleOK}
        footer={[
            <Button key="Cancel" onClick={handleCancle}>Cancel</Button>,
            <Button key="OK" type="primary" onClick={handleOK}>OK</Button>
        ]}
    >
        <div style={{
            display: "flex",
            flexDirection: "column"
        }}>
            <div style={{
                padding: "5px",
            }}>
                <Select
                    showSearch
                    placeholder="Enter address"
                    defaultActiveFirstOption={false}
                    showArrow={false}
                    filterOption={false}
                    onSearch={handleSearchAddress}
                    onSelect={handleSelectAddress}
                    style={{width: '200px'}}
                >{addressSelectOptions}</Select>
            </div>
            <div style={{
                padding: "5px",
            }}>
                Location:({gps.Longitude ? gps.Longitude.toFixed(6) : "-"}, {gps.Latitude ? gps.Latitude.toFixed(6) : "-"})
            </div>
            <div style={{
                width: "100%",
                height: "400px",
            }}>
                <ReactBingmaps
                    bingmapKey={config.bingMapKey}
                    pushPins={pushPins.length!=0 ? pushPins : centerPin||[]}
                    center={center.length!=0 ? center : centerPoint||[]}
                    zoom={5}
                    getLocation={{
                        addHandler: "click",
                        callback: handleMapClick,
                    }}
                ></ReactBingmaps>
            </div>
        </div>
    </Modal>);
}

export default GPSGetter;