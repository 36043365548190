import React, { useState } from 'react';
import { TimePicker } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

const HourMinuteEditorForSungery = ({value, pendingValue, onChange}) => {
    dayjs.extend(customParseFormat);
    var str = ((value>>8)&0xff).toString().concat(":", (value&0xff).toString());
    const ot = dayjs(str, "HHmm");

    const onTimeChange = (time, timeString) => {
        onChange?.((time.hour()<<8) + time.minute());
    }

    if (pendingValue!=undefined&&pendingValue!=null) {
        return <TimePicker format={"HH:mm"} 
            value={ot}
            suffixIcon={ <EditOutlined />}
            allowClear={false}
            onChange={onTimeChange}
        />;
    }
    return <TimePicker format={"HH:mm"} 
        value={ot}
        allowClear={false}
        onChange={onTimeChange}
    />;
}

export default HourMinuteEditorForSungery;