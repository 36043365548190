import http from '../Http';
import config from '../Config'
import { useContext, useState, useEffect, useRef } from 'react';
import utils from '../utils';
import { Checkbox, Input, Select, Modal, Table, Button } from 'antd';
//import { makeStyles } from '@material-ui/core/styles';
import { ReactBingmaps } from 'react-bingmaps';
import Modifier from '../DistributorManagement/Modifier';
import Monitor from '../Monitor';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';

// const useStyles = makeStyles({
//     ib: {
//         position: "absolute",
//         width: "200px",
//         //height: "100px",
//         borderRadius: "4px",
//         backgroundColor: "#eee",
//         boxShadow: "10px 10px 10px #888",
//         padding: "5px",
//         display: "flex",
//         flexDirection: "column",
//     },
//     ibTitle: {
//         padding: "5px",
//         fontSize: "20px",
//         color: "darkslateblue",
//         textAlign: "center",
//     },
//     ibSubtitle: {
//         padding: "4px",
//         fontSize: "14px",
//         color: "#777",
//         textAlign: "center",
//     },
//     ibValue: {
//         padding: "4px",
//         fontSize: "18px",
//         color: "black",
//         textAlign: "center",
//     }
// })

function Dashboard(props) {

    const setTip = useContext(utils.tipContext);

    //const classes = useStyles();

    const [shows, setShows] = useState(['Show Distributors']);
    const [summary, setSummary] = useState({});
    const [distributors, setDistributors] = useState(null);
    const [devices, setDevices] = useState(null);
    const [pins, setPins] = useState([]);
    const [infoBox, setInfoBox] = useState([/*{
        location: [31.362448, 117.548881],
        option: {
            title: "Test",
            description: "aaa",
        }
    }*/]);

    const [dvOrDb, setDvOrDb] = useState('Device');
    const [searchDvOptions, setSearchDvOptions] = useState([])
    const [searchDbOptions, setSearchDbOptions] = useState([])
    const [searchDvs, setSearchDvs] = useState([])
    const [searchDbs, setSearchDbs] = useState([])
    const refTimerDvOrDbSearcher = useRef(null);
    const refDvOrDbSearchValue = useRef(null);
    const [center, setCenter] = useState([])

    const [showModifier, setShowModifier] = useState(false);  //点击distributor时显示
    const [distributorInfo, setDistributorInfo] = useState(null)
    const [showDevicesOfDistributor, setShowDevicesOfDistributor] = useState(false)
    const [selectedDistributorName, setSelectedDistributorName] = useState(null)
    const [devicesRows, setDevicesRows] = useState([]);
    const [devicesRowTotal, setDevicesRowTotal] = useState(0);
    const [loading, setLoading] = useState(false);
    const [pageNo, setPageNo] = useState(0);
    const [pageSize, setPageSize] = useState(25);


    useEffect(() => {
        let active = true;

        (async () => {
            let resp = await http.post("dashboard/summary/get", {
                //
            }).then(async (r) => {
                return await r.json();
            }).catch((e) => {
                console.log("[Dashboard]", e);
                return null;
            });

            if (!active) {
                return;
            }

            if (resp === null || resp.code !== 200) {
                setTip({ open: true, severity: "error", msg: (resp && resp.msg) ? resp.msg : "load data error" });

                setSummary({});

                return;
            }
            setSummary(resp.data);
        })();

        return () => {
            active = false;
        };
    }, []);

    useEffect(() => {
        let active = true;

        if (shows.indexOf("Show Distributors") < 0) {
            setDistributors(null);
        }
        if (shows.indexOf("Show Devices") < 0) {
            setDevices(null);
        }

        let typ = "";
        if (shows.length < 1) {
            return;
        }
        if (shows.indexOf("Show Distributors") > -1 && distributors === null) { //no-safe
            typ = "distributors";
        } else if (shows.indexOf("Show Devices") > -1 && devices === null) {
            typ = "devices";
        } else {
            return;
        }

        (async (typ) => {
            let resp = await http.post("dashboard/" + typ + "/get", {
                //
            }).then(async (r) => {
                return await r.json();
            }).catch((e) => {
                console.log("[Dashboard]", e);
                return null;
            });

            if (!active) {
                return;
            }

            if (resp === null || resp.code !== 200) {
                setTip({ open: true, severity: "error", msg: (resp && resp.msg) ? resp.msg : "load data error" });

                if (typ === "distributors") {
                    setDistributors(null);
                } else {
                    setDevices(null);
                }

                return;
            }
            if (typ === "distributors") {
                setDistributors(resp.data);
            } else {
                setDevices(resp.data);
            }
        })(typ);

        return () => {
            active = false;
        };
    }, [shows]);

    useEffect(() => {
        let ps = [];
        if (distributors) {
            ps = distributors.map((v) => {
                //console.log(v)
                return {
                    //location: [(v["Latitude"] || 1) / 1000000, (v["Longitude"] || 1) / 1000000],
                    location: [(v["Latitude"]&&v["Latitude"]!=0 ? v["Latitude"] : 1) / 1000000, (v["Longitude"]&&v["Longitude"]!=0 ? v["Longitude"] : 1) / 1000000],
                    option: {
                        color: "green",
                        icon: "https://api.uhomeenergy.com:7443/images/distributor.png",
                    },
                    "addHandler": {
                        type: "click",
                        callback: () => { handleDistributorPinClick(v) },
                    }
                }
            })
        }
        if (devices) {
            let ps2 = devices.map((v) => {
                let icon = "device_offline.png";
                if (v.Alarming === "1") {
                    icon = "device_warning.png";
                } else if (v.Online === "1") {
                    icon = "device_online.png";
                }
                return {
                    //location: [(v["Latitude"] || 1) / 1000000, (v["Longitude"] || 1) / 1000000],
                    location: [(v["Latitude"]&&v["Latitude"]!=0 ? v["Latitude"] : 1) / 1000000, (v["Longitude"]&&v["Longitude"]!=0 ? v["Longitude"] : 1) / 1000000],
                    option: {
                        color: "green",
                        icon: "https://api.uhomeenergy.com:7443/images/" + icon,
                    },
                    "addHandler": {
                        type: "click",
                        callback: () => { handleDevicePinClick(v) },
                    }
                }
            })
            ps = ps.concat(ps2);
        }
        const timer = setTimeout(() => {
            //console.log("2021-12-18==>", ps)
            setPins(ps);
        }, 1000);
        // setPins(ps);
        return () => {
            clearTimeout(timer);
        }
    }, [distributors, devices]);

    useEffect(() => {
        if(selectedDistributorName == null) {
            return
        }
        (async () => {
            setLoading(true);
            
            let resp = await http.post("device/management/list", {
                PageNo: pageNo,
                PageSize: pageSize,
                DistributorName: selectedDistributorName
            }).then(async (r)=>{
                return await r.json();
            }).catch((e)=>{
                console.log("[DeviceManagement]", e);
                return null;
            });
            
            if (resp === null || resp.code !== 200) {
                setTip({open:true, severity:"error", msg:(resp && resp.msg) ? resp.msg : "load data error"});
                
                setDevicesRowTotal(0)
                setDevicesRows([]);
        
                return;
            }
            setDevicesRowTotal(resp.data.RowTotal);
            setDevicesRows(resp.data.Rows);
            setLoading(false);
            setShowDevicesOfDistributor(true);
        })();
    }, [pageNo, pageSize, selectedDistributorName])

    const handleMapClick = (e) => {
        //console.log(e);

    }

    const handleShowsChange = (vs) => {
        setShows(vs);
    }

    const handleDevicePinClick = (d) => {
        //console.log("DevicePinClick", d);
        setInfoBox([{
            //location: [(d["Latitude"] || 1) / 1000000, (d["Longitude"] || 1) / 1000000],
            location: [(d["Latitude"]&&d["Latitude"]!=0 ? d["Latitude"] : 1) / 1000000, (d["Longitude"]&&d["Longitude"]!=0 ? d["Longitude"] : 1) / 1000000],
            option: {
                title: "Device: " + d.SN,
                description: "Distributor: " + (d.DistributorName || " - "),
                offset: { x: 0, y: 32 },
                actions: [{
                    label: 'Monitor',
                    eventHandler: function () {
                        convertToMonitor(d.ID, d.SN)
                    }
                }],
            }
        }]);
    }

    const convertToMonitor = (id, sn) => {  //点击设备时跳转到Monitor页面
        props.handleConverToMonitor(id, sn);
        props.onNavigate('Monitor', (props) => {
            return <Monitor />
        })
    }

    const handleDistributorPinClick = (d) => {
        //console.log("DistributorPinClick", d);

        const ds = JSON.parse(d.Devices || "{}")
        setInfoBox([{
            //location: [(d["Latitude"] || 1) / 1000000, (d["Longitude"] || 1) / 1000000],
            location: [(d["Latitude"]&&d["Latitude"]!=0 ? d["Latitude"] : 1) / 1000000, (d["Longitude"]&&d["Longitude"]!=0 ? d["Longitude"] : 1) / 1000000],
            option: {
                title: "Dirtributor: " + d.Name,
                description: "Devices: Total:" + (ds["Total"] === null ? " - " : ds["Total"]) +
                    ", Warnings:" + (ds["Alarmings"] === null ? " - " : ds["Alarmings"]) +
                    ", Onlines:" + (ds["Onlines"] === null ? " - " : ds["Onlines"]),
                offset: { x: 0, y: 32 },
                actions: [{
                    label: 'Distributor',
                    eventHandler: function () { 
                        (async () => {
                            let resp = await http.post("distributor/management/list", {
                                PageNo: 0,
                                PageSize: 1,
                                DistributorName: d.Name
                            }).then(async (r)=>{
                                return await r.json();
                            }).catch((e)=>{
                                console.log("[DistributorManagement]", e);
                                return null;
                            });
                            
                            if (resp === null || resp.code !== 200) {
                                setTip({open:true, severity:"error", msg:(resp && resp.msg) ? resp.msg : "load data error"});
                                return;
                            }
                            setDistributorInfo(resp.data.Rows[0])
                            setShowModifier(true)
                        })();
                    }
                }, {
                    label: 'Devices',
                    eventHandler: function () {
                        setSelectedDistributorName(d.Name)
                        setPageNo(0)
                        setPageSize(25)
                    }
                }],
            }
        }]);
    }

    const closeModalWindow = () => {
        setShowDevicesOfDistributor(false)
        setSelectedDistributorName(null)
    }

    const SelectDvOrDb = (value) => {
        setDvOrDb(value)
        if(value == 'Device') {
            setSearchDbOptions([])
        } else {
            setSearchDvOptions([])
        }
    }

    const handleSearchDvOrDb = (v) => {
        if (refTimerDvOrDbSearcher.current != null) {
            clearTimeout(refTimerDvOrDbSearcher.current);
        }
        refDvOrDbSearchValue.current = v;

        const dd = dvOrDb=='Device' ? 'devices' : 'distributors';
        const param = dvOrDb=='Device' ? {SN: v} : {Name: v};

        refTimerDvOrDbSearcher.current = setTimeout(async () => {
            const resp = await http.post("dashboard/" + dd + "/get", param).then(async (r) => {
                return await r.json();
            }).catch((e) => {
                return null;
            });

            if (refDvOrDbSearchValue.current !== v) {
                return;
            }

            //console.log(dvOrDb, ': ', resp.data[0])

            try {
                if (resp.code !== 200) {
                    setTip({ open: true, severity: "error", msg: (resp && resp.msg) ? resp.msg : "search data list error" });
                    return;
                }

                let ls = [];
                if(dvOrDb == 'Device') {
                    setSearchDvs(resp.data);
                    ls = resp.data.map(({ SN, ID }) => {
                        return (
                            <Select.Option key={ID} value={SN}>{SN}</Select.Option>
                        );
                    });
                    setSearchDvOptions(ls);
                } else {
                    setSearchDbs(resp.data);
                    ls = resp.data.map(({ Name, ID }) => {
                        return (
                            <Select.Option key={ID} value={Name}>{Name}</Select.Option>
                        );
                    });
                    setSearchDbOptions(ls);
                }
            } catch (e) {
                console.log("[DeviceManagement]", e);

                setTip({ open: true, severity: "error", msg: "load product list error" });
            }
        }, 300);
    }

    const handleSelectDvOrDb = (v) => {
        let pin = null;
        if(dvOrDb == 'Device') {
            searchDvs.forEach(item => {
                if(item.SN == v) {
                    let icon = "device_offline.png";
                    if (item.Alarming === "1") {
                        icon = "device_warning.png";
                    } else if (item.Online === "1") {
                        icon = "device_online.png";
                    }
                    pin = {
                        location: [(item["Latitude"]&&item["Latitude"]!=0 ? item["Latitude"] : 1) / 1000000, (item["Longitude"]&&item["Longitude"]!=0 ? item["Longitude"] : 1) / 1000000],
                        option: {
                            color: "green",
                            icon: "https://api.uhomeenergy.com:7443/images/" + icon,
                        },
                        "addHandler": {
                            type: "click",
                            callback: () => { handleDevicePinClick(item) },
                        },
                        isSelected: true
                    }
                    return;
                }
            })
        } else {
            searchDbs.forEach(item => {
                if(item.Name == v) {
                    pin = {
                        location: [(item["Latitude"]&&item["Latitude"]!=0 ? item["Latitude"] : 1) / 1000000, (item["Longitude"]&&item["Longitude"]!=0 ? item["Longitude"] : 1) / 1000000],
                        option: {
                            color: "green",
                            icon: "https://api.uhomeenergy.com:7443/images/distributor.png",
                        },
                        "addHandler": {
                            type: "click",
                            callback: () => { handleDistributorPinClick(item) },
                        },
                        isSelected: true
                    }
                    return;
                }
            })
        }
        setPins(prePins => {
            prePins.forEach((item, index) => {
                if(item.isSelected == true) {
                    prePins.splice(index, 1);  //先删除上次选择的pin, 再添加本次选择的pin
                    return
                }
            })
            return prePins.concat(pin);
        })
        setCenter(pin.location)
    }

    const handleModifierClose = () => {
        setShowModifier(false);
    }

    return (
        <div style={{
            flex: "1",
            position: "relative",
        }}>
            <div style={{
                width: "100%",
                height: "12%",
                minHeight: "80px",
                display: "flex",
                justifyContent: "space-around",
                alignItems: "center",
            }}>
                <div style={{display: 'flex', alignItems: 'center'}}>
                    <div style={{fontSize: '18px', padding: '3px 15px 5px 0', borderRight: '3px solid darkslateblue', marginRight: '5px', color: 'darkslateblue'}}>
                        Devices
                    </div>
                    <div>
                       <div>Onlines,Warns,Total</div>
                       <div style={{fontSize: '16px'}}>{(summary.Devices || {}).Onlines || " - "},{(summary.Devices || {}).Warns || " - "},{(summary.Devices || {}).Total || " - "}</div>
                    </div>
                </div>
                <div style={{display: 'flex', alignItems: 'center'}}>
                    <div style={{fontSize: '18px', padding: '3px 15px 5px 0', borderRight: '3px solid red', marginRight: '5px', color: 'red'}}>
                        Warn
                    </div>
                    <div>
                        <div>Todays,This Month,Total</div>
                        <div style={{fontSize: '16px'}}>{(summary.Warns || {}).Todays || " - "},{(summary.Warns || {}).ThisMonth || " - "},{(summary.Warns || {}).Total || " - "}</div>
                    </div>
                </div>
                <div style={{display: 'flex', alignItems: 'center'}}>
                    <div style={{fontSize: '18px', padding: '3px 15px 5px 0', borderRight: '3px solid #e040fb', marginRight: '5px', color: '#e040fb'}}>
                        Fault
                    </div>
                    <div>
                       <div>Todays,This Month,Total</div>
                       <div style={{fontSize: '16px'}}>{(summary.Faults || {}).Todays || " - "},{(summary.Faults || {}).ThisMonth || " - "},{(summary.Faults || {}).Total || " - "}</div>
                    </div>
                </div>
                <div>
                    <Checkbox.Group value={shows} onChange={handleShowsChange}>
                        <Checkbox value="Show Distributors">Show Distributors</Checkbox><br />
                        <Checkbox value="Show Devices">Show Devices</Checkbox>
                    </Checkbox.Group>
                </div>
                <div>
                    <Input.Group compact>
                        <Select defaultValue="Device" onSelect={SelectDvOrDb}>
                            <Select.Option value="Device">Device</Select.Option>
                            <Select.Option value="Distributor">Distributor</Select.Option>
                        </Select>
                        <Select 
                            style={{width: '170px'}}
                            placeholder={dvOrDb == 'Device' ? 'SN' : 'Name'}
                            defaultActiveFirstOption={false}
                            showSearch
                            showArrow={false}
                            filterOption={false}
                            onSearch={handleSearchDvOrDb}
                            onSelect={handleSelectDvOrDb}
                        >{dvOrDb=='Device' ? searchDvOptions : searchDbOptions}</Select>
                    </Input.Group>
                </div>
            </div>
            <div style={{
                width: "100%",
                height: "88%",
                top: "12%",
            }}><ReactBingmaps
                bingmapKey={config.bingMapKey}
                mapTypeId={"road"}
                supportedMapTypes={["road", "canvasDark"]}
                zoom={5}
                disableStreetside={true}
                pushPins={pins}
                infoboxes={infoBox}
                center={center}
                getLocation={{
                    addHandler: "click",
                    callback: handleMapClick,
                }}
            ></ReactBingmaps></div>
            {/* <div className={classes.ib} style={{
                left: "10px",
                top: "10px",
            }}>
                <div className={classes.ibTitle}>Devices</div>
                <div className={classes.ibSubtitle}>Onlines|Warns|Total</div>
                <div className={classes.ibValue}>{(summary.Devices || {}).Onlines || " - "}<label style={{ color: "#aaa" }}>|</label>{(summary.Devices || {}).Warns || " - "}<label style={{ color: "#aaa" }}>|</label>{(summary.Devices || {}).Total || " - "}</div>
            </div>
            <div className={classes.ib} style={{
                left: "230px",
                top: "10px",
            }}>
                <div className={classes.ibTitle} style={{ color: "red", }}>Warn</div>
                <div className={classes.ibSubtitle}>Todays|This Month|Total</div>
                <div className={classes.ibValue}>{(summary.Warns || {}).Todays || " - "}<label style={{ color: "#aaa" }}>|</label>{(summary.Warns || {}).ThisMonth || " - "}<label style={{ color: "#aaa" }}>|</label>{(summary.Warns || {}).Total || " - "}</div>
            </div>
            <div className={classes.ib} style={{
                left: "450px",
                top: "10px",
            }}>
                <div className={classes.ibTitle} style={{ color: "#e040fb" }}>Fault</div>
                <div className={classes.ibSubtitle}>Todays|This Month|Total</div>
                <div className={classes.ibValue}>{(summary.Faults || {}).Todays || " - "}<label style={{ color: "#aaa" }}>|</label>{(summary.Faults || {}).ThisMonth || " - "}<label style={{ color: "#aaa" }}>|</label>{(summary.Faults || {}).Total || " - "}</div>
            </div>

            <div className={classes.ib} style={{
                left: "670px",
                top: "10px",
                flexDirection: "row",
                padding: "10px 20px",
            }}>
                <Checkbox.Group options={["Show Distributors", 'Show Devices']} value={shows} onChange={handleShowsChange} />
            </div> */}

            {/* search device or distributor */}
            {/* <div className={classes.ib} style={{
                left: "890px", //"10px",
                top: "10px", //"200px",
                width: 'auto',
                padding: 0,
                borderRadius: 0
            }}>
                <Input.Group compact>
                    <Select defaultValue="Device" onSelect={SelectDvOrDb}>
                        <Select.Option value="Device">Device</Select.Option>
                        <Select.Option value="Distributor">Distributor</Select.Option>
                    </Select>
                    <Select 
                        style={{width: '170px'}}
                        placeholder={dvOrDb == 'Device' ? 'SN' : 'Name'}
                        defaultActiveFirstOption={false}
                        showSearch
                        showArrow={false}
                        filterOption={false}
                        onSearch={handleSearchDvOrDb}
                        onSelect={handleSelectDvOrDb}
                    >{dvOrDb=='Device' ? searchDvOptions : searchDbOptions}</Select>
                </Input.Group>
            </div> */}

            {/* 点击distributor时显示 */}
            {showModifier===true ? <Modifier data={distributorInfo} show={showModifier} showInfoOnly={true} onClose={handleModifierClose} /> : ""}
            {/* 点击distributor时显示其devices信息 */}
            <Modal 
                title="Devices List"
                visible={showDevicesOfDistributor}
                cancelText="cancel"
                okText="OK"
                mask={true}
                maskClosable={false}
                width={800}
                onCancel={closeModalWindow}
                onOk={closeModalWindow}
            >
                <Table
                    columns={[{
                        title: "",
                        width: 70,
                        fixed: 'left',
                        render: (v, r, idx) => {
                            return (pageNo * pageSize + idx + 1);
                        }
                    }, {
                        title: "SN",
                        dataIndex: "SN",
                        width: 200,
                        fixed: 'left',
                    }, {
                        title: "Product",
                        dataIndex: 'ProductName',
                        width: 200,
                    }, {
                        title: "FirmwareVersion",
                        dataIndex: 'FirmwareVersion',
                        width: 200,
                    }, {
                        title: "Distributor",
                        dataIndex: "DistributorName",
                        width: 200,
                    }, {
                        title: "Users",
                        dataIndex: "Users",
                        width: 200,
                    }, {
                        title: "Inverters",
                        dataIndex: "Inverters",
                        width: 200,
                        render: (v, r, idx) => {
                            return v ? v.replace(/;/g, "; ") : "";
                        }
                    }, {
                        title: "Enabled",
                        dataIndex: "Enabled",
                        width: 80,
                        render: (v, r, idx) => {
                            return v === 1 || v === "1" ? (<CheckOutlined style={{ color: "#5b8c00" }} />)
                                : (<CloseOutlined style={{ color: "#ad2102" }} />)
                        }
                    }, {
                        title: "GPS",
                        width: 200,
                        render: (v, r, idx) => {
                            if (!r["Longitude"] && !r["Latitude"]) {
                                return ""
                            }

                            return `(${r["Longitude"] !== null ? (r["Longitude"] / 1000000).toFixed(6) : "-"}, ${r["Latitude"] !== null ? (r["Latitude"] / 1000000).toFixed(6) : "-"})`
                        }
                    }, {
                        title: "Timezone",
                        dataIndex: "Timezone",
                        width: 120,
                        render: (v, r, idx) => {
                            var n = parseInt(r["GmtOffset"]);
                            var tz = "UTC+";
                            if (n < 0) {
                                tz = "UTC-";
                                n = Math.abs(n);
                            }
                            tz += Math.floor(n / 3600).toString().padStart(2, "0") + ":" + Math.floor((n % 3600) / 60).toString().padStart(2, "0")
                            tz +="("+v+")"
                            return tz;
                        }
                    }, {
                        title: "Create Time",
                        dataIndex: 'CreateTime',
                        width: 150,
                    }, {
                        title: 'Action',
                        key: 'Action',
                        width: 90,
                        render: (v, r, idx) => {
                            return (
                                <Button type="link" onClick={() => {
                                    convertToMonitor(r.ID, r.SN)
                                }}>Monitor</Button>
                            )
                        }
                    }]}
                    pagination={{
                        pageSizeOptions: [25, 50, 100],
                        current: pageNo + 1,
                        pageSize: pageSize,
                        total: devicesRowTotal,
                        showSizeChanger: true,
                        showTotal: (total) => { return `Total ${total}` },
                        onShowSizeChange: (current, size) => {
                            setPageSize(size);
                        },
                        onChange: (page, pageSize) => {
                            setPageNo(page - 1);
                        }
                    }}
                    tableLayout="fixed"
                    bordered={true}
                    size="middle"
                    loading={loading}
                    rowKey="ID"
                    dataSource={devicesRows}
                    scroll={{x: 'max-content', y: 400}}
                />
            </Modal>
        </div>
    )
}

export default Dashboard;